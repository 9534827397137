import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { handleScrollTo } from "../../../helpers"
import useWideScreenDetector from "../../../hooks/useWideScreenDetector"
import useDesktopDestector from "../../../hooks/useDesktopDetector"

interface AnchoredSubNavTypes {
  contactTeam?: string
  showFollower: boolean
  contactEmail?: string

  anchorItems: AnchorItem[]
  followerWidth?: string
  initialFollowerStartPosition?: string
  activeId: string

  isSectorPage?: boolean
  isParentCapabilityPage?: boolean
  isChildCapabilityPage?: boolean
}

export type AnchorItem = {
  id: number
  name: string
  anchor: string
}

const AnchoredSubNav: React.FC<AnchoredSubNavTypes> = (props) => {
  const { showFollower, activeId, anchorItems } = props
  const isDesktop = useDesktopDestector()
  const isWideScreen = useWideScreenDetector()

  const handleActiveState = (name) => {
    if (name === activeId) {
      return "active"
    } else return "disabled"
  }

  const ulModiferClassnames = classnames({
    "no-follower": !showFollower,
  })

  const [followerXpos, setFollowerXpos] = useState(0)

  let tabRefs: NodeListOf<any> = document.querySelectorAll(
    ".anchored-sub-nav__link-item"
  )

  useEffect(() => {
    if (tabRefs !== null) {
      tabRefs = document.querySelectorAll(".anchored-sub-nav__link-item")
      for (let i = 0; i < tabRefs.length; i++) {
        for (let b = 0; b < tabRefs[i].classList.length; b++) {
          if (tabRefs[i].classList[b] === "active") {
            if (tabRefs[i] !== null) {
              const offsetParent = tabRefs[i].offsetParent.offsetLeft
              const offsetLeft = tabRefs[i].offsetLeft
              const clientWidth = tabRefs[i].clientWidth
              setFollowerXpos(offsetParent + offsetLeft + clientWidth)
              window.addEventListener("resize", () => {
                if (tabRefs[i].offsetParent !== null) {
                  setFollowerXpos(offsetLeft + clientWidth)
                }
              })
            }
          }
        }
      }

      return () => {
        window.removeEventListener("resize", () => {
          null
        })
      }
    }
  }, [tabRefs, isDesktop])

  const scrollerRef = React.createRef<HTMLUListElement>()
  const [scrollerWidth, setScrollerWidth] = useState(0)
  useEffect(() => {
    if (!isDesktop) {
      const items = scrollerRef?.current?.children
      if (items) {
        let width = items.length * 35

        for (let i = 0; i < items.length; i++) {
          width += items[i].clientWidth
          if (i === items.length - 1) {
            setScrollerWidth(width)
          }
        }
      }
    }
  }, [scrollerRef, isDesktop])

  return (
    <>
      <section className="anchored-sub-nav">
        {showFollower && (
          <div
            className="anchored-sub-nav__scroll-bar-follower"
            style={{
              left: `calc(-100% + ${followerXpos}px)`,
            }}
          />
        )}
        <nav className="anchored-sub-nav__content">
          {anchorItems !== null && (
            <ul
              className={`anchored-sub-nav__links ${ulModiferClassnames}`}
              style={{
                width: `${!isDesktop ? scrollerWidth + "px" : "auto"}`,
              }}
              ref={scrollerRef}
            >
              {anchorItems.map((item) => {
                const { anchor, name } = item
                return (
                  <>
                    {isWideScreen ? (
                      <li
                        onClick={() => handleScrollTo(anchor, -500)}
                        key={anchor}
                        className={`anchored-sub-nav__link-item ${handleActiveState(
                          anchor
                        )}`}
                      >
                        <a
                          onClick={() => handleActiveState(anchor)}
                          className={`anchored-sub-nav__button ${handleActiveState(
                            anchor
                          )}`}
                        >
                          {name}
                        </a>
                      </li>
                    ) : (
                      <li
                        onClick={() => handleScrollTo(anchor, -100)}
                        key={anchor}
                        className={`anchored-sub-nav__link-item ${handleActiveState(
                          anchor
                        )}`}
                      >
                        <a
                          onClick={() => handleActiveState(anchor)}
                          className={`anchored-sub-nav__button ${handleActiveState(
                            anchor
                          )}`}
                        >
                          {name}
                        </a>
                      </li>
                    )}
                  </>
                )
              })}
            </ul>
          )}
        </nav>
      </section>
    </>
  )
}

export default AnchoredSubNav
