import React from "react"

const JobTable = ({ children }) => {
  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: "60%" }}>Position</th>
          <th style={{ width: "20%" }}>Location</th>
          <th style={{ width: "20%" }}>Advertised</th>
        </tr>
      </thead>
      <tbody id="recent-jobs-content">{children}</tbody>
    </table>
  )
}

export default JobTable
