import React, { useEffect, useState } from "react"

import classnames from "classnames"
import ContentPage from "../../layout/content_page"
import LinkModule from "../../modules/link_module"
import SideNav from "../../modules/side_nav"
import { FeaturedMediaTextTypes } from "../../modules/featured_media_text/types"
import FeaturedMediaText from "../../modules/featured_media_text"
import Spacer from "../../layout/spacer"
import { ParallaxProvider } from "react-scroll-parallax"

import linksContent from "../../__mocks__/links_module.json"
import Wysiwyg from "../../ui/wysiwyg"
import linkData from "./links.json"

import useDesktopDetector from "../../../hooks/useDesktopDetector"

type LinksTypes = {
  name: string
  link: string
  hover_image_url: string
}

interface ContentPageTypes {
  wysiwyg: string
  anchoredSideNav?: boolean
  fullWidthContent?: any
  isTransitionLanding?: boolean
  featuredContent: FeaturedMediaTextTypes[]
  isVisionstream?: boolean
}

const LandingPage: React.FC<ContentPageTypes> = ({
  wysiwyg,
  anchoredSideNav,
  featuredContent,
  isTransitionLanding,
}) => {
  const contentClassnames = classnames({
    "content-page__inner--with-side-bar ": anchoredSideNav,
  })

  const [links, setLinks] = useState<LinksTypes[]>([])
  const [isBroadspectrum, setIsBroadspectrum] = useState(false)
  useEffect(() => {
    if (location.pathname === "/visionstream") {
      setIsBroadspectrum(false)
      setLinks(linkData.visionstream)
    }
    if (location.pathname === "/broadspectrum") {
      setIsBroadspectrum(true)
      setLinks(linkData.broadspectrum)
    }
  }, [])

  const isDesktop = useDesktopDetector()

  return (
    <>
      <ContentPage>
        <section
          className={`content-page__inner content-page__inner--lift-up ${contentClassnames}`}
        >
          <section
            className={`${
              anchoredSideNav
                ? "content-page__with-sidebar"
                : "content-page__full-width-content"
            }`}
          >
            {wysiwyg && <Wysiwyg content={wysiwyg} />}
          </section>
          {anchoredSideNav && isDesktop && (
            <SideNav
              cta={{ isVisible: false }}
              formHtml={null}
              team={null}
              isArticle={false}
              isCampaign={false}
              hasLeadGenForm={false}
              image1x={null}
              image2x={null}
              name={null}
              title={null}
              linkedin={null}
              email={null}
              phone={null}
              hidePhone={false}
              hideEmail={false}
              contactLabel={null}
              relatedCapabilities={[]}
              relatedIndustries={[]}
              relatedServices={[]}
            />
          )}
        </section>
        {!isTransitionLanding && (
          <ParallaxProvider>
            <section className="content-page__full-width-content">
              <Spacer deviceSize="xm" />
              {featuredContent.length >= 1 &&
                featuredContent.map((row: FeaturedMediaTextTypes, i) => {
                  const {
                    images,
                    title,
                    subtitle,
                    cta_path,
                    body_text,
                    cta_text,
                    blockColourOverride,
                    aligned,
                  } = row
                  return (
                    <FeaturedMediaText
                      key={i}
                      title={title}
                      subtitle={subtitle}
                      cta_path={cta_path}
                      cta_text={cta_text}
                      body_text={body_text}
                      images={images}
                      blockColourOverride={blockColourOverride}
                      aligned={aligned}
                    />
                  )
                })}
            </section>
          </ParallaxProvider>
        )}
      </ContentPage>

      {isTransitionLanding && (
        <LinkModule
          title="Quick links"
          links={links}
          image={linksContent[0].hover_image_url}
          isBroadspectrum={isBroadspectrum}
          broadspectrumImage={linkData.broadspectrum[0].hover_image_url}
        />
      )}
    </>
  )
}

export default LandingPage
