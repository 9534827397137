import React, { useEffect, useState } from "react"
import classnames from "classnames"
import Odometer from "react-odometerjs"
import useDesktopDetector from "../../../hooks/useDesktopDetector"
import { DataTypes } from "./types"

const Data: React.FC<DataTypes> = ({
  id,
  column_width,
  is_dollar,
  is_year,
  stat,
  unit,
  overview,
  printOnlyStat,
}) => {
  const [odometerValue, setOdometerValue] = useState(0)
  useEffect(() => {
    setOdometerValue(stat)
  }, [stat])

  const isDesktop = useDesktopDetector()

  const typographyClassnames = classnames("typography__color--green", {
    "typography__data--01":
      column_width === "full_width" ||
      column_width === null ||
      column_width === "half_width" ||
      (column_width === "third_width" && !isDesktop),
    "typography__data--02": column_width === "third_width" && isDesktop,
  })

  return (
    <li
      id={id}
      className={`data-block__column data-block__column--${
        column_width ? column_width : "full_width"
      }`}
    >
      <div id="data-block--not-for-print">
        <div
          className={
            is_dollar ? "data-value data-value--with-dollar" : "data-value"
          }
        >
          <div className="data-number-wrapper">
            {is_dollar && (
              <div className={`data-dollar ${typographyClassnames}`}>$</div>
            )}

            {is_year ? (
              <h2 className={typographyClassnames}>
                <Odometer value={odometerValue} format="(dddd)" />
              </h2>
            ) : (
              <h2 className={typographyClassnames}>
                <Odometer value={odometerValue} format="(,ddd)" />
              </h2>
            )}
          </div>
          <div className="data-unit">
            <h3 className="typography__display--04 typography__color--green">
              {unit}
            </h3>
          </div>
        </div>
        <div className="data-text">
          <p className="typography__body--01 typography__color--navy stats-description">
            {overview}
          </p>
        </div>
      </div>

      <div id="data-block--for-print">
        <div
          className={
            is_dollar ? "data-value data-value--with-dollar" : "data-value"
          }
        >
          <div className="data-number-wrapper">
            {is_dollar && (
              <div
                className={`data-dollar  typography__color--green ${typographyClassnames}`}
              >
                $
              </div>
            )}

            <h2 className="typography__data--01  typography__color--green">
              {printOnlyStat}
            </h2>
          </div>
          <div className="data-unit">
            <h3 className="typography__display--04 typography__color--green">
              {unit}
            </h3>
          </div>
        </div>
        <div className="data-text">
          <p className="typography__body--01 typography__color--navy stats-description">
            {overview}
          </p>
        </div>
      </div>
    </li>
  )
}

export default Data
