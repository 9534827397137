export const configurationNames = {
  SSS: "Single | Single | Single",
  SSG: "Single | Single | Group",
  FL: "Featured (left)",
  FR: "Featured (right)",
  SGS: "Single | Group | Single",
  GGG: "Group | Group | Group",
  GSG: "Group | Single | Group",
  SGG: "Single | Group | Group",
  GSS: "Group | Single | Single",
}
