import React from "react"

const ClearSearch: React.FC = () => {
  return (
    <div className="job-search__clear-wrap">
      <a href="/careers" className="job-search__clear">
        <span>Clear</span>
      </a>
    </div>
  )
}
export default ClearSearch
