import React, { useEffect, useState } from "react"
import { Waypoint } from "react-waypoint"
import AnchoredSubNav from "../../../modules/anchored_sub_nav"
import SideNav from "../../../modules/side_nav"

import ContentPage from "../../../layout/content_page"
import Wysiwyg from "../../../ui/wysiwyg"

// Modules
import Multimedia from "../../../modules/multimedia"
import DataBlock from "../../../modules/data_block"
import RelatedCapabilities from "../../../modules/related_capabilities"
import RelatedNews from "../../../modules/related_news"
import RelatedProjects from "../../../modules/related_projects"
import OurPeople from "../../../modules/our_people"
import VideoImage from "../../../modules/video_image"
import CoreCapabilities from "../../../modules/core_capabilities"
import Spacer from "../../../layout/spacer"
import ContentWithStickyAside from "../../../modules/content_with_sticky_aside"

// Data
import { PageModuleTypes } from "../../../modules/capabilities/types"
import useDesktopDetector from "../../../../hooks/useDesktopDetector"

function wysiwygContent(
  isDesktop: boolean,
  sideNav: () => void,
  overview: string,
  handleWaypoint: (string) => void,
  overviewExist: boolean
): React.ReactElement {
  return (
    <>
      <ContentPage modifierClass="capability">
        {!isDesktop && sideNav()}
        <span id="overview" />
        <Waypoint
          onEnter={() => handleWaypoint("overview")}
          bottomOffset={600}
        />

        <section className="content-page__inner">
          <section className="content-page__with-sidebar">
            {overviewExist && <Wysiwyg content={overview} />}
          </section>
        </section>
      </ContentPage>
    </>
  )
}

const Capability: React.FC<PageModuleTypes> = ({
  wysiwyg,
  featuredProjects,
  dataBlockStats,
  featuredNews,
  relatedCapabilities,
  coreCapabilitiesList,
  downloads,
  videos,
  ourPeople,
  capability,
  leadGen,
  hasLeadGenForm,
  leadGenFormUrl,
  videoImage,
  headingForVideos,
  relatedNewsHeading,
}) => {
  const overview = wysiwyg[0].content
  const [overviewExist, setOverviewExist] = useState(false)
  const [projectsExist, setProjectsExist] = useState(false)
  const [relatedCapabilitiesExist, setRelatedCapabilitiesExist] = useState(
    false
  )
  const [coreCapabilities, setCoreCapabilities] = useState(false)
  const [videosExist, setVideosExist] = useState(false)
  const [downloadsExist, setDownloadsExist] = useState(false)
  const [ourPeopleExist, setOurPeopleExist] = useState(false)
  const [newsExist, setNewsExist] = useState(false)

  useEffect(() => {
    if (wysiwyg) setOverviewExist(true)
    else setOverviewExist(false)

    if (featuredProjects.length >= 1) setProjectsExist(true)
    else setProjectsExist(false)

    if (relatedCapabilities.length >= 1 && coreCapabilitiesList.length >= 1)
      setRelatedCapabilitiesExist(false)
    if (relatedCapabilities.length >= 1 && !coreCapabilitiesList.length)
      setRelatedCapabilitiesExist(true)
    else {
      setRelatedCapabilitiesExist(false)
    }

    if (coreCapabilitiesList.length >= 1) setCoreCapabilities(true)
    else setCoreCapabilities(false)

    if (downloads.length >= 1) setDownloadsExist(true)
    else setDownloadsExist(false)

    if (videos.length >= 1) setVideosExist(true)
    else setVideosExist(false)

    if (ourPeople.length >= 1) setOurPeopleExist(true)
    else setOurPeopleExist(false)

    if (featuredNews.length >= 1) setNewsExist(true)
    else setNewsExist(false)
  }, [
    featuredProjects,
    wysiwyg,
    relatedCapabilities,
    downloads,
    coreCapabilitiesList,
    featuredNews,
  ])

  const anchorItems = [
    {
      id: 0,
      name: "Overview",
      anchor: "overview",
      show: overviewExist,
    },
    {
      id: 1,
      name: "Projects",
      anchor: "projects",
      show: projectsExist,
    },
    {
      id: 2,
      name: "Related Capabilities",
      anchor: "related-capabilities",
      show: relatedCapabilitiesExist,
    },
    {
      id: 3,
      name: "Core Capabilities",
      anchor: "core-capabilities",
      show: coreCapabilities,
    },
    {
      id: 4,
      name: "Resources",
      anchor: "multimedia",
      show: downloadsExist || videosExist,
    },
    {
      id: 5,
      name: "Statistics",
      anchor: "statistics",
      show: !!dataBlockStats.length,
    },
    {
      id: 6,
      name: "Our people",
      anchor: "our-people",
      show: ourPeopleExist,
    },
    {
      id: 7,
      name: "News",
      anchor: "related-news",
      show: newsExist,
    },
  ]

  const filteredItems = anchorItems.filter((i) => i.show)

  const [activeId, setActiveId] = useState("overview")

  const handleWaypointEnter = (id: string) => {
    setActiveId(id)
  }

  const isDesktop = useDesktopDetector()

  const sideNav = () => {
    return (
      <SideNav
        cta={{ isVisible: false }}
        isArticle={false}
        isCampaign={false}
        name={leadGen.name}
        linkedin={leadGen.linkedin}
        title={leadGen.title}
        email={leadGen.email}
        image1x={leadGen.image1x}
        image2x={leadGen.image2x}
        phone={leadGen.phone}
        contactLabel={leadGen.contactLabel}
        hidePhone={leadGen.hidePhone}
        hideEmail={leadGen.hideEmail}
        team={capability}
        hasLeadGenForm={hasLeadGenForm}
        formHtml={leadGenFormUrl}
        relatedCapabilities={[]}
        relatedIndustries={[]}
        relatedServices={[]}
        relatedLocations={[]}
      />
    )
  }

  return (
    <>
      <AnchoredSubNav
        anchorItems={filteredItems}
        showFollower={true}
        activeId={activeId}
      />

      <ContentWithStickyAside
        hasStickySubNav={true}
        content={wysiwygContent(
          isDesktop,
          sideNav,
          overview,
          () => handleWaypointEnter("overview"),
          overviewExist
        )}
        aside={sideNav()}
      />

      {videoImage && (
        <VideoImage
          image_content_type={videoImage.image_content_type}
          video_id={videoImage.video_id}
          images={videoImage.images}
          image_alt_text={videoImage.image_alt_text}
        />
      )}
      <>
        {projectsExist && (
          <ContentPage modifierClass="device-content-padding">
            <div>
              <Waypoint
                onEnter={() => handleWaypointEnter("projects")}
                bottomOffset={600}
              />
              <RelatedProjects name={capability} projects={featuredProjects} />
            </div>
          </ContentPage>
        )}

        {coreCapabilities && (
          <div>
            <Waypoint
              onEnter={() => handleWaypointEnter("core-capabilities")}
              bottomOffset={600}
            />
            <CoreCapabilities links={coreCapabilitiesList} />
          </div>
        )}

        {!coreCapabilities && relatedCapabilitiesExist && (
          <>
            <ContentPage modifierClass="device-content-padding">
              <Waypoint
                onEnter={() => handleWaypointEnter("related-capabilities")}
                bottomOffset={600}
              />
              <RelatedCapabilities relatedCapabilities={relatedCapabilities} />
            </ContentPage>
          </>
        )}

        {(downloads.length > 0 || videos.length > 0) && (
          <>
            <Waypoint
              onEnter={() => handleWaypointEnter("multimedia")}
              bottomOffset={600}
            />
            <Multimedia
              heading={headingForVideos}
              videos={videos}
              downloads={downloads}
            />
          </>
        )}
        {dataBlockStats.length >= 1 && (
          <>
            <Waypoint
              onEnter={() => handleWaypointEnter("statistics")}
              bottomOffset={600}
            />
            <Spacer deviceSize="s" />
            <DataBlock stats={dataBlockStats} />
            <Spacer deviceSize="s" />
          </>
        )}

        {ourPeopleExist && (
          <ContentPage modifierClass="device-content-padding content-page__our-people">
            <Waypoint
              onEnter={() => handleWaypointEnter("our-people")}
              bottomOffset={600}
            />
            <OurPeople name={capability} people={ourPeople} />
          </ContentPage>
        )}
        {newsExist && (
          <div>
            <Waypoint
              onEnter={() => handleWaypointEnter("related-news")}
              bottomOffset={600}
            />
            <RelatedNews
              name={
                relatedNewsHeading ? relatedNewsHeading : `${capability} news`
              }
              articles={featuredNews}
              articlesHeading={""}
              inCampaign={false}
            />
          </div>
        )}
      </>
    </>
  )
}

export default Capability
