/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from "react"
import { isDesktop as isScreenDesktopSize } from "../helpers"

// * Sets state for desktop size screen on window load and window resize

const useDesktopDetector = () => {
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    if (isScreenDesktopSize()) {
      setIsDesktop(true)
    }

    window.addEventListener("resize", () => {
      if (isScreenDesktopSize()) {
        setIsDesktop(true)
      } else {
        setIsDesktop(false)
      }
    })

    return () => window.removeEventListener("resize", () => null)
  }, [isScreenDesktopSize()])

  return isDesktop
}

export default useDesktopDetector
