import React, { useState } from "react"
import Tabs from "./tabs"
import Table from "./table"
import Spacer from "../../layout/spacer"

export interface DocumentLibraryTypes {
  library_module_tabs: TabsTypes[]
}

export type TabsTypes = {
  id: number
  library_module_tables: Tables[]
  title: string
}

type Tables = {
  id: number
  library_module_items: Items[]
  title: string
  name: string
}

export type Items = {
  id: number
  link: string
  subtitle: string
  title: string
  url: string
}
const DocumentLibrary: React.VFC<DocumentLibraryTypes> = ({
  library_module_tabs,
}) => {
  const [activeId, setActiveId] = useState(
    library_module_tabs.length ? library_module_tabs[0].title : ""
  )

  const handleActiveTab = (tab) => {
    setActiveId(tab)
  }
  const activeTable = library_module_tabs.length
    ? library_module_tabs.filter((i) => i.title === activeId)
    : []

  return (
    <section id="document-library" className="device-content-padding">
      {library_module_tabs.length >= 1 && (
        <>
          <Tabs
            showFollower={true}
            tabs={library_module_tabs}
            activeId={activeId}
            handleOnClick={(e) => handleActiveTab(e)}
          />
          <Spacer deviceSize="s" desktopSize="m" />
          <>
            {activeTable[0].library_module_tables.map((table) => {
              return (
                <Table
                  key={table.id}
                  year={activeTable[0].title}
                  caption={table.title}
                  rows={table.library_module_items}
                />
              )
            })}
          </>
        </>
      )}
    </section>
  )
}

export default DocumentLibrary
