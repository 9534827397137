import React, { useEffect, useState } from "react"
import FilterButton from "../../filters/filter_button"
import Filter from "../../filters/filter"
import FilterResultsCount from "../filter_results_count"
import { FiltersInterface } from "./interface"
import { FilterTypes } from "../types"
import { removeLocations } from "./remove_list"

const FilterBar: React.FC<FiltersInterface> = ({
  availableIndustries,
  availableServices,
  availableLocations,
  availableCategories,
  handleApply,
  isLoading,
  children,
  handleClear,
  areFiltersChecked,
  industries,
  services,
  categories,
  locations,
  handleIndustryKeywordClick,
  handleServicesKeywordClick,
  handleCategoriesKeywordClick,
  handleLocationsKeywordClick,
  checkedIndustriesFilters,
  checkedServicesFilters,
  checkedCategoriesFilters,
  checkedLocationsFilters,
  isExpanded,
  results,
}) => {
  let output: FilterTypes[] = []
  const [cleanedCategories, setCleanedCategories] = useState(output)
  useEffect(() => {
    output = categories.filter((cat) => {
      return !removeLocations.includes(cat.name)
    })
    setCleanedCategories(output)
  }, [categories])

  return (
    <div
      className={`filter-bar filter-bar--filter-list-module ${
        isExpanded ? "filter-bar--expanded" : "filter-bar--contracted"
      }`}
    >
      {children}
      <span className="filter-types--title">filter by</span>
      <div
        className={`filter-types ${
          isExpanded ? "filter-types--expand" : "filter-types--contract"
        }`}
      >
        {/* Industries */}
        <div className="filter-list">
          <FilterButton
            title="industries"
            checkedFilters={checkedIndustriesFilters}
          />

          <div
            className={`filters__wrapper ${
              isExpanded ? "filters__wrapper--show" : "filters__wrapper--hide"
            }`}
          >
            <div className="container container--filters_list">
              <div className="filter__list-container">
                <ul>
                  {industries !== null &&
                    industries.map(({ name, id }) => {
                      if (handleIndustryKeywordClick) {
                        let isDisabled = true
                        for (let i = 0; i < availableIndustries.length; i++) {
                          if (availableIndustries[i].name === name) {
                            isDisabled = false
                          }
                        }

                        return (
                          <Filter
                            isDisabled={isDisabled}
                            filterModifierClass="filter-industries"
                            id={`${id}`}
                            key={`industries--${id}`}
                            category="industries"
                            filter={`${name}`}
                            onChange={handleIndustryKeywordClick}
                          />
                        )
                      }
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Services */}
        <div className="filter-list">
          <FilterButton
            title="services"
            checkedFilters={checkedServicesFilters}
          />

          <div
            className={`filters__wrapper ${
              isExpanded ? "filters__wrapper--show" : "filters__wrapper--hide"
            }`}
          >
            <div className="container container--filters_list">
              <div className="filter__list-container">
                <ul>
                  {services !== null &&
                    services.map(({ name, id }) => {
                      if (handleServicesKeywordClick) {
                        let isDisabled = true
                        for (let i = 0; i < availableServices.length; i++) {
                          if (availableServices[i].name === name) {
                            isDisabled = false
                          }
                        }
                        return (
                          <Filter
                            isDisabled={isDisabled}
                            filterModifierClass="filter-services"
                            id={`${id}`}
                            key={`services--${id}`}
                            category="services"
                            filter={`${name}`}
                            onChange={handleServicesKeywordClick}
                          />
                        )
                      }
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Locations */}
        {locations.length >= 1 && (
          <div className="filter-list">
            <FilterButton
              title="locations"
              checkedFilters={checkedLocationsFilters}
            />

            <div
              className={`filters__wrapper ${
                isExpanded ? "filters__wrapper--show" : "filters__wrapper--hide"
              }`}
            >
              <div className="container container--filters_list">
                <div className="filter__list-container">
                  <ul>
                    {locations.length >= 1 &&
                      locations.map(({ name, id }) => {
                        if (handleLocationsKeywordClick) {
                          let isDisabled = true
                          for (let i = 0; i < availableLocations.length; i++) {
                            if (availableLocations[i].name === name) {
                              isDisabled = false
                            }
                          }
                          return (
                            <Filter
                              isDisabled={isDisabled}
                              filterModifierClass="filter-locations"
                              id={`${id}`}
                              key={`locations--${id}`}
                              category="locations"
                              filter={`${name}`}
                              onChange={handleLocationsKeywordClick}
                            />
                          )
                        }
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Categories */}
        {categories.length >= 1 && (
          <div className="filter-list">
            <FilterButton
              title="categories"
              checkedFilters={checkedCategoriesFilters}
            />

            <div
              className={`filters__wrapper ${
                isExpanded ? "filters__wrapper--show" : "filters__wrapper--hide"
              }`}
            >
              <div className="container container--filters_list">
                <div className="filter__list-container">
                  <ul>
                    {categories !== null &&
                      cleanedCategories.map(({ name, id }) => {
                        if (handleCategoriesKeywordClick) {
                          let isDisabled = true
                          for (let i = 0; i < availableCategories.length; i++) {
                            if (availableCategories[i].name === name) {
                              isDisabled = false
                            }
                          }
                          return (
                            <Filter
                              isDisabled={isDisabled}
                              filterModifierClass="filter-categories"
                              id={`${id}`}
                              key={`categories--${name}`}
                              category="categories"
                              filter={`${name}`}
                              onChange={handleCategoriesKeywordClick}
                            />
                          )
                        }
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={`filter-actions ${
            isExpanded ? "filter-actions--border" : "filter-actions--no-border"
          }
      `}
        >
          <button onClick={handleApply} className="filter-action filters-apply">
            Apply
          </button>
          <button
            onClick={handleClear}
            className="filter-action filters-clear"
            disabled={!areFiltersChecked ? true : false}
          >
            Clear
          </button>
        </div>
      </div>

      <FilterResultsCount
        isExpanded={isExpanded}
        isLoading={isLoading}
        handleOnClick={handleApply}
        results={results}
      />
    </div>
  )
}

export default FilterBar
