/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from "react"
import { isWideScreen as isScreenWideScreenSize } from "../helpers"

// * Sets state for desktop size screen on window load and window resize

const useWideScreenDetector = () => {
  const [isWideScreen, setIsWideScreen] = useState(false)

  useEffect(() => {
    if (isScreenWideScreenSize()) {
      setIsWideScreen(true)
    }

    window.addEventListener("resize", () => {
      if (isScreenWideScreenSize()) {
        setIsWideScreen(true)
      } else {
        setIsWideScreen(false)
      }
    })

    return () => window.removeEventListener("resize", () => null)
  }, [isScreenWideScreenSize()])

  return isWideScreen
}

export default useWideScreenDetector
