import React from "react"

interface MobileMenuContactTypes {
  isVisible: boolean
}

const MobileMenuContact: React.FC<MobileMenuContactTypes> = ({ isVisible }) => {
  return (
    <a
      href="/contact"
      className={` cta__mobile-menu--contact ${isVisible ? "show" : "hide"}`}
    >
      <span className="cta__label typography__color--white typography__caption--01 typography__uppercase">
        Contact
      </span>
    </a>
  )
}

export default MobileMenuContact
