import React, { useState } from "react"
import SecondaryCta from "../../cta/secondary_cta"
import Spacer from "../../layout/spacer"
import { placeholderImage } from "../../__mocks__/child_capabilities.json"

interface CoreCapabilitiesTypes {
  links: LinkTypes[]
}
interface LinkTypes {
  slug: string
  name: string
  header_image: any
}

const CoreCapabilities: React.FC<CoreCapabilitiesTypes> = ({ links }) => {
  const [secondaryImage, setSecondaryImage] = useState(placeholderImage)

  const handleMouseEnter = (img) => {
    if (img === null) {
      setSecondaryImage(placeholderImage)
    } else {
      setSecondaryImage(img.recommendation)
    }
  }

  return (
    <section id="core-capabilities" className="core-capabilities">
      <div className="core-capabilities__image-bg">
        <picture className="core-capabilities__picture">
          <img className="content-banner__image" src={secondaryImage} alt="" />
        </picture>
      </div>
      <div className="grid">
        <div className="grid-column">
          <div className="grid grid-direction--row">
            <div className="grid-column">
              <Spacer deviceSize="l" desktopSize="xxl" />
              <h1 className="typography__display--03 typography__color--white">
                Core Capabilities
              </h1>
              <Spacer deviceSize="m" desktopSize="l" />
            </div>
          </div>
          <div className="grid grid-direction--row">
            <div className="grid-column">
              <ul className="grid grid-direction--row core-capabilities__list">
                {links !== null &&
                  links.map(({ name, slug, header_image }, i) => {
                    return (
                      <li
                        onMouseOver={() => handleMouseEnter(header_image)}
                        key={name}
                        className="grid-column core-capabilities__link-items"
                      >
                        <span className="core-capabiltities-cta__wrapper">
                          <a
                            href={`/capabilities/${slug}`}
                            className="core-capabiltities-cta typography__display--05 typography__weight--600  "
                          >
                            {name}
                          </a>
                          <Spacer desktopSize="xs" />
                        </span>
                      </li>
                    )
                  })}
              </ul>

              <Spacer deviceSize="m" desktopSize="xxl" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CoreCapabilities
