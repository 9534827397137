import React from "react"
import classnames from "classnames"

interface PrimaryNavTypes {
  label: string
  modifierClass?: string
  onMouseEnter?: (e) => void
  onMouseLeave?: (e) => void
}

const PrimaryVanityCta: React.FC<PrimaryNavTypes> = ({
  label,
  modifierClass,
  onMouseEnter,
  onMouseLeave,
}) => {
  const modifierClassnames = classnames({
    [`${modifierClass}`]: modifierClass,
  })
  return (
    <div
      className={`primary-cta primary-vanity-cta  ${modifierClassnames}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span className="typography__cta--01 typography__weight--600 typography__uppercase primary-cta__label">
        {label}
      </span>
    </div>
  )
}

export default PrimaryVanityCta
