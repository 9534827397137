import React, { useEffect, useState } from "react"
import classnames from "classnames"
import useWideScreenDetector from "../../../../hooks/useWideScreenDetector"
import useDesktopDestector from "../../../../hooks/useDesktopDetector"
import Spacer from "../../../layout/spacer"

interface AnchoredSubNavTypes {
  showFollower: boolean
  tabs: TabItem[]
  activeId: string
  handleOnClick: (e) => void
}

export type TabItem = {
  title: string
}

const Tabs: React.FC<AnchoredSubNavTypes> = ({
  showFollower,
  activeId,
  tabs,
  handleOnClick,
}) => {
  const isDesktop = useDesktopDestector()
  const isWideScreen = useWideScreenDetector()

  const handleActiveState = (name) => {
    if (name === activeId) {
      return "active"
    } else return "disabled"
  }

  const ulModiferClassnames = classnames({
    "no-follower": !showFollower,
  })

  const [followerXpos, setFollowerXpos] = useState(0)

  let tabRefs: NodeListOf<any> = document.querySelectorAll(
    ".document-library-tabs__link-item"
  )

  useEffect(() => {
    if (tabRefs !== null) {
      tabRefs = document.querySelectorAll(".document-library-tabs__link-item")
      for (let i = 0; i < tabRefs.length; i++) {
        for (let b = 0; b < tabRefs[i].classList.length; b++) {
          if (tabRefs[i].classList[b] === "active") {
            if (tabRefs[i] !== null) {
              const offsetParent = tabRefs[i].offsetParent.offsetLeft
              const offsetLeft = tabRefs[i].offsetLeft
              const clientWidth = tabRefs[i].clientWidth
              setFollowerXpos(offsetParent + offsetLeft + clientWidth)
              window.addEventListener("resize", () => {
                if (tabRefs[i].offsetParent !== null) {
                  setFollowerXpos(offsetLeft + clientWidth)
                }
              })
            }
          }
        }
      }

      return () => {
        window.removeEventListener("resize", () => {
          null
        })
      }
    }
  }, [tabRefs, isDesktop])

  const scrollerRef = React.createRef<HTMLUListElement>()
  const [scrollerWidth, setScrollerWidth] = useState(0)
  useEffect(() => {
    const items = scrollerRef?.current?.children
    if (items) {
      let width = items.length * 35

      for (let i = 0; i < items.length; i++) {
        width += items[i].clientWidth
        if (i === items.length - 1) {
          setScrollerWidth(width)
        }
      }
    }
  }, [scrollerRef])

  return (
    <>
      <section className="document-library-tabs">
        <nav className="document-library-tabs__content">
          {showFollower && (
            <div
              className="document-library-tabs__scroll-bar-follower"
              style={{
                left: `calc(-100% + ${followerXpos}px)`,
              }}
            />
          )}
          {tabs !== null && (
            <ul
              className={`document-library-tabs__links ${ulModiferClassnames}`}
              style={{
                width: `${!isDesktop ? scrollerWidth + "px" : "auto"}`,
              }}
              ref={scrollerRef}
            >
              {tabs.map((item) => {
                const { title } = item
                return (
                  <>
                    {isWideScreen ? (
                      <li
                        onClick={() => handleOnClick(title)}
                        key={title}
                        className={`document-library-tabs__link-item ${handleActiveState(
                          title
                        )}`}
                      >
                        <button
                          className={`document-library-tabs__button typography__display--05 typography__weight--700 ${handleActiveState(
                            title
                          )}`}
                        >
                          {title}
                        </button>
                      </li>
                    ) : (
                      <li
                        onClick={() => handleOnClick(title)}
                        key={title}
                        className={`document-library-tabs__link-item ${handleActiveState(
                          title
                        )}`}
                      >
                        <button
                          className={`document-library-tabs__button typography__display--05 typography__weight--700 ${handleActiveState(
                            title
                          )}`}
                        >
                          {title}
                        </button>
                      </li>
                    )}
                  </>
                )
              })}
            </ul>
          )}
        </nav>
      </section>
    </>
  )
}

export default Tabs
