import React, { useEffect, useState } from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import { useInView } from "react-intersection-observer"

// Components
import AnchoredSubNavCareers from "../../modules/anchored_sub_nav_careers"
import FeaturedVideoText from "../../modules/featured_media_text/video"
import FeaturedMediaText from "../../modules/featured_media_text"
import LinkModule from "../../modules/link_module"
import ContentPage from "../../layout/content_page"
import TwoColumnTextBlock from "../../modules/two_column_text_block"
import Tryptic from "../../modules/tryptic"
import FeaturedTileSlider from "../../modules/featured_tile_slider"
import Wysiwyg from "../../ui/wysiwyg"
import Spacer from "../../layout/spacer"
import Breadcrumbs from "../../ui/breadcrumbs"
import Multimedia from "../../modules/multimedia"
import LinkedInFeed from "../../modules/linkedin_feed"

// Types
import { ContentPageTypes } from "./types"

// Variables
import { ourTeam, latestProjects, industryJobs, whyJoinUs } from "./variables"

import { colors } from "../../../variables"
import linksContent from "../../__mocks__/links_module.json"

// Hooks
import useWideScreenDetector from "../../../hooks/useWideScreenDetector"

const ProgramLandingPage: React.FC<ContentPageTypes> = ({
  pageName,
  wysiwyg,
  featuredTileSliderHeading,
  featuredTileSliderContent,
  featuredVideoTextContent,
  showFeatureTiles,
  campaignTilesHeading,
  campaignTiles,
  benefitsBlockHeader,
  benefitsBlock,
  benefitsBlockCtaTitle,
  benefitsBlockCtaLink,
  trypticContent,
  featuredImageTextBlocks,
  linkModuleContent,
  showBenefitsBlock,
  showFeaturedImageTextBlocks,
  showTrypticContent,
  showFeaturedVideoTextBlock,
  showCampaignTiles,
  linkedInFeed,
}) => {
  // Benefits Block CTA
  const benefistBlockCta = {
    label: benefitsBlockCtaTitle,
    link: benefitsBlockCtaLink,
  }

  // -----------------------------------------------------
  // start: Anchored Sub Items
  // 1. check If modules are in CMS

  const ourTeamExists = featuredVideoTextContent ? true : false
  const latestProjectsExists = showFeatureTiles
  const industryJobsExists = campaignTiles.length >= 1 && showCampaignTiles ? true : false
  const whyJoinUsExists = showBenefitsBlock

  const formatAnchor = (input) => {
    const output = input.toLowerCase().replace(" ", "-")
    return `anchor-${output}`
  }

  const isWideScreen = useWideScreenDetector()
  const [ourTeamRef, ourTeamInView] = useInView({
    threshold: isWideScreen ? 0.5 : 0.3,
  })
  const [latestProjectsRef, latestProjectsInView] = useInView({
    threshold: isWideScreen ? 0.5 : 0.3,
  })
  const [whyJoinUsRef, whyJoinUsInView] = useInView({
    threshold: isWideScreen ? 0.6 : 0.2,
  })
  const [industryJobsRef, industryJobsInView] = useInView({
    threshold: isWideScreen ? 0.7 : 0.3,
  })

  useEffect(() => {
    if (ourTeamInView) {
      setActiveId(formatAnchor(ourTeam.anchor))
    }
    if (latestProjectsInView) {
      setActiveId(formatAnchor(latestProjects.anchor))
    }
    if (whyJoinUsInView) {
      setActiveId(formatAnchor(whyJoinUs.anchor))
    }
  }, [ourTeamInView, latestProjectsInView, whyJoinUsInView])

  const anchorItems = [
    {
      id: 0,
      name: ourTeam.label,
      anchor: formatAnchor(ourTeam.anchor),
      show: ourTeamExists,
    },
    {
      id: 1,
      name: latestProjects.label,
      anchor: formatAnchor(latestProjects.anchor),
      show: latestProjectsExists,
    },
    {
      id: 2,
      name: whyJoinUs.label,
      anchor: formatAnchor(whyJoinUs.anchor),
      show: whyJoinUsExists,
    },
    {
      id: 3,
      name: industryJobs.label,
      anchor: formatAnchor(industryJobs.anchor),
      show: industryJobsExists,
    },
  ]
  const filteredItems = anchorItems.filter((i) => i.show)
  const [activeId, setActiveId] = useState(formatAnchor(ourTeam.anchor))

  const filteredLinkModulesContent = linkModuleContent.filter(
    (i) => i.name !== "Graduate program"
  )

  return (
    <>
      <AnchoredSubNavCareers
        anchorItems={filteredItems}
        showFollower={true}
        activeId={activeId}
      />
      <Breadcrumbs
        isParentPage={true}
        parentPage={{ name: "Careers", slug: "/careers" }}
        currentPage={pageName}
      />
      {wysiwyg && (
        <ContentPage modifierClass="work-with-us">
          <section className="content-page__inner content-page__inner--lift-up">
            <section className="content-page__with-sidebar" id="overview">
              <Wysiwyg content={`${wysiwyg}`} />
            </section>
          </section>
        </ContentPage>
      )}
      {showFeaturedVideoTextBlock && (
        <div id={formatAnchor(ourTeam.anchor)} ref={ourTeamRef}>
          <ContentPage>
            {featuredVideoTextContent && (
              <ParallaxProvider>
                <FeaturedVideoText
                  blockColourOverride={colors.green}
                  aligned="right"
                  content={featuredVideoTextContent}
                  deviceImageOnTop={true}
                />
              </ParallaxProvider>
            )}
          </ContentPage>
        </div>
      )}
      {latestProjectsExists && (
        <div
          className="device-content-padding"
          id={formatAnchor(latestProjects.anchor)}
          ref={latestProjectsRef}
        >
          <FeaturedTileSlider
            heading={`${featuredTileSliderHeading}`}
            content={featuredTileSliderContent}
          />
        </div>
      )}
      <div id={formatAnchor(whyJoinUs.anchor)} ref={whyJoinUsRef}>
        {showBenefitsBlock && (
          <ContentPage>
            <div className="device-content-padding">
              <section>
                <TwoColumnTextBlock
                  id="benefits"
                  header={benefitsBlockHeader}
                  content={benefitsBlock}
                  withCta={true}
                  cta={benefistBlockCta}
                />
              </section>
            </div>
          </ContentPage>
        )}
      </div>
      {showTrypticContent && (
        <div>
          <Spacer deviceSize="xm" />
          <Tryptic content={trypticContent} />
          <Spacer deviceSize="xm" />
        </div>
      )}

      {showFeaturedImageTextBlocks && (
        <>
          {featuredImageTextBlocks.length && (
            <>
              <Spacer deviceSize="xs" deviceOnly />
              {featuredImageTextBlocks.map((props, i) => {
                const index = i + 1
                const aligned = index % 2 ? "right" : "left"

                return (
                  <div key={props.name}>
                    <ContentPage>
                      <ParallaxProvider>
                        <FeaturedMediaText
                          deviceImageOnTop={true}
                          aligned={aligned}
                          {...props}
                          modifierClass="featured-media-text--work-with-us"
                        />
                      </ParallaxProvider>
                    </ContentPage>
                  </div>
                )
              })}
            </>
          )}
        </>
      )}
      {industryJobsExists && (
        <div id={formatAnchor(industryJobs.anchor)} ref={industryJobsRef}>
          <Multimedia
            heading={campaignTilesHeading}
            downloads={[]}
            videos={[]}
            links={campaignTiles}
          />
        </div>
      )}

      <div>
        {filteredLinkModulesContent.length >= 1 && (
          <LinkModule
            modifierClass="link-module__work-with-us"
            title="Discover more"
            links={filteredLinkModulesContent}
            image={filteredLinkModulesContent[0].hover_image_url}
          />
        )}
      </div>

      {/* {linkedInFeed && linkedInFeed.length >= 1 && (
        <LinkedInFeed feed={linkedInFeed} solidBackground={true}/>
      )} */}
    </>
  )
}

export default ProgramLandingPage
