import { ChangeEvent } from "react"
import arrayJoiner from "./arrayJoiner"

let output: string[] = []

// * On evey toggle of a filter item this function adds (checked) or removes (!checked) it from the array (output).

export function activeFilterBuilder(
  e: ChangeEvent | any,
  filterType: string
): string[] {
  if (e !== null) {
    const { checked, value } = e.target as HTMLInputElement
    const id = parseInt(value)
    const filterId = `${filterType}[]=${id}&`

    let initalArray: string[] = []

    if (checked) {
      initalArray = output
      initalArray = [...output, arrayJoiner([id], filterType)]
      output = initalArray
      // console.log("output", output)
      return output
    } else {
      const filteredOutList = output.filter((i) => i !== filterId)
      output = filteredOutList
      // console.log("output", output)
      return output
    }
  } else {
    let initalArray: string[] = []
    initalArray = output
    initalArray = []
    output = initalArray
    // console.log("output", output)
    return output
  }
}

export function activeCapabilitiesFilterBuilder(
  e: ChangeEvent,
  name: string
): string[] {
  const { checked } = e.target as HTMLInputElement

  if (checked) {
    let initalArray = output
    initalArray = [...output, name]

    output = initalArray

    // console.log("output", output)
    return output
  } else {
    const filteredOutList = output.filter((i) => i !== name)
    output = filteredOutList

    console.log({ output })
    return output
  }
}
