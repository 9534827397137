import React from "react"
import ArticleListing from "../../modules/article_listing"
import ContentPage from "../../layout/content_page"
import { FilterTypes } from "../../modules/filters/types"

interface Props {
  industries: FilterTypes[]
  services: FilterTypes[]
  categories: FilterTypes[]
  locations: FilterTypes[]
}

export const perPageLimit = 9
const endpoint = "/api/projects"
const noLimitEndpoint = "/api/no-limit-projects"

const CaseStudies: React.FC<Props> = ({
  industries,
  services,
  categories,
  locations,
}) => {
  return (
    <ContentPage modifierClass="case-studies">
      <section>
        <div className="device-content-padding">
          <ArticleListing
            articleType="CaseStudy"
            currentPath="/projects"
            endpoint={endpoint}
            noLimitEndpoint={noLimitEndpoint}
            industries={industries}
            services={services}
            categories={categories}
            locations={locations}
          />
        </div>
      </section>
    </ContentPage>
  )
}

export default CaseStudies
