import React from "react"
import classnames from "classnames"

interface IconButtonTypes {
  anchorLink?: string
  isAnchorLink?: boolean
  modifierClass?: string
  onClick?: (e) => void
  isToggled?: boolean
}

const IconButton: React.FC<IconButtonTypes> = ({
  anchorLink,
  isAnchorLink,
  modifierClass,
  onClick,
  isToggled,
  children,
}) => {
  const iconClassnames = classnames({
    icon_button: true,
    [`${modifierClass}`]: modifierClass,
    toggled: isToggled,
  })

  if (!children) {
    return <button onClick={onClick} className={iconClassnames} />
  } else {
    return isAnchorLink ? (
      <a href={anchorLink} className={iconClassnames}>
        {children}
      </a>
    ) : (
      <button onClick={onClick} className={iconClassnames}>
        {children}
      </button>
    )
  }
}

export default IconButton
