import React from "react"
import { LinkTypes } from "../../types"

const Link: React.FC<LinkTypes> = ({ slug, name }) => {
  return (
    <a
      href={slug}
      className="cta__link"
      id={name.toLowerCase().replace(/\s/g, "-")}
    >
      {name}
    </a>
  )
}

export default Link
