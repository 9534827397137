import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { Parallax } from "react-scroll-parallax"
import Grid from "../../modules/grid"
import Column from "../../modules/grid/column"
import Row from "../../modules/grid/row"
import Spacer from "../../layout/spacer"
import TertiaryCta from "../../cta/tertiary_cta"
import { minDesktopSize } from "../../../variables"
import classnames from "classnames"
import useComponentInView from "../../../hooks/useComponentInView"
import { renderHtmlString } from "../../../helpers"
import { FeaturedImageTextTypes } from "./types"

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.75,
    },
  },
}

const item = {
  hidden: { opacity: 0, y: "60px" },
  show: { opacity: 1, y: 0 },
}

const animateInUp = {
  hidden: { y: "60px", opacity: 0 },
  visible: { y: 0, opacity: 1 },
}
const animateInAcross = {
  hidden: { opacity: 0, x: "-60px" },
  visible: { opacity: 1, x: 0 },
}

const FeaturedImageText: React.FC<FeaturedImageTextTypes> = ({
  title,
  subtitle,
  cta_path,
  body_text,
  cta_text,
  images,
  blockColourOverride,
  aligned,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
  })

  const containerClasses = classnames("featured-image-text", {
    [`featured-image-text--${aligned}`]: aligned,
    "device-content-padding": true,
  })

  const componentSeen = useComponentInView(inView)

  const [imageYCors, setImageYCords] = useState([12, -50])
  const [desktopContent, setDesktopContent] = useState(true)

  const handleResize = () => {
    if (window.innerWidth < minDesktopSize) {
      setImageYCords([0, 0])
      setDesktopContent(false)
    } else {
      setDesktopContent(true)
      setImageYCords([0, -50])
    }
  }

  useEffect(() => {
    // on first load
    if (window.innerWidth < minDesktopSize) {
      setImageYCords([0, 0])
      setDesktopContent(false)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div ref={ref} className={containerClasses}>
      <article className="featured-image-text__article">
        <Grid modifierClass="featured-image-text__grid">
          <Row columnCount={2}>
            {!desktopContent && (
              <Column>
                <motion.div
                  style={{ width: "100%" }}
                  variants={container}
                  initial="hidden"
                  animate={componentSeen ? "show" : "hide"}
                >
                  <motion.h1
                    className="typography__display--04"
                    variants={item}
                    transition={{
                      default: { duration: 0.3, ease: "easeOut" },
                    }}
                  >
                    {title}
                  </motion.h1>
                  {subtitle !== null && (
                    <>
                      <Spacer deviceSize="n" desktopSize="l" />
                      <motion.h2
                        className="typography__display--06"
                        variants={item}
                        transition={{
                          default: {
                            duration: 0.3,
                            ease: "easeOut",
                            delay: 0.3,
                          },
                        }}
                      >
                        {subtitle}
                      </motion.h2>
                    </>
                  )}
                  <Spacer deviceSize="n" desktopSize="s" />
                </motion.div>
              </Column>
            )}
            <Column>
              {desktopContent ? (
                <motion.div
                  initial="hidden"
                  animate={componentSeen ? "visible" : "hidden"}
                  variants={animateInAcross}
                  transition={{
                    default: {
                      damping: 20,
                      delay: 1.2,
                      duration: 0.6,

                      type: "spring",
                      ease: "easeOut",
                    },
                  }}
                >
                  <Parallax y={imageYCors}>
                    <picture
                      className={`featured-image-text__image-wrapper ${
                        componentSeen ? "slide-in" : "slide-out"
                      }`}
                    >
                      <source
                        srcSet={`${images.medium} 1x, ${images.medium} 2x`}
                      />
                      <img
                        className="featured-image-text__image"
                        src={images.medium}
                        alt=""
                      />
                    </picture>
                  </Parallax>
                </motion.div>
              ) : (
                <motion.picture
                  animate={componentSeen ? "visible" : "hidden"}
                  variants={animateInAcross}
                  transition={{
                    default: {
                      damping: 50,
                      type: "spring",
                      ease: "easeOut",
                      delay: 0.4,
                    },
                  }}
                  className={`featured-image-text__image-wrapper ${
                    componentSeen ? "slide-in" : "slide-out"
                  }`}
                >
                  <source srcSet={`${images.medium} 1x, ${images.medium} 2x`} />
                  <img
                    className="featured-image-text__image"
                    src={images.medium}
                    alt=""
                  />
                </motion.picture>
              )}

              <motion.div
                className="featured-image-text__bg-color"
                animate={componentSeen ? "visible" : "hidden"}
                variants={animateInUp}
                initial="hidden"
                transition={{
                  default: {
                    damping: 12,
                    duration: 0.6,
                    delay: 0.8,
                    type: "spring",
                    ease: "easeOut",
                  },
                }}
                style={{
                  backgroundColor: blockColourOverride
                    ? blockColourOverride
                    : undefined,
                }}
              />
            </Column>
            <Column>
              <motion.div
                style={{ width: "100%" }}
                variants={container}
                initial="hidden"
                animate={componentSeen ? "show" : "hide"}
              >
                {desktopContent && (
                  <>
                    <Spacer deviceSize="s" />
                    <motion.h1
                      className="typography__display--04"
                      variants={item}
                      transition={{
                        default: { duration: 0.6, ease: "easeOut" },
                      }}
                    >
                      {title}
                    </motion.h1>
                    {subtitle ? (
                      <>
                        <Spacer deviceSize="n" desktopSize="m" />
                        <motion.h2
                          className="typography__display--06 featured-image-text__subtitle"
                          variants={item}
                          transition={{
                            default: {
                              duration: 0.6,
                              delay: 0.3,
                              ease: "easeOut",
                            },
                          }}
                        >
                          {subtitle}
                        </motion.h2>
                        <Spacer deviceSize="xs" />
                      </>
                    ) : (
                      <Spacer deviceSize="xm" />
                    )}
                  </>
                )}
                <motion.div
                  className="featured-image-text__body-text"
                  variants={item}
                  transition={{
                    default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
                  }}
                  dangerouslySetInnerHTML={renderHtmlString(body_text)}
                />

                <Spacer deviceSize="m" />
                {cta_text && (
                  <motion.div
                    className="featured-image-text__words"
                    variants={item}
                    transition={{
                      default: { delay: 1.2, duration: 0.6, ease: "easeOut" },
                    }}
                  >
                    <TertiaryCta slug={cta_path} name={cta_text} />
                  </motion.div>
                )}
              </motion.div>
            </Column>
          </Row>
        </Grid>
      </article>
    </div>
  )
}

export default FeaturedImageText
