import React from "react"
import classnames from "classnames"
import Spacer from "../../layout/spacer"
import Hyperlink from "../../cta/hyperlink"
import NewsInsightsTile from "../../modules/tile/news_insights"
import ContentPage from "../../layout/content_page"
import Grid from "../../modules/grid"
import Row from "../../modules/grid/row"
import { renderHtmlString, removePTagFromString } from "../../../helpers"
import { NewsTileTypes } from "../tile/types"

import useDesktopDetector from "../../../hooks/useDesktopDetector"

interface RelatedNews {
  name?: string
  articlesHeading: string
  articles: NewsTileTypes[]
  inCampaign: boolean
  hasBackgroundColor?: boolean
}

const RelatedNews: React.FC<RelatedNews> = ({
  name,
  articlesHeading,
  articles,
  inCampaign,
  hasBackgroundColor,
}) => {
  const modifiers = classnames({
    "related-news__no-bg": !hasBackgroundColor,
    "related-news__in-campaign": inCampaign,
  })
  const isDesktop = useDesktopDetector()

  const readMoreLabel = isDesktop ? "See more news" : "More news"

  const articlesHeadingLength = articlesHeading.slice().length
  const nameLength = name?.slice().length || 0
  return (
    <section
      id="related-news"
      className={`related-news device-content-padding ${modifiers}`}
    >
      <ContentPage>
        {name ? (
          <div className="related-news__top-bar">
            <h1 className="typography__weight--700 typography__display--05">
              {name}
            </h1>
            <Spacer deviceSize="l" desktopSize="s" />
            <div className="horizontal-divider" />

            <div
              className={`${
                nameLength < 20
                  ? "related-news__top-bar--right-cta"
                  : "related-news__top-bar--bottom-cta"
              }`}
            >
              <Hyperlink
                link="/news-and-insights"
                relatedArticlesCta={true}
                label={`${readMoreLabel}`}
              />
            </div>
          </div>
        ) : (
          <div className="related-news__top-bar">
            <>
              {articlesHeading ? (
                <>
                  <h1
                    className="typography__weight--700 typography__display--05"
                    dangerouslySetInnerHTML={renderHtmlString(
                      removePTagFromString(articlesHeading)
                    )}
                  />

                  {articlesHeadingLength < 20 ? (
                    <Spacer deviceSize="s" />
                  ) : (
                    <Spacer deviceSize="l" desktopSize="s" />
                  )}
                  <div className="horizontal-divider" />

                  <div
                    className={`${
                      articlesHeadingLength < 20
                        ? "related-news__top-bar--right-cta"
                        : "related-news__top-bar--bottom-cta"
                    }`}
                  >
                    <Hyperlink
                      relatedArticlesCta={true}
                      link="/news-and-insights"
                      label={`${readMoreLabel}`}
                    />
                  </div>
                </>
              ) : (
                <>
                  <h1 className="typography__weight--700 typography__display--05">
                    Related news
                  </h1>

                  <Spacer deviceSize="s" />
                  <div className="horizontal-divider" />

                  <div className="related-news__top-bar--right-cta">
                    <Hyperlink
                      relatedArticlesCta={true}
                      link="/news-and-insights"
                      label={`${readMoreLabel}`}
                    />
                  </div>
                </>
              )}
            </>
          </div>
        )}
        <Spacer deviceSize="s" />

        {articles.length && (
          <Grid>
            <Row columnCount={3}>
              {articles.map((article: NewsTileTypes) => {
                const {
                  id,
                  category,
                  listing_images,
                  title,
                  content,
                  slug,
                  publish_date,
                  read_in,
                } = article
                return (
                  <NewsInsightsTile
                    key={`news-tile--${id}`}
                    id={id}
                    listing_images={listing_images}
                    title={title}
                    content={content}
                    category={category}
                    slug={slug}
                    publish_date={publish_date}
                    published_industries={[]}
                    published_services={[]}
                    read_in={read_in}
                  />
                )
              })}
            </Row>
          </Grid>
        )}
      </ContentPage>
    </section>
  )
}

export default RelatedNews
