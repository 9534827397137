import React, { useState } from "react"
import { Waypoint } from "react-waypoint"
import ContentPage from "../../layout/content_page"

import Wysiwyg from "../../ui/wysiwyg"
import useDesktopDetector from "../../../hooks/useDesktopDetector"

// Content Modules
import DataBlock from "../../modules/data_block"
import Quote from "../../modules/quote"
import Multimedia from "../../modules/multimedia"
import VideoImage from "../../modules/video_image"
import OurPeople from "../../modules/our_people"
import RelatedNews from "../../modules/related_news"

// UI Modules
import Spacer from "../../layout/spacer"
import AnchoredSubNav from "../../modules/anchored_sub_nav"
import SideNav from "../../modules/side_nav"
import Grid from "../../modules/grid"
import Row from "../../modules/grid/row"
import Column from "../../modules/grid/column"
import FeaturedProjectTile from "../../modules/tile/featured/project"
import ContentWithStickyAside from "../../modules/content_with_sticky_aside"

import { renderHtmlString } from "../../../helpers"

import RelatedCapabilities from "../../modules/related_capabilities"
import { PageModuleTypes } from "./types"

function wysiwygContent(
  isDesktop: boolean,
  sideNav: () => void,
  wysiwyg: string,
  handleWaypoint: (id) => void,
  hasWysiwyg: boolean
): React.ReactElement {
  return (
    <ContentPage modifierClass="case-study">
      {!isDesktop && sideNav()}
      <Waypoint onEnter={() => handleWaypoint("overview")} bottomOffset={600} />
      <section className="content-page__inner content-page__inner--lift-up">
        <section className="content-page__with-sidebar" id="overview">
          {hasWysiwyg && <Wysiwyg content={wysiwyg} />}
        </section>
      </section>
    </ContentPage>
  )
}

const CaseStudy: React.FC<PageModuleTypes> = ({
  videos,
  headingForVideos,
  downloads,
  wysiwyg,
  dataBlockStats,
  relatedIndustries,
  relatedServices,
  relatedCapabilities,
  ourPeople,
  featuredProject,
  featuredNews,
  showStickyNav,
  leadGen,
  hasLeadGenForm,
  leadGenFormUrl,
  videoImage,
  quoteContent,
  caseStudy,
  ourPeopleHeading,
  featuredArticleHeading,
  relatedNewsHeading,
}) => {
  const hasMultimedia = videos.length >= 1 || downloads.length >= 1
  const hasOverview = !!wysiwyg
  const hasStatistics = dataBlockStats.length >= 1 ? true : false
  const hasRelatedCapabilites = relatedCapabilities.length >= 1
  const hasOurPeople = ourPeople.length >= 1
  const hasFeaturedProject = !!featuredProject
  const hasNews = featuredNews.length >= 1

  const isDesktop = useDesktopDetector()
  const anchorItems = [
    {
      id: 0,
      name: "Overview",
      anchor: "overview",
      show: hasOverview,
    },
    {
      id: 1,
      name: "Statistics",
      anchor: "statistics",
      show: hasStatistics,
    },
    {
      id: 2,
      name: "Related Capabilities",
      anchor: "related-capabilities",
      show: hasRelatedCapabilites,
    },
    {
      id: 3,
      name: "Resources",
      anchor: "multimedia",
      show: hasMultimedia,
    },
    {
      id: 4,
      name: "Our people",
      anchor: "our-people",
      show: hasOurPeople,
    },
    {
      id: 5,
      name: "Featured Project",
      anchor: "featured-project",
      show: hasFeaturedProject,
    },
    {
      id: 6,
      name: "News",
      anchor: "related-news",
      show: hasNews,
    },
  ]

  const filteredItems = anchorItems.filter((i) => i.show)
  const [activeId, setActiveId] = useState("overview")
  const sideNav = () => {
    return (
      <SideNav
        cta={{ isVisible: false }}
        isCampaign={false}
        isArticle={true}
        name={leadGen.name}
        linkedin={leadGen.linkedin}
        title={leadGen.title}
        email={leadGen.email}
        image1x={leadGen.image1x}
        image2x={leadGen.image2x}
        phone={leadGen.phone}
        contactLabel={leadGen.contactLabel}
        hidePhone={leadGen.hidePhone}
        hideEmail={leadGen.hideEmail}
        team={null}
        hasLeadGenForm={hasLeadGenForm}
        formHtml={leadGenFormUrl}
        relatedCapabilities={relatedCapabilities}
        relatedIndustries={relatedIndustries}
        relatedServices={relatedServices}
        relatedLocations={[]}
      />
    )
  }
  return (
    <>
      {showStickyNav && (
        <AnchoredSubNav
          anchorItems={filteredItems}
          showFollower={true}
          activeId={activeId}
        />
      )}
      <ContentWithStickyAside
        hasStickySubNav={showStickyNav}
        content={wysiwygContent(
          isDesktop,
          sideNav,
          wysiwyg[0].content,
          () => setActiveId("overview"),
          wysiwyg.length >= 1
        )}
        aside={sideNav()}
      />

      {(hasMultimedia || downloads.length >= 1) && (
        <div>
          <Waypoint
            onEnter={() => setActiveId("multimedia")}
            bottomOffset={600}
          />
          <Multimedia
            videos={videos}
            downloads={downloads}
            heading={headingForVideos}
          />
        </div>
      )}
      {videoImage && (
        <VideoImage
          image_content_type={videoImage.image_content_type}
          video_id={videoImage.video_id}
          images={videoImage.images}
          image_alt_text={videoImage.image_alt_text}
        />
      )}
      {dataBlockStats.length >= 1 && (
        <div>
          <Waypoint
            onEnter={() => setActiveId("statistics")}
            bottomOffset={600}
          />
          <Spacer deviceSize="s" hideForPrint={true} />
          <DataBlock stats={dataBlockStats} />
        </div>
      )}
      {quoteContent && (
        <div style={{ position: "relative", overflowX: "hidden" }}>
          <Quote
            company={quoteContent.company}
            author={quoteContent.author}
            text={quoteContent.text}
          />
        </div>
      )}

      {/* todo: anchor */}
      {hasRelatedCapabilites && (
        <ContentPage modifierClass="device-content-padding print-only---page-break">
          <Waypoint
            onEnter={() => setActiveId("related-capabilities")}
            bottomOffset={600}
          />
          <RelatedCapabilities relatedCapabilities={relatedCapabilities} />
        </ContentPage>
      )}

      {hasOurPeople && (
        <ContentPage modifierClass="device-content-padding content-page__our-people">
          <Waypoint
            onEnter={() => setActiveId("our-people")}
            bottomOffset={600}
          />
          <OurPeople
            name={caseStudy}
            people={ourPeople}
            blockHeading={ourPeopleHeading}
          />
        </ContentPage>
      )}
      {featuredProject && (
        <ContentPage modifierClass="device-content-padding print-only---page-break">
          <Waypoint
            onEnter={() => setActiveId("featured-project")}
            bottomOffset={600}
          />
          <span id="featured-project">
            <Grid>
              <Row columnCount={1}>
                <Column>
                  {featuredArticleHeading ? (
                    <>
                      <h1
                        className="typography__weight--700 related-news__article-heading typography__display--05"
                        dangerouslySetInnerHTML={renderHtmlString(
                          featuredArticleHeading
                        )}
                      />
                      <Spacer deviceSize="s" />
                      <div className="horizontal-divider" />
                      <Spacer deviceSize="s" />
                    </>
                  ) : (
                    <Spacer deviceSize="xxs" deviceOnly={true} />
                  )}
                  <FeaturedProjectTile
                    id={featuredProject.id}
                    listing_image={featuredProject.listing_images.large}
                    name={featuredProject.name}
                    teaser={featuredProject.teaser}
                    slug={featuredProject.slug}
                    client_name={featuredProject.client_name}
                    leftAlignedFeature={true}
                  />
                </Column>
              </Row>
            </Grid>
          </span>
        </ContentPage>
      )}
      {featuredNews.length >= 1 && (
        <div>
          <Waypoint
            onEnter={() => setActiveId("news")}
            onLeave={() => setActiveId("featured-project")}
            bottomOffset={600}
          />
          <RelatedNews
            articles={featuredNews}
            articlesHeading={relatedNewsHeading ? relatedNewsHeading : ""}
            inCampaign={false}
          />
        </div>
      )}
    </>
  )
}

export default CaseStudy
