import React from "react"
import Slider from "react-slick"

import classnames from "classnames"
import Spacer from "../../layout/spacer"
import { QuoteTypes } from "./types"

// these Types are weird...
interface QuoteBlockTypes {
  quotes?: QuoteTypes[]
  author?: string
  text?: string
  theme?: string
  company?: string
}

const QuoteBlock: React.FC<QuoteBlockTypes> = ({
  quotes,
  author,
  company,
  text,
  theme = "grey",
}) => {
  const themeClassnames = classnames({
    white: theme === "white",
    grey: theme === "grey",
  })

  const quoteSliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 5000,
  }

  return (
    <div className="quote-block--wrapper">
      {quotes && quotes.length >= 1 ? (
        <Slider {...quoteSliderSettings}>
          {quotes.map((quote, i) => {
            return (
              <section key={i} className={`quote-block ${themeClassnames}`}>
                <Spacer deviceSize="s" desktopSize="xm" />
                <div className={`quote-block__content ${themeClassnames}`}>
                  <blockquote className="quote-block__blockquote">
                    <p>{quote.text}</p>
                  </blockquote>
                </div>
                <Spacer deviceSize="xs" />
                <h1 className="typography__caption--01 typography__align--center typography__color--green">
                  {quote.author} - {quote.company}
                </h1>

                <Spacer deviceSize="s" desktopSize="xm" />
              </section>
            )
          })}
        </Slider>
      ) : (
        <section className={`quote-block ${themeClassnames}`}>
          <Spacer deviceSize="s" desktopSize="xm" />
          <div className={`quote-block__content ${themeClassnames}`}>
            <blockquote className="quote-block__blockquote">
              <p>{text}</p>
            </blockquote>
          </div>
          <Spacer deviceSize="xs" />
          <h1 className="typography__caption--01 typography__align--center typography__color--green">
            {author} - {company}
          </h1>

          <Spacer deviceSize="s" desktopSize="xm" />
        </section>
      )}
    </div>
  )
}

export default QuoteBlock
