import React, { useEffect, useState } from "react"

export interface VideoTileTypes {
  video_url: string
}

const VideoTile: React.FC<VideoTileTypes> = ({
  video_url,
}) => {
  const [videoThumbnail, setVideoThumbnail] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    if (video_url) {
      fetch(`https://vimeo.com/api/v2/video/${video_url}.json`)
        .then((res) => res.json())
        .then((data) => {
          setVideoThumbnail(data[0].thumbnail_large)
        })
        .catch((err) => console.error(err))
    }
  }, [videoThumbnail])

  return (
    <>
      <div
        className={`video-title-copy__video-wrapper`}
      >
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={`https://player.vimeo.com/video/${video_url}`}
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </>
  )
}

export default VideoTile
