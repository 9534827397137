import React from "react"
import classnames from "classnames"

interface GridTypes {
  children: any
  modifierClass?: string
}

const Grid: React.FC<GridTypes> = ({ modifierClass, children }) => {
  const gridClassnames = classnames({
    [`${modifierClass}`]: modifierClass,
  })
  return <section className={`grid ${gridClassnames}`}>{children}</section>
}

export default Grid
