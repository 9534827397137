import React, { useEffect, useState } from "react"
import { Dialog } from "@reach/dialog"
import TextInputSearch from "../../../ui/forms/text_input_search"
import TabbedContent from "../../../modules/tabbed_content"
import LetterRange from "./letter_range"
import FilterBar from "./filter_bar"
import Toggler from "../../filters/toggler"
import useDesktopDetector from "../../../../hooks/useDesktopDetector"
import IconClose from "../../../../images/svgs/icon_close"
import { colors } from "../../../../variables"
import DeviceFilterBar from "../../filters/device_filter_bar"
import { FilterTypes } from "../../../modules/filters/types"
import { CapabilityListTypes } from "../types"

// Helpers
import { assignLetter } from "./helpers"
import { activeCapabilitiesFilterBuilder } from "../../article_listing/activeFilterBuilder"
import { getFilterCount } from "../../article_listing/getFilterCount"

interface FilterListTypes {
  showCategories: boolean
  list: CapabilityListTypes[]
  heading: string
  parentPath: string
  industries: FilterTypes[]
  services: FilterTypes[]
}

const FilterListModule: React.FC<FilterListTypes> = ({
  list,
  parentPath,
  industries,
  services,
}) => {
  const isDesktop = useDesktopDetector()
  const [filterTerm, setFilterTerm] = useState("")
  const [defaultList, setDefaultList] = useState<CapabilityListTypes[]>([])
  const [filteredList, setFilteredList] = useState<any[]>(defaultList)
  const [capabilitiesLoading, setCapabilitiesLoading] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [checkedIndustriesFilters, setIndustriesCheckedFilters] = useState([])
  const [checkedServicesFilters, setServicesCheckedFilters] = useState([])

  // ***************************************
  // * Filter Logic
  // ***************************************

  const [isToggling, setIsToggling] = useState(false)

  const industriesCheckboxes = document.querySelectorAll(
    "input[type=checkbox].filter-industries"
  ) as NodeListOf<HTMLInputElement>

  const servicesCheckboxes = document.querySelectorAll(
    "input[type=checkbox].filter-services"
  ) as NodeListOf<HTMLInputElement>

  // State for each 'letter range' of results
  const [abcdList, setAbcdList] = useState<FilterTypes[]>([])
  const [efghList, setEfghList] = useState<FilterTypes[]>([])
  const [ijklList, setIjklList] = useState<FilterTypes[]>([])
  const [mnopList, setMnopList] = useState<FilterTypes[]>([])
  const [qrstList, setQrstList] = useState<FilterTypes[]>([])
  const [uvwxyList, setUvxyList] = useState<FilterTypes[]>([])

  useEffect(() => {
    setAbcdList(assignLetter(defaultList, 4, ["A", "B", "C", "D"]))
    setEfghList(assignLetter(defaultList, 4, ["E", "F", "G", "H"]))
    setIjklList(assignLetter(defaultList, 4, ["I", "J", "K", "L"]))
    setMnopList(assignLetter(defaultList, 4, ["M", "N", "O", "P"]))
    setQrstList(assignLetter(defaultList, 4, ["Q", "R", "S", "T"]))
    setUvxyList(assignLetter(defaultList, 5, ["U", "V", "X", "Y", "Z"]))

    if (list.length) {
      setDefaultList(list)
    }
  }, [list, filterTerm, defaultList])

  // **********
  // * Handlers
  // **********
  const handleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const handleClearFilters = () => {
    setIsToggling(false)
    setDisableAllTabs(false)
    const checkboxes = document.querySelectorAll(
      "input[type=checkbox]"
    ) as NodeListOf<HTMLInputElement>
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false
    }

    setFilteredList([])
    setDefaultList(list)
    setIsExpanded(false)
    setServicesCheckedFilters([])
    setIndustriesCheckedFilters([])
  }

  const [disableAllTabs, setDisableAllTabs] = useState(false)

  const handleFiltering = (e) => {
    setDisableAllTabs(true)
    const { value } = e.target
    setFilterTerm(value.toLowerCase())

    let updatedList = defaultList
    updatedList = updatedList.filter(
      ({ name }) =>
        name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
    )

    if (updatedList.length === defaultList.length) setFilteredList([])
    else {
      setFilteredList(updatedList)
    }

    if (e.target.value === "") setDisableAllTabs(false)
  }

  const handleDisableList = (list) => {
    if (!list.length) return true
    else return false
  }

  const handleSlug = (slug) => {
    if (parentPath === null) return `/${slug}`
    else return `${parentPath}/${slug}`
  }

  const content = [
    {
      id: 0,
      tab: {
        label: "ABCD",
      },
      isDisabled: handleDisableList(abcdList),
      component: (
        <LetterRange
          defaultList={abcdList}
          filteredList={filteredList}
          handleSlug={handleSlug}
        />
      ),
      count: 0,
      filteredComponent: (
        <LetterRange
          defaultList={isToggling ? [] : defaultList}
          filteredList={isToggling ? [] : filteredList}
          handleSlug={handleSlug}
        />
      ),
    },
    {
      id: 1,
      tab: {
        label: "EFGH",
      },
      isDisabled: handleDisableList(efghList),
      component: (
        <LetterRange
          defaultList={efghList}
          filteredList={filteredList}
          handleSlug={handleSlug}
        />
      ),
      count: 0,
      filteredComponent: null,
    },
    {
      id: 2,
      tab: {
        label: "IJKL",
      },
      isDisabled: handleDisableList(ijklList),
      component: (
        <LetterRange
          defaultList={ijklList}
          filteredList={filteredList}
          handleSlug={handleSlug}
        />
      ),
      count: 0,
      filteredComponent: null,
    },
    {
      id: 3,
      tab: {
        label: "MNOP",
      },
      isDisabled: handleDisableList(mnopList),
      component: (
        <LetterRange
          defaultList={mnopList}
          filteredList={filteredList}
          handleSlug={handleSlug}
        />
      ),
      count: 0,
      filteredComponent: null,
    },
    {
      id: 4,
      tab: {
        label: "QRST",
      },
      isDisabled: handleDisableList(qrstList),
      component: (
        <LetterRange
          defaultList={qrstList}
          filteredList={filteredList}
          handleSlug={handleSlug}
        />
      ),
      count: 0,
      filteredComponent: null,
    },
    {
      id: 5,
      tab: {
        label: "UVWXZ",
      },
      isDisabled: handleDisableList(uvwxyList),
      component: (
        <LetterRange
          defaultList={uvwxyList}
          filteredList={filteredList}
          handleSlug={handleSlug}
        />
      ),
      count: 0,
      filteredComponent: null,
    },
  ]

  useEffect(() => {
    if (!checkedIndustriesFilters.length && !checkedServicesFilters.length) {
      setDisableAllTabs(false)
    }
  }, [checkedIndustriesFilters, checkedServicesFilters])

  const [activeFilters, setActiveFilters] = useState<string[]>([])

  const filteredResults: any[] = []
  useEffect(() => {
    setCapabilitiesLoading(true)
    defaultList.forEach((list) => {
      list.industries.forEach((i) => {
        activeFilters.forEach((a) => {
          if (a === i.name) {
            filteredResults.push(list)
          }
        })
      })
      list.services.forEach((i) => {
        activeFilters.forEach((a) => {
          if (a === i.name) {
            filteredResults.push(list)
          }
        })
      })
    })

    if (filteredResults.length) {
      setDisableAllTabs(true)
      const filteredArr = filteredResults.reduce((acc, current) => {
        const x = acc.find((item) => item.name === current.name)
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])

      setFilteredList(filteredArr)
    } else {
      setFilteredList(abcdList)
      setDisableAllTabs(false)
    }

    setCapabilitiesLoading(false)
  }, [activeFilters])

  const filterBar = () => {
    return (
      <FilterBar
        areFiltersChecked={true}
        handleApply={handleExpand}
        isLoading={capabilitiesLoading}
        handleClear={handleClearFilters}
        industries={industries}
        services={services}
        isExpanded={isExpanded}
        checkedIndustriesFilters={getFilterCount(industriesCheckboxes)}
        checkedServicesFilters={getFilterCount(servicesCheckboxes)}
        handleIndustryKeywordClick={(e, n) =>
          setActiveFilters(activeCapabilitiesFilterBuilder(e, n))
        }
        handleServicesKeywordClick={(e, n) =>
          setActiveFilters(activeCapabilitiesFilterBuilder(e, n))
        }
        results={filteredList.length ? filteredList.length : defaultList.length}
      >
        {isDesktop ? (
          <Toggler
            isNewsProjects={false}
            handleOnClick={handleExpand}
            isToggled={isExpanded}
          />
        ) : (
          <button className="close-button" onClick={handleExpand}>
            <IconClose color={colors.green} />
          </button>
        )}
      </FilterBar>
    )
  }

  return (
    <>
      {isDesktop ? (
        filterBar()
      ) : (
        <>
          <DeviceFilterBar
            totalFiltersActive={
              checkedIndustriesFilters.length + checkedServicesFilters.length
            }
            handleClearFilters={handleClearFilters}
            handleExpand={handleExpand}
            areFiltersChecked={true}
          />
          <Dialog isOpen={isExpanded} onDismiss={handleExpand}>
            {filterBar()}
          </Dialog>
        </>
      )}
      <TextInputSearch
        modiferClass="filter-list-module"
        searchTerm={filterTerm}
        handleOnChange={handleFiltering}
        placeholder="Search with keywords"
        submit={false}
      />

      <TabbedContent
        disablingAllTabs={disableAllTabs}
        content={content}
        isFilterList={true}
        modifierClass="filter-page"
        isContentLoading={false}
        isNewsInsightsTab={false}
      />
    </>
  )
}

export default FilterListModule
