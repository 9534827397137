import React from "react"

const IconScrollDownPrompt = ({ color = "#ffffff" }) => (
  <svg
    width="8"
    height="7"
    viewBox="0 0 8 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      d="M2.78137 4.82564C2.69724 4.74106 2.62313 4.64698 2.56055 4.54492L3.66798 6.46726C3.76928 6.64335 3.93368 6.64335 4.03498 6.46726L5.14241 4.54492C5.07983 4.64698 5.00571 4.74132 4.92159 4.82564C4.64794 5.09993 4.26939 5.26985 3.85161 5.26985C3.43356 5.26985 3.05502 5.09993 2.78137 4.82564Z"
    />
    <path
      fill={color}
      fillOpacity="0.8"
      d="M2.78107 4.82454C3.05472 5.09883 3.43327 5.26875 3.85131 5.26875C4.26909 5.26875 4.64764 5.09883 4.92129 4.82454C5.00541 4.74022 5.07953 4.64588 5.14211 4.54382L5.18366 4.47185C5.29881 4.25745 5.36447 4.01221 5.36447 3.7518C5.36447 2.91943 4.83358 1.98345 3.82797 1.28809C2.83621 1.98345 2.33789 2.91943 2.33789 3.7518C2.33789 4.01221 2.40355 4.25771 2.51896 4.4721L2.56025 4.54382C2.62283 4.64588 2.69695 4.73997 2.78107 4.82454Z"
    />
    <path
      fill={color}
      fillOpacity="0.4"
      d="M0.451136 0.253288C0.248783 0.244804 0.166713 0.388247 0.268018 0.56408L2.51878 4.47174C2.40337 4.2576 2.33771 4.0121 2.33771 3.7517C2.33771 2.91906 2.83578 1.98308 3.82805 1.28772C3.00761 0.720376 1.87197 0.313441 0.451136 0.253288Z"
    />
    <path
      fill={color}
      fillOpacity="0.4"
      d="M5.1816 4.47221L7.43237 0.564553C7.53367 0.38872 7.45135 0.245277 7.24874 0.253246C5.77943 0.311857 4.63764 0.719564 3.82617 1.28845C4.83153 1.98381 5.36267 2.91953 5.36267 3.75217C5.36267 4.01283 5.29702 4.25782 5.1816 4.47221Z"
    />
  </svg>
)

export default IconScrollDownPrompt
