import { useEffect, useState } from "react"

const useComponentVisible = (inView = false) => {
  const [componentVisible, setComponentVisible] = useState(false)

  useEffect(() => {
    if (inView) {
      setComponentVisible(true)
    }
  }, [inView])

  return componentVisible
}

export default useComponentVisible
