import React from "react"
import { renderHtmlString } from "../../../../helpers"
import { truncateTitleText, truncateBodyText } from "../helpers"
import Column from "../../../modules/grid/column"
import Spacer from "../../../layout/spacer"
import classnames from "classnames"
import DownloadCta from "../../../cta/download"
import { DownloadTileTypes } from "../types"
import useDesktopDetector from "../../../../hooks/useDesktopDetector"

const DownloadTile: React.FC<DownloadTileTypes> = ({
  id,
  file_content_type,
  image_file_name,
  images,
  summary,
  url,
  name,
}) => {
  const isDesktop = useDesktopDetector()

  const h1Classnames = classnames({
    "typography__display--06": isDesktop,
    "typography__body--01-900": !isDesktop,
  })

  const file_content_typeClassnames = classnames({
    "tile__image-format--icon-jpeg": file_content_type === "image/jpeg",
    "tile__image-format--icon-gif": file_content_type === "image/gif",
    "tile__image-format--icon-png": file_content_type === "image/png",
    "tile__image-format--icon-doc": file_content_type === "application/doc",
    "tile__image-format--icon-pdf": file_content_type === "application/pdf",
    "tile__image-format--icon-csv":
      file_content_type === "application/csv" ||
      file_content_type === "application/xml",
  })

  return (
    <Column>
      <article
        className="tile tile__multimedia tile__download tile__single"
        id={`${id}`}
      >
        <a href={`${url}`} download>
          <div className="tile__image-cell">
            {images !== null &&
              (image_file_name !== null ? (
                <picture className="tile__image-wrapper">
                  <img
                    className="tile__image"
                    src={images.desktop}
                    alt={name}
                  />
                </picture>
              ) : (
                <div className="tile__image-wrapper tile__image-format ">
                  <span
                    className={`tile__image-format--icon ${file_content_typeClassnames}`}
                  />
                </div>
              ))}
          </div>

          <div className="tile__content-cell">
            <div className="tile__info--backslash" />
            <h1
              className={`${h1Classnames} typography__weight--700  typography__color--navy`}
            >
              {truncateTitleText(name, isDesktop)}
            </h1>
            <Spacer deviceSize="xs" />
            {summary && (
              <div
                className="tile__overview typography__color--mid-grey"
                dangerouslySetInnerHTML={renderHtmlString(
                  truncateBodyText(name, summary, isDesktop)
                )}
              />
            )}
          </div>
          <DownloadCta link={url} />
        </a>
      </article>
      <Spacer deviceSize="xxs" />
    </Column>
  )
}

export default DownloadTile
