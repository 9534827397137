import React from "react";
import PropTypes from "prop-types";

const IconCtaArrow = ({ color, modifierClass }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="15"
    viewBox="0 0 26 15"
    preserveAspectRatio="xMinYMax"
    className={`svg_icon svg_cta_arrow ${modifierClass}`}
  >
    <path
      fill={color}
      d="M0 7h24.3L18 .8a.5.5 0 0 1 .4-.8.5.5 0 0 1 .4 0l7 7a.5.5 0 0 1 .2.3.4.4 0 0 1 0 .2.5.5 0 0 1 0 .2l-7.2 7a.5.5 0 0 1-.8-.2.5.5 0 0 1 0-.4l6.3-6H0a4.5 4.5 0 0 1 0-1z"
    />
  </svg>
);

IconCtaArrow.propTypes = {
  color: PropTypes.string.isRequired,
  modifierClass: PropTypes.string,
};

IconCtaArrow.defaultProps = {
  color: `#000`,
};

export default IconCtaArrow;
