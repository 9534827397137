import React, { useState } from "react"
import queryString from "query-string"
import SearchFilters from "./search-filters"
import { CareerSearchTypes, FilterStateTypes } from "./types"

const defaultFilters: FilterStateTypes = {
  "location[]": [],
  "work_type[]": [],
  "category[]": [],
  keywords: "",
}

const CareerSearch: React.FC<CareerSearchTypes> = ({
  locations,
  work_types,
  categories,
}) => {
  const searchQuery = queryString.parse(location.search)
  const baseFiltersState = { ...defaultFilters }
  // marry the default filters object with existing
  // filters in query string so we can have the state
  // exist on pageload based on the query string e.g.
  // someone gets linked with a filter applied.
  Object.keys(searchQuery).map((key) => {
    baseFiltersState[key] = searchQuery[key]
  })

  const [activeFilters, setActiveFilters] = useState<FilterStateTypes>(
    baseFiltersState
  )

  return (
    <>
      <div id="jobs-form" className="job-search__filter-wrapper">
        <SearchFilters
          locations={locations}
          work_types={work_types}
          categories={categories}
          setActiveFilters={setActiveFilters}
          activeFilters={activeFilters}
        />
      </div>
    </>
  )
}
export default CareerSearch
