import React, { useEffect, useState } from "react"
import Grid from "../../modules/grid"
import Tab from "../../ui/tabs/tab"
import Spacer from "../../layout/spacer"
import TabRow from "../../ui/tabs/row"
import ContentPage from "../../layout/content_page"

interface TabbedContentTypes {
  disablingAllTabs: boolean
  isFilterList: boolean
  content: Content[]
  isContentLoading: boolean
  isNewsInsightsTab: boolean
  modifierClass?: string
  modifierClassTwo?: string
}

interface Content {
  id: number
  tab: {
    label: string
  }
  component: React.ReactNode
  filteredComponent: React.ReactNode | null
  count: number
  isDisabled: boolean
}

const TabbedContent: React.FC<TabbedContentTypes> = ({
  disablingAllTabs,
  isFilterList = false,
  content,
  isContentLoading,
  isNewsInsightsTab,
  modifierClass,
  modifierClassTwo,
}) => {
  const [followerWidth, setFollowerWidth] = useState(0)
  const [followerXpos, setFollowerXpos] = useState(0)

  const tabRefs = NodeList

  useEffect(() => {
    if (tabRefs !== null) {
      for (let i = 0; i < tabRefs.length; i++) {
        for (let b = 0; b < tabRefs[i].classList.length; b++) {
          if (tabRefs[i].classList[b] === "active") {
            setFollowerWidth(tabRefs[i].clientWidth)
            setFollowerXpos(tabRefs[i].offsetLeft)
            window.addEventListener("resize", () => {
              if (tabRefs[i] !== null) {
                setFollowerWidth(tabRefs[i].clientWidth)
              }
            })
          }
        }
      }

      return () => {
        window.removeEventListener("resize", () => null)
      }
    }
  }, [tabRefs])

  const [activeTab, setActiveTab] = useState<number>(0)

  const handleTabClick = (id: number) => {
    setActiveTab(id)
  }
  const location = window.location.search
  useEffect(() => {
    if (isNewsInsightsTab) {
      if (location === "?type=insights") {
        setActiveTab(1)
      }
    }
  }, [location])

  useEffect(() => {
    if (disablingAllTabs) {
      setActiveTab(0)
    }
  }, [disablingAllTabs])

  return (
    <>
      <ContentPage modifierClass={modifierClass}>
        <section>
          <div className={modifierClassTwo}>
            {content &&
              content.map(({ component, filteredComponent, id }) => {
                return (
                  activeTab === id && (
                    <>
                      <Grid modifierClass="grid--tab-row">
                        <TabRow
                          followerWidth={followerWidth}
                          followerXpos={followerXpos}
                        >
                          {content.map(({ id, count, tab }, i) => {
                            const { label } = tab
                            const isActive = id === 0

                            return (
                              <React.Fragment key={id}>
                                <Tab
                                  allTabsDisabled={disablingAllTabs}
                                  isFilterList={isFilterList}
                                  activeId={activeTab}
                                  setActive={handleTabClick}
                                  ref={(ref) => (tabRefs[i] = ref)}
                                  isLoading={isContentLoading}
                                  newsInsightTabs={isNewsInsightsTab}
                                  isActive={isActive}
                                  id={id}
                                  tabIndex={1}
                                  label={label}
                                  isFocused={false}
                                  isDisabled={
                                    activeTab === id ||
                                    disablingAllTabs === true
                                  }
                                  count={count ? count : 0}
                                />
                              </React.Fragment>
                            )
                          })}
                        </TabRow>
                        <Spacer desktopSize="m" />
                      </Grid>

                      {disablingAllTabs ? (
                        filteredComponent && (
                          <section>{filteredComponent}</section>
                        )
                      ) : (
                        <section>{component}</section>
                      )}
                    </>
                  )
                )
              })}
          </div>
        </section>
      </ContentPage>
    </>
  )
}

export default TabbedContent
