import React from "react"

interface RedirectionAlertTypes {
  redirectedFrom: string
}

const RedirectionAlert: React.FC<RedirectionAlertTypes> = ({
  redirectedFrom,
}) => {
  return redirectedFrom !== null ? (
    <div id="redirection-alert" role="alert" className="redirection-alert">
      <h1 className="typography__body--01 typography__weight--600 typography__color--white">
        You’ve been redirected from the {redirectedFrom} website to Ventia due
        to the acquisition.
        <a
          href="/"
          className="typography__color--white  typography__weight--400"
        >
          Learn more
        </a>
      </h1>
    </div>
  ) : null
}

export default RedirectionAlert
