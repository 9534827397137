import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { Parallax } from "react-scroll-parallax"
import Grid from "../../modules/grid"
import Column from "../../modules/grid/column"
import Row from "../../modules/grid/row"
import { minDesktopSize } from "../../../variables"
import classnames from "classnames"
import useComponentInView from "../../../hooks/useComponentInView"
import Wysiwyg from "../../ui/wysiwyg"
import { SideVisualBlockTypes } from "./types"
import VideoTile from "./video_tile"
import PrimaryCta from "../../cta/primary_cta"

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.75,
    },
  },
}

const item = {
  hidden: { opacity: 0, y: "60px" },
  show: { opacity: 1, y: 0 },
}

const animateInUp = {
  hidden: { y: "60px", opacity: 0 },
  visible: { y: 0, opacity: 1 },
}

const animateInAcross = {
  hidden: { opacity: 0, x: "-60px" },
  visible: { opacity: 1, x: 0 },
}

const SideVisualBlock: React.FC<SideVisualBlockTypes> = ({
  body_text,
  top_images,
  vimeo_id,
  aligned,
  modifierClass,
  imageRevealEffect,
  ctaLink,
  ctaLabel
}) => {
  const [ref, inView] = useInView({
    threshold: 0,
  })

  const containerClasses = classnames("side-visual-block", {
    [`side-visual-block--${aligned}`]: aligned,
    [`side-visual-block--image-reveal`]: imageRevealEffect,
    [`${modifierClass}`]: modifierClass,
  })

  const componentSeen = useComponentInView(inView)

  const [imageYCors, setImageYCords] = useState([0, 0])

  const handleResize = () => {
    if (window.innerWidth < minDesktopSize) {
      setImageYCords([0, 0])
    } else {
      setImageYCords([0, 0])
    }
  }

  useEffect(() => {
    if (window.innerWidth < minDesktopSize) {
      setImageYCords([0, 0])
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div ref={ref} className="showcase__block-wrapper showcase-bg--white showcase-wrapper__side-visual-block">
      <div ref={ref} className={containerClasses}>
        <article className={`side-visual-block__article side-visual-block__reveal-wrapper`}>
          <Grid modifierClass="side-visual-block__grid">
            <Row columnCount={2}>
              <Column>
                <motion.div
                  variants={container}
                  initial="hidden"
                  className="device-content-padding side-visual-block__article--text"
                  animate={componentSeen ? "show" : "hide"}
                >
                  <motion.div
                    className="side-visual-block__body-text"
                    variants={item}
                    transition={{
                      default: { delay: 0.1, duration: 0.6, ease: "easeOut" },
                    }}
                  >
                    <Wysiwyg content={body_text} />

                    {ctaLink && (
                      <>
                        <div className="side-visual-block__btn-wrapper">
                          <PrimaryCta link={ctaLink} label={ctaLabel} />
                        </div>
                      </>
                    )}
                  </motion.div>
                </motion.div>
              </Column>

              <Column>
                <div className="side-visual-block__image side-visual-block__image--top" data-where="first">
                  <motion.div
                    initial="hidden"
                    animate={componentSeen ? "visible" : "hidden"}
                    variants={animateInAcross}
                    transition={{
                      default: {
                        damping: 20,
                        delay: 1.2,
                        duration: 0.1,
                        type: "spring",
                        ease: "easeOut",
                      },
                    }}
                  >
                    <Parallax y={imageYCors}>
                      {top_images && (
                        <div className="side-visual-block__image-overlay">
                          <picture
                            className={`side-visual-block__image-wrapper ${componentSeen ? " side-visual-block__image-reveal" : ""} `}

                          >
                            <source
                              srcSet={`${top_images.medium} 1x, ${top_images.large} 2x`}
                            />
                            <img
                              className="side-visual-block__image--file"
                              src={top_images.medium}
                              alt=""

                            />
                          </picture>
                        </div>
                      )}
                      {vimeo_id && (
                        <div className="side-visual-block__image-overlay side-visual-block__video-overlay">
                          <div className={`side-visual-block__image-wrapper ${componentSeen ? " side-visual-block__image-reveal" : ""} `}>
                            <VideoTile
                              componentSeen={componentSeen}
                              video_url={vimeo_id}
                            />
                          </div>
                        </div>
                      )}
                    </Parallax>
                  </motion.div>
                </div>
              </Column>
            </Row>
          </Grid>
        </article>
      </div>
    </div>
  )
}

export default SideVisualBlock
