import React, { useEffect, useState, useRef, useLayoutEffect } from "react"
import { useInView } from "react-intersection-observer"
import {
  motion,
  useViewportScroll,
  useTransform
} from "framer-motion"
import { minDesktopSize } from "../../../variables"
import classnames from "classnames"
import useComponentInView from "../../../hooks/useComponentInView"
import { Parallax } from "react-scroll-parallax"
import Wysiwyg from "../../ui/wysiwyg"
import useTabletDetector from "../../../hooks/useTabletDetector"
import Slider from "./slider"
import type { ThreeColumnModuleTypes } from "./types"


const ThreeColumnModule: React.FC<ThreeColumnModuleTypes> = ({
  heading,
  contentOne,
  contentTwo,
  contentThree,
  contentFour,
  contentFive,
  contentSix,
  modifierClass,
}) => {
  const componentRef = useRef<HTMLInputElement>();
  const isTablet = useTabletDetector()

  const [ref, inView] = useInView({
    threshold: 0.2,
  })

  const containerClasses = classnames("three-column-module", {
    [`${modifierClass}`]: modifierClass,
  })

  const componentSeen = useComponentInView(inView)

  const [imageYCors, setImageYCords] = useState([12, -10])
  const [imageXYCors, setImageXYCords] = useState([12, -14])

  const [scrollPercentageStart, setScrollPercentageStart] = useState(null);
  const [scrollPercentageEnd, setScrollPercentageEnd] = useState(null);


  const { scrollYProgress } = useViewportScroll();
  const scrollPercentageEndMobile = scrollPercentageEnd * 2;
  const xPosAnim = useTransform(scrollYProgress, [scrollPercentageStart, scrollPercentageEnd], [0, -3000])
  const xPosAnimReverse = useTransform(scrollYProgress, [scrollPercentageStart, scrollPercentageEnd], [0, 3000])

  const smallxPosAnim = useTransform(scrollYProgress, [scrollPercentageStart, scrollPercentageEndMobile], [0, -1500])
  const smallxPosAnimReverse = useTransform(scrollYProgress, [scrollPercentageStart, scrollPercentageEndMobile], [0, 1500])

  useLayoutEffect(() => {
    if (!componentRef?.current) {
      return;
    }
    // Get the distance from the start of the page to the element start
    const rect = componentRef?.current?.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    const offsetStart = (rect.top + scrollTop - 600);
    const offsetEnd = (offsetStart + rect.height - 600);

    const elementScrollStart = offsetStart / document.body.clientHeight;
    const elementScrollEnd = offsetEnd / document.body.clientHeight;

    setScrollPercentageStart(elementScrollStart);
    setScrollPercentageEnd(elementScrollEnd);
  });

  const handleResize = () => {
    if (window.innerWidth < minDesktopSize) {
      setImageYCords([0, 0])
    } else {
      setImageYCords([0, 0])
    }
  }

  useEffect(() => {
    if (window.innerWidth < minDesktopSize) {
      setImageYCords([0, 0])

    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div ref={ref} className="showcase__block-wrapper showcase-bg--white showcase-wrapper__three-column-module">
      <div className={containerClasses}>

        <div
          ref={componentRef}
          className={`three-column-module__wrapper `}
        >

          {isTablet ? (
            <>
              <div className="three-column-module__heading">
                <div className="three-column-module__words-wrapper">
                  <motion.div
                    style={{
                      x: xPosAnim
                    }}
                    transition={{ type: "spring", stiffness: 100 }}
                    aria-hidden="true"
                    className=""
                  >
                    <h2>

                      {[...Array(10)].map((index) => (
                        <span
                          key={index}
                        >
                          {heading}&nbsp;/&nbsp;
                        </span>
                      ))}
                    </h2>
                  </motion.div>
                  <motion.div
                    style={{
                      x: xPosAnimReverse
                    }}
                    transition={{ type: "spring", stiffness: 100 }}
                    aria-hidden="true"
                    className="three-column-module__heading-reverse"
                  >
                    <div
                      className="">
                      {[...Array(10)].map((index) => (
                        <span
                          key={index}
                        >
                          {heading}&nbsp;/&nbsp;
                        </span>
                      ))}
                    </div>
                  </motion.div>
                </div>
              </div>
              <div className="three-column-module__grid">
                <motion.div
                  initial="hidden"
                  className="three-column-module__item"
                  animate={componentSeen ? "show" : "hide"}
                  transition={{
                    default: {
                      damping: 20,
                      delay: 1.2,
                      duration: 0.6,
                      type: "spring",
                      ease: "easeOut",
                    },
                  }}
                >
                  <Parallax y={imageYCors}>
                    <motion.div
                      className="three-column-module__item__body-text"
                      transition={{
                        default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
                      }}
                    >
                      <Wysiwyg content={contentOne} />
                    </motion.div>
                  </Parallax>
                </motion.div>
                <motion.div
                  initial="hidden"
                  className="three-column-module__item"
                  animate={componentSeen ? "show" : "hide"}
                  transition={{
                    default: {
                      damping: 20,
                      delay: 1.2,
                      duration: 0.6,
                      type: "spring",
                      ease: "easeOut",
                    },
                  }}
                >
                  <Parallax y={imageXYCors}>
                    <motion.div
                      className="three-column-module__item__body-text"
                      transition={{
                        default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
                      }}
                    >
                      <Wysiwyg content={contentTwo} />
                    </motion.div>
                  </Parallax>
                </motion.div>
                <motion.div
                  initial="hidden"
                  className="three-column-module__item"
                  animate={componentSeen ? "show" : "hide"}
                  transition={{
                    default: {
                      damping: 20,
                      delay: 1.2,
                      duration: 0.6,
                      type: "spring",
                      ease: "easeOut",
                    },
                  }}
                >
                  <Parallax y={imageYCors}>
                    <motion.div
                      className="three-column-module__item__body-text"
                      transition={{
                        default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
                      }}
                    >
                      <Wysiwyg content={contentThree} />
                    </motion.div>
                  </Parallax>
                </motion.div>
                <motion.div
                  initial="hidden"
                  className="three-column-module__item"
                  animate={componentSeen ? "show" : "hide"}
                  transition={{
                    default: {
                      damping: 20,
                      delay: 1.2,
                      duration: 0.6,
                      type: "spring",
                      ease: "easeOut",
                    },
                  }}
                >
                  <Parallax y={imageXYCors}>
                    <motion.div
                      className="three-column-module__item__body-text"
                      transition={{
                        default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
                      }}
                    >
                      <Wysiwyg content={contentFour} />
                    </motion.div>
                  </Parallax>
                </motion.div>
                <motion.div
                  initial="hidden"
                  className="three-column-module__item"
                  animate={componentSeen ? "show" : "hide"}
                  transition={{
                    default: {
                      damping: 20,
                      delay: 1.2,
                      duration: 0.6,
                      type: "spring",
                      ease: "easeOut",
                    },
                  }}
                >
                  <Parallax y={imageYCors}>
                    <motion.div
                      className="three-column-module__item__body-text"
                      transition={{
                        default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
                      }}
                    >
                      <Wysiwyg content={contentFive} />
                    </motion.div>
                  </Parallax>
                </motion.div>
                <motion.div
                  initial="hidden"
                  className="three-column-module__item"
                  animate={componentSeen ? "show" : "hide"}
                  transition={{
                    default: {
                      damping: 20,
                      delay: 1.2,
                      duration: 0.6,
                      type: "spring",
                      ease: "easeOut",
                    },
                  }}
                >
                  <Parallax y={imageYCors}>
                    <motion.div
                      className="three-column-module__item__body-text"
                      transition={{
                        default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
                      }}
                    >
                      <Wysiwyg content={contentSix} />
                    </motion.div>
                  </Parallax>
                </motion.div>


              </div>
            </>
          ) : (
            <>
              <div className="three-column-module__heading">
                <div className="three-column-module__words-wrapper">
                  <motion.div
                    style={{
                      x: smallxPosAnim
                    }}
                    transition={{ type: "spring", stiffness: 100 }}
                    aria-hidden="true"
                    className=""
                  >
                    <h2>

                      {[...Array(10)].map((index) => (
                        <span
                          key={index}
                        >
                          {heading}&nbsp;/&nbsp;
                        </span>
                      ))}
                    </h2>
                  </motion.div>
                  <motion.div
                    style={{
                      x: smallxPosAnimReverse
                    }}
                    transition={{ type: "spring", stiffness: 100 }}
                    aria-hidden="true"
                    className="three-column-module__heading-reverse"
                  >
                    <div
                      className="">
                      {[...Array(10)].map((index) => (
                        <span
                          key={index}
                        >
                          {heading}&nbsp;/&nbsp;
                        </span>
                      ))}
                    </div>
                  </motion.div>
                </div>
              </div>
              <Slider
                contentOne={contentOne}
                contentTwo={contentTwo}
                contentThree={contentThree}
                contentFour={contentFour}
                contentFive={contentFive}
                contentSix={contentSix} />
            </>
          )}
        </div>
      </div>
    </div >
  )
}

export default ThreeColumnModule
