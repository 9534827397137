// Section/ID names
export const ourTeam = {
  anchor: "our-team",
  label: "Our team",
}
export const latestProjects = {
  anchor: "latest-projects",
  label: "Latest projects",
}
export const industryJobs = {
  anchor: "industry-jobs",
  label: "Jobs by Industry",
}
export const whyJoinUs = {
  anchor: "why-join-us",
  label: "Why join us",
}
export const lifestyle = {
  anchor: "your-lifestyle",
  label: "Lifestyle",
}
export const health = {
  anchor: "your-health",
  label: "Health",
}
export const career = {
  anchor: "your-career",
  label: "Career",
}
export const recruitmentProcess = {
  anchor: "recruitment-process",
  label: "Recruitment process",
}

export const linksModule = {
  anchor: "recruitment-process",
  label: "Recruitment process",
}
