import React from "react"
import Spacer from "../../layout/spacer"
import Grid from "../../modules/grid"
import Row from "../../modules/grid/row"
import ProjectCaseStudyTile from "../../modules/tile/project_case_study"
import { ProjectTileTypes } from "../../modules/tile/types"

import Hyperlink from "../../cta/hyperlink"
import useDesktopDetector from "../../../hooks/useDesktopDetector"

interface Props {
  name: string
  projects: ProjectTileTypes[]
}

const RelatedProjects: React.FC<Props> = ({ name, projects }) => {
  const isDesktop = useDesktopDetector()
  const readMoreLabel = isDesktop ? "See more projects" : "More projects"

  const nameLength = name?.slice().length + 8 //  8 for [p, r, o, j, e, c, t, s]
  return (
    <section id="projects">
      <div className="related-projects__top-bar">
        <h1 className="typography__display--05 typography__weight--700">
          {name} projects
        </h1>

        <div
          className={`${
            nameLength < 20
              ? "related-projects__top-bar--right-cta"
              : "related-projects__top-bar--bottom-cta"
          }`}
        >
          <Hyperlink
            link="/projects"
            relatedArticlesCta={true}
            label={`${readMoreLabel}`}
          />
        </div>
      </div>

      {nameLength < 20 ? (
        <Spacer deviceSize="s" hideForPrint={true} />
      ) : (
        <Spacer deviceSize="l" desktopSize="s" hideForPrint={true} />
      )}
      <div className="horizontal-divider hide-for-print" />
      <Spacer deviceSize="s" hideForPrint={true} />
      <Grid>
        <Row columnCount={3}>
          {projects.map((project: ProjectTileTypes) => {
            const {
              id,
              client_name,
              listing_images,
              name,
              teaser,
              slug,
            } = project

            return (
              <ProjectCaseStudyTile
                id={id}
                key={`project-tile--${id}`}
                client_name={client_name}
                listing_images={listing_images}
                name={name}
                teaser={teaser}
                slug={slug}
              />
            )
          })}
        </Row>
      </Grid>
    </section>
  )
}

export default RelatedProjects
