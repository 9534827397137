import React from "react"

interface Props {
  prevClick: (e: React.SyntheticEvent<HTMLElement>) => void
  nextClick: (e: React.SyntheticEvent<HTMLElement>) => void
  modifier?: "multimedia" | "linkedIn"
}
const CarouselNavigation: React.FC<Props> = ({
  prevClick,
  nextClick,
  modifier,
}) => {
  return (
    <div className={`carousel-navigation ${modifier ? modifier : ""}`}>
      <div className="carousel-navigatio__buttons-wrapper">
        <button
          onClick={prevClick}
          className="carousel-navigation__button carousel-navigation__button--prev "
        >
          <span>prev</span>
        </button>
        <button
          onClick={nextClick}
          className="carousel-navigation__button carousel-navigation__button--next"
        >
          <span>Next</span>
        </button>
      </div>
    </div>
  )
}

export default CarouselNavigation
