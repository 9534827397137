import React from "react"

const IconScrollUp = ({ color = "#ffffff" }) => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width="15"
    height="16"
    viewBox="0 0 14.969 16"
  >
    <path
      fill={color}
      className="cls-1"
      d="M8 0v14.3L14.2 8a.5.5 0 0 1 .6 0 .5.5 0 0 1 .2.4.5.5 0 0 1-.2.4l-7 7a.5.5 0 0 1 0 .2.5.5 0 0 1-.3 0 .5.5 0 0 1-.2 0L0 8.7A.5.5 0 0 1 .3 8a.5.5 0 0 1 .3 0 .5.5 0 0 1 .3 0L7 14.4V0a5.2 5.2 0 0 1 1 0z"
    />
  </svg>
)

export default IconScrollUp
