import React from "react"

type Props = {
  leftSide: string
  rightSide: string
  isLastYear: boolean
  isVisible: boolean
}

const Year: React.FC<Props> = ({
  leftSide = "19",
  rightSide = "56",
  isLastYear = false,
  isVisible = true,
}) => {
  return (
    <div className={`our-history__odometer ${isVisible ? "show" : "hide"}`}>
      <div
        className={`our-history__odometer-value our-history__odometer-left-side ${
          isLastYear
            ? "our-history__odometer-filled"
            : "our-history__odometer-stroked"
        }`}
      >
        {leftSide}
      </div>
      <div className="our-history__odometer-value our-history__odometer-filled">
        {rightSide}
      </div>
    </div>
  )
}

export default Year
