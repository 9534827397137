import React from "react"
import classnames from "classnames"

interface PrimaryNavTypes {
  link: string
  label: string
  modifierClass?: string
  openInNewTab?: boolean
  onMouseEnter?: (e) => void
  onMouseLeave?: (e) => void
}

const PrimaryCta: React.FC<PrimaryNavTypes> = ({
  link,
  label,
  modifierClass,
  openInNewTab = false,
  onMouseEnter,
  onMouseLeave,
}) => {
  const modifierClassnames = classnames({
    [`${modifierClass}`]: modifierClass,
  })
  return (
    <>
      {openInNewTab ? (
        <a
          className={`primary-cta ${modifierClassnames}`}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          <span className="typography__cta--01 typography__weight--600 typography__uppercase primary-cta__label">
            {label}
          </span>
        </a>
      ) : (
        <a
          className={`primary-cta ${modifierClassnames}`}
          href={link}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <span className="typography__cta--01 typography__weight--600 typography__uppercase primary-cta__label">
            {label}
          </span>
        </a>
      )}
    </>
  )
}

export default PrimaryCta
