import React from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import classnames from "classnames"
import useComponentInView from "../../../hooks/useComponentInView"
import { FullWidthVisualHeadingTypes } from "./types"

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.75,
    },
  },
}

const FullWidthVisualHeading: React.FC<FullWidthVisualHeadingTypes> = ({
  banners,
  loopingVidBanners,
  aligned,
  modifierClass,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
  })

  const containerClasses = classnames("full-width-visual-heading ", { [`full-width-visual-heading--${aligned}`]: aligned, [`${modifierClass}`]: modifierClass, })

  const componentSeen = useComponentInView(inView)


  return (
    <div ref={ref} className={containerClasses}>
      {banners.map(
        ({ images, quote, author }) => {
          return (

            <>


              {(loopingVidBanners !== null) ? (
                <>

                  <div
                    className="full-width-visual-heading__content full-width-visual-heading__content-video page-banner__slide"
                    style={{ backgroundImage: `url(${images})` }}
                  >
                    <div
                      className={`full-width-visual-heading__content-wrap ${modifierClass}`}
                    >
                      <motion.div
                        variants={container}
                        initial="hidden"
                        className="full-width-visual-heading__article--text"
                        animate={componentSeen ? "show" : "hide"}
                      >
                        <motion.div
                          className="full-width-visual-heading__body-text"
                          transition={{
                            default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
                          }}
                        >
                          <figure>
                            <blockquote>{quote}</blockquote>
                            <figcaption>- {author}</figcaption>
                          </figure>
                        </motion.div>
                      </motion.div>
                    </div>
                    <div className="page-banner__gradient page-banner__gradient--top" />
                    <div className="page-banner__gradient page-banner__gradient--bottom" />
                    <div className="page-banner__gradient page-banner__gradient--cover" />
                    <video
                      playsInline={true}
                      className="full-width-visual-heading__video active"
                      width="100%"
                      poster={loopingVidBanners.files.mobile_image_mobile}
                      muted={true}
                      loop={true}
                      autoPlay
                    >
                      <source
                        id="webmSource"
                        src={loopingVidBanners.files.webm_version}
                        type="video/webm"
                      />
                      <source
                        id="mp4Source"
                        src={loopingVidBanners.files.mp4_version}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </>

              ) : (

                <div
                  className="full-width-visual-heading__content"
                  style={{ backgroundImage: `url(${images})` }}
                >
                  <div
                    className={`full-width-visual-heading__content-wrap ${modifierClass}`}
                  >
                    <motion.div
                      variants={container}
                      initial="hidden"
                      className="full-width-visual-heading__article--text"
                      animate={componentSeen ? "show" : "hide"}
                    >
                      <motion.div
                        className="full-width-visual-heading__body-text"
                        transition={{
                          default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
                        }}
                      >
                        <figure>
                          <blockquote>{quote}</blockquote>
                          <figcaption>- {author}</figcaption>
                        </figure>
                      </motion.div>
                    </motion.div>
                  </div>
                  <div className="showcase__full-gradient" />
                </div>

              )}

            </>

          )
        }
      )}
    </div >
  )
}

export default FullWidthVisualHeading
