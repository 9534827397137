import React from "react"
import classnames from "classnames"

interface ToggleCtaTypes {
  onClick: (e) => void
  label: string
  modifierClass?: string
}

const ToggleCta: React.FC<ToggleCtaTypes> = ({
  onClick,
  label,
  modifierClass,
}) => {
  const modifierClassnames = classnames({
    [`${modifierClass}`]: modifierClass,
  })
  return (
    <a className={`toggle-cta ${modifierClassnames}`} onClick={onClick}>
      <span className="typography__cta--01 typography__weight--600 toggle-cta__label">
        {label}
      </span>
    </a>
  )
}

export default ToggleCta
