import React from "react"

const IconLinkedIn = ({ color = "#ffffff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.9"
    height="12.9"
    viewBox="0 0 12.875 12.87"
    className="svg_icon svg_linkedin"
  >
    <path
      fill={color}
      d="M3 13H0V4.2H3V13zM1.4 3A1.6 1.6 0 1 1 3 1.6 1.5 1.5 0 0 1 1.6 3zM13 13H10V8.6c0-1 0-2.3-1.4-2.3s-1.6 1-1.6 2.2V13H4.5V4.2H7v1a2.8 2.8 0 0 1 2.7-1.2C12.4 4 13 6 13 8v5z"
    />
  </svg>
)

export default IconLinkedIn
