import React from "react"
import IconCtaPlay from "../../images/svgs/icon_play"

interface DownloadTypes {
  onClick: () => void
}

const Video: React.FC<DownloadTypes> = ({ onClick }) => {
  return (
    <a className="tile__cta play" onClick={onClick}>
      <IconCtaPlay />
    </a>
  )
}

export default Video
