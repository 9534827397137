import React from "react"
import Cta from "../cta"
import {
  renderHtmlString,
  truncateText,
  strippedString,
} from "../../../../helpers"
import Column from "../../../modules/grid/column"
import Spacer from "../../../layout/spacer"
import classnames from "classnames"
import useDesktopDetector from "../../../../hooks/useDesktopDetector"
import useTabletDetector from "../../../../hooks/useTabletDetector"
import { ProjectTileTypes } from "../types"
import Picture from "../../../elements/picture"

// Helpers
import { handleTileClick } from "../helpers"

const ProjectCaseStudyTile: React.FC<ProjectTileTypes> = ({
  sb_hover_active,
  client_name,
  teaser,
  name,
  listing_images: { small, large },
  slug,
}) => {
  const currentPath = "/projects"
  const completeSlug = currentPath ? `${currentPath}/${slug}` : slug

  const isDesktop = useDesktopDetector()
  const isTablet = useTabletDetector()

  const h1Classnames = classnames({
    "typography__display--06": isDesktop,
    "typography__body--01-700": !isDesktop,
  })

  const _storybookHoverClass = classnames({
    __hover: sb_hover_active,
  })

  function truncateTitleText(title) {
    let text = title
    if (isTablet) {
      text = truncateText(title, 50)
    } else {
      text = truncateText(title, 47)
    }
    return text
  }
  return (
    <Column>
      <article
        className={`tile tile__single tile__projects ${_storybookHoverClass}`}
        onClick={() => handleTileClick(completeSlug)}
      >
        <div className="tile__image-cell">
          <Picture
            cssClassName="tile__image-wrapper"
            image1x={small}
            image2x={large}
          />
        </div>

        <div className="tile__content-cell">
          <section className="tile__info tile__info--flex">
            <div className="tile__info-col tile__info--backslash tile__info--case-study">
              <h2 className="typography__caption--02 typography__weight--400 typography__color--green typography__weight--600">
                {client_name}
              </h2>
            </div>
          </section>
          <Spacer deviceSize="n" />
          <h1
            className={`${h1Classnames} typography__weight--700  typography__color--navy`}
          >
            {truncateTitleText(name)}
          </h1>
          <Spacer desktopOnly={true} desktopSize="xs" deviceLayout="row" />
          {teaser && (
            <div
              className="tile__overview typography__color--mid-grey"
              dangerouslySetInnerHTML={renderHtmlString(
                truncateText(strippedString(teaser), 142)
              )}
            />
          )}
        </div>
        <Cta completeSlug={completeSlug} />
      </article>
      <Spacer deviceSize="xxs" deviceLayout="row" />
    </Column>
  )
}

export default ProjectCaseStudyTile
