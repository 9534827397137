import React from "react"
import Spacer from "../../../layout/spacer"

import { Items } from "../../../modules/document_library"

interface Props {
  caption: string
  year: string
  rows: Items[]
}

const Table: React.VFC<Props> = ({ year, caption, rows }) => {
  function rowClassNames(itr) {
    if (itr.link && !itr.url) return "link"
    else if (!itr.link && itr.url) return "url"
    else return "label-only"
  }

  return (
    <>
      <table
        className="document-library-table"
        cellPadding="1"
        cellSpacing="1"
        summary="Table Summary"
        id={year}
      >
        {caption && (
          <caption className="typography__display--04 typography__weight--700">
            {caption}
          </caption>
        )}
        <tbody>
          {rows.length >= 1 && (
            <>
              {rows.map((i) => {
                return (
                  <tr key={i.id} className={rowClassNames(i)}>
                    <td>
                      <p className="typography__body--01">{i.title}</p>
                    </td>
                    <td className="document-library-table__icon-cell">
                      {i.link && !i.url && (
                        <a
                          href={i.link}
                          className="document-library-table__link-icon"
                        >
                          LINK
                        </a>
                      )}
                      {!i.link && i.url && (
                        <a
                          href={i.url}
                          download
                          className="document-library-table__url-icon"
                        >
                          URL
                        </a>
                      )}
                    </td>
                    <td>
                      {i.link && !i.url && (
                        <p className="typography__body--01">
                          <a href={i.link}>{i.subtitle}</a>
                        </p>
                      )}
                      {!i.link && i.url && (
                        <p className="typography__body--01">
                          <a href={i.url} download>
                            {i.subtitle}
                          </a>
                        </p>
                      )}
                      {!i.link && !i.url && (
                        <p className="typography__body--01">
                          <span>{i.subtitle}</span>
                        </p>
                      )}
                    </td>
                  </tr>
                )
              })}
            </>
          )}
        </tbody>
      </table>
      <Spacer deviceSize="s" desktopSize="m" />
    </>
  )
}

export default Table
