import React from "react"
import Spacer from "../layout/spacer"
import { LinkTypes } from "../../types"
import classnames from "classnames"

const SecondaryCta: React.FC<LinkTypes> = ({
  slug,
  name,
  inLinkModule = false,
  isChildCapability = false,
  openInNewTab = false,
}) => {
  const handleLinkClick = () => {
    if (openInNewTab) {
      window.open(slug, "_blank")
    } else {
      location.href = `${slug}`
    }
  }

  const spanModifier = classnames({
    "secondary-cta__in-link-module": inLinkModule,
    "secondary-cta__in-link-module--child-capability": isChildCapability,
  })

  const buttonModifier = classnames({
    "typography__body--01 ": !isChildCapability,
    "typography__display--05 secondary-cta-nav-link--child-capability": isChildCapability,
  })
  return (
    <span className={`secondary-cta__wrapper ${spanModifier}`}>
      <button
        id={name.toLowerCase().replace(/\s/g, "-")}
        onClick={handleLinkClick}
        className={`typography__weight--600 secondary-cta-nav-link ${buttonModifier}`}
      >
        {name}
      </button>
      {!inLinkModule ? (
        <Spacer desktopSize="xs" />
      ) : (
        <div className="secondary-cta__in-link-module--hover-line" />
      )}
    </span>
  )
}

export default SecondaryCta
