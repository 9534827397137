import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import { ParallaxProvider } from "react-scroll-parallax"
import Year from "./year"
import VerticalProgressorIcon from "../../elements/vertical_progressor_icon"

import ContentPage from "../../layout/content_page"
import AnchoredSideNav from "../../modules/anchored_side_nav"
import ContentSection from "./content_section"
import { data } from "./data"
import useDesktopDetector from "../../../hooks/useDesktopDetector"
import Spacer from "../../layout/spacer"
import { handleScrollTo } from "../../../helpers"

const OurHistory: React.FC = () => {
  const isDesktop = useDesktopDetector()
  const { content, intro } = data

  const [ref1, inView1] = useInView({
    threshold: 0.3,
  })
  const [ref2, inView2] = useInView({
    threshold: 0.3,
  })
  const [ref3, inView3] = useInView({
    threshold: 0.3,
  })
  const [ref4, inView4] = useInView({
    threshold: 0.3,
  })
  const [ref5, inView5] = useInView({
    threshold: 0.3,
  })
  const [ref6, inView6] = useInView({
    threshold: 0.3,
  })
  const [ref7, inView7] = useInView({
    threshold: 0.3,
  })
  const [ref8, inView8] = useInView({
    threshold: 0.3,
  })
  const [ref9, inView9] = useInView({
    threshold: 0.3,
  })
  const [ref10, inView10] = useInView({
    threshold: 0.3,
  })
  const [ref11, inView11] = useInView({
    threshold: 0.5,
  })
  const [refEnd, inViewEnd] = useInView({
    threshold: 0.2,
  })

  const year1 = content[0].name
  const year2 = content[1].name
  const year3 = content[2].name
  const year4 = content[3].name
  const year5 = content[4].name
  const year6 = content[5].name
  const year7 = content[6].name
  const year8 = content[7].name
  const year9 = content[8].name
  const year10 = content[9].name
  const year11 = content[10].name
  const [odometerStrokeValue, setOdometerStokeValue] = useState("19")
  const [odometerFilledValue, setOdometerFilledValue] = useState("56")
  const [activeId, setActiveId] = useState(content[0].name)
  useEffect(() => {
    if (inView1) {
      setActiveId(year1)
      setOdometerStokeValue("19")
      setOdometerFilledValue("56")
    }
    if (inView2) {
      setActiveId(year2)
      setOdometerStokeValue("19")
      setOdometerFilledValue("87")
    }
    if (inView3) {
      setActiveId(year3)
      setOdometerStokeValue("19")
      setOdometerFilledValue("89")
    }
    if (inView4) {
      setActiveId(year4)
      setOdometerStokeValue("19")
      setOdometerFilledValue("94")
    }
    if (inView5) {
      setActiveId(year5)
      setOdometerStokeValue("19")
      setOdometerFilledValue("95")
    }
    if (inView6) {
      setActiveId(year6)
      setOdometerStokeValue("19")
      setOdometerFilledValue("96")
    }
    if (inView7) {
      setActiveId(year7)
      setOdometerStokeValue("20")
      setOdometerFilledValue("00")
    }
    if (inView8) {
      setActiveId(year8)
      setOdometerStokeValue("20")
      setOdometerFilledValue("10")
    }
    if (inView9) {
      setActiveId(year9)
      setOdometerStokeValue("20")
      setOdometerFilledValue("15")
    }

    if (inView10) {
      setActiveId(year10)
      setOdometerStokeValue("20")
      setOdometerFilledValue("20")
    }
    if (inView11) {
      setActiveId(year11)
      setOdometerStokeValue("20")
      setOdometerFilledValue("21")
    }
  }, [
    inView1,
    inView2,
    inView3,
    inView4,
    inView5,
    inView6,
    inView7,
    inView8,
    inView9,
    inView10,
    inView11,
  ])

  return (
    <>
      <ParallaxProvider>
        {!isDesktop && (
          <AnchoredSideNav
            showFollower={false}
            followerHeight={0}
            activeId={activeId}
            anchorItems={content}
            isVisible={isDesktop ? activeId !== year11 : true}
          />
        )}
        <ContentPage
          modifierClass="our-history device-content-padding"
          intro={intro}
        >
          {isDesktop && (
            <AnchoredSideNav
              showFollower={false}
              followerHeight={0}
              activeId={activeId}
              anchorItems={content}
              isVisible={activeId !== year11}
            />
          )}

          <div className="our-history__page-wrapper">
            <Year
              leftSide={odometerStrokeValue}
              rightSide={odometerFilledValue}
              isLastYear={inView11}
              isVisible={!inViewEnd}
            />
            <section className="our-history__content-section">
              <article className="our-history__content-article our-history__content-article--first">
                <VerticalProgressorIcon
                  modifierClass="vertical-page-progressor__wrapper--first"
                  onClick={() => handleScrollTo(content[0].anchor, -300)}
                />
              </article>
            </section>
            <ContentSection
              onVerticalProgressorClick={() =>
                handleScrollTo(content[1].anchor, -300)
              }
              id={content[0].anchor}
              ref={ref1}
              title={content[0].title}
              paragraph={content[0].paragraph}
              history={content[0].history}
              images={content[0].images}
              inView={inView1}
            />
            <ContentSection
              onVerticalProgressorClick={() =>
                handleScrollTo(content[2].anchor, -300)
              }
              id={content[1].anchor}
              ref={ref2}
              title={content[1].title}
              paragraph={content[1].paragraph}
              history={content[1].history}
              images={content[1].images}
              inView={inView2}
            />

            <ContentSection
              onVerticalProgressorClick={() =>
                handleScrollTo(content[3].anchor, -300)
              }
              id={content[2].anchor}
              ref={ref3}
              title={content[2].title}
              paragraph={content[2].paragraph}
              history={content[2].history}
              images={content[2].images}
              inView={inView3}
            />
            <ContentSection
              onVerticalProgressorClick={() =>
                handleScrollTo(content[4].anchor, -300)
              }
              id={content[3].anchor}
              ref={ref4}
              title={content[3].title}
              paragraph={content[3].paragraph}
              history={content[3].history}
              images={content[3].images}
              inView={inView4}
            />
            <ContentSection
              onVerticalProgressorClick={() =>
                handleScrollTo(content[5].anchor, -300)
              }
              id={content[4].anchor}
              ref={ref5}
              title={content[4].title}
              paragraph={content[4].paragraph}
              history={content[4].history}
              images={content[4].images}
              inView={inView5}
            />
            <ContentSection
              onVerticalProgressorClick={() =>
                handleScrollTo(content[6].anchor, -300)
              }
              id={content[5].anchor}
              ref={ref6}
              title={content[5].title}
              paragraph={content[5].paragraph}
              history={content[5].history}
              images={content[5].images}
              inView={inView6}
            />
            <ContentSection
              onVerticalProgressorClick={() =>
                handleScrollTo(content[7].anchor, -300)
              }
              id={content[6].anchor}
              ref={ref7}
              title={content[6].title}
              paragraph={content[6].paragraph}
              history={content[6].history}
              images={content[6].images}
              inView={inView7}
            />
            <ContentSection
              onVerticalProgressorClick={() =>
                handleScrollTo(content[8].anchor, -300)
              }
              id={content[7].anchor}
              ref={ref8}
              title={content[7].title}
              paragraph={content[7].paragraph}
              history={content[7].history}
              images={content[7].images}
              inView={inView8}
            />
            <ContentSection
              onVerticalProgressorClick={() =>
                handleScrollTo(content[9].anchor, -300)
              }
              id={content[8].anchor}
              ref={ref9}
              title={content[8].title}
              paragraph={content[8].paragraph}
              history={content[8].history}
              images={content[8].images}
              inView={inView9}
            />
            <ContentSection
              onVerticalProgressorClick={() =>
                handleScrollTo(content[10].anchor, -300)
              }
              isLastSlide={true}
              id={content[9].anchor}
              ref={ref10}
              title={content[9].title}
              paragraph={content[9].paragraph}
              history={content[9].history}
              images={content[9].images}
              inView={inView10}
              isVisible={inView10}
            />
            <ContentSection
              onVerticalProgressorClick={undefined}
              isLastSlide={true}
              id={content[10].anchor}
              ref={ref11}
              title={content[10].title}
              paragraph={content[10].paragraph}
              history={content[10].history}
              images={content[10].images}
              inView={inView11}
              isVisible={true}
            />
          </div>
        </ContentPage>
        {/* <div ref={refEnd} /> */}
      </ParallaxProvider>

      <Spacer deviceSize="m" deviceOnly />
    </>
  )
}
export default OurHistory
