import React from "react"

interface Props {
  // currentPage: string
  parentPage: {
    slug: string
    name: string
  }
  isParentPage: boolean
  currentPage: string
}
const Breadcrumbs: React.VFC<Props> = ({
  parentPage,
  isParentPage,
  // childPage,
  currentPage,
}) => {
  return (
    <div className="breadcrumbs">
      <ol>
        <li>
          <a href="/">Ventia</a>
        </li>
        {!isParentPage && (
          <li>
            <a href={`${parentPage.slug}`}>{parentPage.name}</a>
          </li>
        )}
        <li>
          <span>{currentPage}</span>
        </li>
      </ol>
    </div>
  )
}

export default Breadcrumbs
