import React from "react"

const IconVimeo = ({ color = "#ffffff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="13"
    viewBox="0 0 15 13"
    className="svg_icon svg_linkedin"
  >
    <path
      fill={color}
      d="M15 3q0 2.2-3 6-3 4-5.2 4-1.4 0-2.3-2.4L3.3 6C3 4.6 2.3 3.8 2 3.8a4.8 4.8 0 0 0-1.3.7l-.7-1c.7-.6 1.5-1.2 2.2-2A5 5 0 0 1 4.4.3Q6.2 0 6.6 2.6L7.2 6c.3 1.6.7 2.3 1 2.3.4 0 1-.5 1.5-1.5a6 6 0 0 0 1-2.2 1 1 0 0 0-1-1.3 3 3 0 0 0-1 .2c.6-2.4 2-3.6 4-3.5 1.6 0 2.4 1 2.3 3z"
    />
  </svg>
)

export default IconVimeo
