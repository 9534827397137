import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"
import NewsMetadata from "../tile/news_metadata"
import Spacer from "../../layout/spacer"
import { orderedByPublishDate, truncateText } from "../../../helpers"

const motionContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const motionItem = {
  hidden: { opacity: 0, y: "30px" },
  show: { opacity: 1, y: 0 },
}

export interface NewsTypes {
  title: string
  slug: string
  read_in?: string
  path: string
  publish_date: string
}

const staticEndpoint = "/static-featured-news"

const News: React.FC = () => {
  const [featuredNews, setFeaturedNews] = useState<NewsTypes[]>([])
  const [featuredNewsLoading, setFeaturedNewsLoading] = useState(true)

  // Variants
  const url_variant = window.location.search

  const handleEndpoint = () => {
    if (url_variant) {
      return `${staticEndpoint}${url_variant}`
    } else {
      return staticEndpoint
    }
  }

  async function fetchFeaturedNews(endpoint) {
    if (endpoint) {
      setFeaturedNewsLoading(true)
      let articles: any = await fetch(endpoint)
      articles = await articles.json()
      setFeaturedNews(articles)
      setFeaturedNewsLoading(false)
    } else return null
  }

  useEffect(() => {
    fetchFeaturedNews(handleEndpoint())
  }, [])

  const orderedByDate = featuredNews ? orderedByPublishDate(featuredNews) : []
  return (
    <>
      <motion.div
        style={{ width: "100%" }}
        variants={motionContainer}
        initial="hidden"
        animate={`${!featuredNewsLoading ? "show" : "hidden"}`}
        className="page-banner__news-grid"
      >
        {featuredNewsLoading ? (
          <h2>Loading...</h2>
        ) : (
          orderedByDate.map(
            ({ title, read_in, publish_date, path }: NewsTypes) => {
              return (
                <motion.a
                  key={path}
                  variants={motionItem}
                  transition={{
                    default: {
                      damping: 6,
                      duration: 0.4,
                      ease: "easeIn",
                    },
                  }}
                  className="banner-news-item typography__color--white"
                  href={path}
                >
                  <div className="banner-news-item__metadata">
                    <NewsMetadata
                      darkBg={true}
                      date={publish_date}
                      readIn={read_in}
                    />
                  </div>
                  <Spacer deviceSize="xxs" />
                  <p className="banner-news-item__title typography__body--01 typography__text-shadow">
                    {truncateText(title, 80)}
                  </p>
                  <Spacer deviceSize="xs" />
                </motion.a>
              )
            }
          )
        )}
        <Spacer deviceSize="n" />
      </motion.div>
    </>
  )
}

export default News
