import React, { useState } from "react"
import { Waypoint } from "react-waypoint"
import useDesktopDetector from "../../../hooks/useDesktopDetector"

// Content Modules
import AnchoredSubNav from "../../modules/anchored_sub_nav"
import DataBlock from "../../modules/data_block"
import SideNav from "../../modules/side_nav"
import Multimedia from "../../modules/multimedia"
import RelatedCapabilities from "../../modules/related_capabilities"
import OurPeople from "../../modules/our_people"
import VideoImage from "../../modules/video_image"
import SingleProject from "../../modules/related_projects/single"
import Quote from "../../modules/quote"

// UI Modules
import ContentPage from "../../layout/content_page"
import Wysiwyg from "../../ui/wysiwyg"
import Spacer from "../../layout/spacer"
import ContentWithStickyAside from "../../modules/content_with_sticky_aside"

// Data
import { SectorTypes, SectorAnchor } from "./types"
import { colors } from "../../../variables"

function wysiwygContent(
  isDesktop: boolean,
  sideNav: () => void,
  wysiwyg: string,
  handleWaypoint: (id) => void,
  parent: string,
  hasWysiwyg: boolean
): React.ReactElement {
  return (
    <ContentPage modifierClass={`${parent}-sector`}>
      {!isDesktop && sideNav()}
      <Waypoint onEnter={() => handleWaypoint("overview")} bottomOffset={600} />
      <section className="content-page__inner">
        <section className="content-page__with-sidebar" id="overview">
          {hasWysiwyg && <Wysiwyg content={wysiwyg} />}
        </section>
      </section>
    </ContentPage>
  )
}

const Sector: React.FC<SectorTypes> = ({
  headingForVideos,
  dataBlockStats,
  downloads,
  hasLeadGenForm,
  ourPeople,
  videoImage,
  videoCarousel,
  singleCaseStudy,
  relatedCapabilities,
  leadGen,
  leadGenFormUrl,
  sector,
  showSubNav,
  parent,
  quote,
  wysiwyg,
  relatedSectors,
}) => {
  console.log({ relatedSectors })
  const isDesktop = useDesktopDetector()

  const hasWysiwyg = wysiwyg.length >= 1 ? true : false
  const hasRelatedCapabilities = relatedCapabilities.length >= 1 ? true : false
  const hasCaseStudy =
    singleCaseStudy !== null && typeof singleCaseStudy !== undefined
  const hasMultimedia =
    downloads.length >= 1 || videoCarousel.length >= 1 ? true : false
  const hasOurPeople = ourPeople.length >= 1

  const anchorItems: SectorAnchor[] = [
    {
      id: 0,
      name: "Overview",
      anchor: "overview",
      show: hasWysiwyg,
    },
    {
      id: 1,
      name: "Capabilities",
      anchor: "related-capabilities",
      show: hasRelatedCapabilities,
    },
    {
      id: 2,
      name: "Projects & News",
      anchor: "projects-and-news",
      show: hasCaseStudy,
    },
    {
      id: 3,
      name: "Resources",
      anchor: "multimedia",
      show: hasMultimedia,
    },
    {
      id: 4,
      name: "Statistics",
      anchor: "statistics",
      show: !!dataBlockStats.length,
    },
    {
      id: 5,
      name: "Our People",
      anchor: "our-people",
      show: hasOurPeople,
    },
  ]

  const filteredItems = anchorItems.filter((i) => i.show)

  const [activeId, setActiveId] = useState("overview")

  const sideNav = () => {
    return (
      <SideNav
        cta={{ isVisible: false }}
        isArticle={false}
        isCampaign={false}
        name={leadGen.name}
        linkedin={leadGen.linkedin}
        title={leadGen.title}
        email={leadGen.email}
        image1x={leadGen.image1x}
        image2x={leadGen.image2x}
        phone={leadGen.phone}
        contactLabel={leadGen.contactLabel}
        hidePhone={leadGen.hidePhone}
        hideEmail={leadGen.hideEmail}
        team={sector}
        hasLeadGenForm={hasLeadGenForm}
        formHtml={leadGenFormUrl}
        relatedCapabilities={[]}
        relatedIndustries={parent === "service" ? relatedSectors : []}
        relatedServices={parent === "industry" ? relatedSectors : []}
        relatedLocations={[]}
      />
    )
  }
  return (
    <>
      {showSubNav && (
        <AnchoredSubNav
          anchorItems={filteredItems}
          showFollower={true}
          activeId={activeId}
        />
      )}
      <ContentWithStickyAside
        hasStickySubNav={showSubNav}
        content={wysiwygContent(
          isDesktop,
          sideNav,
          wysiwyg,
          () => setActiveId("overview"),
          parent,
          hasWysiwyg
        )}
        aside={sideNav()}
      />

      {hasRelatedCapabilities && (
        <div
          style={{ background: colors.grey }}
          className="print-only--sector--related-capabilities  print-only---page-break"
        >
          <ContentPage modifierClass="device-content-padding">
            <Waypoint
              onEnter={() => setActiveId("related-capabilities")}
              bottomOffset={600}
            />
            <RelatedCapabilities
              theme={colors.grey}
              relatedCapabilities={relatedCapabilities}
            />
          </ContentPage>
        </div>
      )}

      {videoImage && (
        <VideoImage
          image_content_type={videoImage.image_content_type}
          video_id={videoImage.video_id}
          images={videoImage.images}
          image_alt_text={videoImage.image_alt_text}
        />
      )}
      {hasCaseStudy && (
        <div id="projects-and-news">
          <Waypoint
            onEnter={() => setActiveId("projects-and-news")}
            bottomOffset={600}
          />
          <ContentPage modifierClass="device-content-padding">
            <SingleProject relatedTo={sector} project={singleCaseStudy} />
          </ContentPage>
        </div>
      )}

      {hasMultimedia && (
        <div className="device-content-padding">
          <Waypoint
            onEnter={() => setActiveId("multimedia")}
            bottomOffset={600}
          />
          <Multimedia
            heading={headingForVideos}
            videos={videoCarousel}
            downloads={downloads}
          />
        </div>
      )}

      {dataBlockStats.length >= 1 && (
        <div>
          <Waypoint
            onEnter={() => setActiveId("statistics")}
            bottomOffset={600}
          />
          <Spacer deviceSize="s" hideForPrint={true} />
          <DataBlock stats={dataBlockStats} />
        </div>
      )}

      {hasOurPeople && (
        <ContentPage modifierClass="device-content-padding content-page__our-people">
          <Waypoint
            onEnter={() => setActiveId("our-people")}
            bottomOffset={600}
          />
          <OurPeople name={sector} people={ourPeople} />
        </ContentPage>
      )}

      {quote && (
        <>
          <Quote author={quote.author} text={quote.text} theme="grey" />
        </>
      )}
    </>
  )
}

export default Sector
