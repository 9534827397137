import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { Parallax } from "react-scroll-parallax"
import Grid from "../../modules/grid"
import Column from "../../modules/grid/column"
import Row from "../../modules/grid/row"
import Spacer from "../../layout/spacer"
import TertiaryCta from "../../cta/tertiary_cta"
import { minDesktopSize } from "../../../variables"
import classnames from "classnames"
import useComponentInView from "../../../hooks/useComponentInView"
import { renderHtmlString } from "../../../helpers"
import { FeaturedMediaTextTypes } from "./types"
import VideoTile from "./video_tile"

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.75,
    },
  },
}

const item = {
  hidden: { opacity: 0, y: "60px" },
  show: { opacity: 1, y: 0 },
}

const animateInUp = {
  hidden: { y: "60px", opacity: 0 },
  visible: { y: 0, opacity: 1 },
}

const animateInAcross = {
  hidden: { opacity: 0, x: "-60px" },
  visible: { opacity: 1, x: 0 },
}

const FeaturedMediaText: React.FC<FeaturedMediaTextTypes> = ({
  title,
  subtitle,
  cta_path,
  body_text,
  cta_text,
  images,
  vimeo_id,
  blockColourOverride,
  aligned,
  modifierClass,
  deviceImageOnTop,
  abortEntryTransition,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
  })

  const containerClasses = classnames("featured-media-text", {
    [`featured-media-text--${aligned}`]: aligned,
    [`${modifierClass}`]: modifierClass,
  })

  const componentSeen = useComponentInView(inView)

  const [imageYCors, setImageYCords] = useState([12, -50])
  const [desktopContent, setDesktopContent] = useState(true)

  const handleResize = () => {
    if (window.innerWidth < minDesktopSize) {
      setImageYCords([0, 0])
      setDesktopContent(false)
    } else {
      setDesktopContent(true)
      setImageYCords([0, -50])
    }
  }

  useEffect(() => {
    if (window.innerWidth < minDesktopSize) {
      setImageYCords([0, 0])
      setDesktopContent(false)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div ref={ref} className={containerClasses}>
      <article className="featured-media-text__article">
        <Grid modifierClass="featured-media-text__grid">
          <Row columnCount={2}>
            {!desktopContent && !deviceImageOnTop && (
              <Column>
                <motion.div
                  style={{ width: "auto !important" }}
                  variants={container}
                  initial="hidden"
                  animate={componentSeen ? "show" : "hide"}
                >
                  <div className="device-content-padding">
                    <motion.h1
                      className="typography__display--04"
                      variants={item}
                      transition={{
                        default: { duration: 0.3, ease: "easeOut" },
                      }}
                    >
                      {title}
                    </motion.h1>
                    {subtitle !== null && (
                      <>
                        <Spacer deviceSize="n" desktopSize="l" />
                        <motion.h2
                          className="typography__display--06"
                          variants={item}
                          transition={{
                            default: {
                              duration: 0.3,
                              ease: "easeOut",
                              delay: 0.3,
                            },
                          }}
                        >
                          {subtitle}
                        </motion.h2>
                      </>
                    )}
                    <Spacer deviceSize="n" desktopSize="s" />
                  </div>
                </motion.div>
              </Column>
            )}
            <Column>
              {desktopContent ? (
                <motion.div
                  initial="hidden"
                  animate={componentSeen ? "visible" : "hidden"}
                  variants={animateInAcross}
                  transition={{
                    default: {
                      damping: 20,
                      delay: 1.2,
                      duration: 0.6,
                      type: "spring",
                      ease: "easeOut",
                    },
                  }}
                >
                  <Parallax y={imageYCors}>
                    {images && (
                      <picture
                        className={`featured-media-text__image-wrapper ${
                          componentSeen ? "slide-in" : "slide-out"
                        }`}
                      >
                        <source
                          srcSet={`${images.medium} 1x, ${images.medium} 2x`}
                        />
                        <img
                          className="featured-media-text__image"
                          src={images.medium}
                          alt=""
                        />
                      </picture>
                    )}
                    {vimeo_id && (
                      <VideoTile
                        componentSeen={componentSeen}
                        video_url={vimeo_id}
                      />
                    )}
                  </Parallax>
                </motion.div>
              ) : (
                <div className="device-content-padding">
                  {images && (
                    <>
                      {abortEntryTransition ? (
                        <div>
                          <picture
                            className={`featured-media-text__image-wrapper ${
                              deviceImageOnTop
                                ? "featured-media-text__image-top-wrapper"
                                : ""
                            } ${componentSeen ? "slide-in" : "slide-out"}`}
                          >
                            <source
                              srcSet={`${images.medium} 1x, ${images.medium} 2x`}
                            />
                            <img
                              className="featured-media-text__image"
                              src={images.medium}
                              alt=""
                            />
                          </picture>
                        </div>
                      ) : (
                        <motion.picture
                          animate={componentSeen ? "visible" : "hidden"}
                          variants={animateInAcross}
                          transition={{
                            default: {
                              damping: 50,
                              type: "spring",
                              ease: "easeOut",
                              delay: 0.4,
                            },
                          }}
                          className={`featured-media-text__image-wrapper ${
                            deviceImageOnTop
                              ? "featured-media-text__image-top-wrapper"
                              : ""
                          } ${componentSeen ? "slide-in" : "slide-out"}`}
                        >
                          <source
                            srcSet={`${images.medium} 1x, ${images.medium} 2x`}
                          />
                          <img
                            className="featured-media-text__image"
                            src={images.medium}
                            alt=""
                          />
                        </motion.picture>
                      )}
                    </>
                  )}

                  {vimeo_id && (
                    <VideoTile
                      deviceImageOnTop={deviceImageOnTop}
                      componentSeen={componentSeen}
                      video_url={vimeo_id}
                    />
                  )}
                </div>
              )}

              <motion.div
                className="featured-media-text__bg-color"
                animate={componentSeen ? "visible" : "hidden"}
                variants={animateInUp}
                initial="hidden"
                transition={{
                  default: {
                    damping: 12,
                    duration: 0.6,
                    delay: 0.8,
                    type: "spring",
                    ease: "easeOut",
                  },
                }}
                style={{
                  backgroundColor: blockColourOverride
                    ? blockColourOverride
                    : undefined,
                }}
              />
            </Column>
            <Column>
              <motion.div
                style={{ width: "auto !important" }}
                variants={container}
                initial="hidden"
                className="device-content-padding"
                animate={componentSeen ? "show" : "hide"}
              >
                {desktopContent && (
                  <>
                    <Spacer deviceSize="s" />
                    <motion.h1
                      className="typography__display--04"
                      variants={item}
                      transition={{
                        default: { duration: 0.6, ease: "easeOut" },
                      }}
                    >
                      {title}
                    </motion.h1>
                    {subtitle ? (
                      <>
                        <Spacer deviceSize="n" desktopSize="m" />
                        <motion.h2
                          className="typography__display--06 featured-media-text__subtitle"
                          variants={item}
                          transition={{
                            default: {
                              duration: 0.6,
                              delay: 0.3,
                              ease: "easeOut",
                            },
                          }}
                        >
                          {subtitle}
                        </motion.h2>
                        <Spacer deviceSize="xs" />
                      </>
                    ) : (
                      <Spacer deviceSize="xm" />
                    )}
                  </>
                )}

                {!desktopContent && deviceImageOnTop && (
                  <>
                    <Spacer deviceSize="m" />
                    <motion.h1
                      className="typography__display--04"
                      variants={item}
                      transition={{
                        default: { duration: 0.6, ease: "easeOut" },
                      }}
                    >
                      {title}
                    </motion.h1>
                    <Spacer deviceSize="s" />
                    {subtitle && (
                      <>
                        <motion.h2
                          className="typography__display--06 featured-media-text__subtitle"
                          variants={item}
                          transition={{
                            default: {
                              duration: 0.6,
                              delay: 0.3,
                              ease: "easeOut",
                            },
                          }}
                        >
                          {subtitle}
                        </motion.h2>
                        <Spacer deviceSize="s" />
                      </>
                    )}
                  </>
                )}

                {!desktopContent && !deviceImageOnTop && (
                  <Spacer deviceSize="xxs" />
                )}
                <motion.p
                  className="featured-media-text__body-text  typography__body--01"
                  variants={item}
                  transition={{
                    default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
                  }}
                  dangerouslySetInnerHTML={renderHtmlString(body_text)}
                />

                <Spacer deviceSize="xs" desktopSize="m" />
                {cta_text && (
                  <motion.div
                    className="featured-media-text__words"
                    variants={item}
                    transition={{
                      default: { delay: 1.2, duration: 0.6, ease: "easeOut" },
                    }}
                  >
                    <TertiaryCta slug={cta_path} name={cta_text} />
                  </motion.div>
                )}
              </motion.div>
            </Column>
          </Row>
        </Grid>
      </article>
    </div>
  )
}

export default FeaturedMediaText
