/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import MobileMenuContact from "../../cta/mobile_menu_contact"
import NavItem from "../navigation/nav_item"
import Actions from "../header/actions"
import Hamburger from "../navigation/hamburger"
import StockPrice from "../../modules/stock_price"

import useDesktopDetector from "../../../hooks/useDesktopDetector"
import { HeaderTypes, PrimaryNavItemTypes } from "../../../types"
import classnames from "classnames"

const Header: React.FC<HeaderTypes> = ({
  navItems,
  storybookSearchOpen,
  isStorybookExample,
}) => {
  const [hamburgerToggled, setHamburgerToggled] = useState(false)

  function handleHamburgerClick() {
    setHamburgerToggled(!hamburgerToggled)
  }

  const backdropClassnames = classnames({
    "navigation__mobile-backdrop": true,
    toggled: hamburgerToggled,
  })

  const [scrollOffset, setScrollOffset] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 60) {
        setScrollOffset(true)
      } else {
        setScrollOffset(false)
      }
    })

    return () => {
      window.removeEventListener("scroll", () => {
        null
      })
    }
  }, [])

  const [isHeaderHovered, setIsHeaderHovered] = useState(false)

  const [isSearchDropdownVisible, setIsSearchDropdownVisible] = useState(false)

  useEffect(() => {
    if (storybookSearchOpen) {
      setIsSearchDropdownVisible(true)
    }
  }, [storybookSearchOpen])

  const [falseBg, setFalseBg] = useState(false)
  const [contentFade, setContentFade] = useState(false)

  const isDesktop = useDesktopDetector()
  const handleNavMouseEnter = () => {
    if (isDesktop) {
      setFalseBg(true)
      setTimeout(() => {
        setContentFade(true)
      }, 0)
    }
  }
  const handleNavMouseOut = () => {
    if (!isSearchDropdownVisible) {
      setFalseBg(false)
    }
  }

  const handleNavMouseLeave = () => {
    if (!isSearchDropdownVisible) {
      setContentFade(false)
    }
  }

  const handleSearchDropdownVisibility = () => {
    //  if search is already opened AND then hamburger is clicked...
    if (isSearchDropdownVisible && hamburgerToggled) {
      return false
    } else if (isSearchDropdownVisible) return true
    else return false
  }

  const handleHeaderMouseLeave = () => {
    if (isDesktop) {
      if (!isSearchDropdownVisible || !isStorybookExample) {
        setIsHeaderHovered(false)
      }

      if (isSearchDropdownVisible) {
        setIsHeaderHovered(true)
      }
    }
  }

  const handleHeaderMouseEnter = () => {
    if (isDesktop) {
      setIsHeaderHovered(true)
    }
  }

  useEffect(() => {
    if (isDesktop) {
      if (isStorybookExample || isSearchDropdownVisible) {
        setIsHeaderHovered(true)
      }
    }
  }, [isStorybookExample, isSearchDropdownVisible, isDesktop])

  const [currentHoveredNavItem, setCurrentHoveredNavItem] = useState("")
  return (
    <>
      <StockPrice isNz={window.newZealandContext} />
      <header
        className={`header ${isHeaderHovered ? "hovered" : ""}`}
        onMouseEnter={handleHeaderMouseEnter}
        onMouseLeave={handleHeaderMouseLeave}
      >
        <div
          className={`header__content ${scrollOffset ? "scrolled" : "default"}`}
        >
          <div
            className={`header__logo-wrapper ${
              hamburgerToggled ? "push-back" : ""
            }`}
          >
            <a href="/">
              <h1 className="header__logo">Ventia</h1>
            </a>
          </div>
          <div className={`header__nav ${hamburgerToggled ? "toggled" : ""}`}>
            <MobileMenuContact isVisible={hamburgerToggled} />
            <nav
              onMouseOver={handleNavMouseEnter}
              onMouseOut={handleNavMouseOut}
              onMouseLeave={handleNavMouseLeave}
              id="navigation"
              className={`navigation ${hamburgerToggled ? "toggled" : ""}`}
            >
              <ul
                className={`navigation__list--primary ${
                  hamburgerToggled ? "toggled" : ""
                }`}
              >
                {navItems.map((navData: PrimaryNavItemTypes) => {
                  return (
                    <NavItem
                      key={navData.name}
                      navData={navData}
                      contentFade={contentFade}
                      setIsSearchDropdownVisible={setIsSearchDropdownVisible}
                      currentNavItemHovered={(e) => setCurrentHoveredNavItem(e)}
                    />
                  )
                })}
                {/* Storybook only */}
                {isStorybookExample && (
                  <NavItem
                    key={navItems[4].name}
                    navData={navItems[4]}
                    storybookNavData={navItems[4]}
                    contentFade={contentFade}
                    setIsSearchDropdownVisible={setIsSearchDropdownVisible}
                    isStorybookExample={isStorybookExample}
                    currentNavItemHovered={(e) => setCurrentHoveredNavItem(e)}
                  />
                )}
              </ul>
            </nav>

            <Actions
              setIsSearchDropdownVisible={setIsSearchDropdownVisible}
              isSearchDropdownVisible={handleSearchDropdownVisibility()}
            />
            <Hamburger
              onHamburgerClick={handleHamburgerClick}
              isHamburgerToggled={hamburgerToggled}
            />
            <div
              className={`navigation-dropdown__background ${
                falseBg || isStorybookExample ? "show" : "hide"
              } ${
                currentHoveredNavItem === "where-we-work"
                  ? "navigation-dropdown__background--where-we-work"
                  : ""
              }`}
            />
          </div>
        </div>
        <div className={backdropClassnames} />
      </header>
    </>
  )
}

export default Header
