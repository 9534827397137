import React from "react"
import classnames from "classnames"

interface VerticalProgressorIconTypes {
  onClick: undefined | ((e: React.MouseEvent<HTMLButtonElement>) => void)
  inView?: boolean
  modifierClass?: string
}

const VerticalProgressorIcon: React.FC<VerticalProgressorIconTypes> = ({
  onClick,
  modifierClass,
}) => {
  const modiferClassnames = classnames({
    [`${modifierClass}`]: modifierClass,
  })
  return (
    <div className={`vertical-page-progressor__wrapper ${modiferClassnames}`}>
      <button className="vertical-page-progressor__icon" onClick={onClick}>
        <span />
      </button>
    </div>
  )
}

export default VerticalProgressorIcon
