import React, { useRef } from "react"
import SwiperCore, { Navigation, EffectFade } from "swiper"
import { Swiper as SwiperReact, SwiperSlide } from "swiper/react"

// Components
import Grid from "../grid"
import Row from "../grid/row"
import FeaturedTile from "../../modules/tile/featured"

export type FeaturedTileSliderTypes = {
  id: number
  name: string
  slug: string
  overview: string
  image: string
}

interface Props {
  heading: string
  content: FeaturedTileSliderTypes[]
}
const FeaturedTileSlider: React.FC<Props> = ({ heading, content }) => {
  SwiperCore.use([Navigation, EffectFade])
  const prevRef = useRef<HTMLDivElement | null>(null)
  const nextRef = useRef<HTMLDivElement | null>(null)

  return (
    <Grid>
      <Row columnCount={3}>
        <div role="banner" className="featured-tile-slider">
          <h2 className="featured-tile-slider__title typography__display--04">
            {heading}
          </h2>
          <div className="page-banner__navigation  page-banner__navigation--horizontal featured-tile-slider__navigation">
            <div
              ref={prevRef}
              className="featured-tile-slider-navigation__button featured-tile-slider-navigation__button--prev"
            >
              <span />
            </div>
            <div
              ref={nextRef}
              className="featured-tile-slider-navigation__button featured-tile-slider-navigation__button--next"
            >
              <span />
            </div>
          </div>
          <div className="featured-tile-slider--swiper-wrapper">
            <SwiperReact
              effect="slide"
              speed={900}
              navigation={{
                prevEl: prevRef.current ? prevRef.current : undefined,
                nextEl: prevRef.current ? prevRef.current : undefined,
              }}
              onInit={(swiper: any) => {
                swiper.params.navigation.prevEl = prevRef.current
                swiper.params.navigation.nextEl = nextRef.current
              }}
              spaceBetween={0}
              slidesPerView={1}
              loop
            >
              {content &&
                content.map(({ id, name, image, overview, slug }) => {
                  return (
                    <SwiperSlide key={id}>
                      <div className="featured-tile-slider--swiper">
                        <FeaturedTile
                          modifierClass="feature-slider-tile feature-slider-tile--work-with-us"
                          id={id}
                          slug={slug}
                          name={name}
                          overview={overview}
                          leftAlignedFeature={false}
                          listing_image={image}
                          isCareersLanding={true}
                        />
                      </div>
                    </SwiperSlide>
                  )
                })}
            </SwiperReact>
          </div>
        </div>
      </Row>
    </Grid>
  )
}

export default FeaturedTileSlider
