import React from "react"
import Cta from "../cta"
import Tag from "../../../elements/tag"
import {
  renderHtmlString,
  truncateText,
  strippedString,
} from "../../../../helpers"
import Column from "../../../modules/grid/column"
import Spacer from "../../../layout/spacer"
import classnames from "classnames"
import useDesktopDetector from "../../../../hooks/useDesktopDetector"
import useTabletDetector from "../../../../hooks/useTabletDetector"
import NewsMetadata from "../news_metadata"
import { NewsTileTypes } from "../types"
import Picture from "../../../elements/picture"

// Helpers
import { handleTileClick } from "../helpers"

const NewsInsightsTile: React.FC<NewsTileTypes> = ({
  sb_hover_active,
  content,
  slug,
  title,
  publish_date,
  read_in,
  listing_images: { small, large },
  published_industries,
  published_services,
}) => {
  const currentPath = "/news-and-insights"
  const completeSlug = currentPath ? `${currentPath}/${slug}` : slug

  const isDesktop = useDesktopDetector()
  const isTablet = useTabletDetector()

  const h1Classnames = classnames({
    "typography__display--06": isDesktop,
    "typography__body--01-700": !isDesktop,
  })

  const _storybookHoverClass = classnames({
    __hover: sb_hover_active,
  })

  const teaserLength =
    published_industries.length || published_services.length ? 150 : 190

  function truncateTitleText(title) {
    let text = title
    if (isTablet) {
      text = truncateText(title, 80)
    } else {
      text = truncateText(title, 47)
    }
    return text
  }

  return (
    <Column>
      <article
        className={`tile tile__single tile__news-insights ${_storybookHoverClass}`}
      >
        <div
          className="tile__image-cell"
          onClick={() => handleTileClick(completeSlug)}
        >
          <a href={completeSlug}>
            {small && large && (
              <Picture
                cssClassName="tile__image-wrapper"
                image1x={small}
                image2x={large}
              />
            )}
          </a>
        </div>

        <div className="tile__content-cell">
          <section className="tile__info tile__info--flex">
            <NewsMetadata darkBg={false} date={publish_date} readIn={read_in} />
          </section>

          <Spacer deviceSize="n" />
          <h1
            onClick={() => handleTileClick(completeSlug)}
            className={`${h1Classnames} typography__weight--700  typography__color--navy`}
          >
            {truncateTitleText(title)}
          </h1>

          <Spacer desktopOnly={true} desktopSize="xs" deviceLayout="row" />
          {content && (
            <div
              onClick={() => handleTileClick(completeSlug)}
              className="tile__overview typography__color--mid-grey"
              dangerouslySetInnerHTML={renderHtmlString(
                truncateText(strippedString(content), teaserLength)
              )}
            />
          )}
          <>
            {(published_industries.length >= 1 ||
              published_services.length >= 1) && (
              <>
                <Spacer desktopSize="xs" />
                <div className="tile__tags-wrapper">
                  <ul className="tile__tags">
                    {published_industries.length >= 1 &&
                      published_industries.map(({ name, slug }) => {
                        return (
                          <Tag
                            id={name}
                            isTileTag={true}
                            name={name}
                            key={name}
                            link={`/industries/${slug}`}
                          />
                        )
                      })}
                    {published_services.length >= 1 &&
                      published_services.map(({ name, slug }) => {
                        return (
                          <Tag
                            id={name}
                            isTileTag={true}
                            name={name}
                            key={name}
                            link={`/services/${slug}`}
                          />
                        )
                      })}
                  </ul>
                </div>
              </>
            )}
          </>
        </div>
        <Cta completeSlug={completeSlug} />
      </article>
      <Spacer deviceSize="xxs" />
    </Column>
  )
}

export default NewsInsightsTile
