export const data = {
  intro:
    "Ventia brings more than 65 years' knowledge, understanding and experience in providing essential infrastructure services across Australia and New Zealand.  We're proud of our company's diverse history: combining the expertise of Leighton Contractors Services, Thiess Services, Visionstream, and Broadspectrum to form a dynamic infrastructure services company with a bright future. So, let’s go back to where it all began.",
  content: [
    {
      id: 0,
      name: "1956",
      anchor: "1956",
      title: "Transfield Services is founded",
      paragraph:
        "Transfield Services is founded in Australia by Italian-born electrical engineer Franco Belgiorno-Nettis. He is joined by his former colleague from Electric Power Transmission, Carlo Salteri. Together, they went on to build Transfield into one of Australia’s most successful companies, focusing on major engineering projects.",
      history: [
        {
          title: "Early 1980s",
          paragraph:
            "Transfield Services grows to be the biggest engineering firm in south-east Asia. The company is so well known that Pope John Paul II tours its factory in Seven Hills, NSW during his visit to Australia in 1986.",
        },
      ],
      images: [
        {
          large: {
            src: "/images/history/1large.png",
            caption: "Transfield Services is founded in Australia in 1956",
          },
          small: {
            src: "/images/history/1small.png",
            caption: "Pope John Paul II visits a Transfield factory",
          },
        },
      ],
    },
    {
      id: 1,
      name: "1987",
      anchor: "1987",
      title: "Environmental services",
      paragraph:
        "Thiess officially launches its environmental services business as a core offering after winning its remediation contract in 1984.",
      history: [
        {
          title: "Late 1980s",
          paragraph:
            "Transfield Services expands into the defence sector and establishes a naval shipbuilding division.",
        },
      ],
      images: [
        {
          large: {
            src: "/images/history/2large.png",
            caption:
              "Thiess launches its environmental services business in 1984 ",
          },
        },
      ],
    },
    {
      id: 2,
      name: "1989",
      anchor: "1989",
      title: "ANZAC class frigates defence contract",
      paragraph:
        "Transfield Services acquires the Williamstown Dockyard in Melbourne, and wins the $6 billion contract to build 10 ANZAC class frigates for the Australian and New Zealand governments. This was the largest defence contract in Australia at the time.",
      history: [
        {
          title: "1993",
          paragraph: "The Transfield Services division is created.",
        },
      ],
      images: [
        {
          large: {
            src: "/images/history/3large.png",
            caption: "The official launch of HMAS ANZAC on 16 September 1994",
          },
          small: {
            src: "/images/history/3small.png",
            caption:
              "A hull block under construction in the shipyard prior to movement onto the slipway",
          },
        },
      ],
    },
    {
      id: 3,
      name: "1994",
      anchor: "1994",
      title: "Visionstream",
      paragraph:
        "Visionstream is established as a subsidiary of Telstra, to design and build the Telstra cable TV rollout.",
      history: [],
      images: [
        {
          large: {
            src: "/images/history/4large.png",
            caption: "Telstra establishes Visionstream as a subsidiary, 1994",
          },
        },
      ],
    },
    {
      id: 4,
      name: "1995",
      anchor: "1995",
      title: "Oil & gas",
      paragraph:
        "Transfield Services enters the upstream oil and gas sector. The company forms a joint venture with Worley Parsons to provide engineering and  services to Woodside Energy’s Australian gas assets. In the same year, the Salteri and Belgiorno-Nettis families evenly divide Transfield Services. The Belgiorno-Nettis family keeps the Transfield name, having established Transfield Holdings’ Operations and Maintenance division in 1993 at Mobil Altona in Victoria.",
      history: [],
      images: [
        {
          large: {
            src: "/images/history/5large.png",
            caption: "Transfield enters the oil & gas sector, 1993",
          },
        },
      ],
    },
    {
      id: 5,
      name: "1996",
      anchor: "1996",
      title: "Visionstream purchase",
      paragraph:
        "Leighton Contractors purchases Visionstream with the aim of growing the business. Since then, Visionstream has become a leader in Australia and New Zealand’s telecommunications market.",
      history: [
        {
          title: "1997",
          paragraph: "Transfield enters the defence services sector.",
        },
      ],
      images: [
        {
          large: {
            src: "/images/history/1996-large.png",
            caption:
              "Following Leighton Contractors purchase of Visionstream from Telstra in 1996, they become a leader in telecommunications",
          },
          small: {
            src: "/images/history/6large.png",
          },
        },
      ],
    },
    {
      id: 6,
      name: "2000",
      anchor: "2000",
      title: "Joint venture with Silcar",
      paragraph:
        "Thiess forms a joint venture with Silcar to commence work with Telstra contracts. This relationship eventually leads to Silcar joining the Thiess business. Later that year, Leighton Contractors Services is created after it wins a maintenance contract for Sydney’s Eastern Distributor – a contract we still proudly retain to this day.",
      history: [
        {
          title: "2001",
          paragraph: "Transfield Services is listed on the ASX.",
        },
      ],
      images: [
        {
          large: {
            src: "/images/history/2000-large.png",
            caption: "Thiess forms a joint venture with Silcar",
          },
        },
      ],
    },
    {
      id: 7,
      name: "2010",
      anchor: "2010",
      title: "More technical and niche services",
      paragraph:
        "Transfield Services purchases Easternwell, strengthening its position in oil and gas by offering more technical and niche services. Today, Easternwell is Australia’s leading integrated services provider to large oil and gas exploration companies and the broader energy industry.",
      history: [],
      images: [
        {
          large: {
            src: "/images/history/2010-large.png",
            caption: "Transfield Services purchases Easternwell, 2010",
          },
        },
      ],
    },
    {
      id: 8,
      name: "2015",
      anchor: "2015",
      title: "Creation of Ventia",
      paragraph:
        "Ventia is created following the merger of Leighton Contractors Services, Thiess Services and Visionstream.",
      history: [
        {
          title: "2016",
          paragraph:
            "Ferrovial purchases Broadspectrum, which results in the delisting of Broadspectrum from the ASX after 15 years as a publicly listed company.",
        },
        {
          title: "2019",
          paragraph:
            "Ventia’s Asset & Infrastructure Services and Utilities Services divisions merge to create a new division called Infrastructure Services.",
        },
      ],
      images: [
        {
          large: {
            src: "/images/history/2015-large.png",
            caption: "Ventia is created, 2015",
          },
          small: {
            src: "/images/history/2015-small.png",
            caption: "Ventia’s Asset & Infrastructure Services and Utilities",
          },
        },
      ],
    },
    {
      id: 9,
      name: "2020",
      anchor: "2020",
      title: "Ventia purchased Broadspectrum",
      paragraph:
        "Ventia purchases Broadspectrum to form one of the largest infrastructure services companies in Australia and New Zealand, and with an unrivalled service offering.",
      history: [],
      images: [
        {
          large: {
            src: "/images/history/2019-large.png",
            caption:
              "Ventia purchases Broadspectrum, two highly complementary essential infrastructure services companies.",
          },
        },
      ],
    },
    {
      id: 10,
      name: "2021",
      anchor: "2021",
      title: "Listed on the ASX and NZX",
      paragraph:
        "Ventia is listed on the Australian Securities Exchange and the New Zealand Securities Exchange.",
      history: [],
      images: [
        {
          large: {
            src: "/images/history/2021-large.jpeg",
            caption: "Ventia lists on the ASX and NZX, 2021",
          },
        },
      ],
    },
  ],
}
