


 export function getFilterCount(checkboxes: NodeListOf<HTMLInputElement>): number {
  let output: HTMLInputElement[] = []
  for (let i = 0; i < checkboxes.length; i++) {
    let initialIndustryCount = output
    if (checkboxes[i].checked) {
      initialIndustryCount = [...output, checkboxes[i]]
      output = initialIndustryCount
    }
  }
  return output.length
}