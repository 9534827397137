import React from "react"
import ContentPage from "../../layout/content_page"
import Grid from "../../modules/grid"
import Spacer from "../../layout/spacer"
import Picture from "../../elements/picture"

export type VideoImageTypes = {
  video_id: string
  images: {
    large: string
    featured_tile: string
  }
  image_alt_text: string
  image_content_type: string | null
}

const VideoImage: React.FC<VideoImageTypes> = ({
  video_id,
  images,
  image_alt_text,
  image_content_type,
}) => {
  const hasImage = image_content_type || !video_id ? true : false
  const hasVideo = video_id ? true : false

  return (
    <ContentPage modifierClass="device-content-padding">
      <Grid>
        {hasImage && hasVideo && (
          <section className="video-image">
            <Picture
              alt={image_alt_text}
              cssClassName="tile__image-wrapper"
              image1x={images.large}
              image2x={images.featured_tile}
            />

            <Spacer deviceSize="m" />
            <iframe
              src={`https://player.vimeo.com/video/${video_id}`}
              width="100%"
              height="100%"
              frameBorder={0}
            />
          </section>
        )}

        {hasImage && !hasVideo && (
          <section className="video-image">
            <Picture
              cssClassName="tile__image-wrapper"
              image1x={images.large}
              image2x={images.featured_tile}
            />
          </section>
        )}

        {hasVideo && !hasImage && (
          <section className="video-image">
            <iframe
              src={`https://player.vimeo.com/video/${video_id}`}
              width="100%"
              height="100%"
              frameBorder={0}
            />
          </section>
        )}
      </Grid>
    </ContentPage>
  )
}

export default VideoImage
