/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from "react"
import { isTablet as isScreenTabletSize } from "../helpers"

// * Sets state for tablet size screen on window load and window resize

const useTabletDetector = () => {
  const [isTablet, setIsTablet] = useState(false)

  useEffect(() => {
    if (isScreenTabletSize()) {
      setIsTablet(true)
    }

    window.addEventListener("resize", () => {
      if (isScreenTabletSize()) {
        setIsTablet(true)
      } else {
        setIsTablet(false)
      }
    })

    return () => window.removeEventListener("resize", () => null)
  }, [isScreenTabletSize()])

  return isTablet
}

export default useTabletDetector
