import React from "react"

interface Props {
  src: string
}

const VimeoIframe: React.FC<Props> = ({ src }) => {
  return (
    <div className="page-banner__slide-background">
      <div
        style={{
          padding: "56.25% 0 0 0",
          position: "relative",
        }}
      >
        <iframe
          src={`https://player.vimeo.com/video/${src}?autoplay=1&autopause=0&controls=0&loop=1&title=0&byline=0&portrait=0&muted=1?background=1`}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "110%",
          }}
          frameBorder="0"
          allow="autoplay"
        />
      </div>
    </div>
  )
}

export default VimeoIframe
