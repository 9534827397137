import React from "react"
import IconMail from "../../../images/svgs/icon_mail"
import IconPhone from "../../../images/svgs/icon_phone"
import { colors } from "../../../variables"
import classnames from "classnames"
import Spacer from "../../layout/spacer"
import TagGroup from "../../modules/tag_group"
import { contactDetails } from "../../../global"
import PrimaryCta from "../../cta/primary_cta"

import useDesktopDetector from "../../../hooks/useDesktopDetector"
import LeadGenDetails, { LeadGenTypes } from "./lead_gen_details"
import { FilterTypes } from "../../modules/filters/types"

interface Props extends LeadGenTypes {
  cta: {
    isVisible: boolean
    label?: string
    link?: string
  }
  team: string | null
  formHtml: string | null
  isArticle: boolean
  isCampaign: boolean
  hasLeadGenForm: boolean
  relatedCapabilities: FilterTypes[] | []
  relatedIndustries: FilterTypes[] | []
  relatedServices: FilterTypes[] | []
  relatedLocations?: FilterTypes[] | []
}

const SideNav: React.FC<Props> = (props) => {
  const contactUrl = "/forms/contact-our-team"
  const isDesktop = useDesktopDetector()

  const contactNumber = () => {
    if (window.newZealandContext) {
      return contactDetails.nzPhone
    }

    if (window.internationalContext) {
      return contactDetails.internationalPhone
    }

    return contactDetails.auPhone
  }

  const contactWrapperClassnames = classnames({
    "side-nav__contact-wrapper--article": props.isArticle,
  })

  const contactClassnames = classnames({
    "side-nav__contact--article": props.isArticle,
    "side-nav__contact--article-device": props.isArticle && !isDesktop,
  })

  const contactBoxClassnames = classnames({
    "side-nav__contact-box--article": props.isArticle || props.isCampaign,
  })

  return (
    <>
      <aside
        className={`side-nav__contact-wrapper ${contactWrapperClassnames}`}
      >
        <section className={`side-nav__contact ${contactClassnames}`}>
          <div className={`side-nav__contact-box ${contactBoxClassnames}`}>
            <>
              {props.cta.isVisible && props.cta.label && props.cta.link && (
                <>
                  <PrimaryCta
                    label={props.cta.label || ""}
                    link={props.cta.link || ""}
                    modifierClass="primary-cta--side-nav"
                  />
                </>
              )}
              {props.relatedCapabilities.length >= 1 && (
                <TagGroup
                  tags={props.relatedCapabilities}
                  tagType="Capabilities"
                />
              )}
              {props.relatedIndustries.length >= 1 && (
                <TagGroup tags={props.relatedIndustries} tagType="Industries" />
              )}
              {props.relatedServices.length >= 1 && (
                <TagGroup tags={props.relatedServices} tagType="Services" />
              )}
              {props.relatedLocations.length >= 1 && (
                <TagGroup tags={props.relatedLocations} tagType="Locations" />
              )}

              {props.isArticle ? (
                <ul className="side-nav__contact--methods">
                  <>
                    {props.hasLeadGenForm ? (
                      <LeadGenDetails
                        {...props}
                        contactLabel={props.contactLabel}
                      />
                    ) : (
                      <>
                        {!props.hidePhone && (
                          <li className="side-nav__contact-item">
                            <div className="side-nav__contact-icon">
                              <a href={`tel: ${contactNumber()}`}>
                                <IconPhone color={colors.white} />
                              </a>
                            </div>
                            <Spacer desktopSize="n" />
                            <div className="side-nav__contact-text">
                              <div className="typography__caption--01 typography__color--dark-green typography__weight--400">
                                <h1 className="typography__caption--01 typography__color--dark-green typography__weight--400">
                                  <a href={`tel: ${contactNumber()}`}>
                                    {contactNumber()}
                                    <br />
                                    {contactDetails.bracketed}
                                  </a>
                                </h1>
                              </div>
                            </div>
                          </li>
                        )}
                        {!props.hideEmail && (
                          <li className="side-nav__contact-item">
                            <nav className="side-nav__content">
                              <ul className="side-nav__links  side-nav__social-links">
                                <li>
                                  <div className="side-nav__contact-icon">
                                    <a href={contactUrl}>
                                      <IconMail color={colors.white} />
                                    </a>
                                  </div>
                                  <div className="side-nav__contact-text side-nav__contact-social-text">
                                    <span className="typography__caption--01 typography__color--dark-green typography__weight--400">
                                      {props.team ? (
                                        <a href={contactUrl}>
                                          Contact the {props.team} team
                                        </a>
                                      ) : (
                                        <a href={contactUrl}>
                                          Contact the Ventia team
                                        </a>
                                      )}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            </nav>
                          </li>
                        )}
                      </>
                    )}
                  </>
                </ul>
              ) : (
                <ul className="side-nav__contact--methods">
                  {props.hasLeadGenForm ? (
                    <>
                      <LeadGenDetails {...props} />
                    </>
                  ) : (
                    <>
                      {!props.hideEmail && (
                        <li className="side-nav__contact-item">
                          <div className="side-nav__contact-icon">
                            <IconMail color={colors.white} />
                          </div>
                          <div className="side-nav__contact-text side-nav__contact-social-text">
                            <span className="typography__caption--01 typography__color--dark-green typography__weight--400">
                              <a href={contactUrl}>
                                Contact the {props.team} team
                              </a>
                            </span>
                          </div>
                        </li>
                      )}
                      {!props.hidePhone && (
                        <li className="side-nav__contact-item">
                          <div className="side-nav__contact-icon">
                            <a href={`tel: ${contactNumber()}`}>
                              <IconPhone color={colors.white} />
                            </a>
                          </div>
                          <div className="side-nav__contact-text">
                            <h1 className="typography__caption--01 typography__color--dark-green typography__weight--400">
                              <a href={`tel: ${contactNumber()}`}>
                                {contactNumber()}
                                <br />
                                {!window.internationalContext &&
                                  contactDetails.bracketed}
                              </a>
                            </h1>
                          </div>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              )}
            </>
          </div>
        </section>
      </aside>
    </>
  )
}

export default SideNav
