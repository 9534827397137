import React, { useState } from "react"

// Compontents
import Spacer from "../../layout/spacer"
import Hyperlink from "../../cta/hyperlink"

// Helpers
import useDesktopDetector from "../../../hooks/useDesktopDetector"

import { TrypticTypes } from "./types"

interface Props {
  content: TrypticTypes[]
}

const Tryptic: React.FC<Props> = ({ content }) => {
  const isDesktop = useDesktopDetector()
  const [defaultState, setDefaultState] = useState(true)
  const [bgImg, setBgImg] = useState(content[0].image.url)

  // Events
  const handleMouseenter = (url: string) => {
    if (isDesktop) {
      setBgImg(url)
      setDefaultState(false)
    }
  }

  const handleMouseleave = () => {
    if (isDesktop) {
      setDefaultState(true)
    }
  }

  const handleClick = (url: string) => {
    if (!isDesktop) {
      setBgImg(url)
      setDefaultState(false)
    }
  }

  const titleFont = isDesktop
    ? "typography__display--03"
    : "typography__caption--01"

  const typticClassState = defaultState ? "default-state" : ""

  return (
    <div className={`tryptic ${typticClassState}`}>
      {content.map(
        ({ title, text, link, link_text, image: { url } }, index) => {
          return (
            <React.Fragment key={index}>
              <section
                className="tryptic__section"
                onMouseEnter={() => handleMouseenter(url)}
                onMouseLeave={handleMouseleave}
                data-image={url}
              >
                <div className="tryptic__content">
                  <div className="tryptic__content-wrapper">
                    <h2
                      onClick={() => handleClick(url)}
                      className={`${titleFont} typography__color--white typography__uppercase tryptic__title`}
                    >
                      {title}
                    </h2>
                    <Spacer deviceSize="s" />

                    <p className="tryptic__text typography__body--02 typography__color--white">
                      {text}
                    </p>
                  </div>
                </div>

                {link && isDesktop && (
                  <div className="tryptic__link">
                    <Hyperlink
                      label={link_text}
                      link={link}
                      modifierClass=" hyperlink__tryptic  hyperlink__size--xs"
                    />
                  </div>
                )}
              </section>
              {!isDesktop && (
                <div
                  className={`tryptic__content--mobile ${
                    bgImg === url ? "tryptic__show" : "tryptic__hide"
                  }`}
                >
                  <div className="tryptic__content-wrapper--mobile">
                    <h3 className="typography__color--white typography__uppercase typography__display--03">
                      {title}
                    </h3>
                    <Spacer deviceSize="s" />

                    <p className="tryptic__text typography__body--02 typography__color--white">
                      {text}
                    </p>
                  </div>
                  {link && (
                    <div className="tryptic__link">
                      <Hyperlink
                        label={link_text}
                        link={link}
                        modifierClass=" hyperlink__tryptic  hyperlink__size--xs"
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="tryptic__image-wrap">
                <div
                  className={`tryptic__image ${
                    bgImg === url ? "tryptic__show" : "tryptic__hide"
                  }`}
                  style={{ background: `url(${bgImg})` }}
                />
              </div>
            </React.Fragment>
          )
        }
      )}
    </div>
  )
}

export default Tryptic
