import React, { useEffect, useState } from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import { useInView } from "react-intersection-observer"

// Components
import AnchoredSubNavCareers from "../../modules/anchored_sub_nav_careers"
import SingleFeaturedImageText from "../../modules/featured_media_text/single"
import FeaturedVideoText from "../../modules/featured_media_text/video"
import FeaturedMediaText from "../../modules/featured_media_text"
import Multimedia from "../../modules/multimedia"
import LinkModule from "../../modules/link_module"
import ContentPage from "../../layout/content_page"
import TwoColumnTextBlock from "../../modules/two_column_text_block"
import Tryptic from "../../modules/tryptic"
import FeaturedTileSlider from "../../modules/featured_tile_slider"
import Wysiwyg from "../../ui/wysiwyg"
import Spacer from "../../layout/spacer"
import Breadcrumbs from "../../ui/breadcrumbs"

// Types
import { ContentPageTypes } from "./types"

// Variables
import {
  ourTeam,
  latestProjects,
  industryJobs,
  whyJoinUs,
  recruitmentProcess,
} from "./variables"

import { colors } from "../../../variables"

import linksContent from "../../__mocks__/links_module.json"

// Hooks
import useWideScreenDetector from "../../../hooks/useWideScreenDetector"

const WorkWithUsPage: React.FC<ContentPageTypes> = ({
  pageName,
  wysiwyg,
  featuredTileSliderHeading,
  featuredTileSliderContent,
  featuredImageTextContent,
  featuredVideoTextContent,
  campaignTilesHeading,
  campaignTiles,
  benefitsBlockHeader,
  benefitsBlock,
  trypticContent,
  featuredImageTextBlocks,
  linkModuleContent,
}) => {
  // Benefits Block CTA
  const benefistBlockCta = {
    label: "Search & apply for roles",
    link: "https://jobs.ventia.com/go/All-Jobs/8714700/",
  }

  // -----------------------------------------------------
  // start: Anchored Sub Items
  // 1. check If modules are in CMS

  const ourTeamExists = featuredVideoTextContent ? true : false
  const latestProjectsExists = featuredTileSliderContent ? true : false
  const industryJobsExists = campaignTiles.length >= 1 ? true : false
  const whyJoinUsExists = benefitsBlock ? true : false
  const recruitmentProcessExists = true

  const formatAnchor = (input) => {
    const output = input.toLowerCase().replace(" ", "-")
    return `anchor-${output}`
  }

  const isWideScreen = useWideScreenDetector()
  const [ourTeamRef, ourTeamInView] = useInView({
    threshold: isWideScreen ? 0.5 : 0.3,
  })
  const [latestProjectsRef, latestProjectsInView] = useInView({
    threshold: isWideScreen ? 0.5 : 0.3,
  })
  const [whyJoinUsRef, whyJoinUsInView] = useInView({
    threshold: isWideScreen ? 0.6 : 0.2,
  })
  const [industryJobsRef, industryJobsInView] = useInView({
    threshold: isWideScreen ? 0.7 : 0.3,
  })
  const [recruitmentProcessRef, recruitmentProcessInView] = useInView({
    threshold: isWideScreen ? 0.7 : 0.3,
  })

  useEffect(() => {
    if (ourTeamInView) {
      setActiveId(formatAnchor(ourTeam.anchor))
    }
    if (latestProjectsInView) {
      setActiveId(formatAnchor(latestProjects.anchor))
    }
    if (whyJoinUsInView) {
      setActiveId(formatAnchor(whyJoinUs.anchor))
    }
    if (industryJobsInView) {
      setActiveId(formatAnchor(industryJobs.anchor))
    }
    if (recruitmentProcessInView) {
      setActiveId(formatAnchor(recruitmentProcess.anchor))
    }
  }, [
    ourTeamInView,
    latestProjectsInView,
    whyJoinUsInView,
    industryJobsInView,
    recruitmentProcessInView,
  ])

  const anchorItems = [
    {
      id: 0,
      name: ourTeam.label,
      anchor: formatAnchor(ourTeam.anchor),
      show: ourTeamExists,
    },
    {
      id: 1,
      name: latestProjects.label,
      anchor: formatAnchor(latestProjects.anchor),
      show: latestProjectsExists,
    },
    {
      id: 2,
      name: whyJoinUs.label,
      anchor: formatAnchor(whyJoinUs.anchor),
      show: whyJoinUsExists,
    },
    {
      id: 3,
      name: industryJobs.label,
      anchor: formatAnchor(industryJobs.anchor),
      show: industryJobsExists,
    },
    {
      id: 4,
      name: recruitmentProcess.label,
      anchor: formatAnchor(recruitmentProcess.anchor),
      show: recruitmentProcessExists,
    },
  ]
  const filteredItems = anchorItems.filter((i) => i.show)
  const [activeId, setActiveId] = useState(formatAnchor(ourTeam.anchor))

  // Remove 'Careers' from list module. The 'Careers' link is coming from the Sub-Nav and still needs to be visible there.
  const filteredLinkModulesContent = linkModuleContent.filter(
    (i) => i.name !== "Careers"
  )
  return (
    <>
      <AnchoredSubNavCareers
        anchorItems={filteredItems}
        showFollower={true}
        activeId={activeId}
      />
      <Breadcrumbs
        isParentPage={true}
        parentPage={{ name: "Careers", slug: "/careers" }}
        currentPage={pageName}
      />
      {wysiwyg && (
        <ContentPage modifierClass="work-with-us">
          <section className="content-page__inner content-page__inner--lift-up">
            <section className="content-page__with-sidebar" id="overview">
              <Wysiwyg content={`${wysiwyg}`} />
            </section>
          </section>
        </ContentPage>
      )}
      <div id={formatAnchor(ourTeam.anchor)} ref={ourTeamRef}>
        <ContentPage>
          {featuredVideoTextContent && (
            <ParallaxProvider>
              <FeaturedVideoText
                blockColourOverride={colors.green}
                aligned="right"
                content={featuredVideoTextContent}
                deviceImageOnTop={true}
              />
            </ParallaxProvider>
          )}
        </ContentPage>
      </div>
      {latestProjectsExists && (
        <div
          className="int-ob-test device-content-padding"
          id={formatAnchor(latestProjects.anchor)}
          ref={latestProjectsRef}
        >
          <FeaturedTileSlider
            heading={`${featuredTileSliderHeading}`}
            content={featuredTileSliderContent}
          />
        </div>
      )}
      <div id={formatAnchor(whyJoinUs.anchor)} ref={whyJoinUsRef}>
        {benefitsBlock !== null && (
          <ContentPage>
            <div className="device-content-padding">
              <section>
                <TwoColumnTextBlock
                  id="benefits"
                  header={benefitsBlockHeader}
                  content={benefitsBlock}
                  withCta={true}
                  cta={benefistBlockCta}
                />
              </section>
            </div>
          </ContentPage>
        )}
      </div>
      {trypticContent.length >= 1 && (
        <div>
          <Spacer deviceSize="xm" />
          <Tryptic content={trypticContent} />
          <Spacer deviceSize="xm" />
        </div>
      )}

      {featuredImageTextBlocks && (
        <>
          <Spacer deviceSize="xs" deviceOnly />
          {featuredImageTextBlocks.slice(0, 3).map((props, i) => {
            const index = i + 1
            const aligned = index % 2 ? "right" : "left"
            return (
              <div key={props.name}>
                <ContentPage>
                  <ParallaxProvider>
                    <FeaturedMediaText
                      deviceImageOnTop={true}
                      aligned={aligned}
                      {...props}
                      modifierClass="featured-media-text--work-with-us"
                    />
                  </ParallaxProvider>
                </ContentPage>
              </div>
            )
          })}
        </>
      )}

      {industryJobsExists && (
        <div id={formatAnchor(industryJobs.anchor)} ref={industryJobsRef}>
          <Multimedia
            downloads={[]}
            videos={[]}
            heading={`${campaignTilesHeading}`}
            links={campaignTiles}
          />
        </div>
      )}

      <div
        id={formatAnchor(recruitmentProcess.anchor)}
        ref={recruitmentProcessRef}
      >
        <ContentPage>
          {featuredImageTextContent && (
            <ParallaxProvider>
              <SingleFeaturedImageText
                aligned="left"
                content={featuredImageTextContent}
                modifierClass="featured-media-text--work-with-us"
              />
            </ParallaxProvider>
          )}
        </ContentPage>
      </div>
      <div>
        {filteredLinkModulesContent.length >= 1 && (
          <LinkModule
            modifierClass="link-module__work-with-us"
            title="Discover more"
            links={filteredLinkModulesContent}
            image={linksContent[0].hover_image_url}
          />
        )}
      </div>
    </>
  )
}

export default WorkWithUsPage
