import React from "react"
import { minDesktopSize } from "../../variables"

interface PictureTypes {
  image1x: string
  image2x: string
  cssClassName: string
  alt?: string
}

const Picture: React.FC<PictureTypes> = ({
  cssClassName,
  image1x,
  image2x,
  alt,
}) => {
  return (
    <picture className={cssClassName}>
      <source srcSet={image2x} media={`(min-width: ${minDesktopSize})`} />
      <img
        className="tile__image"
        src={`${image1x}`}
        alt={alt ? alt : ""}
        style={{ width: "100%" }}
      />
    </picture>
  )
}

export default Picture
