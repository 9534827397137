import React from "react"
import Data from "./data"
import { DataBlockTypes } from "./types"
import { useInView } from "react-intersection-observer"

const DataBlock: React.FC<DataBlockTypes> = ({ stats }) => {
  const [ref, inView] = useInView({
    threshold: 0,
  })

  return (
    <div ref={ref} id="statistics" className="wrapper wrapper--stats">
      <div className="container container--stats">
        <ul className="data-block">
          {stats.map(
            (
              { column_width, stat, overview, unit, is_dollar, is_year },
              index
            ) => {
              return (
                <Data
                  key={index}
                  id={`${index}`}
                  stat={inView ? stat : 0}
                  overview={overview}
                  unit={unit}
                  is_year={is_year}
                  is_dollar={is_dollar}
                  dataItemCount={stats.length}
                  column_width={column_width}
                  printOnlyStat={stat}
                />
              )
            }
          )}
        </ul>
      </div>
    </div>
  )
}
export default DataBlock
