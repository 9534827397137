import React from "react"
import PrimaryCta from "../../../cta/primary_cta"
import Spacer from "../../../layout/spacer"

interface Props {
  bannerName: string
  bannerLinkText: string
  bannerLink: string
}
const TextCtaContent: React.FC<Props> = ({
  bannerName,
  bannerLinkText,
  bannerLink,
}) => {
  return (
    <div className="page-banner__content-wrap">
      <div className="page-banner__content-row">
        <div className="page-banner__content">
          <div className="page-banner__content--top">
            <h1 className="typography__display--02 typography__text-shadow typography__uppercase typography__color--white ">
              {bannerName}
            </h1>
          </div>
          <Spacer deviceSize="xs" />
          <div className="banner-content__bottom">
            {bannerLinkText && bannerLink ? (
              <PrimaryCta label={bannerLinkText} link={bannerLink} />
            ) : (
              <PrimaryCta label="Apply for roles" link="/careers" />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextCtaContent
