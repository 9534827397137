import React, { useEffect, useState } from "react"
import Spacer from "../../layout/spacer"
import IconMail from "../../../images/svgs/icon_mail"
import IconLinkedin from "../../../images/svgs/icon_linkedin"
import IconPhone from "../../../images/svgs/icon_phone"
import { colors } from "../../../variables"
import Picture from "../../elements/picture"
import useBodyLock from "../../../hooks/useBodyLock"
import { contactDetails } from "../../../global"

export interface LeadGenTypes {
  image1x: string | null
  image2x: string | null
  name: string | null
  title: string | null
  linkedin: string | null
  email: string | null
  phone: string | null
  hidePhone: boolean
  hideEmail: boolean
  contactLabel: string | null
}

const LeadGenDetails: React.FC<LeadGenTypes> = ({
  image1x,
  image2x,
  name,
  contactLabel,
  title,
  linkedin,
  phone,
  hidePhone,
}) => {
  const [toggleForm, setToggleForm] = useState(false)

  const formModal = document.getElementById("form-modal")
  const formUnderlay = document.querySelector(".form-underlay")
  const modalClose = document.querySelector(".lead-gen-modal__close-button")

  const contactNumber = () => {
    if (!!window.newZealandContext) {
      return contactDetails.nzPhone
    }

    if (!!window.internationalContext) {
      return contactDetails.internationalPhone
    }

    return contactDetails.auPhone
  }

  function closeAll() {
    setToggleForm(false)
    if (formModal && formUnderlay) {
      formModal.classList.remove("form-modal--open")
      formModal.classList.add("form-modal--close")
      formUnderlay.classList.remove("form-underlay--visible")
      formUnderlay.classList.add("form-underlay--hidden")
    }
  }

  useEffect(() => {
    if (formModal && formUnderlay && modalClose) {
      formUnderlay.addEventListener("click", () => {
        closeAll()
      })
      modalClose.addEventListener("click", () => {
        closeAll()
      })
    }
  }, [formModal, formUnderlay, modalClose])

  const handleEnquireOnClick = () => {
    if (formModal && formUnderlay) {
      formModal.classList.remove("form-modal--close")
      formModal.classList.add("form-modal--open")
      formUnderlay.classList.add("form-underlay--visible")
      setToggleForm(true)
    }
  }

  useBodyLock(toggleForm)
  const newZealandContext = window.newZealandContext

  return (
    <section className="lead-gen-details">
      <div className="lead-gen-details__header">
        {image1x && image2x && (
          <Picture
            image1x={`${image1x}`}
            image2x={`${image2x}`}
            cssClassName="lead-gen-details__image"
          />
        )}
        <div className="side-nav__contact-item">
          <div className="side-nav__contact-text side-nav__contact-social-text">
            <span className="typography__display--06 typography__color--green typography__weight--700">
              <a
                className="lead-gen-details__cta"
                onClick={handleEnquireOnClick}
              >
                {name}
              </a>

              <Spacer deviceSize="n" />
              <span className="typography__caption--01 typography__color--dark-grey typography__weight--400">
                {title}
              </span>
              <Spacer desktopSize="xs" />
            </span>
          </div>
        </div>
      </div>

      <ul className="lead-gen-details__contact-methods">
        <li
          className={`side-nav__contact-item lead-gen-details__contact-item ${
            !linkedin && hidePhone
              ? "lead-gen-details__contact-item-full-width"
              : ""
          }`}
          style={{ cursor: "pointer" }}
          onClick={handleEnquireOnClick}
        >
          <div className="side-nav__contact-icon">
            <IconMail color={colors.white} />
          </div>

          <div className="side-nav__contact-text side-nav__contact-social-text">
            <span className="typography__caption--01 typography__color--dark-green typography__weight--400">
              <span>{`${contactLabel ? contactLabel : "Contact Me"}`}</span>
            </span>
          </div>
        </li>

        {linkedin && (
          <li className="side-nav__contact-item lead-gen-details__contact-item">
            <div className="side-nav__contact-icon">
              <IconLinkedin color={colors.white} />
            </div>
            <div className="side-nav__contact-text side-nav__contact-social-text">
              <span className="typography__caption--01 typography__color--dark-green typography__weight--400">
                <a href={linkedin} target="_blank" rel="noreferrer">
                  Connect on LinkedIn
                </a>
              </span>
            </div>
          </li>
        )}

        {!hidePhone && (
          <li className="side-nav__contact-item lead-gen-details__contact-item">
            {phone ? (
              <>
                <div className="side-nav__contact-icon">
                  <a href={`tel: ${phone}`}>
                    <IconPhone color={colors.white} />
                  </a>
                </div>
                <div className="side-nav__contact-text">
                  <h1 className="typography__caption--01 typography__color--dark-green typography__weight--400">
                    <a href={`tel: ${phone}`}>{phone}</a>
                  </h1>
                </div>
              </>
            ) : (
              <>
                <div className="side-nav__contact-icon">
                  <a href={`tel: ${contactNumber()}`}>
                    <IconPhone color={colors.white} />
                  </a>
                </div>
                <div className="side-nav__contact-text">
                  <h1 className="typography__caption--01 typography__color--dark-green typography__weight--400">
                    <a href={`tel: ${contactNumber()}`}>{contactNumber()}</a>
                    <br />
                    {!window.internationalContext && contactDetails.bracketed}
                  </h1>
                </div>
              </>
            )}
          </li>
        )}
      </ul>
    </section>
  )
}

export default LeadGenDetails
