import React from "react"
import SwiperCore, { Autoplay } from "swiper"
import { Swiper as SwiperReact, SwiperSlide } from "swiper/react"
import Spacer from "../../layout/spacer"
import { renderHtmlString } from "../../../helpers"
import classnames from "classnames"
import ArticleHeader from "../../ui/article_header"
import images from "./images.json"
import useDesktopDetector from "../../../hooks/useDesktopDetector"


import {
  useViewportScroll,
  useTransform
} from "framer-motion"
import VimeoIframe from "./vimeo_iframe"
import UploadedVideo from "./uploaded_video"


import StatusPill, { StatusTypes } from "../../elements/status_pill"
import { SliderBannerTypes } from "./types"


const SliderBanner: React.FC<SliderBannerTypes> = ({
  modifierClass,
  banners,
  isGenericHeader,
  isCapSerIndHeader,
  isCampaignHeader,
  isSector,
  isOurHistory,
  introText,
  isArticleHeader,
  readIn,
  heading,
  date,
  breadcrumb,
  project_status,
  loopingVidBanners,
}) => {

  const isDesktop = useDesktopDetector()
  const hasIntroText = introText ? true : false

  const { scrollYProgress } = useViewportScroll();


  SwiperCore.use([Autoplay])

  const sliderBannerClassnames = classnames({
    "slider-banner__generic-page":
      isGenericHeader ||
      isArticleHeader ||
      isCampaignHeader ||
      isCapSerIndHeader,
    "with-intro-text": hasIntroText,
    "slider-banner__article-page": isArticleHeader
  })

  const modiferClassName = classnames({
    [`${modifierClass}`]: modifierClass,
  })

  return (
    <>
      <div id="print-only-logo">
        <img src="/images/ventia_logo_white.svg" />
      </div>
      <div
        id="print-only-banner"
        className={`print-only-banner ${isSector ? "print-only-banner--sector" : ""
          }`}
      >
        {banners[0] && (
          <picture className="print-only-banner__picture">
            <img src={banners[0].image} />
          </picture>
        )}
        <span className="print-only-banner__picture-bg" />
      </div>

      {isOurHistory ? (
        <div
          role="banner"
          className={`slider-banner ${sliderBannerClassnames} `}
        >
          <div className="slider-banner__words-wrapper">
            <div className="slider-banner__heading">
              <h1 className="typography__display--02 typography__uppercase typography__color--white typography__text-shadow">
                {heading}
              </h1>
            </div>
          </div>
          <div className="page-banner">
            <div className="page-banner__wrapper swiper-wrapper">
              <div className="page-banner__slide swiper-slide">
                <div className="page-banner__slide-background  page-banner__slide-background--single">
                  <div
                    className="page-banner__background-image"
                    style={{
                      backgroundImage: `url(${images[0].ourHistoryBanner})`,
                    }}
                  />
                </div>
                <div className="page-banner__gradient page-banner__gradient--top" />
                <div className="page-banner__gradient page-banner__gradient--article-cover" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          role="banner"
          className={`slider-banner ${sliderBannerClassnames} `}
        >
          <div className="page-banner">
            <div className="page-banner__wrapper">
              {banners.length > 1 && (
                <SwiperReact
                  effect="fade"
                  speed={1000}
                  spaceBetween={0}
                  slidesPerView={1}
                  loop
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: true,
                  }}
                >
                  {banners.map(({ id, image, video_id }) => {
                    return (
                      <SwiperSlide key={id}>
                        {video_id === "" ||
                          !!loopingVidBanners ||
                          (video_id === null && image) ? (
                          <div className="page-banner__slide  swiper-slide">
                            <div
                              className="page-banner__slide-background"
                              style={{ backgroundImage: `url(${image})` }}
                            />
                            <div className="page-banner__gradient page-banner__gradient--top" />
                            {isCapSerIndHeader ? (
                              <div className="page-banner__gradient page-banner__gradient--capabilities-cover" />
                            ) : (
                              <div className="page-banner__gradient page-banner__gradient--article-cover" />
                            )}
                          </div>
                        ) : (
                          <div className="page-banner__slide  swiper-slide">
                            <>
                              {/* Vimeo on desktop with image fallback */}
                              {isDesktop && image && video_id && (
                                <VimeoIframe src={video_id} />
                              )}

                              {/* Image on mobile */}
                              {!isDesktop && image && video_id && (
                                <div
                                  className="page-banner__slide-background"
                                  style={{ backgroundImage: `url(${image})` }}
                                />
                              )}

                              {/* When there is no image */}
                              {(!image ||
                                image === "/images/original/missing.png") &&
                                video_id && <VimeoIframe src={video_id} />}
                            </>
                            <div className="page-banner__gradient page-banner__gradient--top" />
                            {isCapSerIndHeader ? (
                              <div className="page-banner__gradient page-banner__gradient--capabilities-cover" />
                            ) : (
                              <div className="page-banner__gradient page-banner__gradient--article-cover" />
                            )}
                          </div>
                        )}
                      </SwiperSlide>
                    )
                  })}
                </SwiperReact>
              )}

              {banners.length === 1 &&
                banners.map(({ image, video_id }) => {
                  return (
                    <>
                      {video_id === "" || (video_id === null && image) ? (
                        <div className="page-banner__slide  swiper-slide">
                          <div
                            className="page-banner__slide-background"
                            style={{ backgroundImage: `url(${image})` }}
                          />
                          <div className="page-banner__gradient page-banner__gradient--top" />
                          {isCapSerIndHeader ? (
                            <div className="page-banner__gradient page-banner__gradient--capabilities-cover" />
                          ) : (
                            <div className="page-banner__gradient page-banner__gradient--article-cover" />
                          )}
                        </div>
                      ) : (
                        <div className="page-banner__slide  swiper-slide">
                          <>
                            {/* Video on desktop with image fallback */}
                            {isDesktop && image && video_id && (
                              <VimeoIframe src={video_id} />
                            )}

                            {/* Image on mobile */}
                            {!isDesktop && image && video_id && (
                              <div
                                className="page-banner__slide-background"
                                style={{ backgroundImage: `url(${image})` }}
                              />
                            )}

                            {/* When there is no image */}
                            {(!image ||
                              image === "/images/original/missing.png") &&
                              video_id && <VimeoIframe src={video_id} />}
                          </>
                          <div className="page-banner__gradient page-banner__gradient--top" />
                          {isCapSerIndHeader ? (
                            <div className="page-banner__gradient page-banner__gradient--capabilities-cover" />
                          ) : (
                            <div className="page-banner__gradient page-banner__gradient--article-cover" />
                          )}
                        </div>
                      )}
                    </>
                  )
                })}

              {!banners.length && loopingVidBanners && (
                <>
                  {isDesktop ? (
                    <UploadedVideo
                      webm={loopingVidBanners.files.webm_version}
                      mp4={loopingVidBanners.files.mp4_version}
                    />
                  ) : (
                    <div className="page-banner__slide  swiper-slide">
                      <div
                        className="page-banner__slide-background"
                        style={{
                          backgroundImage: `url(${loopingVidBanners.files.mobile_image_mobile})`,
                        }}
                      />
                      <div className="page-banner__gradient page-banner__gradient--top" />
                      {isCapSerIndHeader ? (
                        <div className="page-banner__gradient page-banner__gradient--capabilities-cover" />
                      ) : (
                        <div className="page-banner__gradient page-banner__gradient--article-cover" />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          {isArticleHeader && (
            <>
              <ArticleHeader
                title={heading ? heading : ""}
                readIn={readIn ? readIn : ""}
                date={date ? date : ""}
                breadcrumb={breadcrumb ? breadcrumb : ""}
              />
            </>
          )}

          {isCampaignHeader && (
            <div className="slider-banner__words-wrapper">
              <div className="slider-banner__heading">
                <h1 className="typography__display--01 typography__uppercase typography__color--white typography__text-shadow">
                  {heading}
                </h1>
                <Spacer deviceSize="s" deviceOnly />
                {hasIntroText && isDesktop && (
                  <div className="intro-text__wrapper">
                    <Spacer deviceSize="s" />
                    <div
                      className="intro-text__text typography__text-shadow typography__color--white"
                      dangerouslySetInnerHTML={renderHtmlString(introText)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {isGenericHeader && (
            <div className={`slider-banner__words-wrapper ${modiferClassName}`}>
              <div className="slider-banner__heading">
                <h1
                  className={`${isDesktop
                    ? "typography__display--02"
                    : "typography__display--03"
                    } typography__uppercase typography__color--white typography__text-shadow`}
                >
                  {heading}
                </h1>
                {typeof project_status !== "undefined" && (
                  <>
                    {project_status !== null && (
                      <>
                        <Spacer deviceSize="s" />
                        <StatusPill status={project_status as StatusTypes} />
                      </>
                    )}
                  </>
                )}
                <Spacer deviceSize="s" deviceOnly />
                {hasIntroText && isDesktop && (
                  <div className="intro-text__wrapper">
                    <Spacer deviceSize="s" />
                    <div
                      className="intro-text__text typography__text-shadow typography__color--white"
                      dangerouslySetInnerHTML={renderHtmlString(introText)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {hasIntroText && !isDesktop && (
            <div className="intro-text__wrapper">
              <div
                className="intro-text__text typography__text-shadow"
                dangerouslySetInnerHTML={renderHtmlString(introText)}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default SliderBanner
