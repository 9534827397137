import React from "react"
import Spacer from "../../../layout/spacer"
import { LinkTypes } from "../../../../types"

const Link: React.FC<LinkTypes> = ({ slug, name }) => {
  const handleLinkClick = (e) => {
    e.preventDefault()
    location.href = `${slug}`
  }
  return (
    <span className="secondary-cta__wrapper">
      <button
        onClick={handleLinkClick}
        className="typography__caption--01 typography__weight--600 secondary-cta-nav-link"
      >
        {name}
      </button>
      <Spacer deviceSize="n" />
    </span>
  )
}

export default Link
