import React from "react"
import IconClose from "../../../../images/svgs/icon_close"
import { colors } from "../../../../variables"

interface DeviceFilterBarTypes {
  handleExpand: (e) => void
  handleClearFilters: (e) => void
  totalFiltersActive: number
  areFiltersChecked: boolean
}

const DeviceFilterBar: React.FC<DeviceFilterBarTypes> = ({
  handleExpand,
  handleClearFilters,
  totalFiltersActive,
  areFiltersChecked,
}) => {
  const hasActiveFilters = totalFiltersActive > 0 ? true : false
  return (
    <div className="filter-bar--device filter-actions">
      <button className="filter-action filters-expand" onClick={handleExpand}>
        <span
          className={
            hasActiveFilters
              ? "typography__color--green"
              : "typography__color--dark-blue"
          }
        >
          Filters
        </span>
        {totalFiltersActive > 0 && (
          <span
            className={`filter__keyword-counter filter__keyword-counter--collective ${hasActiveFilters ? "typography__color--green" : ""
              }`}
          >
            {totalFiltersActive}
          </span>
        )}
        <span className="filters-expand--icon">
          <IconClose color={colors.green} />
        </span>
      </button>
      <button
        disabled={!areFiltersChecked ? true : false}
        className="filter-action filters-clear"
        onClick={handleClearFilters}
      >
        CLEAR ALL
      </button>
    </div>
  )
}

export default DeviceFilterBar
