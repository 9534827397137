import { truncateText, removeImgTagFromString } from "../../../helpers"

export function handleTileClick(completeSlug: string): string {
  return (location.href = completeSlug)
}

export function truncateTitleText(title: string, isDesktop: boolean): string {
  let text = title
  if (isDesktop) {
    text = truncateText(title, 45)
  } else {
    text = truncateText(title, 38)
  }
  return text
}

export function truncateBodyText(
  title: string,
  summary: string,
  isDesktop: boolean
): string {
  let summary_text = summary

  // if two line title
  if (title.length > 28) {
    if (isDesktop) {
      summary_text = truncateText(removeImgTagFromString(summary), 60)
    } else {
      summary_text = truncateText(removeImgTagFromString(summary), 50)
    }
    return summary_text
  } else {
    if (isDesktop) {
      summary_text = truncateText(removeImgTagFromString(summary), 100)
    } else {
      summary_text = truncateText(removeImgTagFromString(summary), 90)
    }
    return summary_text
  }
}
