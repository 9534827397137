import React, { useEffect, useState } from "react"
import Odometer from "react-odometerjs"

interface ArticleCountTypes {
  count: number
  isLoading: boolean
}
const ArticleCount: React.FC<ArticleCountTypes> = ({ count, isLoading }) => {
  const [output, setOutput] = useState(0)
  useEffect(() => {
    setOutput(count)
  }, [count])

  return (
    <div className="article-count typography__body--01">
      <span className="typography__weight--600 article-results-odometer">
        {isLoading ? (
          <span style={{ opacity: 0.4 }} className="typography__caption--01">
            loading...
          </span>
        ) : (
          <>
            <Odometer value={output} format="(d)" />
            <span className="article-results--label">results</span>
          </>
        )}
      </span>
    </div>
  )
}

export default ArticleCount
