import React, { useEffect, useState } from "react"
import Cta from "../cta"
import NewsMetaData from "../news_metadata"

import {
  renderHtmlString,
  truncateText,
  strippedString,
} from "../../../../helpers"
import Spacer from "../../../layout/spacer"
import classnames from "classnames"
import { FeaturedNewsTileTypess } from "../types"

// Helpers
import { handleTileClick } from "../helpers"
import useDesktopDetector from "../../../../hooks/useDesktopDetector"

const FeaturedNewsTile: React.FC<FeaturedNewsTileTypess> = ({
  id,
  publish_date,
  read_in,
  leftAlignedFeature,
  listing_image,
  title,
  content,
  slug,
  sb_hover_active,
}) => {
  const tileClassnames = classnames("tile tile__feature", {
    "tile__feature--left": leftAlignedFeature,
    __hover: sb_hover_active,
  })

  const currentPath = "/news-and-insights"

  const completeSlug = currentPath ? `${currentPath}/${slug}` : slug

  const isDesktop = useDesktopDetector()

  const h1Classnames = classnames({
    "typography__display--06": !isDesktop,
    "typography__display--04": isDesktop,
  })

  const overviewLength = isDesktop ? 140 : 80
  return (
    <article id={`${id}`}>
      <a href={completeSlug} style={{ textDecoration: "none" }}>
        <div className={tileClassnames}>
          <div className="tile__image-cell">
            <picture className="tile__image-wrapper">
              <img className="tile__image" src={listing_image} alt="" />
            </picture>
          </div>
          <div className="tile__content-cell">
            <section className="tile__info tile__info--flex">
              <Spacer deviceSize="xxs" desktopSize="s" />
              <NewsMetaData
                darkBg={false}
                date={publish_date}
                readIn={read_in}
              />
              <Spacer deviceSize="xs" />
            </section>
            <span className="tile__title-link">
              <h1
                className={`${h1Classnames} typography__weight--700  typography__color--navy
              }`}
              >
                {truncateText(title, 67)}
              </h1>

              <Spacer deviceSize="xs" />

              {content && isDesktop && (
                <div
                  onClick={() => handleTileClick(completeSlug)}
                  className="typography__body--01 tile__overview typography__color--mid-grey"
                  dangerouslySetInnerHTML={renderHtmlString(
                    truncateText(strippedString(content), overviewLength)
                  )}
                />
              )}
            </span>
            <Cta completeSlug={completeSlug} />
          </div>
        </div>
      </a>
    </article>
  )
}

export default FeaturedNewsTile
