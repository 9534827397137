/* eslint-disable react/display-name */
import React from "react"
import Spacer from "../../layout/spacer"
import { motion } from "framer-motion"
import { Parallax } from "react-scroll-parallax"
import { ParallaxCache } from "../../../helpers"
import VerticalProgressorIcon from "../../elements/vertical_progressor_icon"

type Props = {
  onVerticalProgressorClick:
    | undefined
    | ((e: React.MouseEvent<HTMLButtonElement>) => void)
  isFirstSlide?: boolean
  isLastSlide?: boolean
  inView: boolean
  title: string
  paragraph: string
  history: History[]
  id: string
  images: Image[]
  isVisible?: boolean
}

interface History {
  title: string
  paragraph: string
}

interface Image {
  large?: {
    src: string
    caption: string
  }
  small?: {
    src: string
    caption?: string
  }
}

const animateInUp = {
  hidden: { opacity: 0, y: "60px" },
  visible: { opacity: 1, y: 0 },
}

const ContentSection = React.forwardRef<HTMLElement, Props>((props, ref) => {
  const {
    onVerticalProgressorClick,
    title,
    id,
    paragraph,
    history,
    images,
    inView,
    isFirstSlide = false,
    isLastSlide = false,
    isVisible = true,
  } = props

  return (
    <>
      <ParallaxCache />
      <section
        id={id}
        className={`our-history__content-section ${
          isVisible ? "show" : "hide"
        }`}
        ref={ref}
      >
        <article className="our-history__content-article">
          <div className="our-history__content-grid">
            {!isFirstSlide ? (
              <motion.div
                className="our-history__content-col our-history__content-col--text"
                variants={animateInUp}
                animate={inView ? "visible" : "hidden"}
                transition={{
                  staggerChildren: 0.5,
                  duration: 0.8,
                }}
              >
                <motion.div
                  variants={animateInUp}
                  transition={{
                    default: {
                      damping: 50,
                      type: "spring",
                      ease: "easeInOut",
                    },
                  }}
                >
                  <h1 className="typography__display--04 our-history__content--h1">
                    <span className="our-history__content--h1-backslash" />
                    {title}
                  </h1>
                </motion.div>

                <Spacer deviceSize="xs" />
                <motion.p
                  variants={animateInUp}
                  transition={{
                    default: {
                      damping: 50,
                      type: "spring",
                      ease: "easeInOut",
                    },
                  }}
                  className="typography__body--01"
                >
                  {paragraph}
                </motion.p>

                <Spacer deviceSize="m" />
                {history.length > 0 &&
                  history.map(({ title, paragraph }) => {
                    return (
                      <motion.div
                        key={title}
                        variants={animateInUp}
                        transition={{
                          default: {
                            damping: 50,
                            type: "spring",
                            ease: "easeInOut",
                          },
                        }}
                      >
                        <h2 className="typography__display--06 our-history__content--h2">
                          <span className="our-history__content--h2-backslash" />
                          {title}
                        </h2>
                        <Spacer deviceSize="n" />
                        <p className="typography__body--01">{paragraph}</p>
                        <Spacer deviceSize="s" />
                      </motion.div>
                    )
                  })}
              </motion.div>
            ) : (
              <Parallax
                y={history.length < 3 ? [0, -50] : [50, -50]}
                className="history-content__text"
              >
                <motion.div
                  className="our-history__content-col our-history__content-col--text"
                  variants={animateInUp}
                  animate={inView ? "visible" : "hidden"}
                  transition={{
                    staggerChildren: 0.5,
                    duration: 0.8,
                  }}
                >
                  <motion.div
                    variants={animateInUp}
                    transition={{
                      default: {
                        damping: 50,
                        type: "spring",
                        ease: "easeInOut",
                      },
                    }}
                  >
                    <h1 className="typography__display--04 our-history__content--h1">
                      <span className="our-history__content--h1-backslash" />
                      {title}
                    </h1>
                  </motion.div>

                  <Spacer deviceSize="xs" />
                  <motion.p
                    variants={animateInUp}
                    transition={{
                      default: {
                        damping: 50,
                        type: "spring",
                        ease: "easeInOut",
                      },
                    }}
                    className="typography__body--01"
                  >
                    {paragraph}
                  </motion.p>

                  <Spacer deviceSize="m" />
                  {history.length > 0 &&
                    history.map(({ title, paragraph }) => {
                      return (
                        <motion.div
                          key={title}
                          variants={animateInUp}
                          transition={{
                            default: {
                              damping: 50,
                              type: "spring",
                              ease: "easeInOut",
                            },
                          }}
                        >
                          <h2 className="typography__display--06 our-history__content--h2">
                            <span className="our-history__content--h2-backslash" />
                            {title}
                          </h2>
                          <Spacer deviceSize="n" />
                          <p className="typography__body--01">{paragraph}</p>
                          <Spacer deviceSize="s" />
                        </motion.div>
                      )
                    })}
                </motion.div>
              </Parallax>
            )}

            <div className="our-history__content-col our-history__content-col--image">
              <div className="our-history__content-images">
                {images.map((img, i) => {
                  return (
                    <div key={i}>
                      {img.large && img.small ? (
                        <>
                          <motion.figure
                            className="our-history__content-image--large our-history__image-wrapper"
                            variants={animateInUp}
                            animate={inView ? "visible" : "hidden"}
                            transition={{
                              x: {
                                damping: 12,
                                type: "spring",
                                ease: "easeInOut",
                              },
                              default: {
                                duration: 0.5,
                              },
                              opacity: {
                                duration: 1,
                              },
                            }}
                          >
                            <picture
                              className={`our-history__content-image--large our-history__image-wrapper ${
                                inView ? "slide-in" : "slide-out"
                              }`}
                            >
                              <source srcSet={img.large.src} />
                              <img src={img.large.src} alt="" />
                            </picture>
                            <figcaption className="typography__caption--02 typography__color--dark-grey">
                              {img.large.caption}
                            </figcaption>
                          </motion.figure>

                          <Spacer deviceSize="s" />
                          <motion.figure
                            className="our-history__image-wrapper our-history__content-image--small"
                            variants={animateInUp}
                            animate={inView ? "visible" : "hidden"}
                            transition={{
                              x: {
                                damping: 10,
                                type: "spring",
                                ease: "easeInOut",
                              },
                              default: {
                                delay: 0.24,
                                duration: 0.5,
                              },
                            }}
                          >
                            <picture
                              className={`our-history__image-wrapper our-history__content-image--small  ${
                                inView ? "slide-in" : "slide-out"
                              }`}
                            >
                              <source srcSet={img.small.src} />
                              <img src={img.small.src} alt="" />
                            </picture>{" "}
                            {img.small.caption && (
                              <figcaption className="typography__caption--02 typography__color--dark-grey">
                                {img.small.caption}
                              </figcaption>
                            )}
                          </motion.figure>
                        </>
                      ) : (
                        <motion.figure
                          className="our-history__content-image--large our-history__image-wrapper"
                          variants={animateInUp}
                          animate={inView ? "visible" : "hidden"}
                          transition={{
                            default: {
                              duration: 1.5,
                              damping: 12,
                              type: "spring",
                              ease: "easeInOut",
                            },
                          }}
                        >
                          {img.large && (
                            <>
                              <picture
                                className={`our-history__content-image--large our-history__image-wrapper  ${
                                  inView ? "slide-in" : "slide-out"
                                }`}
                              >
                                <source srcSet={img.large.src} />
                                <img src={img.large.src} alt="" />
                              </picture>
                              <figcaption className="typography__caption--02 typography__color--dark-grey">
                                {img.large.caption}
                              </figcaption>
                            </>
                          )}
                        </motion.figure>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          {!isLastSlide && !isFirstSlide && (
            <VerticalProgressorIcon onClick={onVerticalProgressorClick} />
          )}
        </article>
      </section>
    </>
  )
})

export default ContentSection
