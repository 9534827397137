import React from "react"
import Cta from "../cta"

import {
  renderHtmlString,
  truncateText,
  strippedString,
} from "../../../../helpers"
import Spacer from "../../../layout/spacer"
import classnames from "classnames"
import { FeaturedProjectTileTypes } from "../types"

// Helpers
import { handleTileClick } from "../helpers"
import useDesktopDetector from "../../../../hooks/useDesktopDetector"

const FeaturedProjectTile: React.FC<FeaturedProjectTileTypes> = ({
  leftAlignedFeature,
  teaser,
  listing_image,
  client_name,
  name,
  slug,
  sb_hover_active,
}) => {
  const tileClassnames = classnames("tile tile__feature", {
    "tile__feature--left": leftAlignedFeature,
    __hover: sb_hover_active,
  })

  const currentPath = "/projects"
  const completeSlug = currentPath ? `${currentPath}/${slug}` : slug

  const isDesktop = useDesktopDetector()

  const h1Classnames = classnames({
    "typography__display--06": !isDesktop,
    "typography__display--04": isDesktop,
  })
  const overviewLength = isDesktop ? 140 : 80
  return (
    <article>
      <a href={completeSlug} style={{ textDecoration: "none" }}>
        <div className={tileClassnames}>
          <div className="tile__image-cell">
            <picture className="tile__image-wrapper">
              <img className="tile__image" src={listing_image} alt="" />
            </picture>
          </div>
          <div className="tile__content-cell">
            <section className="tile__info tile__info--flex">
              <Spacer deviceSize="xxs" desktopSize="s" />
              <div className="tile__info-col tile__info--backslash tile__info--case-study">
                <h2 className="typography__caption--02 typography__weight--400 typography__color--green typography__weight--600">
                  {client_name}
                </h2>
              </div>
              <Spacer deviceSize="xs" />
            </section>
            <span className="tile__title-link">
              {name && (
                <h1
                  className={`${h1Classnames} typography__weight--700  typography__color--navy 
              }`}
                >
                  {truncateText(name, 67)}
                </h1>
              )}

              <Spacer deviceSize="xs" />

              {teaser && isDesktop && (
                <div
                  onClick={() => handleTileClick(completeSlug)}
                  className="typography__body--01 tile__overview typography__color--mid-grey"
                  dangerouslySetInnerHTML={renderHtmlString(
                    truncateText(strippedString(teaser), overviewLength)
                  )}
                />
              )}
            </span>
            <Cta completeSlug={completeSlug} vanityCta={true} />
          </div>
        </div>
      </a>
    </article>
  )
}

export default FeaturedProjectTile
