import React from "react"

import Grid from "../../modules/grid"
import Row from "../../modules/grid/row"
import EmployeeTile from "../../modules/tile/employee"
import Column from "../../modules/grid/column"
import Spacer from "../../layout/spacer"
import { PersonTileTypes } from "../../modules/tile/types"

import { renderHtmlString } from "../../../helpers"

interface PeopleTypes {
  people: PersonTileTypes[]
  name?: string
  title?: string
  blockHeading?: string
}

const People: React.FC<PeopleTypes> = ({ people, name, blockHeading }) => {
  return (
    <section id="our-people">
      <div>
        {name === "SAFETY & HEALTH" ? (
          <h1 className="typography__display--05 typography__weight--700">
            What safety means to our people
          </h1>
        ) : (
          <h1 className="typography__display--05 typography__weight--700">
            Meet some of the team
          </h1>
        )}

        {blockHeading && (
          <div
            className="our-people__block-heading"
            dangerouslySetInnerHTML={renderHtmlString(blockHeading)}
          />
        )}

        <Spacer deviceSize="s" />
        <div className="horizontal-divider hide-for-print" />
        <Spacer deviceSize="s" hideForPrint={true} />
        <Grid>
          <Row columnCount={3}>
            {people.map((person) => {
              const {
                id,
                bio,
                name,
                summary,
                teaser,
                profile_pics,
                linkedin_link,
                executive,
                job_title,
                quote,
              } = person
              return (
                <Column key={`${id}-capability-our-people-${id}`}>
                  <EmployeeTile
                    id={id}
                    bio={bio}
                    name={name}
                    teaser={teaser}
                    summary={summary}
                    profile_pics={profile_pics}
                    linkedin_link={linkedin_link}
                    executive={executive}
                    job_title={job_title}
                    quote={quote}
                  />
                  <Spacer deviceSize="s" hideForPrint={true} />
                </Column>
              )
            })}
          </Row>
        </Grid>
      </div>
    </section>
  )
}

export default People
