import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import useDesktopDetector from "../../hooks/useDesktopDetector"
import CarouselNavigation from "../elements/carousel-navigation"

interface Props {
  heading: string | null
  id: string
  settings: {
    infinite: boolean
    slidesToShow: number
    speed: number
    swipeToSlide: boolean
    touchThreshold: number
    touchMove: boolean
    responsive: [
      {
        breakpoint: number
        settings: {
          slidesToShow: number
          slidesToScroll: number
        }
      },
      {
        breakpoint: number
        settings: {
          slidesToShow: number
          slidesToScroll: number
        }
      }
    ]
  }
}

const TileSlider: React.FC<Props> = ({ id, heading, settings, children }) => {
  let slider = new Slider({})

  const next = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault()
    slider.slickNext()
  }
  const prev = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault()
    slider.slickPrev()
  }

  const isDesktop = useDesktopDetector()

  const ref = useRef<HTMLDivElement | null>(null)
  const sliderContentRef = useRef<HTMLDivElement | null>(null)
  const [contentOffsetLeft, setContentOffsetLeft] = useState(0)
  const [showNavigation, setShowNavigation] = useState(true)

  const shouldShowPagination = () => {
    const sliderContent = sliderContentRef.current
    if (sliderContent) {
      const slides = sliderContent.getElementsByClassName("slick-slide")
      const sliderContainerWidth = sliderContent.offsetWidth

      let slickSlidesCumulativeWidth = 0
      if (slides) {
        Object.keys(slides).forEach((key) => {
          slickSlidesCumulativeWidth += slides[key].offsetWidth
        })
      }
      setShowNavigation(slickSlidesCumulativeWidth > sliderContainerWidth)
    }
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const updateSlick = () => {
    shouldShowPagination()
    offsetContent()
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    updateSlick()
    window.addEventListener("resize", updateSlick)
    return () => {
      window.removeEventListener("resize", updateSlick)
    }
  }, [ref, isDesktop, window])

  const offsetContent = () => {
    if (!isDesktop) {
      setContentOffsetLeft(0)
    } else {
      setContentOffsetLeft(ref.current ? ref.current.offsetLeft : 0)
    }
  }

  const renderHeading = () => {
    if (heading) {
      return heading
    }
  }
  return (
    <section id={id}>
      <div className="tile-slider-wrapper tile-slider-wrapper__grey">
        <div className="tile-slider-carousel__navigation-wrapper" ref={ref}>
          <h2 className="typography__display--05 tile-slider-title">
            {renderHeading()}
          </h2>

          {showNavigation && (
            <CarouselNavigation prevClick={prev} nextClick={next} />
          )}
        </div>

        <div
          ref={sliderContentRef}
          style={{
            left: `${contentOffsetLeft}px`,
            position: "relative",
            width: `${windowWidth - contentOffsetLeft}px`,
          }}
        >
          <Slider ref={(c: Slider) => (slider = c)} {...settings}>
            {children}
          </Slider>
        </div>
      </div>
    </section>
  )
}

export default TileSlider
