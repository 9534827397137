import React, { useState } from "react"
import ToggleCta from "../../../cta/toggle_cta"
import Bio from "./bio"
import Column from "../../../modules/grid/column"

import { truncateText } from "../../../../helpers"
import Spacer from "../../../layout/spacer"
import classnames from "classnames"
import { isDesktop } from "../../../../helpers"
import useToggleBodyLock from "../../../../hooks/useBodyLock"
import { PersonTileTypes } from "../../tile/types"
import Picture from "../../../elements/picture"

const EmployeeTile: React.FC<PersonTileTypes> = ({
  id,
  bio,
  summary,
  teaser,
  name,
  profile_pics,
  linkedin_link,
}) => {
  const [bioActive, setBioActive] = useState<boolean>(false)
  const h1Classnames = classnames({
    "typography__display--06": isDesktop(),
    "typography__body--01-900": !isDesktop(),
  })

  function handleToggleBio(e) {
    e.preventDefault()
    setBioActive(true)
  }

  useToggleBodyLock(bioActive)

  return (
    <Column>
      <article className="tile tile__people single" id={`${id}`}>
        <div className="tile__image-cell">
          <Picture
            cssClassName="tile__image-wrapper"
            image1x={profile_pics.large}
            image2x={profile_pics.large}
          />
        </div>
        <div className="tile__content-cell">
          <div className="tile__people-name">
            <h1
              className={`${h1Classnames} typography__weight--700  typography__color--navy`}
            >
              {isDesktop() ? truncateText(name, 60) : truncateText(name, 47)}
            </h1>
          </div>

          <Spacer desktopOnly={true} desktopSize="xs" />

          {teaser !== undefined && (
            <p className="tile__overview typography__body--02 typography__color--mid-grey">
              {truncateText(teaser, 80)}
            </p>
          )}
          <ToggleCta
            label="Read More"
            modifierClass="tile__cta"
            onClick={handleToggleBio}
          />
        </div>
        <Bio
          summary={summary}
          bio={bio}
          setBioActive={setBioActive}
          isActive={bioActive}
          linkedinLink={linkedin_link}
        />
      </article>
      <Spacer deviceSize="s" />
    </Column>
  )
}

export default EmployeeTile
