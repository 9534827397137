import React from "react"
import PropTypes from "prop-types"

const IconCtaArrow = ({ color, modifierClass }) => (
  <svg
    width="35px"
    height="35px"
    viewBox="0 0 35 35"
    version="1.1"
    className={`svg_icon svg_cta_arrow ${modifierClass}`}
  >
    <g
      id="icon/download-copy"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="color">
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1" />
        </mask>
        <use id="Oval-Copy-3" stroke="#009946" xlinkHref="#path-1"></use>
      </g>
      <g id="Group" transform="translate(11.000000, 8.000000)" fill="#009946">
        <path
          fill={color}
          d="M-0.920743781,7.41220284 L11.1539324,7.41919008 L7.19535346,2.97976214 C6.99624249,2.75144367 6.9960752,2.40438774 7.18842757,2.18374154 C7.28709642,2.07025592 7.42079621,2.00602351 7.56510231,2.00042915 C7.70964263,1.99449228 7.84732397,2.05036739 7.95308599,2.15476503 C7.95308599,2.15476503 12.8415075,7.59935136 12.8415075,7.59935136 C12.8923308,7.65276039 12.9315776,7.71525741 12.9593146,7.7868424 C12.9853452,7.85525346 12.9987286,7.92544557 13,7.99737308 C12.9986617,8.07836574 12.9853452,8.14784999 12.9605191,8.21418314 C12.9321129,8.28679567 12.8920632,8.35139343 12.8407045,8.40514497 L7.96529831,13.8235634 C7.96258818,13.827057 7.954692,13.8361222 7.94632739,13.8434519 C7.72653912,14.0620202 7.37867207,14.050489 7.18043102,13.8165761 C7.08182909,13.7045062 7.03040352,13.5564726 7.0354892,13.4028445 C7.04037413,13.2485315 7.10140226,13.1060694 7.20716428,12.9999363 L11.154267,8.5734325 L-0.988162469,8.568546 C-1.02757647,8.57658361 -0.959120573,7.41220284 -0.920743781,7.41220284 Z"
          id="Right-Arrow-copy"
          transform="translate(6.000000, 8.000000) rotate(90.000000) translate(-6.000000, -8.000000) "
        />
        <polygon
          fill={color}
          id="Rectangle"
          points="-2.27318164e-13 18 13 18 13 19 -2.27318164e-13 19"
        />
      </g>
    </g>
  </svg>
)

IconCtaArrow.propTypes = {
  color: PropTypes.string.isRequired,
  modifierClass: PropTypes.string,
}

IconCtaArrow.defaultProps = {
  color: `#000`,
}

export default IconCtaArrow
