import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { Parallax } from "react-scroll-parallax"
import Grid from "../../modules/grid"
import Column from "../../modules/grid/column"
import Row from "../../modules/grid/row"
import Spacer from "../../layout/spacer"
import { minDesktopSize } from "../../../variables"
import classnames from "classnames"
import useComponentInView from "../../../hooks/useComponentInView"
import Wysiwyg from "../../ui/wysiwyg"
import { TwoColumnLayoutSlideTypes } from "./types"
import VideoTile from "./video_tile"

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.75,
    },
  },
}


const animateInUp = {
  hidden: { y: "60px", opacity: 0 },
  visible: { y: 0, opacity: 1 },
}

const animateInAcross = {
  hidden: { opacity: 0, x: "-60px" },
  visible: { opacity: 1, x: 0 },
}

const TwoColumnLayoutSlide: React.FC<TwoColumnLayoutSlideTypes> = ({
  title,
  body_text,
  top_images,
  bottom_images,
  description,
  vimeo_id,
  blockColourOverride,
  aligned,
  modifierClass,
  imageRevealEffect,
}) => {
  const [ref, inView] = useInView({
    threshold: 0,
  })


  const containerClasses = classnames("two-column-layout-slide", {
    [`two-column-layout-slide--${aligned}`]: aligned,
    [`two-column-layout-slide--image-reveal`]: imageRevealEffect,
    [`${modifierClass}`]: modifierClass,
  })

  const componentSeen = useComponentInView(inView)
  const componentSeenBottom = useComponentInView(inView)

  const [imageYCors, setImageYCords] = useState([0, -10])
  const [imageYBottomCors, setImageYBottomCords] = useState([0, -14])

  const handleResize = () => {
    if (window.innerWidth < minDesktopSize) {
      setImageYCords([0, 0])
      setImageYBottomCords([0, 0])
    } else {
      setImageYCords([0, -10])
      setImageYBottomCords([0, -14])
    }
  }

  useEffect(() => {
    if (window.innerWidth < minDesktopSize) {
      setImageYCords([0, 0])
      setImageYBottomCords([0, 0])
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div className="showcase__block-wrapper showcase-bg--white showcase-wrapper__two-column-layout-slide">
      <div className={containerClasses}>
        <article className={`two-column-layout-slide__article two-column-layout-slide__reveal-wrapper`}>
          <Grid modifierClass="two-column-layout-slide__grid">
            <Row columnCount={2}>
              <Column>
                <motion.div
                  className="device-content-padding two-column-layout-slide__article--text"
                >
                  <motion.div
                    className="two-column-layout-slide__body-text"
                  >
                    {title && (
                      <h2 className="two-column-layout-slide__body-title">{title}</h2>
                    )}
                    <Wysiwyg content={body_text} />
                  </motion.div>
                </motion.div>

                <motion.div
                  className="device-content-padding visible__desktop-up"
                >
                  <motion.div
                    className="two-column-layout-slide__body-text two-column-layout-slide__caption"
                  >
                    {description && (
                      <>
                        <Spacer deviceSize="xs" desktopSize="s" />
                        <span>Description</span>
                        <p>{description}</p>
                      </>
                    )}
                  </motion.div>
                </motion.div>
              </Column>



              <Column>
                <div ref={ref} className="two-column-layout-slide__images">
                  <div className="two-column-layout-slide__image two-column-layout-slide__image--top">
                    <motion.div
                      initial="hidden"
                      animate={componentSeen ? "visible" : "hidden"}
                      variants={animateInAcross}
                      transition={{
                        default: {
                          damping: 20,
                          delay: 1,
                          duration: 0.6,
                          type: "spring",
                          ease: "easeOut",
                        },
                      }}
                    >
                      <Parallax y={imageYCors}>
                        {top_images && (
                          <div className="two-column-layout-slide__image-overlay">
                            <picture
                              className={`two-column-layout-slide__image-wrapper ${componentSeen ? " two-column-layout-slide__image-reveal" : ""} `}
                            >
                              <source
                                srcSet={`${top_images.medium} 1x, ${top_images.large} 2x`}
                              />
                              <img
                                className="two-column-layout-slide__image--file"
                                src={top_images.medium}
                                alt=""

                              />
                            </picture>
                          </div>
                        )}
                        {vimeo_id && (
                          <VideoTile
                            componentSeen={componentSeen}
                            video_url={vimeo_id}
                          />
                        )}
                      </Parallax>
                    </motion.div>


                    <motion.div
                      className="two-column-layout-slide__bg-color"
                      animate={componentSeen ? "visible" : "hidden"}
                      variants={animateInUp}
                      initial="hidden"
                      transition={{
                        default: {
                          damping: 12,
                          delay: 0.8,
                          duration: 0.6,
                          type: "spring",
                          ease: "easeOut",
                        },
                      }}
                      style={{
                        backgroundColor: blockColourOverride
                          ? blockColourOverride
                          : undefined,
                      }}
                    />
                  </div>



                  <div ref={ref} className="two-column-layout-slide__image two-column-layout-slide__image--bottom">
                    <motion.div
                      initial="hidden"
                      animate={componentSeenBottom ? "visible" : "hidden"}
                      variants={animateInAcross}
                      transition={{
                        default: {
                          damping: 20,
                          delay: 1.5,
                          duration: 0.6,
                          type: "spring",
                          ease: "easeOut",
                        },
                      }}
                    >
                      <Parallax y={imageYBottomCors}>
                        {bottom_images && (
                          <div className="two-column-layout-slide__image-overlay">
                            <picture
                              className={`two-column-layout-slide__image-wrapper ${componentSeenBottom ? " two-column-layout-slide__image-reveal-bottom" : ""} `}

                            >
                              <source
                                srcSet={`${bottom_images.medium} 1x, ${bottom_images.medium} 2x`}
                              />
                              <img
                                className="two-column-layout-slide__image--file"
                                src={bottom_images.medium}
                                alt=""

                              />
                            </picture>
                          </div>
                        )}
                        {vimeo_id && (
                          <VideoTile
                            componentSeen={componentSeenBottom}
                            video_url={vimeo_id}
                          />
                        )}
                      </Parallax>
                    </motion.div>

                    <motion.div
                      className="two-column-layout-slide__bg-color"
                      animate={componentSeenBottom ? "visible" : "hidden"}
                      variants={animateInUp}
                      initial="hidden"
                      transition={{
                        default: {
                          damping: 12,
                          delay: 1.3,
                          duration: 0.6,
                          type: "spring",
                          ease: "easeOut",
                        },
                      }}
                      style={{
                        backgroundColor: blockColourOverride
                          ? blockColourOverride
                          : undefined,
                      }}
                    />

                  </div>

                  <motion.div
                    variants={container}
                    initial="hidden"
                    className="device-content-padding two-column-layout-slide__caption visible__desktop-down"
                    animate={componentSeen ? "show" : "hide"}
                  >
                    <motion.div
                      className="two-column-layout-slide__body-text"
                    >
                      {description && (
                        <>
                          <span>Description</span>
                          <p>{description}</p>
                        </>
                      )}
                    </motion.div>
                  </motion.div>
                </div>
              </Column>


            </Row>

          </Grid>
        </article>
      </div>
    </div >
  )
}

export default TwoColumnLayoutSlide
