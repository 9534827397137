import React, { useEffect } from "react"
import Swiper from "swiper/swiper-bundle.min.js"
import Spacer from "../../../layout/spacer"
import PrimaryCta from "../../../cta/primary_cta"
import News from "../../homepage_tiles/news"
import { HomepageSliderBannerTypes } from "../types"
import useDesktopDetector from "../../../../hooks/useDesktopDetector"

const HomepageSliderBanner: React.FC<HomepageSliderBannerTypes> = ({
  banners,
  loopingVidBanners,
  isStorybook = false,
}) => {
  const swiperRef = React.createRef<HTMLDivElement>()

  useEffect(() => {
    const swiper = new Swiper(swiperRef.current, {
      slidesPerView: 1,
      navigation: {
        nextEl: ".page-banner__next",
        prevEl: ".page-banner__prev",
      },
      autoplay: {
        delay: 6000,
      },
      speed: 800,
      resistance: false,
      loop: true,
      effect: "slide",
      fadeEffect: {
        crossfade: true,
      },
    })

    if (swiperRef.current) {
      swiper
    }
  }, [swiperRef])

  const isDesktop = useDesktopDetector()

  return (
    <div role="banner" className="slider-banner">
      <div className="slider-banner__home-page">
        <div className="page-banner" ref={swiperRef}>
          <div className="page-banner__navigation">
            <div className="page-banner__navigation-button page-banner__prev"></div>
            <span className="navigation-button-divider"></span>
            <div className="page-banner__navigation-button page-banner__next"></div>
          </div>

          <div className="page-banner__wrapper swiper-wrapper">
            {banners.length >= 1 &&
              banners.map(
                ({ id, image, title, link_text, link, looping_vid_id }) => {
                  return (
                    <div key={id} className="page-banner__slide swiper-slide">
                      <div className="page-banner__slide-background homepage">
                        {looping_vid_id ? (
                          loopingVidBanners.map((vid) => {
                            if (vid.id === looping_vid_id) {
                              return (
                                <React.Fragment key={looping_vid_id}>
                                  <div className="page-banner__content-wrap">
                                    <div className="page-banner__content-row">
                                      <div className="page-banner__content">
                                        <div className="page-banner__content--top">
                                          <h1 className="typography__display--02 typography__text-shadow typography__uppercase typography__color--white ">
                                            {title}
                                          </h1>
                                        </div>
                                        <Spacer deviceSize="xs" />
                                        <div className="banner-content__bottom">
                                          {link_text && (
                                            <PrimaryCta
                                              label={link_text}
                                              link={link}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="page-banner__gradient page-banner__gradient--top" />
                                  <div className="page-banner__gradient page-banner__gradient--bottom" />
                                  <div className="page-banner__gradient page-banner__gradient--cover" />
                                  <video
                                    playsInline={true}
                                    className="homepage-video home-bg__video active"
                                    width="100%"
                                    poster={vid.files.mobile_image_mobile}
                                    muted={true}
                                    loop={true}
                                    autoPlay
                                  >
                                    <source
                                      id="webmSource"
                                      src={vid.files.webm_version}
                                      type="video/webm"
                                    />
                                    <source
                                      id="mp4Source"
                                      src={vid.files.mp4_version}
                                      type="video/mp4"
                                    />
                                  </video>
                                </React.Fragment>
                              )
                            }
                          })
                        ) : (
                          <div
                            className="page-banner__homepage-content"
                            style={{ backgroundImage: `url(${image})` }}
                          >
                            <div className="page-banner__content-wrap">
                              <div className="page-banner__content-row">
                                <div className="page-banner__content">
                                  <div className="page-banner__content--top">
                                    <h1 className="typography__display--02 typography__text-shadow typography__uppercase typography__color--white ">
                                      {title}
                                    </h1>
                                  </div>
                                  <Spacer deviceSize="xs" />
                                  <div className="banner-content__bottom">
                                    {link_text && (
                                      <PrimaryCta
                                        label={link_text}
                                        link={link}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {isDesktop && (
                              <div
                                className="page-banner__background-image"
                                style={{ backgroundImage: `url(${image})` }}
                              />
                            )}
                            <div className="page-banner__gradient page-banner__gradient--top" />
                            <div className="page-banner__gradient page-banner__gradient--bottom" />
                            <div className="page-banner__gradient page-banner__gradient--cover" />
                          </div>
                        )}
                      </div>
                    </div>
                  )
                }
              )}
          </div>
          <div className="page-banner__news">
            {!isStorybook && <News />}
            <Spacer deviceSize="n" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomepageSliderBanner
