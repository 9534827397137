import React from "react"
import FilterButton from "../../../filters/filter_button"
import Filter from "../../../filters/filter"
import FilterResultsCount from "../../../filters/filter_results_count"
import { FiltersInterface } from "./interface"

const FilterBar: React.FC<FiltersInterface> = ({
  handleApply,
  isLoading,
  children,
  handleClear,
  areFiltersChecked,
  industries,
  services,
  handleIndustryKeywordClick,
  handleServicesKeywordClick,
  checkedIndustriesFilters,
  checkedServicesFilters,
  isExpanded,
  results,
}) => {
  return (
    <div
      className={`filter-bar filter-list-module filter-list-module--capabilities ${
        isExpanded ? "filter-bar--expanded" : "filter-bar--contracted"
      }`}
    >
      {children}
      <span className="filter-types--title">filter by</span>
      <div
        className={`filter-types ${
          isExpanded ? "filter-types--expand" : "filter-types--contract"
        }`}
      >
        {/* Industries */}
        <div className="filter-list capabilities">
          <FilterButton
            title="industries"
            checkedFilters={checkedIndustriesFilters}
          />

          <div
            className={`filters__wrapper ${
              isExpanded ? "filters__wrapper--show" : "filters__wrapper--hide"
            }`}
          >
            <div className="container container--filters_list">
              <div className="filter__list-container">
                <ul>
                  {industries !== null &&
                    industries.map(({ name, id }) => {
                      return (
                        <Filter
                          isDisabled={false}
                          filterModifierClass="filter-industries"
                          id={`${id}`}
                          key={`industries--${id}`}
                          category="industries"
                          filter={`${name}`}
                          onChange={(e) => handleIndustryKeywordClick(e, name)}
                        />
                      )
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Services */}
        <div className="filter-list">
          <FilterButton
            title="services"
            checkedFilters={checkedServicesFilters}
          />

          <div
            className={`filters__wrapper ${
              isExpanded ? "filters__wrapper--show" : "filters__wrapper--hide"
            }`}
          >
            <div className="container container--filters_list">
              <div className="filter__list-container">
                <ul>
                  {services !== null &&
                    services.map(({ name, id }) => {
                      return (
                        <Filter
                          isDisabled={false}
                          filterModifierClass="filter-services"
                          id={`${id}`}
                          key={`services--${id}`}
                          category="services"
                          filter={`${name}`}
                          onChange={(e) => handleServicesKeywordClick(e, name)}
                        />
                      )
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`filter-actions ${
            isExpanded ? "filter-actions--border" : "filter-actions--no-border"
          }
      `}
        >
          <button onClick={handleApply} className="filter-action filters-apply">
            Apply
          </button>
          <button
            onClick={handleClear}
            className="filter-action filters-clear"
            disabled={!areFiltersChecked ? true : false}
          >
            Clear
          </button>
        </div>
      </div>

      <FilterResultsCount
        isExpanded={isExpanded}
        isLoading={isLoading}
        handleOnClick={handleApply}
        results={results}
      />
    </div>
  )
}

export default FilterBar
