import React from "react"
import ToggleCta from "../../../cta/toggle_cta"
import { renderHtmlString, truncateText } from "../../../../helpers"
import { minDesktopSize } from "../../../../variables"
import Column from "../../../modules/grid/column"
import Spacer from "../../../layout/spacer"
import classnames from "classnames"
import { isDesktop, openExternalLink } from "../../../../helpers"
import placeholder from "../thumbnails.json"
import { LocationTileTypes } from "../types"

const LocationTile: React.FC<LocationTileTypes> = ({
  external_link,
  details,
  name,
  image_large,
  position,
}) => {
  const h1Classnames = classnames({
    "typography__body--02": isDesktop(),
    "typography__body--01-900": !isDesktop(),
  })

  return (
    <Column>
      <article className="tile tile__single tile__location" id={`${position}`}>
        <div className="tile__image-cell">
          <picture className="tile__image-wrapper">
            <source
              srcSet={image_large || placeholder.locationImage}
              media={`(min-width:${minDesktopSize}px)`}
            />
            <img
              className="tile__image"
              src={image_large || placeholder.locationImage}
              alt={name}
            />
          </picture>
        </div>
        <div className="tile__content-cell">
          <div className="tile__location-name">
            <h1
              className={`${h1Classnames} typography__weight--700  typography__color--navy`}
            >
              {isDesktop() ? truncateText(name, 60) : truncateText(name, 47)}
            </h1>
          </div>

          <Spacer desktopOnly={true} desktopSize="xs" deviceLayout="row" />

          {details !== null && (
            <div
              className="tile__overview typography__color--mid-grey"
              dangerouslySetInnerHTML={renderHtmlString(
                truncateText(details, 122)
              )}
            />
          )}
          <ToggleCta
            label="See on Google Maps"
            modifierClass="tile__cta"
            onClick={() => openExternalLink(external_link)}
          />
        </div>
      </article>
      <Spacer deviceSize="xxs" />
    </Column>
  )
}

export default LocationTile
