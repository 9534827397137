import React from "react"
import { Job } from "./types"
import moment from "moment"
import TertiaryCta from "../../cta/tertiary_cta"

type JobItemTypes = Pick<
  Job,
  "name" | "summary" | "location" | "origin_published_date" | "slug"
>

const JobItem: React.FC<JobItemTypes> = ({
  name,
  summary,
  location,
  origin_published_date,
  slug,
}) => {
  const formattedDate = moment(origin_published_date).format("D MMM YYYY")

  return (
    <>
      <tr>
        <td>
          <a
            className="job-link"
            href={`${window.location.origin}${window.location.pathname}/${slug}`}
          >
            {name}
          </a>
        </td>
        <td>
          <span className="location">{location}</span>
        </td>
        <td>
          <span className="close-date">{formattedDate}</span>
        </td>
      </tr>
      <tr className="summary">
        <td>{summary}</td>
        <td></td>
        <td>
          <TertiaryCta
            slug={`${window.location.origin}${window.location.pathname}/${slug}`}
            name="learn More"
          />
        </td>
      </tr>
    </>
  )
}

export default JobItem
