import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import Column from "../../modules/grid/column"
import DownloadTile from "../../modules/tile/download"
import LinkTile from "../../modules/tile/link"
import VideoTile from "../../modules/tile/video"
import useDesktopDetector from "../../../hooks/useDesktopDetector"
import CarouselNavigation from "../../elements/carousel-navigation"

import { DownloadTileTypes, VideoTileTypes, LinkTileTypes } from "../tile/types"

interface MultimediaTypes {
  downloads?: DownloadTileTypes[]
  videos?: VideoTileTypes[]
  links?: LinkTileTypes[]
  heading: string | null
}

const Multimedia: React.FC<MultimediaTypes> = ({
  heading,
  downloads = [],
  videos = [],
  links = [],
}) => {
  const moreThanOne = () => {
    if (downloads.length >= 2 || videos.length >= 2 || links.length >= 2) {
      return true
    } else return false
  }
  const settings = {
    infinite: false,
    slidesToShow: 4,
    speed: 900,
    swipeToSlide: true,
    touchThreshold: 10,
    touchMove: true,

    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: moreThanOne() ? 1.1 : 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  let slider = new Slider({})

  const next = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault()
    slider.slickNext()
  }
  const prev = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault()
    slider.slickPrev()
  }

  const isDesktop = useDesktopDetector()

  const ref = useRef<HTMLDivElement | null>(null)
  const sliderContentRef = useRef<HTMLDivElement | null>(null)
  const [contentOffsetLeft, setContentOffsetLeft] = useState(0)
  const [showNavigation, setShowNavigation] = useState(true)

  const shouldShowPagination = () => {
    const sliderContent = sliderContentRef.current
    // cant use a ref here i think? because this div is generated by the slick package
    if (sliderContent) {
      const slides = sliderContent.getElementsByClassName("slick-slide")
      const sliderContainerWidth = sliderContent.offsetWidth

      let slickSlidesCumulativeWidth = 0
      if (slides) {
        Object.keys(slides).forEach((key) => {
          slickSlidesCumulativeWidth += slides[key].offsetWidth
        })
      }
      setShowNavigation(slickSlidesCumulativeWidth > sliderContainerWidth)
    }
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const updateSlick = () => {
    shouldShowPagination()
    offsetContent()
    setWindowWidth(window.innerWidth)
  }
  useEffect(() => {
    updateSlick()

    window.addEventListener("resize", updateSlick)
    return () => {
      window.removeEventListener("resize", updateSlick)
    }
  }, [ref, isDesktop, videos, downloads, window])

  const offsetContent = () => {
    if (!isDesktop) {
      setContentOffsetLeft(0)
    } else {
      setContentOffsetLeft(ref.current ? ref.current.offsetLeft : 0)
    }
  }

  const downloadItems = (items: DownloadTileTypes[]) => {
    const output = items.map((download) => {
      const {
        id,
        url,
        summary,
        images,
        file_content_type,
        image_file_name,
        name,
      } = download

      return (
        <Column key={id}>
          <div className="multimedia-tile--wrapper ">
            <DownloadTile
              id={id}
              file_content_type={file_content_type}
              image_file_name={image_file_name}
              images={images}
              name={name}
              summary={`${summary === null ? "" : summary}`}
              url={url}
            />
          </div>
        </Column>
      )
    })
    return output
  }

  const videoItems = (items: VideoTileTypes[]) => {
    const output = items.map(({ id, name, video_url, summary, images }) => {
      return (
        <Column key={id}>
          <div className="multimedia-tile--wrapper ">
            <VideoTile
              id={id}
              name={name}
              summary={summary}
              video_url={video_url}
              images={images}
            />
          </div>
        </Column>
      )
    })
    return output
  }

  const linkItems = (items: LinkTileTypes[]) => {
    const output = items.map(({ id, name, summary, images, url, link_text }) => {
      return (
        <Column key={id}>
          <div className="multimedia-tile--wrapper ">
            <LinkTile
              id={id}
              name={name}
              summary={summary}
              images={images}
              url={url}
              link_text={link_text}
            />
          </div>
        </Column>
      )
    })
    return output
  }

  const renderHeading = () => {
    if (heading && (!!videos || !!links)) {
      return heading
    } else {
      return "Resources"
    }
  }
  return (
    <section id="multimedia">
      <div className="multimedia-wrapper multimedia-wrapper__grey">
        <div className="multimedia-carousel__navigation-wrapper" ref={ref}>
          <h2 className="typography__display--05 multimedia-title">
            {renderHeading()}
          </h2>

          {showNavigation && (
            <CarouselNavigation prevClick={prev} nextClick={next} />
          )}
        </div>

        <div
          ref={sliderContentRef}
          style={{
            left: `${contentOffsetLeft}px`,
            position: "relative",
            width: `${windowWidth - contentOffsetLeft}px`,
          }}
        >
          <Slider
            ref={(c: Slider) => (slider = c)}
            {...settings}
            className={`multimedia-carousel-slider ${
              videos ? "multimedia-carousel-slider--videos" : ""
            }`}
          >
            {downloads && downloadItems(downloads)}
            {videos && videoItems(videos)}
            {links && linkItems(links)}
          </Slider>
        </div>
      </div>
    </section>
  )
}

export default Multimedia
