import React, { useEffect, useState } from "react"

const auEndpoint =
  "https://wcsecure.weblink.com.au/Clients/Ventia/pricejson.aspx"
const nzEndpoint =
  "https://wcsecure.weblink.com.au/Clients/Ventia/nzx/pricejson.aspx"

const auLabel = "ASX"
const nzLabel = "NZX"

type Props = {
  isNz: boolean
}

const StockPrice: React.FC<Props> = ({ isNz }) => {
  const [nzData, setNzData] = useState({
    lastprice: null,
  })
  const [auData, setAuData] = useState({
    lastprice: null,
  })

  useEffect(() => {
    fetch(auEndpoint)
      .then((res) => res.json())
      .then((d) => {
        setAuData({
          lastprice: d.quote[0].lastprice,
        })
      })
    fetch(nzEndpoint)
      .then((res) => res.json())
      .then((d) =>
        setNzData({
          lastprice: d.quote[0].lastprice,
        })
      )
  }, [])

  useEffect(() => {
    if (isNz && nzData) {
      setActive({
        label: auLabel,
        price: nzData.lastprice,
        isAsx: false,
        isNzx: true,
      })
    } else if (!isNz && auData) {
      setActive({
        label: auLabel,
        price: auData.lastprice,
        isAsx: true,
        isNzx: false,
      })
    } else return
  }, [nzData, auData, isNz])

  const [active, setActive] = useState({
    label: isNz ? nzLabel : auLabel,
    price: isNz ? nzData.lastprice : auData.lastprice,
    isAsx: !isNz,
    isNzx: isNz,
  })

  function toggler() {
    if (active.label === auLabel) {
      setActive({
        label: nzLabel,
        price: nzData.lastprice,
        isAsx: false,
        isNzx: true,
      })
    } else {
      setActive({
        label: auLabel,
        price: auData.lastprice,
        isAsx: true,
        isNzx: false,
      })
    }
  }

  return (
    <div className="stock-price">
      <div className="stock-price__toggle">
        <div>
          <label
            className={`typography__caption--01 stock-price__toggle-label ${
              active.label === nzLabel ? "active" : "inactive"
            }`}
          >
            {nzLabel}
          </label>
        </div>
        <button
          onClick={toggler}
          className={`stock-price__toggle-btn ${
            active.isAsx
              ? "stock-price__toggle-btn--asx"
              : "stock-price__toggle-btn--nzx"
          }`}
        />
        <div>
          <label
            className={`typography__caption--01 stock-price__toggle-label ${
              active.label === auLabel ? "active" : "inactive"
            }`}
          >
            {auLabel}
          </label>
        </div>
      </div>
      {/* Price */}
      <p className="typography__caption--01">
        VNT Share Price: ${active.price}
      </p>
    </div>
  )
}

export default StockPrice
