import React from "react"

interface PillTypes {
  isFilterPill?: boolean
  category?: string
  id: string
  onClick?: () => void
  onChange?: any
  checked?: boolean
  name?: string
}

const SquarePill: React.FC<PillTypes> = ({
  isFilterPill = false,
  category,
  id,
  checked,
  onClick,
  onChange,
  name,
}) => {
  return !isFilterPill ? (
    <li className="square-pill__item">
      <button className="square-pill__button" id={id} onClick={onClick}>
        <span className="typography__caption--01">{name}</span>
      </button>
    </li>
  ) : (
    <li className="square-pill__filter-item">
      <input
        className="square-pill__filter-input"
        type="checkbox"
        name={`${category}-checkbox`}
        id={id}
        onChange={onChange}
        value={name}
        onClick={onClick}
        checked={checked}
      />

      <label className="square-pill__filter-label" htmlFor={id}>
        {id}
      </label>
    </li>
  )
}

export default SquarePill
