import React, { useState } from "react"
import SecondaryCta from "../../cta/secondary_cta"
import Spacer from "../../layout/spacer"
import classnames from "classnames"
import { placeholderImage } from "../../__mocks__/placeholder"

interface LinkModuleTypes {
  title: string
  links: LinkTypes[]
  image: string
  isBroadspectrum?: boolean
  broadspectrumImage?: string
  modifierClass?: string
  slim?: boolean
}
interface LinkTypes {
  link: string
  name: string
  hover_image_url: string
}

const LinkModule: React.FC<LinkModuleTypes> = ({
  title,
  links,
  image,
  isBroadspectrum,
  broadspectrumImage,
  modifierClass,
  slim,
}) => {
  const secondaryNavItemClassnames = classnames({
    "link-module__items--1": links.length === 1,
    "link-module__items--2": links.length === 2,
    "link-module__items--4": links.length === 4,
    "link-module__items--6": links.length === 6,
    "link-module__items--many": links.length >= 7,
  })

  const [secondaryImage, setSecondaryImage] = useState(image)
  const handleMouseEnter = (img: string) => {
    if (img) {
      setSecondaryImage(img)
    } else {
      setSecondaryImage(placeholderImage)
    }
  }

  const modifierClasses = classnames({
    [`${modifierClass}`]: modifierClass,
  })

  return (
    <section className={`link-module ${modifierClasses}`}>
      <div className="grid">
        <div className="grid-column">
          <div className="grid grid-direction--row">
            <div className="grid-column">
              {!slim && <Spacer desktopSize="xm" />}
              <h1 className="typography__display--04 typography__color--navy">
                {title}
              </h1>
              <Spacer desktopSize="s" />
            </div>
          </div>
          <div className="grid grid-direction--row">
            <div className="grid-column link-module-grid-column--image">
              <div
                className="link-module__image-wrapper"
                style={{
                  backgroundImage: `url(${
                    isBroadspectrum ? broadspectrumImage : secondaryImage
                  })`,
                }}
              />
            </div>

            <div className="grid-column">
              <ul className="grid grid-direction--row link-module__list">
                {links !== null &&
                  links.map(({ name, link, hover_image_url }) => {
                    return (
                      <li
                        onMouseOver={() =>
                          handleMouseEnter(
                            hover_image_url ? hover_image_url : secondaryImage
                          )
                        }
                        key={name}
                        className={`grid-column link-module__items ${secondaryNavItemClassnames}`}
                      >
                        <SecondaryCta
                          slug={link}
                          name={name}
                          inLinkModule={true}
                        />
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {!slim && <Spacer desktopSize="m" />}
    </section>
  )
}

export default LinkModule
