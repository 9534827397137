import React from "react"
import classnames from "classnames"

interface TagTypes {
  isTileTag: boolean
  id: string
  link: string
  name: string
}

const Tag: React.FC<TagTypes> = ({ id, isTileTag, link, name = "" }) => {
  const aClasses = classnames({
    "typography__caption--02": isTileTag,
    "typography__caption--01": !isTileTag,
  })
  const liClasses = classnames({
    "tag__in-tile": isTileTag,
  })

  return (
    <li className={`tag__item ${liClasses}`}>
      <a className="tag__link" id={id} href={link}>
        <span className={aClasses}>{name}</span>
      </a>
    </li>
  )
}

export default Tag
