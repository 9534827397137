import React from "react"
import Block from './block';

import { ParallaxProvider } from "react-scroll-parallax"
import CtaBanner from "../../modules/cta_banner"
// Types
import { PageModuleTypes } from "./types"

const ShowcaseLandingPage: React.FC<PageModuleTypes> = ({
  showcaseTheme,
  ctaBanner,
  showcaseTiles,
}) => {

  return (
    <>
      <div
        className={`page-content-wrap content-page__wrapper--showcase showcase-color-override-${showcaseTheme}`}
      >
        <ParallaxProvider>
          {showcaseTiles.map((record) => (
            <Block key={`${record.title}-${record.id}`} record={record} />
          ))}
        </ParallaxProvider>

      </div>
      {ctaBanner && (
        <CtaBanner
          isContentBanner={true}
          title={ctaBanner.title}
          description={ctaBanner.description}
          buttonTitle={ctaBanner.button_title}
          ctaLink={ctaBanner.button_url}
          footer={ctaBanner}
        />
      )}
    </>
  )
}

export default ShowcaseLandingPage

