import React from "react"
import classnames from "classnames"
import PrimaryCta from "../../cta/primary_cta"
import { baseUrlForJobSearch } from "../../../global"
import ContentBanner from "../content_banner"
import { CtaBannerTypes } from "./types"

function defaultTitle(bannerText, highlightedBannerText, globalCtaFooter) {
  if (bannerText || highlightedBannerText) {
    return (
      <>
        {bannerText}{" "}
        <span className="typography__color--bright-green">
          {highlightedBannerText}
        </span>
      </>
    )
  } else if (globalCtaFooter) {
    return (
      <>
        {globalCtaFooter.title}{" "}
        <span className="typography__color--bright-green">
          {globalCtaFooter.hightlightedTitle}
        </span>
      </>
    )
  } else return null
}

const CtaBanner: React.FC<CtaBannerTypes> = ({
  id,
  footer,
  description,
  isContentBanner,
  isCapability,
  capability,
  title,
  sector,
  sectorChild,
  buttonTitle,
  bannerText,
  highlightedBannerText,
  globalCtaFooter,
  ctaLink,
  withLargeCta,
  formPage,
}) => {
  const linkClassnames = classnames({
    "with-large-cta": withLargeCta,
    "form-modal-open": formPage,
  })

  return (
    <>
      {!isContentBanner ? (
        <section className="wrapper wrapper--cta" id={`${id ? id : ""}`}>
          <div className="container container--cta">
            <h1 className="typography__display--02 typography__uppercase  cta-title">
              {isCapability
                ? `Submit your ${capability} enquiry`
                : defaultTitle(
                    bannerText,
                    highlightedBannerText,
                    globalCtaFooter
                  )}
            </h1>
            <PrimaryCta
              link={`${baseUrlForJobSearch}${
                globalCtaFooter ? globalCtaFooter.button_url : ctaLink
              }`}
              label={`${globalCtaFooter ? globalCtaFooter.title : buttonTitle}`}
              modifierClass={`${linkClassnames}`}
              openInNewTab={
                buttonTitle.toLowerCase() === "search our current opportunities"
                  ? true
                  : false
              }
            />
          </div>
        </section>
      ) : (
        <>
          {footer && (
            <ContentBanner
              ctaTitle={buttonTitle ? buttonTitle : ""}
              sector={sector}
              service={sectorChild}
              ctaLink={ctaLink ? ctaLink : ""}
              image={footer?.image}
              title={title}
              description={description ? description : ""}
            />
          )}
        </>
      )}
    </>
  )
}

export default CtaBanner
