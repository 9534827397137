import React from "react"

import SideVisualBlock from "."
import { OptionsTypes, SideVisualBlockContentTypes } from "./types"
import classnames from "classnames"
import { ParallaxProvider } from "react-scroll-parallax"

interface SingleSideVisualBlockTypes extends OptionsTypes {
  content: SideVisualBlockContentTypes
  isHomepage?: boolean
  isShowcase?: boolean
  deviceImageOnTop?: boolean
}

const FeaturedVideoText: React.FC<SingleSideVisualBlockTypes> = ({
  content,
  blockColourOverride,
  aligned,
  deviceImageOnTop,
}) => {

  return (
    <div className={`content-page__wrapper content-page__wrapper--showcase`}>
      <ParallaxProvider>
        <SideVisualBlock
          title={content.title}
          subtitle={content.subtitle}
          body_text={content.body_text}
          vimeo_id={content.vimeo_id}
          blockColourOverride={blockColourOverride}
          aligned={aligned}
          deviceImageOnTop={deviceImageOnTop}
        />
      </ParallaxProvider>
    </div>
  )
}

export default FeaturedVideoText
