import React from "react"

const ToggleSearch: React.FC = () => {
  return (
    <a href="#" className="search-toggle">
      <svg
        className="search-toggle--search-icon"
        xmlns="https://www.w3.org/2000/svg"
        viewBox="0 0 27.8 27.5"
      >
        <path d="M27.4 25.4l-6.8-6.8c1.5-2 2.5-4.4 2.5-7C23 5 18 0 11.6 0S0 5.2 0 11.5 5.2 23 11.5 23c2.8 0 5.3-1 7.3-2.6l6.8 6.8c.2.2.6.4 1 .4s.5 0 .8-.4c.5-.5.5-1.3 0-1.8zm-25-14c0-5 4-9 9-9s9 4 9 9-4 9-9 9-9-4-9-9z" />
      </svg>
      <svg
        className="search-toggle--close-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60 60"
      >
        <path
          className="cls-1"
          d="M57.81,28.24h-26v-26a1.76,1.76,0,0,0-3.52,0v26h-26a1.76,1.76,0,1,0,0,3.52h26v26a1.76,1.76,0,0,0,3.52,0v-26h26a1.76,1.76,0,1,0,0-3.52Z"
        />
        <path
          className="cls-1"
          d="M57.81,28.24H2.19a1.76,1.76,0,1,0,0,3.52H57.81a1.76,1.76,0,1,0,0-3.52Z"
        />
      </svg>
    </a>
  )
}
export default ToggleSearch
