import React from "react"

import SliderBanner from "../../modules/slider_banner/homepage"
import Spacer from "../../layout/spacer"
import Single from "../../modules/featured_media_text/single"
import Tryptic from "../../modules/tryptic"
import CtaBanner from "../../modules/cta_banner"
import {
  HomepageBannerTypes,
  LoopingVidTypes,
} from "../../modules/slider_banner/types"

import { FeaturedMediaTextTypes } from "../../modules/featured_media_text/types"
import { TrypticTypes } from "../../modules/tryptic/types"

interface Props {
  banners: HomepageBannerTypes[]
  loopingVidBanners: LoopingVidTypes[]
  featuredImageTextBlock: FeaturedMediaTextTypes[]
  trypticContent: TrypticTypes[]
  ctaBanner: {
    title: string
    description: string
    button_title: string
    button_url: string
    image: string
  }
}

const Home: React.FC<Props> = ({
  banners,
  loopingVidBanners,
  featuredImageTextBlock,
  trypticContent,
  ctaBanner,
}) => {
  return (
    <>
      <SliderBanner banners={banners} loopingVidBanners={loopingVidBanners} />
      <div className="page-content-wrap">
        <Spacer deviceSize="xm" />
        {featuredImageTextBlock.length >= 1 && (
          <Single
            abortEntryTransition={true}
            deviceImageOnTop={true}
            content={featuredImageTextBlock[0]}
            blockColourOverride="#009946"
            isHomepage={true}
            aligned="left"
          />
        )}

        <Spacer deviceSize="xm" />
        <Tryptic content={trypticContent} />
        <Spacer deviceSize="xm" />
        {featuredImageTextBlock.length >= 2 && (
          <Single
            deviceImageOnTop={true}
            content={featuredImageTextBlock[1]}
            blockColourOverride="#0B3254"
            isHomepage={true}
            aligned="right"
          />
        )}
        {ctaBanner && (
          <CtaBanner
            isContentBanner={true}
            title={ctaBanner.title}
            description={ctaBanner.description}
            buttonTitle={ctaBanner.button_title}
            ctaLink={ctaBanner.button_url}
            footer={ctaBanner}
          />
        )}
      </div>
    </>
  )
}

export default Home
