import { useLayoutEffect } from "react"
import scrollToElement from "scroll-to-element"
import { useController } from "react-scroll-parallax"
import { NewsTypes } from "./components/modules/homepage_tiles/news"
/*
 * Detects if device has touch.
 * Returns Boolean
 */
export const isTouchDevice = (): boolean => {
  return (
    "ontouchstart" in window || navigator.maxTouchPoints !== 0 // works on most browsers
  ) // works on IE10/11 and Surface
}

/*
 * Detects if browser width is less than indicated size
 * returns Boolean
 */

export const isMobile = (): boolean => {
  return window.innerWidth < 600
}

export const isTablet = (): boolean => {
  return window.innerWidth > 601
}

export const isDesktop = (): boolean => {
  return window.innerWidth > 1024
}

export const isWideScreen = (): boolean => {
  return window.innerWidth > 1801
}

// * for html strings
// * dangerouslySetInnerHTML
export const renderHtmlString = (htmlString: string): { __html: string } => {
  return { __html: `${htmlString}` }
}

// * Limits paragraph to 140 characters
export const truncateText = (text: string, desiredLength: number): string => {
  return text.length <= desiredLength
    ? text
    : `${text.slice(0, desiredLength)}...`
}

// * The text sometimes is coming from a WYSIWYG editor and can be the first element.
// * We dont want an image to be didplayed in the tile content
export const removeImgTagFromString = (text: string): string => {
  if (text.includes("<img")) {
    return text.replace(/<img[^>]*>/g, "")
  } else {
    return text
  }
}

export const removePTagFromString = (text: string): string => {
  if (text.includes("<p")) {
    return text.replace(/<p[^>]*>/g, "")
  } else {
    return text
  }
}

export const handleScrollTo = (id: string, scrollOffset: number): any => {
  if (typeof document !== "undefined") {
    const ele = document.getElementById(id)
    return scrollToElement(ele, {
      ease: "inOutExpo",
      duration: 1200,
      offset: scrollOffset,
    })
  }
}

export const strippedString = (originalString: string): string => {
  return originalString.replace(/(<([^>]+)>)/gi, "")
}

export const ParallaxCache = (): null => {
  const { parallaxController } = useController()

  useLayoutEffect(() => {
    const handler = () => parallaxController.update()
    window.addEventListener("load", handler)
    window.addEventListener("resize", handler)

    return () => {
      window.removeEventListener("load", handler)
      window.removeEventListener("resize", handler)
    }
  }, [parallaxController])

  return null
}

export const openExternalLink = (url: string): void => {
  if (url) {
    const win = window.open(url, "_blank")
    if (win) {
      win.focus()
    }
  }
}

export const upperCaseFirstCharacter = (stn: string): string => {
  // For strings that are CAPITALIZED. CSS has a sad about it when going from lowercase to uppercase
  const lowerName = stn.toLowerCase()
  return lowerName.charAt(0).toUpperCase() + lowerName.slice(1)
}

export const orderedByPublishDate = (arr: NewsTypes[]): NewsTypes[] => {
  const output = arr.sort(
    (a: { publish_date: string }, b: { publish_date: string }) => {
      if (a.publish_date > b.publish_date) {
        return -1
      } else {
        return 1
      }
    }
  )
  return output
}
