import React from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import classnames from "classnames"
import useComponentInView from "../../../hooks/useComponentInView"
import Wysiwyg from "../../ui/wysiwyg"
import { VideoTitleCopyTypes } from "./types"
import VideoTile from "./video_tile"


const VideoTitleCopy: React.FC<VideoTitleCopyTypes> = ({
  title,
  image,
  body_text,
  vimeo_id,
  description_title,
  description,
  modifierClass,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
  })

  const containerClasses = classnames("video-title-copy", {

    [`${modifierClass}`]: modifierClass,
  })

  const componentSeen = useComponentInView(inView)


  return (
    <div ref={ref} className="showcase__block-wrapper showcase-wrapper__video-title-copy">
      <div className={containerClasses}>
        <article className={`video-title-copy__article`}>
          <motion.div
            className="device-content-padding video-title-copy__article--text"
          >
            <motion.div
              className="video-title-copy__body-text"
            >
              <h2 className="video-title-copy__body-heading">{title}</h2>
              <Wysiwyg content={body_text} />
            </motion.div>
          </motion.div>

          <div className="video-title-copy__asset-wrapper">

            <motion.div
            >

              {vimeo_id ? (
                <VideoTile
                  video_url={vimeo_id}
                />
              ) : (
                <picture
                >
                  <source
                    srcSet={`${image.medium} 1x, ${image.large} 2x`}
                  />
                  <img
                    className="two-column-layout-slide__image--file"
                    src={image.medium}
                    alt=""
                  />
                </picture>
              )}
            </motion.div>

          </div>

          <motion.div
            className="device-content-padding video-title-copy__caption"
          >
            <motion.div
              className="video-title-copy__body-text"
            >
              {description && (
                <>
                  <span>{description_title}</span>
                  <p>{description}</p>
                </>
              )}

            </motion.div>
          </motion.div>

        </article>
      </div>
    </div>
  )
}

export default VideoTitleCopy
