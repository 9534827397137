import React, { useState, useRef, useEffect } from "react"
import IconButton from "../../elements/icon_button"
import IconSearch from "../../../images/svgs/icon_search"
import IconClose from "../../../images/svgs/icon_close"

const SearchField: React.FC = () => {
  const [inputValue, setInputValue] = useState("")

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef !== null) {
      setTimeout(() => {
        inputRef?.current?.focus()
      }, 100)
    }
  }, [])
  return (
    <div className="search-field">
      <input
        ref={inputRef}
        className="search-input"
        onChange={(e) => setInputValue(e.target.value)}
        id="search"
        type="text"
        value={inputValue}
        placeholder="What are you looking for?"
        name="query"
      />
      {inputValue && (
        <span
          className="search-field__delete-button"
          onClick={() => setInputValue("")}
        >
          <IconClose color="white" />
        </span>
      )}

      <IconButton
        modifierClass={`icon_button__search-submit ${
          inputValue ? "with-text" : "default"
        }`}
      >
        <IconSearch />
      </IconButton>
    </div>
  )
}

export default SearchField
