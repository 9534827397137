import React from "react"
import Spacer from "../layout/spacer"
import { LinkTypes } from "../../types"

const TertiaryCta: React.FC<LinkTypes> = ({ slug, name }) => {
  const handleLinkClick = (e) => {
    location.href = `${slug}`
  }
  return (
    <span className="tertiary-cta__wrapper">
      <button
        onClick={handleLinkClick}
        className="typography__cta--01 typography__uppercase typography__color--green typography__weight--600 tertiary-cta-nav-link"
      >
        {name}
      </button>
      <Spacer desktopSize="xs" />
    </span>
  )
}

export default TertiaryCta
