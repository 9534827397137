import React from "react"
import classnames from "classnames"

interface Props {
  isNewsProjects: boolean
  handleOnClick: (e) => void
  isToggled: boolean
}

const Toggler: React.FC<Props> = ({
  handleOnClick,
  isNewsProjects,
  isToggled,
}) => {
  const togglerClassName = classnames({
    "filter-bar-toggler--news-projects": isNewsProjects,
    "filter-bar-toggler--open": isToggled,
    "filter-bar-toggler--closed": !isToggled,
  })
  return (
    <button
      className={`filter-bar-toggler ${togglerClassName}`}
      onClick={handleOnClick}
    />
  )
}
export default Toggler
