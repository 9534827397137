import React, { ReactElement } from "react"

// Helpers
import useDesktopDetector from "../../../hooks/useDesktopDetector"

interface Props {
  aside: ReactElement | null
  content: ReactElement
  hasStickySubNav: boolean
}

const ContentWithStickyAside: React.FC<Props> = ({
  aside,
  content,
  hasStickySubNav,
}) => {
  const isDesktop = useDesktopDetector()
  return (
    <div className="content-with-sticky-aside">
      {isDesktop && aside !== null && (
        <aside
          id="aside"
          data-modifier={`${
            !hasStickySubNav ? "with-content-sub-nav" : "no-content-sub-nav"
          }`}
        >
          {aside}
        </aside>
      )}
      <div id="content">{content}</div>
    </div>
  )
}

export default ContentWithStickyAside
