// this should be a context thingy where the source of truth is taken from the header components props.
export const baseUrlForJobSearch = ""
export const contactDetails = {
  auPhone: "1300 VENTIA",
  nzPhone: "0508 VENTIA",
  internationalPhone: "+61 1300 836 842",
  bracketed: "(836 842)",
  linkedIn: "https://www.linkedin.com/company/ventia-pty-ltd",
  vimeo: "https://vimeo.com/ventiaptyltd",
}

export const auPhone = contactDetails.auPhone
export const nzPhone = contactDetails.nzPhone
export const internationalPhone = contactDetails.internationalPhone
