import React from "react"
import Spacer from "../../layout/spacer"
import classnames from "classnames"

interface PageHeaderTypes {
  isCapability?: boolean
  title: string
  modifierClass?: string
}

const PageHeader: React.FC<PageHeaderTypes> = ({
  modifierClass,
  isCapability = false,
  title,
}) => {
  const modifierClassnames = classnames({
    [`${modifierClass}`]: modifierClass,
  })
  return (
    <section className={`page-header ${modifierClassnames}`}>
      {isCapability ? (
        <div className="grid page-header__content page-header__content--capability">
          <h2 className="typography__cta--01 typography__color--white typography__uppercase">
            Capabilities
          </h2>
          <Spacer deviceSize="xs" />
          <h1 className="typography__display--02 typography__color--white typography__uppercase">
            {title}
          </h1>
        </div>
      ) : (
        <div className="grid page-header__content">
          <h1 className="typography__display--02 typography__color--white typography__uppercase">
            {title}
          </h1>
        </div>
      )}

      <div className="page-header__image-bg" />
    </section>
  )
}

export default PageHeader
