import React from "react"
import { LoopingVidTypes } from "../types"
import useDesktopDetector from "../../../../hooks/useDesktopDetector"
import Spacer from "../../../layout/spacer"
import PrimaryCta from "../../../cta/primary_cta"
import TextCtaContent from "./text_cta_content"

interface Props {
  bannerName: string
  bannerLinkText: string
  bannerLink: string
  bannerImage: string
  uploadedVideoId: number | null
  vimeoId: string
  loopingVideoBanners: LoopingVidTypes[]
}

const RenderBannerMedia: React.FC<Props> = ({
  uploadedVideoId,
  bannerImage,
  vimeoId,
  bannerName,
  bannerLinkText,
  bannerLink,
  loopingVideoBanners,
}) => {
  // console.log({ uploadedVideoId })
  // console.log({ bannerImage })
  // console.log({ vimeoId })
  // console.log({ bannerName })
  // console.log({ bannerLinkText })
  // console.log({ bannerLink })
  // console.log({ loopingVideoBanners })
  const isDesktop = useDesktopDetector()

  const renderGradientDomElements = () => {
    return (
      <>
        <div className="page-banner__gradient page-banner__gradient--top" />
        <div className="page-banner__gradient page-banner__gradient--bottom" />
        <div className="page-banner__gradient page-banner__gradient--cover" />
      </>
    )
  }

  // mp4 / webp
  if (uploadedVideoId && loopingVideoBanners.length && !vimeoId) {
    return (
      <>
        <div className="page-banner__content-wrap">
          <div className="page-banner__content-row">
            <div className="page-banner__content">
              <div className="page-banner__content--top">
                <h1 className="typography__display--02 typography__text-shadow typography__uppercase typography__color--white ">
                  {bannerName}
                </h1>
              </div>
              <Spacer deviceSize="xs" />
              <div className="banner-content__bottom">
                {bannerLinkText && (
                  <PrimaryCta label={bannerLinkText} link={bannerLink} />
                )}
              </div>
            </div>
          </div>
        </div>
        {renderGradientDomElements()}
        <div className="page-banner__gradient page-banner__gradient--cover" />
        {loopingVideoBanners.map((vid) => {
          return (
            <>
              {isDesktop ? (
                <div key={`${vid}`}>
                  {vid.id.toString() === uploadedVideoId.toString() && (
                    <video
                      playsInline={true}
                      className="homepage-video home-bg__video active"
                      width="100%"
                      poster={vid.files.mobile_image_mobile}
                      muted={true}
                      loop={true}
                      autoPlay
                    >
                      <source
                        id="webmSource"
                        src={vid.files.webm_version}
                        type="video/webm"
                      />
                      <source
                        id="mp4Source"
                        src={vid.files.mp4_version}
                        type="video/mp4"
                      />
                    </video>
                  )}
                </div>
              ) : (
                <div
                  className="page-banner__slide-background"
                  style={{
                    backgroundImage: `url(${vid.files.mobile_image_mobile})`,
                  }}
                />
              )}
            </>
          )
        })}
      </>
    )
  } else if (uploadedVideoId === null && vimeoId) {
    console.log("show vimeo", vimeoId, uploadedVideoId)
    // Vimeo
    return (
      <div className="page-banner__slide  swiper-slide">
        <TextCtaContent
          bannerName={bannerName}
          bannerLink={bannerLink}
          bannerLinkText={bannerLinkText}
        />

        <div className="page-banner__slide-background">
          {isDesktop ? (
            <div className="page-banner__slide-background--iframe-wrap">
              <iframe
                src={`https://player.vimeo.com/video/${vimeoId}?autoplay=1&autopause=0&controls=0&loop=1&title=0&byline=0&portrait=0&muted=1?background=1`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                allow="autoplay"
                frameBorder="0"
              />
            </div>
          ) : (
            <div
              className="page-banner__homepage-content"
              style={{ backgroundImage: `url(${bannerImage})` }}
            >
              <TextCtaContent
                bannerName={bannerName}
                bannerLink={bannerLink}
                bannerLinkText={bannerLinkText}
              />
            </div>
          )}
        </div>
        <div className="page-banner__gradient page-banner__gradient--top" />
        <div className="page-banner__gradient page-banner__gradient--article-cover" />
      </div>
    )
    // Image only
  } else if (bannerImage && uploadedVideoId === null && !vimeoId) {
    console.log("show banner", bannerImage, uploadedVideoId, vimeoId)
    return (
      <div
        className="page-banner__homepage-content"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <TextCtaContent
          bannerName={bannerName}
          bannerLink={bannerLink}
          bannerLinkText={bannerLinkText}
        />
        {isDesktop && (
          <div
            className="page-banner__background-bannerImage"
            style={{
              backgroundImage: `url(${bannerImage})`,
            }}
          />
        )}

        {renderGradientDomElements()}
      </div>
    )
  } else return null
}

export default RenderBannerMedia
