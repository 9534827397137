import React from "react"
import SearchField from "../../ui/forms/search_field"
import IconButton from "../../elements/icon_button"
import IconClose from "../../../images/svgs/icon_close"

import { SearchDropdownTypes } from "../../../types"

const SearchDropdown: React.FC<SearchDropdownTypes> = ({
  modifierClass,
  isSearchVisible,
  handleCloseSearchClick,
}) => {
  return (
    <div className={`search__dropdown ${modifierClass}`}>
      <IconButton
        modifierClass="button__modal-dropdown--close"
        onClick={handleCloseSearchClick}
      >
        <IconClose color="white" />
      </IconButton>
      <div className="search-content">
        <form action="/search" className="search-form">
          {isSearchVisible && <SearchField />}
        </form>
      </div>
    </div>
  )
}

export default SearchDropdown
