import React, { useEffect, useState } from "react"
import Cta from "../cta"
import {
  renderHtmlString,
  truncateText,
  strippedString,
} from "../../../../helpers"
import Column from "../../../modules/grid/column"
import Spacer from "../../../layout/spacer"
import classnames from "classnames"
import { isDesktop } from "../../../../helpers"
import { SectorTileTypes } from "../types"
import Picture from "../../../elements/picture"

const SectorTile: React.FC<SectorTileTypes> = ({
  sb_hover_active,
  sector,
  teaser,
  name,
  listing_images,
  slug,
}) => {
  const currentPath = `${sector}`
  const completeSlug = currentPath ? `${currentPath}/${slug}` : slug

  const [isDesktopDetector, setIsDesktopDetector] = useState(isDesktop())
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (isDesktop()) setIsDesktopDetector(true)
      else {
        setIsDesktopDetector(false)
      }
    })
    return () => {
      window.removeEventListener("resize", () => null)
    }
  }, [])

  const h1Classnames = classnames({
    "typography__display--06": isDesktopDetector,
    "typography__body--01-700": !isDesktopDetector,
  })
  const _storybookHoverClass = classnames({
    __hover: sb_hover_active,
  })
  return (
    <Column>
      <article
        onClick={() => (location.href = completeSlug)}
        className={`tile tile__single tile__single--sector tile__sector ${_storybookHoverClass}`}
      >
        <div className="tile__image-cell">
          <Picture
            cssClassName="tile__image-wrapper"
            image1x={listing_images.small}
            image2x={listing_images.large}
          />
        </div>

        <div className="tile__content-cell">
          <section className="tile__info">
            <div className="tile__info-col tile__info--backslash  tile__info--sector">
              <h2 className="typography__capitalise typography__caption--02 typography__weight--400 typography__color--green typography__weight--600">
                {sector}
              </h2>
            </div>
            <Spacer desktopOnly desktopSize="n" />
          </section>
          <Spacer deviceSize="n" deviceOnly />
          <h1
            className={`${h1Classnames} typography__weight--700  typography__color--navy`}
          >
            {isDesktop() ? truncateText(name, 50) : truncateText(name, 47)}
          </h1>
          <Spacer desktopOnly={true} desktopSize="xs" deviceLayout="row" />
          {teaser && (
            <div
              className="tile__overview typography__color--mid-grey"
              dangerouslySetInnerHTML={renderHtmlString(
                truncateText(strippedString(teaser), 320)
              )}
            />
          )}
        </div>
        <Cta completeSlug={completeSlug} />
      </article>
      <Spacer deviceSize="xxs" deviceLayout="row" />
    </Column>
  )
}

export default SectorTile
