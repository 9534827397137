import React from "react"
import Grid from "../../modules/grid/"
import Row from "../../modules/grid/row"
import Column from "../../modules/grid/column"
import FeaturedProject from "../../modules/tile/featured/project"
import Spacer from "../../layout/spacer"

import { ProjectTileTypes } from "../tile/types"

interface SingleProjectTypess {
  relatedTo: string
  project: ProjectTileTypes
}

const SingleProject: React.FC<SingleProjectTypess> = ({
  relatedTo,
  project,
}) => {
  return (
    <section id="anchor-projects-section">
      <h1 className="typography__display--05 typography__weight--700">
        {relatedTo} case study
      </h1>

      <Spacer deviceSize="s" hideForPrint={true} />
      <div className="horizontal-divider hide-for-print" />
      <Spacer deviceSize="s" />
      <Grid>
        <Row columnCount={1}>
          <Column>
            <FeaturedProject
              id={project.id}
              name={project.name}
              teaser={project.teaser}
              leftAlignedFeature={false}
              client_name={project.client_name}
              slug={project.slug}
              listing_image={project.listing_images.large}
            />
          </Column>
        </Row>
      </Grid>
    </section>
  )
}

export default SingleProject
