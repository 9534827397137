import React from "react"
import classnames from "classnames"

interface TagTypes {
  isDisabled?: boolean
  category: string
  id: string
  onClick?: () => void
  filterModifierClass?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  name: string
}

const FilterTag: React.FC<TagTypes> = ({
  isDisabled,
  category,
  id,
  checked,
  onClick,
  onChange,
  filterModifierClass,
  name = "",
}) => {
  const filterModifierClasses = classnames({
    [`${filterModifierClass}`]: filterModifierClass,
  })
  return (
    <li className="tag__filter-item">
      <input
        disabled={isDisabled}
        className={`tag__filter-input ${filterModifierClasses}`}
        type="checkbox"
        name={`${category}-checkbox`}
        id={id}
        onChange={onChange}
        value={name}
        onClick={onClick}
        checked={checked}
      />

      <label className="tag__filter-label" htmlFor={id}>
        {id}
      </label>
    </li>
  )
}

export default FilterTag
