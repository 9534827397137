import React from "react"
import classnames from "classnames"

interface HyperlinkTypes {
  link: string
  label?: string
  modifierClass?: string
  vanityCta?: boolean
  relatedArticlesCta?: boolean
}

const Hyperlink: React.FC<HyperlinkTypes> = ({
  link,
  label,
  modifierClass,
  vanityCta,
  relatedArticlesCta,
}) => {
  const modifierClassnames = classnames({
    [`${modifierClass}`]: modifierClass,
  })
  return (
    <>
      {/* Default */}
      {!vanityCta && !relatedArticlesCta && (
        <a className={`hyperlink ${modifierClassnames}`} href={link}>
          <span className="typography__cta--01 typography__weight--600 hyperlink__label">
            {label}
          </span>
        </a>
      )}
      {/* Vanity only */}
      {vanityCta && !relatedArticlesCta && (
        <span className={`hyperlink ${modifierClassnames}`}>
          <span className="typography__cta--01 typography__weight--600 hyperlink__label">
            {label}
          </span>
        </span>
      )}

      {/* Related Articles only */}
      {relatedArticlesCta && !vanityCta && (
        <a className="hyperlink hyperlink--related-articles" href={link}>
          <span className="typography__cta--01 typography__weight--600 hyperlink__label">
            {label}
          </span>
        </a>
      )}
    </>
  )
}

export default Hyperlink
