import React from "react"

import TwoColumnLayoutSlide from "."
import { OptionsTypes, TwoColumnLayoutSlideContentTypes } from "./types"
import { ParallaxProvider } from "react-scroll-parallax"

interface SingleTwoColumnLayoutSlideTypes extends OptionsTypes {
  content: TwoColumnLayoutSlideContentTypes
}

const FeaturedVideoText: React.FC<SingleTwoColumnLayoutSlideTypes> = ({
  content,
  blockColourOverride,
  aligned,
}) => {


  return (
    <div className={`content-page__wrapper content-page__wrapper--showcase`}>
      <ParallaxProvider>
        <TwoColumnLayoutSlide
          title={content.title}
          subtitle={content.subtitle}
          body_text={content.body_text}
          description={content.description}
          vimeo_id={content.vimeo_id}
          blockColourOverride={blockColourOverride}
          aligned={aligned}
        />
      </ParallaxProvider>
    </div>
  )
}

export default FeaturedVideoText
