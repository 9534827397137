import React from "react"

interface ArticleSearchTypes {
  handleSearchSubmit: (e) => void
  handleOnChange: (e) => void
  searchTerm: string
}

const ArticleSearch: React.FC<ArticleSearchTypes> = ({
  handleSearchSubmit,
  handleOnChange,
  searchTerm,
}) => {
  return (
    <div className="article-search">
      <form onSubmit={handleSearchSubmit} className="article-search__form">
        <input
          className="article-search__input"
          type="text"
          value={searchTerm}
          onChange={handleOnChange}
          placeholder="Search with keywords"
        />
        <input hidden type="submit" value="Search" />
      </form>
    </div>
  )
}

export default ArticleSearch
