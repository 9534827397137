
interface FilterType {
  id: number
  name: string
  slug?: string
}

export function filterOutKeywords(newFilters: number[], value: string): number[] {
  return newFilters.filter((i) => i !== parseInt(value))
}

export function pushNewFilters(newFilters: number[], value: string): number {
  return newFilters.push(parseInt(value))
}


export function assignLetter(apiData:FilterType[], characterCount: number, characters:string[]):FilterType[]  {

  const output: FilterType[] = []

  apiData.forEach((i: FilterType) => {
    const firstLetter = i.name.charAt(0)
    if (characterCount === 3) {
      if (
        firstLetter.includes(characters[0]) ||
        firstLetter.includes(characters[1]) ||
        firstLetter.includes(characters[2])
      ) {
        output.push(i)
        return
      }
    }

    if (characterCount === 4) {
      if (
        firstLetter.includes(characters[0]) ||
        firstLetter.includes(characters[1]) ||
        firstLetter.includes(characters[2]) ||
        firstLetter.includes(characters[3])
      ) {
        output.push(i)
        return
      }
    }

    if (characterCount === 5) {
      if (
        firstLetter.includes(characters[0]) ||
        firstLetter.includes(characters[1]) ||
        firstLetter.includes(characters[2]) ||
        firstLetter.includes(characters[3]) ||
        firstLetter.includes(characters[4])
      ) {
        output.push(i)
        return
      }
    }
    return
  })
  return output
}
