/* eslint-disable react/display-name */
import React, { forwardRef } from "react"
import { renderHtmlString } from "../../helpers"

export interface WysiwygTypes {
  content: string
}

const Wysiwyg = forwardRef<HTMLElement, WysiwygTypes>((props, ref) => {
  const { content } = props

  return (
    <article
      ref={ref}
      className="wysiwyg-editor"
      dangerouslySetInnerHTML={renderHtmlString(content)}
    />
  )
})

export default Wysiwyg
