import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import { LinkedInFeedTypes } from "./types"
import Picture from "../../elements/picture"

import CarouselNavigation from "../../elements/carousel-navigation"

const LinkedInFeed: React.FC<LinkedInFeedTypes> = ({ feed, solidBackground= false }) => {
  const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 900,
    swipeToSlide: true,
    touchThreshold: 100,
    touchMove: true,
    variableWidth: true,
  }

  let slider = new Slider({})

  const next = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault()
    slider.slickNext()
  }
  const prev = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault()
    slider.slickPrev()
  }

  function formatDate(date) {
    const input = new Date(date)
    const dateOutput = input.toLocaleString("en-us", {
      year: "numeric",
      day: "numeric",
      month: "long",
    })
    return dateOutput
  }

  const marginRef = useRef<HTMLDivElement | null>(null)
  const [leftMargin, setLeftMargin] = useState("")

  const output = marginRef?.current?.offsetLeft
  useEffect(() => {
    setLeftMargin(`${output}px`)

    window.addEventListener("resize", () => {
      setLeftMargin(`${marginRef?.current?.offsetLeft}px`)
    })

    return () => window.removeEventListener("resize", () => false)
  }, [marginRef, output])

  const sliderContentRef = useRef<HTMLDivElement | null>(null)
  return (
    <>
      <div className={solidBackground ? 'linkedin-feed linkedin-feed-solid' : 'linkedin-feed' }>
        <div
          className="container container__linkedin"
          style={{ marginLeft: `${leftMargin}` }}
        >
          <div className="linkedin-pagination-wrapper">
            <h1 className="typography__display--04">Latest from LinkedIn</h1>
            <CarouselNavigation
              prevClick={prev}
              nextClick={next}
              modifier="linkedIn"
            />
          </div>

          <div className="linkedin-list">
            <div
              ref={sliderContentRef}
              style={{
                position: "relative",
              }}
            >
              <Slider ref={(c: Slider) => (slider = c)} {...settings}>
                {feed.map(
                  (
                    {
                      url,
                      files: { image },
                      publish_date,
                      title,
                      description,
                      read_in,
                    },
                    i
                  ) => {
                    return (
                      <div key={i} className="linkedin-item swiper-slide">
                        <div className="linkedin-item__wrapper">
                          <div className="linkedin-item__image-wrapper">
                            <a href={url}>
                              <Picture
                                cssClassName="avatar"
                                image1x={`https://www.ventia.com/${image}`}
                                image2x={`https://www.ventia.com/${image}`}
                                alt={title}
                              />
                            </a>
                          </div>
                          <div className="linkedin-item__content">
                            <div className="linkedin-item__info">
                              <time
                                className="typography__caption--02"
                                dateTime={publish_date}
                              >
                                {formatDate(publish_date)}
                              </time>
                              <h4>{read_in}</h4>
                            </div>
                            <div className="linkedin-item__title">
                              <p className="typography__body--02">
                                {description}
                              </p>
                            </div>
                            <div className="linkedin-item__footer">
                              <a
                                href={url}
                                className="hyperlink"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span
                                  className=" typography__cta--01 typography__weight--600"
                                  style={{ width: "100%" }}
                                >
                                  Read more
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                )}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={marginRef}
        style={{
          maxWidth: "1015px",
          margin: "0 auto",
          height: "0",
        }}
      />
    </>
  )
}

export default LinkedInFeed
