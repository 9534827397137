import React from "react"

import FeaturedMediaText from "."
import { OptionsTypes, FeaturedMediaTextContentTypes } from "./types"
import classnames from "classnames"
import { ParallaxProvider } from "react-scroll-parallax"

interface SingleFeaturedImageTextTypes extends OptionsTypes {
  content: FeaturedMediaTextContentTypes
  isHomepage?: boolean
  deviceImageOnTop?: boolean
}

const FeaturedVideoText: React.FC<SingleFeaturedImageTextTypes> = ({
  content,
  blockColourOverride,
  aligned,
  isHomepage,
  deviceImageOnTop,
}) => {
  const wrapperClassnames = classnames({
    "content-page__wrapper--home": isHomepage,
  })

  return (
    <div className={`content-page__wrapper ${wrapperClassnames}`}>
      <ParallaxProvider>
        <FeaturedMediaText
          title={content.title}
          subtitle={content.subtitle}
          cta_path={content.cta_path}
          cta_text={content.cta_text}
          body_text={content.body_text}
          vimeo_id={content.vimeo_id}
          blockColourOverride={blockColourOverride}
          aligned={aligned}
          deviceImageOnTop={deviceImageOnTop}
        />
      </ParallaxProvider>
    </div>
  )
}

export default FeaturedVideoText
