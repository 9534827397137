import React, { useState, Dispatch, SetStateAction } from "react"
import { Dialog } from "@reach/dialog"

import queryString from "query-string"
import SearchFilter from "./search-filter"
import FilterButton from "../../modules/filters/filter_button"
import KeywordSearch from "./keyword-search"
import Toggler from "../filters/toggler"
import useDesktopDetector from "../../../hooks/useDesktopDetector"
import IconClose from "../../../images/svgs/icon_close"
import { colors } from "../../../variables"
import DeviceFilterBar from "../filters/device_filter_bar/"

import {
  CareerSearchTypes,
  FilterType,
  FilterStateTypes,
  JobLocation,
} from "./types"

interface SearchFiltersProps extends CareerSearchTypes {
  setActiveFilters: Dispatch<SetStateAction<FilterStateTypes>>
  activeFilters: any // todo: fix this so FilterStateTypes passes as a tyle
}

const SearchFilters: React.FC<SearchFiltersProps> = ({
  locations,
  work_types,
  categories,
  setActiveFilters,
  activeFilters,
}) => {
  const isDesktop = useDesktopDetector()
  const getActiveFiltersByType = (filterType: FilterType): string[] => {
    return activeFilters[`${filterType}[]`]
  }

  const handleFilterClick = (filterType: FilterType, filterName: string) => {
    const filters = { ...activeFilters }
    let currFilterArr = getActiveFiltersByType(filterType)

    // querystring will create a string if only one item in query string
    // so if its a string im forcing it to be an array, might be a way to do
    // this in the actual package but i dont have time to figure it out.
    if (typeof currFilterArr === "string") {
      currFilterArr = [currFilterArr]
    }

    if (currFilterArr.indexOf(filterName) > -1) {
      // Remove clicked filter if it exists
      currFilterArr = currFilterArr.filter((v: string) => v !== filterName)
    } else {
      currFilterArr.push(filterName)
    }

    // Add the merge new filters and update state
    filters[`${filterType}[]`] = currFilterArr
    setActiveFilters(filters)
  }

  const handleSubmit = () => {
    location.search = queryString.stringify(activeFilters)
  }

  const handleClear = () => {
    location.href = "/careers/"
  }

  // Think about merging the renderFilters() and renderLocationFilters()
  const renderLocationFilters = (
    filterType: "location",
    filters: JobLocation[]
  ) =>
    filters
      .map((filter) => filter[0]) // Filtering down to the "regions", not "locations"
      .reduce((arr, item) => (arr.includes(item) ? arr : [...arr, item]), []) // Make the list unique
      .map((filterName, index) => {
        if (!filterName) {
          return null
        }
        // get city city name
        const currFilters = getActiveFiltersByType(filterType)

        return (
          <li key={index} className="filter__item tag__filter-item">
            <SearchFilter
              active={currFilters.includes(filterName)}
              filterType={filterType}
              name={filterName}
              onClick={handleFilterClick}
            />
          </li>
        )
      })

  const renderFilters = (
    filterType: "work_type" | "category",
    filters: string[]
  ) =>
    filters.map((filter, index) => {
      const currFilters = getActiveFiltersByType(filterType)

      // For some reason the data exists with
      // a category that has an empty string???
      if (filter !== "") {
        return (
          <li key={index} className="filter__item tag__filter-item">
            <SearchFilter
              active={currFilters.includes(filter)}
              filterType={filterType}
              name={filter}
              onClick={handleFilterClick}
            />
          </li>
        )
      }
    })

  const [isExpanded, setIsExpanded] = useState(false)
  const handleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const filterBar = () => {
    return (
      <div
        className={`filter-bar  ${
          isExpanded ? "filter-bar--expanded" : "filter-bar--contracted"
        }`}
      >
        {isDesktop ? (
          <Toggler
            isNewsProjects={false}
            handleOnClick={handleExpand}
            isToggled={isExpanded}
          />
        ) : (
          <button className="close-button" onClick={handleExpand}>
            <IconClose color={colors.green} />
          </button>
        )}
        <span className="filter-types--title">filter by</span>
        <div
          id="search-filters"
          className={`filter-types ${
            isExpanded ? "filter-types--expand" : "filter-types--contract"
          }`}
        >
          <div className="filter-list">
            <FilterButton
              title="Work type"
              checkedFilters={
                typeof getActiveFiltersByType("work_type") === "string"
                  ? 1
                  : getActiveFiltersByType("work_type").length
              }
            />
            <div
              className={`filters__wrapper ${
                isExpanded ? "filters__wrapper--show" : "filters__wrapper--hide"
              }`}
            >
              <div className="filter-group">
                <fieldset
                  style={{ border: 0, margin: 0, padding: 0 }}
                  role="group"
                  aria-labelledby="title-filter-results title-work-type"
                >
                  <ul className="filter" role="presentation">
                    {renderFilters("work_type", work_types)}
                  </ul>
                </fieldset>
              </div>
            </div>
          </div>

          <div className="filter-list">
            <FilterButton
              title="Locations"
              checkedFilters={
                typeof getActiveFiltersByType("location") === "string"
                  ? 1
                  : getActiveFiltersByType("location").length
              }
            />
            <div
              className={`filters__wrapper ${
                isExpanded ? "filters__wrapper--show" : "filters__wrapper--hide"
              }`}
            >
              <div className="filter-group">
                <fieldset
                  style={{ border: 0, margin: 0, padding: 0 }}
                  role="group"
                  aria-labelledby="title-filter-results title-location div_location_1"
                >
                  <div id="div_location_1" className="filter-group-title" />
                  <ul className="filter" role="presentation">
                    {renderLocationFilters("location", locations)}
                  </ul>
                </fieldset>
              </div>
            </div>
          </div>

          <div className="filter-list">
            <FilterButton
              title="Categories"
              checkedFilters={
                typeof getActiveFiltersByType("category") === "string"
                  ? 1
                  : getActiveFiltersByType("category").length
              }
            />
            <div
              className={`filters__wrapper ${
                isExpanded ? "filters__wrapper--show" : "filters__wrapper--hide"
              }`}
            >
              <div className="filter-group">
                <fieldset
                  style={{ border: 0, margin: 0, padding: 0 }}
                  role="group"
                  aria-labelledby="title-filter-results title-category div_category_1"
                >
                  <div id="div_category_1" className="filter-group-title" />
                  <ul className="filter" role="presentation">
                    {renderFilters("category", categories)}
                  </ul>
                </fieldset>
              </div>
            </div>
          </div>

          <div className="filter-actions">
            <button
              onClick={handleSubmit}
              className="filter-action filters-apply"
            >
              Apply
            </button>
            <button
              onClick={handleClear}
              className="filter-action filters-clear"
            >
              Clear
            </button>
          </div>
        </div>

        <KeywordSearch
          handleSearchSubmit={handleSubmit}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
        />
      </div>
    )
  }

  return (
    <>
      {isDesktop ? (
        filterBar()
      ) : (
        <>
          <DeviceFilterBar
            totalFiltersActive={
              getActiveFiltersByType("category").length +
              getActiveFiltersByType("location").length +
              getActiveFiltersByType("work_type").length
            }
            handleClearFilters={handleClear}
            handleExpand={handleExpand}
            areFiltersChecked={true}
          />
          <KeywordSearch
            handleSearchSubmit={handleSubmit}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
          />

          <Dialog isOpen={isExpanded} onDismiss={handleExpand}>
            <span id="search-filter-dialog">{filterBar()}</span>
          </Dialog>
        </>
      )}
    </>
  )
}
export default SearchFilters
