import React from "react"
import classnames from "classnames"

interface TextInputSearchTypes {
  name?: string
  id?: string
  modiferClass?: string
  handleSearchSubmit?: (e: React.FormEvent) => void
  searchTerm: string
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder: string
  submit: boolean
}
const TextInputSearch: React.FC<TextInputSearchTypes> = ({
  name,
  id,
  modiferClass,
  handleSearchSubmit,
  searchTerm,
  handleOnChange,
  placeholder = "",
  submit,
}) => {
  const modiferClassName = classnames({
    [`text-input-search--${modiferClass}`]: modiferClass,
  })
  return (
    <div className={`text-input-search ${modiferClassName}`}>
      <form
        onSubmit={
          handleSearchSubmit ? handleSearchSubmit : (e) => e.preventDefault()
        }
        className="text-input-search__form"
      >
        <input
          name={name ? name : ""}
          id={id ? id : ""}
          className="text-input-search__input"
          type="text"
          value={searchTerm}
          onChange={handleOnChange}
          placeholder={placeholder}
        />
        {submit && (
          <>
            <button
              className="text-input-search__submit-btn"
              onClick={handleSearchSubmit}
            >
              search
            </button>
            <input hidden type="submit" value="Search" />
          </>
        )}
      </form>
    </div>
  )
}

export default TextInputSearch
