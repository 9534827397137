import React from "react"
import classnames from "classnames"

interface RowTypes {
  children: any
  columnCount: number
  id?: string
  modifierClass?: string
}

const Row: React.FC<RowTypes> = ({
  children,
  columnCount,
  id,
  modifierClass,
}) => {
  const columnCountClasses = classnames({
    "column-count__1": columnCount === 1,
    "column-count__2": columnCount === 2,
    "column-count__3": columnCount === 3,
    [`${modifierClass}`]: modifierClass,
  })
  return (
    <ul
      id={id}
      className={`grid-row grid-module-row grid-direction--row ${columnCountClasses}`}
    >
      {children}
    </ul>
  )
}

export default Row
