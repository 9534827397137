import React from "react"

import Spacer from "../../layout/spacer"
import { renderHtmlString } from "../../../helpers"

interface ContentBannerTypes {
  image: string
  title: string
  description: string
  ctaLink: string
  ctaTitle: string
  isHomepage?: boolean
  sector?: string
  service?: string
}

const ContentBanner: React.FC<ContentBannerTypes> = ({
  image,
  title,
  description,
  ctaLink,
  sector,
  service,
  ctaTitle,
  isHomepage,
}) => {
  return (
    <section
      className={`content-banner ${
        isHomepage ? "content-banner--homepage" : ""
      }`}
    >
      <picture className="content-banner__picture">
        <img src={image} className="content-banner__image" />
      </picture>

      <div className="content-banner__container">
        <div className="content-banner__text">
          <h2 className="content-banner__title typography__uppercase typography__display--03 typography__color--white">
            {title}
            {sector === "service" && (
              <>
                <br />
                {service}
              </>
            )}
          </h2>

          <Spacer deviceSize="xs" desktopSize="s" />

          {description && (
            <>
              <span
                className="content-banner__description"
                dangerouslySetInnerHTML={renderHtmlString(description)}
              />

              <Spacer deviceSize="xs" desktopSize="s" />
            </>
          )}

          <a href={ctaLink} className="primary-cta content-banner__cta">
            <span className="typography__cta--01 typography__cta--01 typography__weight--600 typography__uppercase primary-cta__label">
              {ctaTitle}
            </span>
          </a>
        </div>
      </div>
      <div className="content-banner__overlay" />
    </section>
  )
}

export default ContentBanner
