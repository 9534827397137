import React, { Dispatch, SetStateAction } from "react"
import { motion } from "framer-motion"
import { renderHtmlString } from "../../../../helpers"
import Spacer from "../../../layout/spacer"

interface BioTypes {
  summary?: string
  bio?: string
  isActive: boolean
  setBioActive: Dispatch<SetStateAction<boolean>>
  linkedinLink?: string
}

const animateInUp4 = {
  hidden: { opacity: 0, y: "20px" },
  visible: { opacity: 1, y: 0 },
}
const Bio: React.FC<BioTypes> = ({
  summary,
  bio,
  setBioActive,
  isActive,
  linkedinLink,
}) => {
  const handleClick = () => {
    setBioActive(false)
  }

  return (
    <div className={`employee-bio ${!isActive ? "closed" : "open"}`}>
      <div className="employee-bio__wrapper">
        <motion.div
          className="container container--employee-bio"
          variants={animateInUp4}
          initial="hidden"
          animate={`${isActive ? "visible" : "hidden"}`}
          transition={{
            default: {
              delay: 0.2,
              type: "spring",
              damping: 30,
              duration: 0.3,
              ease: "easeOut",
            },
          }}
        >
          {summary && (
            <p className="employee-bio__summary typography__display--05 typography__color--green">
              {summary}
            </p>
          )}
          <Spacer deviceSize="s" />
          {bio && (
            <div
              className="employee-bio__description photo-captionable wysiwyg-editor"
              dangerouslySetInnerHTML={renderHtmlString(bio)}
            />
          )}
          {linkedinLink && (
            <>
              <Spacer deviceSize="s" />
              <a
                target="_blank"
                rel="noreferrer"
                href={linkedinLink}
                className="bio-link tertiary-cta__wrapper"
              >
                <span className="bio-link__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.9"
                    height="12.9"
                    viewBox="0 0 12.875 12.87"
                  >
                    <path d="M3 13H0V4.2H3V13zM1.4 3A1.6 1.6 0 1 1 3 1.6 1.5 1.5 0 0 1 1.6 3zM13 13H10V8.6c0-1 0-2.3-1.4-2.3s-1.6 1-1.6 2.2V13H4.5V4.2H7v1a2.8 2.8 0 0 1 2.7-1.2C12.4 4 13 6 13 8v5z" />
                  </svg>
                </span>
                <span className="typography__cta--01 typography__uppercase typography__color--green typography__weight--600 tertiary-cta-nav-link bio-link__text">
                  See Linkedin profile
                </span>
              </a>
            </>
          )}
        </motion.div>

        <button
          onClick={handleClick}
          className="employee-bio__close hamburger__button"
        >
          <span className="hamburger__bar toggled"></span>
        </button>
      </div>
      <div onClick={handleClick} className="employee-bio__overlay" />
    </div>
  )
}

export default Bio
