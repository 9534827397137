import React from "react"
import ListModule from "../list_module"
import { colors } from "../../../variables"

const RelatedCapabilities = ({ relatedCapabilities, theme = colors.white }) => {
  return (
    <section style={{ width: "100%" }} id="related-capabilities">
      <ListModule
        heading="Related Capabilities"
        theme={theme}
        list={relatedCapabilities}
        isListLoading={false}
        parentPath="/capabilities"
      />
    </section>
  )
}

export default RelatedCapabilities
