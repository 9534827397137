import React from "react"
import InstagramIcon from "../../../images/svgs/icon_instagram"
import TwitterIcon from "../../../images/svgs/icon_twitter"
import FacebookIcon from "../../../images/svgs/icon_facebook"
import LinkedInIcon from "../../../images/svgs/icon_linkedin"
import ScrollUpIcon from "../../../images/svgs/icon_scrollup"
import AnchorLink from "react-anchor-link-smooth-scroll"
import VimeoIcon from "../../../images/svgs/icon_vimeo"

interface IconProps {
  url: string
}

const Icon: React.FC<IconProps> = ({ children, url }) => {
  return (
    <a
      href={`${url}`}
      rel="noreferrer"
      className="footer__social-link"
      target="_blank"
    >
      {children}
    </a>
  )
}

type NavItemType = {
  name: string
  link: string
}

type RegionType = "australia" | "new-zealand" | "global"

export interface Props {
  flagOne: string
  flagTwo: string
  navItems: NavItemType[]
  description?: string
  regionSlug: RegionType
}

const Footer: React.VFC<Props> = ({
  flagOne,
  flagTwo,
  navItems,
  description,
  regionSlug,
}) => {
  return (
    <footer className="footer">
      <div className="footer__top-row">
        <div className="footer__top-row-col footer__logo-wrapper">
          <a className="footer__logo" href="/">
            <div className="footer__logo-image" />
          </a>
        </div>
        <div className="footer__top-row-col footer__back-to-top mobile">
          <AnchorLink href="#top">
            <div className="footer__back-to-top--text">
              <p className="typography__caption--01">Back to top</p>
            </div>
            <div className="footer__back-to-top--icon">
              <ScrollUpIcon />
            </div>
          </AnchorLink>
        </div>
      </div>
      <div className="footer__divider" />
      <div className="footer__bottom-row">
        <div
          className={`footer__social-links-wrapper ${
            description ? "" : "long-content"
          }`}
        >
          {/* Icons */}
          <div className="footer__social-links">
            <Icon url="https://www.facebook.com/VentiaCareers">
              <FacebookIcon />
            </Icon>
            <Icon url="https://www.instagram.com/ventiaservices/">
              <InstagramIcon />
            </Icon>
            <Icon url="https://twitter.com/VentiaServices">
              <TwitterIcon />
            </Icon>
            <Icon url="https://www.linkedin.com/company/vnt/">
              <LinkedInIcon />
            </Icon>
            <Icon url="https://vimeo.com/ventiaptyltd">
              <VimeoIcon />
            </Icon>
          </div>

          {/* Legal */}
          <div className="footer__legal-links">
            {navItems.map(({ name, link }) => {
              return (
                <a key={name} href={link} className="footer__link">
                  <span className="typography__caption--01 footer__link-text">
                    {name}
                  </span>
                </a>
              )
            })}
          </div>
        </div>

        {/* Flags */}

        {description && (
          <div className="footer__welcome-to-our-country">
            <>
              {regionSlug === "australia" && (
                <div className="footer__welcome-to-our-country__flags">
                  <img
                    className="footer__welcome-to-our-country__flag"
                    src={`${flagOne}`}
                    alt={""}
                  />
                  <img
                    className="footer__welcome-to-our-country__flag"
                    src={`${flagTwo}`}
                    alt={""}
                  />
                </div>
              )}
              <p className="footer__welcome-to-our-country__text">
                {description}
              </p>
            </>
          </div>
        )}
      </div>
    </footer>
  )
}

export default Footer
