import React from "react"

const IconCtaPlay = () => (
  <svg
    width="7"
    height="7"
    viewBox="0 0 7 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3.5L0 7V0L7 3.5Z"
      fill="#009946"
    />
  </svg>
)

export default IconCtaPlay
