import React from "react"
import Hyperlink from "../../../cta/hyperlink"

interface Props {
  completeSlug: string
  vanityCta?: boolean
  label?: string
}

const Cta: React.FC<Props> = ({ completeSlug, vanityCta = false, label }) => {
  return (
    <Hyperlink
      vanityCta={vanityCta}
      label={label ? label : "Read more"}
      modifierClass="tile__cta"
      link={completeSlug}
    />
  )
}

export default Cta
