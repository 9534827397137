import React, { useEffect, useState } from "react"
import ClampLines from 'react-clamp-lines';
import {
  isDesktop,
  renderHtmlString,
  truncateText,
  removeImgTagFromString,
} from "../../../../helpers"
import Column from "../../../modules/grid/column"
import Spacer from "../../../layout/spacer"
import classnames from "classnames"
import Cta from "../cta"
import { LinkTileTypes } from "../types"

const LinkTile: React.FC<LinkTileTypes> = ({
  id,
  images,
  summary,
  url,
  name,
  link_text,
}) => {
  const [isDesktopDetector, setIsDesktopDetector] = useState(isDesktop())
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (isDesktop()) setIsDesktopDetector(true)
      else {
        setIsDesktopDetector(false)
      }
    })

    return () => {
      window.removeEventListener("resize", () => null)
    }
  }, [])

  return (
    <Column>
      <a href={url} className="tile__link-wrapper">
        <article className="tile tile__single tile__link" id={`${id}`}>
          <div className="tile__image-cell">
            {images !== null && (
              <picture className="tile__image-wrapper">
                <img className="tile__image" src={images.desktop} alt={name} />
              </picture>
            )}
          </div>

          <div className="tile__content-cell">
            <div className="tile__info--backslash" />
            <h1
              className="typography__display--06 typography__weight--700  typography__color--navy"
            >
              {isDesktop() ? truncateText(name, 25) : truncateText(name, 47)}
            </h1>
            <Spacer deviceSize="xs" />
            {summary && (
              <ClampLines
                text={removeImgTagFromString(summary)}
                id="really-unique-id"
                lines={3}
                buttons={false}
                className="tile__overview typography__color--mid-grey"
                innerElement="summary"
              />
            )}
          </div>
          <Cta completeSlug={url} label={link_text} />
        </article>
      </a>
      <Spacer deviceSize="xxs" />
    </Column>
  )
}

export default LinkTile
