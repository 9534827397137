import React, { useEffect, useState } from "react"
import JobTable from "./job-table"
import JobItem from "./job-item"
import { Job } from "./types"
import queryString from "querystring"
import Spacer from "../../layout/spacer"

const jobsEndpoint = `${window.location.protocol}/careers.json${
  location.search ? location.search : "?"
}&limit=1000`

const Careers: React.FC = () => {
  const [jobDataLoading, setJobDataLoading] = useState(true)
  const [jobData, setJobData] = useState([])

  useEffect(() => {
    fetch(jobsEndpoint)
      .then((response) => response.json())
      .then((jobs) => setJobData(jobs))
      .then(() => setJobDataLoading(false))
  }, [jobDataLoading])

  const handleScrollTop = (): void => {
    window.scrollTo(0, 0)
  }

  const parsedQuery = queryString.parse(location.search)
  // not sure why keywords gets the ? in it when none of the others ever did...
  const keywordSearch = parsedQuery["?keywords"]
    ? parsedQuery["?keywords"].toString()
    : undefined

  const displayResults = (source: Job[]) => {
    return source.map(
      ({ uid, name, summary, location, origin_published_date, slug }) => {
        if (
          keywordSearch !== undefined &&
          keywordSearch !== "" &&
          name.toLowerCase().includes(keywordSearch.toLowerCase())
        ) {
          return (
            <JobItem
              key={uid}
              name={name}
              summary={summary}
              location={location}
              origin_published_date={origin_published_date}
              slug={slug}
            />
          )
        } else if (keywordSearch === "" || keywordSearch === undefined) {
          return (
            <JobItem
              key={uid}
              name={name}
              summary={summary}
              location={location}
              origin_published_date={origin_published_date}
              slug={slug}
            />
          )
        }
      }
    )
  }

  return (
    <section id="recent-jobs" className="content-page">
      <div className="container">
        <button
          className="careers-scroll_link desktop job-search-scroll-top"
          onClick={handleScrollTop}
        >
          <svg
            xmlns="https://www.w3.org/2000/svg"
            width="15"
            height="16"
            viewBox="0 0 14.969 16"
          >
            <path
              className="cls-1"
              d="M8 0v14.3L14.2 8a.5.5 0 0 1 .6 0 .5.5 0 0 1 .2.4.5.5 0 0 1-.2.4l-7 7a.5.5 0 0 1 0 .2.5.5 0 0 1-.3 0 .5.5 0 0 1-.2 0L0 8.7A.5.5 0 0 1 .3 8a.5.5 0 0 1 .3 0 .5.5 0 0 1 .3 0L7 14.4V0a5.2 5.2 0 0 1 1 0z"
            />
          </svg>
        </button>
        <Spacer deviceSize="s" />
        <h1>Careers</h1>
        <Spacer deviceSize="s" />
        <JobTable>
          {jobDataLoading ? (
            <tr>
              <td>
                <h2>Loading...</h2>
              </td>
            </tr>
          ) : (
            displayResults(jobData)
          )}
        </JobTable>
      </div>
    </section>
  )
}

export default Careers
