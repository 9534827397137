import React from "react"

import SideVisualBlock from "."
import { OptionsTypes, SideVisualBlockTypes } from "./types"
import classnames from "classnames"
import { ParallaxProvider } from "react-scroll-parallax"

interface SingleSideVisualBlockTypes extends OptionsTypes {
  content: SideVisualBlockTypes
  isHomepage?: boolean
  isShowcase?: boolean
  modifierClass?: string
  deviceImageOnTop?: boolean
  abortEntryTransition?: boolean
}

const SingleSideVisualBlock: React.FC<SingleSideVisualBlockTypes> = ({
  content,
  blockColourOverride,
  aligned,
  isHomepage,
  isShowcase,
  modifierClass,
  deviceImageOnTop,
  abortEntryTransition,
}) => {
  const wrapperClassnames = classnames({
    "content-page__wrapper--home": isHomepage,
    "content-page__wrapper--showcase": isShowcase,
  })

  return (
    <div className={`content-page__wrapper  ${wrapperClassnames}`}>
      <ParallaxProvider>
        <SideVisualBlock
          abortEntryTransition={abortEntryTransition}
          title={content.title}
          subtitle={content.subtitle}
          body_text={content.body_text}
          top_images={content.top_images}
          bottom_images={content.bottom_images}
          blockColourOverride={blockColourOverride}
          aligned={aligned}
          modifierClass={modifierClass}
          deviceImageOnTop={deviceImageOnTop}
        />
      </ParallaxProvider>
    </div>
  )
}

export default SingleSideVisualBlock
