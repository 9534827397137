import React from "react"
import { FilterType } from "./types"
import classnames from "classnames"

interface SearchFilterTypes {
  filterType: FilterType
  name: string
  active: boolean
  onClick: (filterType: FilterType, filterName: string) => void
}

const SearchFilter: React.FC<SearchFilterTypes> = ({
  filterType,
  name,
  onClick,
  active,
}) => {
  const handleChange = () => {
    onClick(filterType, name)
  }

  const labelClasses = classnames("tag__filter-label", {
    checked: active,
  })

  return (
    <label className={labelClasses}>
      <input
        onChange={handleChange}
        className="search-filter__input"
        type="checkbox"
        name={filterType}
        defaultValue={name}
        defaultChecked={active}
      />
      <span>{name}</span>
    </label>
  )
}
export default SearchFilter
