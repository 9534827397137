import React from "react"
import moment from "moment"
import { renderHtmlString } from "../../../helpers"
import Spacer from "../../layout/spacer"

interface ArticleHeaderTypes {
  breadcrumb: string
  title: string
  date: string
  readIn: string
}

const ArticleHeader: React.FC<ArticleHeaderTypes> = ({
  title,
  breadcrumb,
  date,
  readIn,
}) => {
  const formattedDate = moment(date).format("DD.MM.YY")
  const minutes = readIn.split(" ")

  return (
    <div className="article-header__content">
      <div className="article-header__breadcrumbs">
        <Spacer deviceSize="n" desktopSize="xs" />
        <small
          className="typography__caption--02"
          dangerouslySetInnerHTML={renderHtmlString(breadcrumb)}
        />
        <Spacer deviceSize="n" desktopSize="s" />
      </div>

      <div className="article-header__info">
        <div className="article-header__date">
          <small className="typography__caption--02 typography__weight--400 typography__color--white">
            {formattedDate}
          </small>
        </div>
        <div className="article-header__read-in">
          <small className="typography__caption--02 typography__weight--400  typography__color--white">
            {minutes[0]} min
          </small>
        </div>
      </div>
      <Spacer deviceSize="xs" />
      <h1 className="article-header__title typography__color--white typography__text-shadow">
        {title}
      </h1>
    </div>
  )
}

export default ArticleHeader
