import React from "react"

import ShowcaseSliderBanner from "../../modules/slider_banner/showcase"
import TwoColumnLayoutSlide from "../../modules/two_column_layout_slide"
import VideoTitleCopy from "../../modules/video_title_copy"
import SideVisualBlock from "../../modules/side_visual_block"
import FullWidthVisualHeading from "../../modules/full_width_visual_heading"
import ThreeColumnModule from "../../modules/three_column_module"
import { ShowcaseTile } from "./types"

type BlockProps = {
  record: ShowcaseTile;
}

const Block = ({ record }: BlockProps): JSX.Element => {
  const components = {
    'banner_title_with_left_icon_with_right_title_copy_and_call_to_action': ShowcaseSliderBanner,
    'alignable_title_copy_and_call_to_action_with_image': SideVisualBlock,
    'title_copy_with_video_description_title_and_description_copy': VideoTitleCopy,
    'alignable_two_row_title_copy_and_description_with_image': TwoColumnLayoutSlide,
    'alignable_full_width_image_title_and_attribution': FullWidthVisualHeading,
    'three_col_with_title_and_six_copy': ThreeColumnModule
  };

  const Component = components[record.layout];
  const { id, layout, ...props } = record;

  if (Component) {
    return <Component {...props} />;
  }

  return (
    <>
      <p>Don&apos;t know how to render a block!</p>
      <pre>{JSON.stringify(record, null, 2)}</pre>
    </>
  );
};

export default Block;
