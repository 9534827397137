import React, { useEffect, useState } from "react"
import { renderHtmlString, isTablet } from "../../../../helpers"
import { truncateTitleText, truncateBodyText } from "../helpers"
import Spacer from "../../../layout/spacer"
import classnames from "classnames"
import Modal from "react-modal"
import { VideoTileTypes } from "../types"
import VideoCta from "../../../cta/video"
import Picture from "../../../elements/picture"
import useDesktopDetector from "../../../../hooks/useDesktopDetector"

const VideoTile: React.FC<VideoTileTypes> = ({
  id,
  name,
  summary,
  video_url,
  images,
}) => {
  const isDesktop = useDesktopDetector()

  const [videoThumbnail, setVideoThumbnail] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    if (video_url) {
      fetch(`https://vimeo.com/api/v2/video/${video_url}.json`)
        .then((res) => res.json())
        .then((data) => {
          setVideoThumbnail(data[0].thumbnail_large)
        })
        .catch((err) => console.error(err))
    }
  }, [videoThumbnail])

  const [videoModal, setVideoModal] = useState<boolean>(false)

  const toggleVideoModal = () => {
    setVideoModal(!videoModal)
  }

  const h1Classnames = classnames({
    "typography__display--06": isTablet,
    "typography__body--01-900": !isTablet,
  })

  return (
    <article
      className="tile tile__single tile__multimedia tile__video"
      id={`${id}`}
    >
      <div className="tile__image-cell">
        {images?.desktop &&
        images?.desktop !== "/images/desktop/missing.png" ? (
          <>
            <Picture
              cssClassName="tile__image-wrapper tile__image-format--video"
              image1x={images?.desktop}
              image2x={images?.mobile}
            />
            <i className="video-embed__thumbnail-play" />
          </>
        ) : (
          <>
            {videoThumbnail !== undefined ? (
              <>
                <Picture
                  cssClassName="tile__image-wrapper tile__image-format--video"
                  image1x={videoThumbnail}
                  image2x={videoThumbnail}
                />
                <i className="video-embed__thumbnail-play" />
              </>
            ) : (
              <iframe
                className="video-embed__iframe"
                src={`https://player.vimeo.com/video/${video_url}`}
                frameBorder="0"
                allowFullScreen
              />
            )}
          </>
        )}
        <button
          className="tile__video-open"
          onClick={toggleVideoModal}
          name="Play Video"
          aria-label="Play Video"
        />
      </div>

      <div className="tile__content-cell">
        <div className="tile__info--backslash" />
        <h1
          className={`${h1Classnames} typography__weight--700  typography__color--navy`}
        >
          {truncateTitleText(name, isDesktop)}
        </h1>
        <Spacer deviceSize="xs" />
        {summary && (
          <div
            className="tile__overview typography__color--mid-grey"
            dangerouslySetInnerHTML={renderHtmlString(
              truncateBodyText(name, summary, isDesktop)
            )}
          />
        )}
      </div>

      <VideoCta onClick={toggleVideoModal} />
      <Modal
        isOpen={videoModal}
        onRequestClose={toggleVideoModal}
        className="video-modal"
        overlayClassName="video-modal__overlay"
      >
        <div className="video-embed">
          <iframe
            className="video-embed__iframe"
            src={`https://player.vimeo.com/video/${video_url}`}
            frameBorder="0"
            allowFullScreen
          />
        </div>
        <button
          onClick={toggleVideoModal}
          className="video-modal__close hamburger__button"
        >
          <span className="hamburger__bar toggled"></span>
        </button>
      </Modal>
    </article>
  )
}

export default VideoTile
