import React from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import SwiperCore, { Autoplay, Pagination } from "swiper"
import { Swiper as SwiperReact, SwiperSlide } from "swiper/react"
import useComponentInView from "../../../../hooks/useComponentInView"
import Wysiwyg from "../../../ui/wysiwyg"

export interface SliderTypes {
  modifierClass?: string
  contentOne?: string
  contentTwo?: string
  contentThree?: string
  contentFour?: string
  contentFive?: string
  contentSix?: string
}

const Slider: React.FC<SliderTypes> = ({
  contentOne,
  contentTwo,
  contentThree,
  contentFour,
  contentFive,
  contentSix
}) => {

  SwiperCore.use([Autoplay, Pagination])

  const [ref, inView] = useInView({
    threshold: 0.2,
  })

  const componentSeen = useComponentInView(inView)

  return (
    <>
      <SwiperReact
        effect="slide"
        speed={1000}
        pagination={{ el: '.swiper-pagination', type: 'bullets', clickable: true, }}
        spaceBetween={0}
        slidesPerView={1}
        centeredSlides={true}
      >
        <SwiperSlide>
          <motion.div
            initial="hidden"
            className="three-column-module__item"
            animate={componentSeen ? "show" : "hide"}
          >
            <motion.div
              className="three-column-module__item__body-text"
              transition={{
                default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
              }}
            >
              <Wysiwyg content={contentOne} />
            </motion.div>
          </motion.div>
        </SwiperSlide>
        <SwiperSlide>
          <motion.div
            initial="hidden"
            className="three-column-module__item"
            animate={componentSeen ? "show" : "hide"}
          >
            <motion.div
              className="three-column-module__item__body-text"
              transition={{
                default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
              }}
            >
              <Wysiwyg content={contentTwo} />
            </motion.div>
          </motion.div>
        </SwiperSlide>
        <SwiperSlide>
          <motion.div
            initial="hidden"
            className="three-column-module__item"
            animate={componentSeen ? "show" : "hide"}
          >
            <motion.div
              className="three-column-module__item__body-text"
              transition={{
                default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
              }}
            >
              <Wysiwyg content={contentThree} />
            </motion.div>
          </motion.div>
        </SwiperSlide>
        <SwiperSlide>
          <motion.div
            initial="hidden"
            className="three-column-module__item"
            animate={componentSeen ? "show" : "hide"}
          >
            <motion.div
              className="three-column-module__item__body-text"
              transition={{
                default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
              }}
            >
              <Wysiwyg content={contentFour} />
            </motion.div>
          </motion.div>
        </SwiperSlide>
        <SwiperSlide>
          <motion.div
            initial="hidden"
            className="three-column-module__item"
            animate={componentSeen ? "show" : "hide"}
          >
            <motion.div
              className="three-column-module__item__body-text"
              transition={{
                default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
              }}
            >
              <Wysiwyg content={contentFive} />
            </motion.div>
          </motion.div>
        </SwiperSlide>
        <SwiperSlide>
          <motion.div
            initial="hidden"
            className="three-column-module__item"
            animate={componentSeen ? "show" : "hide"}
          >
            <motion.div
              className="three-column-module__item__body-text"
              transition={{
                default: { delay: 0.6, duration: 0.6, ease: "easeOut" },
              }}
            >
              <Wysiwyg content={contentSix} />
            </motion.div>
          </motion.div>
        </SwiperSlide>
      </SwiperReact>
      {contentOne.length > 1 && (<div className="swiper-pagination"></div>)}
    </>
  )
}

export default Slider
