import React from "react"
import Row from "../../modules/grid/row"
import NewsInsightsTile from "../../modules/tile/news_insights"
import { NewsTileTypes } from "../../modules/tile/types"

// These articles are used as a partial in an .erb file located >  'views/enquiries'

interface Props {
  articles: NewsTileTypes[]
}

const Articles: React.FC<Props> = ({ articles }) => {
  return (
    <>
      <Row columnCount={3}>
        {articles.map((article) => {
          const {
            id,
            listing_images,
            title,
            content,
            category,
            slug,
            publish_date,
            read_in,
            published_industries,
            published_services,
          } = article

          return (
            <NewsInsightsTile
              id={id}
              key={`news-article-${id}`}
              listing_images={listing_images}
              title={title}
              content={content}
              slug={slug}
              publish_date={publish_date}
              published_industries={published_industries}
              published_services={published_services}
              read_in={read_in}
              category={category}
            />
          )
        })}
      </Row>
    </>
  )
}

export default Articles
