import React, { useEffect, useState } from "react"
import { Waypoint } from "react-waypoint"
import Wysiwyg from "../../ui/wysiwyg"

// Content Modules
import CtaBanner from "../../modules/cta_banner"
import Multimedia from "../../modules/multimedia"
import DocumentLibrary from "../../modules/document_library"
import FeaturedTileSlider from "../../modules/featured_tile_slider"
import RelatedNews from "../../modules/related_news"
import LinkedInFeed from "../../modules/linkedin_feed"

// UI Modules
import Spacer from "../../layout/spacer"
import SideNav from "../../modules/side_nav"
import ContentWithStickyAside from "../../modules/content_with_sticky_aside"
import Breadcrumbs from "../../ui/breadcrumbs"
import GenericContentWrapper from "../../layout/generic_content_wrapper"
import PrimaryCtaDownload from "../../cta/primary_cta_download"
import PrimaryCta from "../../cta/primary_cta"

// Helpers
import useDesktopDetector from "../../../hooks/useDesktopDetector"

// Types
import { ChildPageModuleTypes, FeatureCta } from "./types"
import { DocumentLibraryTypes } from "../../modules/document_library"

function wysiwygContent(
  pageName: string,
  isDesktop: boolean,
  sideNav: () => void,
  wysiwyg: string,
  documentLibrary: DocumentLibraryTypes,
  handleWaypoint: (string) => void,
  showFeaturedLinkCta: boolean,
  showFeaturedDownloadCta: boolean,
  featuredDownloadCta: FeatureCta,
  featuredLinkCta: FeatureCta
): React.ReactElement {
  const [showTableSpacer, setShowTableSpacer] = useState(false)

  useEffect(() => {
    if (wysiwyg) setShowTableSpacer(true)
    else if (showFeaturedLinkCta || showFeaturedDownloadCta)
      setShowTableSpacer(true)
    else setShowTableSpacer(false)
  }, [wysiwyg, showFeaturedLinkCta, showFeaturedDownloadCta])

  const [showCtaGroupSpacer, setShowCtaGroupSpacer] = useState(false)

  useEffect(() => {
    if (wysiwyg) setShowCtaGroupSpacer(true)
    else setShowCtaGroupSpacer(false)
  }, [wysiwyg])

  return (
    <>
      <section className="content-page__inner content-page__inner--lift-up">
        <section className="content-page__with-sidebar" id="overview">
          <Waypoint
            onEnter={() => handleWaypoint("overview")}
            bottomOffset={600}
          />
          {wysiwyg && <Wysiwyg content={`${wysiwyg}`} />}
          {(showFeaturedLinkCta || showFeaturedDownloadCta) && (
            <>
              {showCtaGroupSpacer && (
                <Spacer deviceSize="xxs" desktopSize="s" />
              )}
              <div className="content-page__cta-group">
                {featuredDownloadCta.name && showFeaturedDownloadCta && (
                  <PrimaryCtaDownload
                    link={featuredDownloadCta.path}
                    label={featuredDownloadCta.name}
                  />
                )}
                {featuredLinkCta.name && showFeaturedLinkCta && (
                  <PrimaryCta
                    modifierClass="small navy"
                    link={featuredLinkCta.path}
                    label={featuredLinkCta.name}
                  />
                )}
              </div>
            </>
          )}

          {pageName === "Announcements" ? (
            <div className="investor-center__iframe-wrapper investor-center__iframe-wrapper--announcements">
              <iframe
                src="https://wcsecure.weblink.com.au/Clients/Ventia/headlineContents.aspx?"
                width="100%"
                height="1200"
                frameBorder="0"
                scrolling="no"
                allowTransparency={true}
              />
            </div>
          ) : (
            <>
              {documentLibrary && (
                <>
                  {documentLibrary.library_module_tabs.length >= 1 && (
                    <>
                      {showTableSpacer && (
                        <Spacer deviceSize="n" desktopSize="s" />
                      )}
                      <DocumentLibrary
                        library_module_tabs={
                          documentLibrary.library_module_tabs
                        }
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </section>
      </section>
      {!isDesktop && sideNav()}
    </>
  )
}

const InvestorCentreChild: React.FC<ChildPageModuleTypes> = ({
  pageName,
  showSideNavCta,
  sideNavCtaText,
  wysiwyg,
  documentLibrary,
  showFeaturedTiles,
  featuredTileSliderHeading,
  featuredTileSliderContent,
  ctaBanner,
  featuredDownloadCta,
  featuredLinkCta,
  showFeaturedLinkCta,
  showFeaturedDownloadCta,
  sideNavCtaPath,
  leadGen,
  hasLeadGenForm,
  leadGenFormUrl,
  downloadSliderHeader,
  downloadSliderContent,
  showDownloadSlider,
  relatedNewsHeading,
  relatedNewsContent,
  showRelatedNews,
  linkedInFeed,
}) => {
  const [documentLibraryExists, setDocumentLibraryExists] = useState(false)

  useEffect(() => {
    if (!documentLibrary) {
      setDocumentLibraryExists(false)
    } else if (
      documentLibrary &&
      documentLibrary.library_module_tabs.length === 0
    ) {
      setDocumentLibraryExists(false)
    } else {
      setDocumentLibraryExists(true)
    }
  }, [documentLibrary])

  const isDesktop = useDesktopDetector()
  const sideNav = () => {
    return (
      <>
        {!documentLibraryExists && <Spacer deviceSize="s" />}
        <SideNav
          cta={{
            isVisible: showSideNavCta,
            label: sideNavCtaText,
            link: sideNavCtaPath,
          }}
          isArticle={false}
          isCampaign={false}
          name={leadGen.name}
          linkedin={leadGen.linkedin}
          title={leadGen.title}
          email={leadGen.email}
          image1x={leadGen.image1x}
          image2x={leadGen.image2x}
          phone={leadGen.phone}
          contactLabel={leadGen.contactLabel}
          hidePhone={leadGen.hidePhone}
          hideEmail={leadGen.hideEmail}
          team="Investors Centre"
          hasLeadGenForm={hasLeadGenForm}
          formHtml={leadGenFormUrl}
          relatedCapabilities={[]}
          relatedIndustries={[]}
          relatedServices={[]}
          relatedLocations={[]}
        />
      </>
    )
  }

  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://wcsecure.weblink.com.au/styles/chartstyles/responsive/WL_TabFrameFunctions.js"
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <Breadcrumbs
        isParentPage={false}
        parentPage={{ name: "Investor Centre", slug: "/investor-centre" }}
        currentPage={pageName}
      />
      <GenericContentWrapper modifierClass="article investors-centre">
        <ContentWithStickyAside
          hasStickySubNav={false}
          content={wysiwygContent(
            pageName,
            isDesktop,
            sideNav,
            wysiwyg,
            documentLibrary,
            () => null,
            showFeaturedLinkCta,
            showFeaturedDownloadCta,
            featuredDownloadCta,
            featuredLinkCta
          )}
          aside={sideNav()}
        />
      </GenericContentWrapper>
      {showFeaturedTiles && (
        <GenericContentWrapper>
          <div className="device-content-padding">
            <FeaturedTileSlider
              heading={`${featuredTileSliderHeading}`}
              content={featuredTileSliderContent}
            />
          </div>
        </GenericContentWrapper>
      )}
      {showDownloadSlider && downloadSliderContent && (
        <Multimedia
          downloads={downloadSliderContent}
          videos={[]}
          heading={downloadSliderHeader ? downloadSliderHeader : ""}
        />
      )}
      {relatedNewsContent && showRelatedNews && (
        <RelatedNews
          articles={relatedNewsContent}
          articlesHeading={relatedNewsHeading ? relatedNewsHeading : ""}
          hasBackgroundColor={false}
          inCampaign={false}
        />
      )}
      {linkedInFeed && linkedInFeed.length >= 1 && (
        <LinkedInFeed feed={linkedInFeed} />
      )}{" "}
      {ctaBanner && (
        <CtaBanner
          isContentBanner={true}
          title={ctaBanner.title}
          description={ctaBanner.description}
          buttonTitle={ctaBanner.button_title}
          ctaLink={ctaBanner.button_url}
          footer={ctaBanner}
        />
      )}
    </>
  )
}

export default InvestorCentreChild
