/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react"
import ContentPage from "../../layout/content_page"
import Grid from "../../modules/grid"
import Row from "../../modules/grid/row"
import SectorTile from "../../modules/tile/sector"
import Spacer from "../../layout/spacer"
import { SectorsTypes } from "../sectors/types"

const Sectors: React.FC<SectorsTypes> = ({ intro, endpoint, sector }) => {
  const [allSectorItems, setAllSectorItems] = useState([])
  const [allSectorItemsLoading, setAllSectorItemsLoading] = useState(true)

  useEffect(() => {
    async function fetchAllArticles() {
      setAllSectorItemsLoading(true)
      let data: any = await fetch(endpoint)
      data = await data.json()
      setAllSectorItemsLoading(false)
      setAllSectorItems(data.data)
    }
    fetchAllArticles()
  }, [])

  const [data, setData] = useState([])

  useEffect(() => {
    if (allSectorItems.length) {
      setData(allSectorItems)
    }
  }, [allSectorItems])

  return (
    <section id={`our-${sector}`}>
      <ContentPage
        isHtmlString={true}
        intro={intro}
        modifierClass="sectors-landing-page device-content-padding"
      >
        <Spacer desktopSize="xxs" />
        <Grid>
          {allSectorItemsLoading ? (
            <h2>Loading...</h2>
          ) : (
            <Row columnCount={3} id="filtered-articled">
              {data.map(({ id, listing_images, teaser, name, slug }) => {
                return (
                  <SectorTile
                    key={id}
                    id={id}
                    listing_images={listing_images}
                    teaser={teaser}
                    name={name}
                    slug={slug}
                    sector={sector}
                  />
                )
              })}
            </Row>
          )}
        </Grid>
      </ContentPage>
    </section>
  )
}

export default Sectors
