import React from "react"
import { HamburgerTypes } from "../../../types"

const Hamburger: React.FC<HamburgerTypes> = ({
  isHamburgerToggled,
  onHamburgerClick,
}) => {
  return (
    <li className="action hamburger">
      <button className="hamburger__button" onClick={onHamburgerClick}>
        Mobile Menu Toggle
        <span
          className={`hamburger__bar ${isHamburgerToggled ? "toggled" : ""}`}
        ></span>
      </button>
    </li>
  )
}

export default Hamburger
