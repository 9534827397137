import React, { useEffect } from "react"
import Swiper from "swiper/swiper-bundle.min.js"

import ContentPage from "../../layout/content_page"
import Filters from "./filters"

// Banner modules
import FeaturedTile from "../../modules/tile/featured"
import MiniTile from "../../modules/tile/mini"
import Column from "../../modules/grid/column"
import Row from "../../modules/grid/row"

import { CapabilitiesTypes } from "./types"

const Capabilities: React.FC<CapabilitiesTypes> = ({
  featured,
  publishedCapabilities,
  industries,
  services,
}) => {
  const swiperRef = React.createRef<HTMLDivElement>()

  useEffect(() => {
    const swiper = new Swiper(swiperRef.current, {
      slidesPerView: 1,
      navigation: {
        nextEl: ".content-slider-navigation__button--next",
        prevEl: ".content-slider-navigation__button--prev",
      },
      autoplay: {
        delay: 6000,
      },
      speed: 800,
      resistance: false,
      loop: true,
      effect: "slide",
      fadeEffect: {
        crossfade: true,
      },
    })
    if (swiperRef.current) {
      swiper
    }
  }, [swiperRef])

  return (
    <ContentPage modifierClass="device-content-padding">
      <Filters
        showCategories={false}
        industries={industries}
        services={services}
        heading="Explore our capabilities"
        list={publishedCapabilities}
        parentPath="/capabilities"
      />

      <div role="banner" className="slider-banner content-slider">
        <div className="" ref={swiperRef}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h1 className="typography__display--04">
              Our enterprise capabilities
            </h1>
            <div className="page-banner__navigation  page-banner__navigation--horizontal capabilities-slider__navigation">
              <div className="content-slider-navigation__button content-slider-navigation__button--prev">
                <span />
              </div>
              <div className="content-slider-navigation__button content-slider-navigation__button--next">
                <span />
              </div>
            </div>
          </div>
          <div className="swiper-wrapper content-slider--swiper-wrapper">
            {featured.length >= 1 &&
              featured.map(
                ({
                  id,
                  name,
                  overview,
                  core_capabilities,
                  header_image: { recommendation },
                  slug,
                }) => {
                  return (
                    <div
                      key={id}
                      className="swiper-slide content-slider--swiper"
                    >
                      <FeaturedTile
                        modifierClass="feature-slider-tile"
                        id={id}
                        slug={slug}
                        currentPath="/capabilities"
                        name={name}
                        overview={overview}
                        leftAlignedFeature={false}
                        listing_image={recommendation}
                      />

                      {core_capabilities.length >= 1 && (
                        <Row
                          columnCount={3}
                          modifierClass="core-capabilities__ul"
                        >
                          {core_capabilities.slice(0, 9).map((i) => {
                            return (
                              <Column key={i.id}>
                                <MiniTile
                                  listing_images={null}
                                  id={i.id}
                                  title={i.name}
                                  text={undefined}
                                  slug={i.slug}
                                  currentPath="/capabilities"
                                  featuredOnCapabilityLanding={true}
                                  parentCapability={name}
                                  recommendedMiniTile={false}
                                />
                              </Column>
                            )
                          })}
                        </Row>
                      )}
                    </div>
                  )
                }
              )}
          </div>
        </div>
      </div>
    </ContentPage>
  )
}

export default Capabilities
