import React from "react"

// Modules
import SideNav from "../../../modules/side_nav"
import Multimedia from "../../../modules/multimedia"
import Row from "../../../modules/grid/row"
import Column from "../../../modules/grid/column"
import DataBlock from "../../../modules/data_block"
import MiniTile from "../../../modules/tile/mini"
import ProjectCaseStudyTile from "../../../modules/tile/project_case_study"
import RelatedProjects from "../../../modules/related_projects"
import RelatedNews from "../../../modules/related_news"
import ContentWithStickyAside from "../../../modules/content_with_sticky_aside"

// Helpers
import useDesktopDetector from "../../../../hooks/useDesktopDetector"

// UI
import Wysiwyg from "../../../ui/wysiwyg"
import ContentPage from "../../../layout/content_page"
import Spacer from "../../../layout/spacer"

import { PageModuleTypes } from "../types"

import { WysiwygType } from "../types"

// Used here and in storybook -> app/javascript/components/modules/content_with_sticky_aside/index.stories.tsx
export function wysiwygContent(
  isDesktop: boolean,
  sideNav: () => void,
  wysiwyg: WysiwygType[] | string
): React.ReactElement {
  return (
    <ContentPage modifierClass="article">
      {!isDesktop && sideNav()}
      <section className="content-page__inner  content-page__inner--lift-up">
        <section
          id="anchor-overview-section"
          className="content-page__with-sidebar"
        >
          {wysiwyg && <Wysiwyg content={`${wysiwyg}`} />}
        </section>
      </section>
    </ContentPage>
  )
}

const Article: React.FC<PageModuleTypes> = ({
  leadGen,
  hasLeadGenForm,
  leadGenFormUrl,
  dataBlockStats,
  relatedCapabilities,
  relatedServices,
  relatedIndustries,
  relatedLocations,
  wysiwyg,
  videos,
  downloads,
  headingForVideos,
  relatedProjects,
  relatedNews,
  relatedNewsHeading,
}) => {
  const isDesktop = useDesktopDetector()

  function sideNav() {
    return (
      <SideNav
        cta={{ isVisible: false }}
        isCampaign={false}
        team={null}
        isArticle={false}
        name={leadGen.name}
        linkedin={leadGen.linkedin}
        title={leadGen.title}
        email={leadGen.email}
        image1x={leadGen.image1x}
        image2x={leadGen.image2x}
        phone={leadGen.phone}
        contactLabel={leadGen.contactLabel}
        hidePhone={leadGen.hidePhone}
        hideEmail={leadGen.hideEmail}
        hasLeadGenForm={hasLeadGenForm}
        formHtml={leadGenFormUrl}
        relatedCapabilities={relatedCapabilities}
        relatedIndustries={relatedIndustries}
        relatedServices={relatedServices}
        relatedLocations={relatedLocations}
      />
    )
  }

  return (
    <>
      <ContentWithStickyAside
        hasStickySubNav={false}
        content={wysiwygContent(isDesktop, sideNav, wysiwyg)}
        aside={sideNav()}
      />

      {dataBlockStats.length >= 1 && (
        <div>
          <Spacer deviceSize="s" hideForPrint={true} />
          <DataBlock stats={dataBlockStats} />
        </div>
      )}
      <Spacer deviceSize="s" />

      {(videos.length >= 1 || downloads.length >= 1) && (
        <Multimedia
          heading={headingForVideos}
          videos={videos}
          downloads={downloads}
        />
      )}

      {relatedProjects.length === 2 && relatedNews.length === 3 ? (
        <>
          <Spacer deviceSize="s" />
          <ContentPage>
            <div className="device-content-padding">
              {isDesktop ? (
                <>
                  <Row columnCount={3}>
                    <Column>
                      <Spacer deviceSize="n" />
                      <h2 className="typography__display--05">
                        Related projects
                      </h2>
                      <Spacer deviceSize="s" />
                    </Column>
                    <Column>&nbsp;</Column>
                    <Column>
                      <Spacer deviceSize="n" />
                      <h2 className="typography__display--05">Related News</h2>
                      <Spacer deviceSize="s" />
                    </Column>
                  </Row>
                  <div
                    style={{
                      width: "100%",
                      background: "#cccccc",
                      height: "1px",
                    }}
                  />
                </>
              ) : (
                <Row columnCount={1}>
                  <Column>
                    <Spacer deviceSize="n" />
                    <h2 className="typography__display--05">
                      Related projects
                    </h2>
                    <Spacer deviceSize="s" />
                  </Column>
                </Row>
              )}
              <Spacer deviceSize="s" />

              <Row id="1" columnCount={3}>
                <>
                  {relatedProjects.map(
                    ({
                      id,
                      client_name,
                      name,
                      slug,
                      listing_images,
                      teaser,
                    }) => {
                      return (
                        <ProjectCaseStudyTile
                          key={id}
                          id={id}
                          client_name={client_name}
                          listing_images={listing_images}
                          name={name}
                          teaser={teaser}
                          slug={slug}
                        />
                      )
                    }
                  )}
                  {!isDesktop && (
                    <Row columnCount={1}>
                      <Column>
                        <Spacer deviceSize="n" />
                        <h2 className="typography__display--05">
                          Related News
                        </h2>
                        <Spacer deviceSize="s" />
                      </Column>
                    </Row>
                  )}

                  <Column>
                    {relatedNews.map(
                      ({ id, title, slug, listing_images, content }) => {
                        return (
                          <MiniTile
                            key={`modular-row-tile-${id}`}
                            id={id}
                            listing_images={listing_images}
                            title={title}
                            text={content}
                            slug={slug}
                            currentPath="/news-and-insights"
                            featuredOnCapabilityLanding={false}
                            recommendedMiniTile={false}
                          />
                        )
                      }
                    )}
                  </Column>
                </>
              </Row>
            </div>
          </ContentPage>
        </>
      ) : (
        <>
          {relatedProjects.length >= 1 && (
            <>
              <Spacer deviceSize="xs" />
              <ContentPage>
                <div className="device-content-padding">
                  <RelatedProjects name="Related" projects={relatedProjects} />
                </div>
              </ContentPage>
              <Spacer deviceSize="xs" />
            </>
          )}

          {relatedNews.length >= 1 && (
            <RelatedNews
              articles={relatedNews}
              articlesHeading={relatedNewsHeading ? relatedNewsHeading : ""}
              inCampaign={false}
            />
          )}
        </>
      )}
    </>
  )
}

export default Article
