import React, { useState, useEffect } from "react"
import ReactPaginate from "react-paginate"
import TextInputSearch from "../../ui/forms/text_input_search"
import useDesktopDetector from "../../../hooks/useDesktopDetector"
import Grid from "../../modules/grid"
import Row from "../../modules/grid/row"
import Column from "../../modules/grid/column"
import ProjectCaseStudyTile from "../../modules/tile/project_case_study"
import NewsInsightsTile from "../../modules/tile/news_insights"
import FilterBar from "../../modules/filters/filter_bar"
import Spacer from "../../layout/spacer"
import Toggler from "../../modules/filters/toggler"
import DeviceFilterBar from "../../modules/filters/device_filter_bar"

import Skelleton from "../../modules/tile/skelleton"
import { ArticleTypes } from "../../pages/_article_helpers/types"
import { ProjectTileTypes, NewsTileTypes } from "../tile/types"

import { activeFilterBuilder } from "./activeFilterBuilder"

import { getFilterCount } from "./getFilterCount"

type AvailableFilterType = {
  name: string
}

type ProjectDataType = {
  tag_industries: AvailableFilterType[]
  tag_services: AvailableFilterType[]
  tag_locations: AvailableFilterType[]
  tag_filter_categories: AvailableFilterType[]
}

type ArticleDataType = {
  published_industries: AvailableFilterType[]
  published_services: AvailableFilterType[]
  published_locations: AvailableFilterType[]
  filter_categories: AvailableFilterType[]
}

type EndpointShapeType = {
  total_pages: number
  projects: ProjectTileTypes[]
  articles: NewsTileTypes[]
  total_count: number
}

const Listing: React.FC<ArticleTypes> = ({
  articleType,
  endpoint,
  noLimitEndpoint,
  industries,
  services,
  categories,
  locations,
}) => {
  const isDesktop = useDesktopDetector()

  // ---------------------------------
  // Initial Load State
  // ---------------------------------
  const [articlesLoading, setArticlesLoading] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [filteredEndpoint, setFilteredEndpoint] = useState(noLimitEndpoint)
  const [isCleared, setIsCleared] = useState(false)
  const [activeFilters, setActiveFilters] = useState<string[]>([])
  const [pageOne, setPageOne] = useState(false)

  // ---------------------------------
  // Initial page load request
  // ---------------------------------
  const [articleList, setArticleList] = useState<EndpointShapeType>({
    total_pages: 0,
    projects: [] as ProjectTileTypes[],
    articles: [] as NewsTileTypes[],
    total_count: 0,
  })

  async function fetchArticles(data) {
    setArticlesLoading(true)
    try {
      const response: Response = await Promise.resolve(fetch(`${data}`))
      const body: EndpointShapeType = (await response.json()) as EndpointShapeType
      if (body) {
        setArticleList(body)
        setArticlesLoading(false)
        setIsCleared(false)
      }
    } catch (err) {
      setArticleList({
        total_pages: 0,
        projects: [],
        articles: [],
        total_count: 0,
      })
    }
  }
  // ---------------------------------
  // Initial page load request for filters -
  // ---------------------------------
  const [noLimitArticleList, setNoLimitArticleList] = useState({
    total_pages: 0,
    projects: [] as ProjectDataType[],
    articles: [] as ArticleDataType[],
    total_count: 0,
  })

  const [noLimitArticlesLoading, setNoLimitArticlesLoading] = useState(false)

  async function fetchNoLimitArticles(data) {
    setNoLimitArticlesLoading(true)
    const response: Response = await Promise.resolve(fetch(`${data}`))

    const body = await response.json()
    if (body) {
      setNoLimitArticleList(body)
      setNoLimitArticlesLoading(false)
    }
  }

  const [availableIndustries, setAvailableIndustries] = useState<
    AvailableFilterType[]
  >([])
  const [availableServices, setAvailableServices] = useState<
    AvailableFilterType[]
  >([])
  const [availableLocations, setAvailableLocations] = useState<
    AvailableFilterType[]
  >([])
  const [availableCategories, setAvailableCategories] = useState<
    AvailableFilterType[]
  >([])

  const industriesCheckboxes = document.querySelectorAll(
    "input[type=checkbox].filter-industries"
  ) as NodeListOf<HTMLInputElement>

  const servicesCheckboxes = document.querySelectorAll(
    "input[type=checkbox].filter-services"
  ) as NodeListOf<HTMLInputElement>

  const locationsCheckboxes = document.querySelectorAll(
    "input[type=checkbox].filter-locations"
  ) as NodeListOf<HTMLInputElement>

  const categoriesCheckboxes = document.querySelectorAll(
    "input[type=checkbox].filter-categories"
  ) as NodeListOf<HTMLInputElement>

  const handleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  // ---------------------------------
  // State: Count of each clicked filter
  // ---------------------------------
  const currentIndustryCount = getFilterCount(industriesCheckboxes)
  const currentServicesCount = getFilterCount(servicesCheckboxes)
  const currentLocationsCount = getFilterCount(locationsCheckboxes)
  const currentCategoriesCount = getFilterCount(categoriesCheckboxes)

  const areAnyFiltersSelected: boolean =
    currentIndustryCount ||
    currentServicesCount ||
    currentLocationsCount ||
    currentCategoriesCount
      ? true
      : false

  // ---------------------------------
  // Clearing
  // ---------------------------------
  const checkboxes = document.querySelectorAll(
    "input[type=checkbox]"
  ) as NodeListOf<HTMLInputElement>

  const handleClearFilters = () => {
    setPageOne(true)
    fetchArticles(endpoint)
      .then(() => fetchNoLimitArticles(noLimitEndpoint))
      .then(() => setActiveFilters(activeFilterBuilder(null, "")))
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false
    }
    setActiveFilters([])
  }

  const activeLi = document.querySelector("li.active")

  useEffect(() => {
    if (isCleared) {
      if (activeLi) {
        activeLi.classList.remove("active")
        activeLi.classList.add("inactive")
      }

      setFilteredEndpoint(endpoint)
    }
  }, [activeLi, isCleared, endpoint])

  // ---------------------------------
  // Searching
  // ---------------------------------
  const [searchTerm, setSearchTerm] = useState("")
  const handleSearchSubmit = (e) => {
    e.preventDefault()
    const searchEndpoint = `${endpoint}?query=${searchTerm}`
    fetchArticles(searchEndpoint)
  }

  // ---------------------------------
  // Pagination
  // ---------------------------------
  const handlePagination = (e) => {
    setPageOne(false)
    const pageNumber = e.selected + 1

    if (areAnyFiltersSelected) {
      fetchArticles(`${filteredEndpoint}page=${pageNumber}&limit=9`)

      e.selected = 0
    } else {
      fetchArticles(`${endpoint}?query=${searchTerm}&page=${pageNumber}`)
    }

    window.scrollTo({ top: isDesktop ? 290 : 310, behavior: "smooth" })
  }
  // ---------------------------------
  // Creating the filtered endpoints
  // ---------------------------------
  const handleFilterClick = (e, filterType: string) => {
    setPageOne(true)
    setIsCleared(false)
    setActiveFilters(activeFilterBuilder(e, filterType))
  }

  useEffect(() => {
    if (!isCleared) {
      const joined = activeFilters.join("")
      fetchArticles(`${endpoint}?${joined}`)
        .then(() => fetchNoLimitArticles(`${noLimitEndpoint}?${joined}`))
        .then(() => setFilteredEndpoint(`${noLimitEndpoint}?limit=9&${joined}`))
    }
  }, [activeFilters, isCleared])

  useEffect(() => {
    if (noLimitArticleList.articles || noLimitArticleList.projects) {
      const publishedServices: AvailableFilterType[] = []
      const publishedIndustries: AvailableFilterType[] = []
      const publishedLocations: AvailableFilterType[] = []
      const filterCategories: AvailableFilterType[] = []

      if (articleType === "news_article") {
        noLimitArticleList.articles.forEach((article) => {
          article.published_industries.forEach((i) => {
            publishedIndustries.push({
              name: i.name,
            })
          })

          article.published_services.forEach((i) => {
            publishedServices.push({
              name: i.name,
            })
          })

          article.published_locations.forEach((i) => {
            publishedLocations.push({
              name: i.name,
            })
          })

          article.filter_categories.forEach((i) => {
            filterCategories.push({
              name: i.name,
            })
          })
        })
      }

      if (articleType === "CaseStudy") {
        noLimitArticleList.projects.forEach((article) => {
          if (article.tag_industries) {
            article.tag_industries.forEach((i) => {
              publishedIndustries.push({
                name: i.name,
              })
            })
          }

          if (article.tag_services) {
            article.tag_services.forEach((i) => {
              publishedServices.push({
                name: i.name,
              })
            })
          }

          if (article.tag_locations) {
            article.tag_locations.forEach((i) => {
              publishedLocations.push({
                name: i.name,
              })
            })
          }

          if (article.tag_filter_categories) {
            article.tag_filter_categories.forEach((i) => {
              filterCategories.push({
                name: i.name,
              })
            })
          }
        })
      }
      setAvailableIndustries(publishedIndustries)
      setAvailableServices(publishedServices)
      setAvailableLocations(publishedLocations)
      setAvailableCategories(filterCategories)
    }
  }, [noLimitArticleList, isCleared])

  useEffect(() => {
    const body = document.querySelector("body")
    if (isExpanded && !isDesktop) {
      if (body) {
        body.classList.add("hide")
      }
    } else {
      if (body) {
        body.classList.remove("hide")
      }
    }
  }, [isExpanded, isDesktop])

  const filterBar = () => {
    return (
      <FilterBar
        handleApply={handleExpand}
        isLoading={noLimitArticlesLoading}
        handleClear={handleClearFilters}
        industries={industries}
        services={services}
        categories={categories}
        locations={locations}
        isExpanded={isExpanded}
        areFiltersChecked={areAnyFiltersSelected}
        availableIndustries={availableIndustries}
        availableServices={availableServices}
        availableLocations={availableLocations}
        availableCategories={availableCategories}
        checkedIndustriesFilters={getFilterCount(industriesCheckboxes)}
        checkedServicesFilters={getFilterCount(servicesCheckboxes)}
        checkedCategoriesFilters={getFilterCount(categoriesCheckboxes)}
        checkedLocationsFilters={getFilterCount(locationsCheckboxes)}
        handleIndustryKeywordClick={(e) => handleFilterClick(e, "industries")}
        handleServicesKeywordClick={(e) => handleFilterClick(e, "services")}
        handleLocationsKeywordClick={(e) => handleFilterClick(e, "locations")}
        handleCategoriesKeywordClick={(e) =>
          handleFilterClick(e, "filter_categories")
        }
        results={noLimitArticleList.total_count}
      >
        <Toggler
          isNewsProjects={true}
          handleOnClick={handleExpand}
          isToggled={isExpanded}
        />
      </FilterBar>
    )
  }

  return (
    <>
      {isDesktop ? (
        filterBar()
      ) : (
        <>
          <div
            className="device-content-padding"
            style={{ position: "relative" }}
          >
            <DeviceFilterBar
              areFiltersChecked={areAnyFiltersSelected}
              totalFiltersActive={
                getFilterCount(industriesCheckboxes) +
                getFilterCount(servicesCheckboxes) +
                getFilterCount(locationsCheckboxes) +
                getFilterCount(categoriesCheckboxes)
              }
              handleClearFilters={handleClearFilters}
              handleExpand={handleExpand}
            />
          </div>

          <div
            className={`filter-bar--device-dialog ${
              isExpanded ? "open" : "close"
            }`}
          >
            {filterBar()}
          </div>
        </>
      )}

      <Grid>
        <Row columnCount={2} modifierClass="flex__align--center">
          {isDesktop && (
            <Column>
              <small className="typography__body--01 typography__color--dark-grey">
                Showing{" "}
                <span className="typography__weight--700">
                  {articleList.total_count}
                </span>{" "}
                results
              </small>
            </Column>
          )}
          <Column>
            <TextInputSearch
              placeholder="Search with keywords"
              submit={true}
              handleSearchSubmit={handleSearchSubmit}
              searchTerm={searchTerm}
              handleOnChange={(e) => setSearchTerm(e.target.value)}
            />
          </Column>
        </Row>
      </Grid>
      <Spacer deviceSize="s" />
      <Grid>
        <Row columnCount={3} id="article-grid">
          {!articlesLoading ? (
            <>
              {articleType === "CaseStudy" &&
                articleList.projects !== null &&
                articleList.projects.map((article) => {
                  const {
                    id,
                    client_name,
                    listing_images,
                    name,
                    teaser,
                    slug,
                  } = article

                  return (
                    <ProjectCaseStudyTile
                      id={id}
                      key={`project-tile--${id}`}
                      client_name={client_name}
                      listing_images={listing_images}
                      name={name}
                      teaser={teaser}
                      slug={slug}
                    />
                  )
                })}

              {articleType === "news_article" &&
                articleList.articles !== null &&
                articleList.articles.map((article) => {
                  const {
                    id,
                    category,
                    listing_images,
                    title,
                    content,
                    slug,
                    publish_date,
                    read_in,
                    published_industries,
                    published_services,
                  } = article
                  return (
                    <NewsInsightsTile
                      id={id}
                      key={`news-tile--${id}`}
                      category={category}
                      listing_images={listing_images}
                      title={title}
                      content={content}
                      slug={slug}
                      publish_date={publish_date}
                      published_industries={published_industries}
                      published_services={published_services}
                      read_in={read_in}
                    />
                  )
                })}
            </>
          ) : (
            <Skelleton />
          )}
        </Row>
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={articleList.total_pages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={6}
          onPageChange={handlePagination}
          containerClassName={"pagination-wrapper"}
          subContainerClassName={"pagination-section"}
          activeClassName={`${pageOne ? "" : "active"} `}
        />
      </Grid>
    </>
  )
}

export default Listing
