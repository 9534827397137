/* eslint-disable react/display-name */
import React, { forwardRef } from "react"
import classnames from "classnames"

import ArticleCount from "../../pages/_article_helpers/article_count"
interface TabsTypes {
  allTabsDisabled?: boolean
  isFilterList?: boolean
  isDisabled: boolean
  isActive?: boolean
  activeId?: number
  count: number
  isLoading: boolean
  newsInsightTabs?: boolean
  id: number
  isFocused: boolean
  tabIndex: number
  label: string
  setActive: (id: number) => void
}

const Tabs = forwardRef<HTMLLIElement, TabsTypes>((props, ref) => {
  const {
    allTabsDisabled,
    isFilterList = false,
    isDisabled = false,
    tabIndex,
    activeId,
    setActive,
    id,
    isFocused,
    label,
    isLoading,
    newsInsightTabs,
    count,
  } = props

  const isActive = id === activeId

  const handleTabClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (activeId !== id) {
      setActive(id)
    } else {
      setActive(-1)
    }
  }

  const tabClassnames = classnames({
    active: isActive,
    inactive: !isActive,
    "tab--filter-list": isFilterList,
  })

  const typographyClassnames = classnames({
    "typography__display--04 typography__weight--700": !isFilterList,
    "typography__body--01 typography typography__weight--600": isFilterList,
    "typography__body--01 typography typography__weight--600 typography__color--grey": allTabsDisabled,
  })

  return (
    <li
      ref={ref}
      id={label}
      role="tab"
      tabIndex={tabIndex}
      className={`tab tab-${tabIndex} ${tabClassnames}`}
      aria-controls={`tabs-${id}`}
      aria-labelledby={`tabs-${id}`}
      aria-selected={isFocused}
      aria-expanded={isFocused}
    >
      <button
        disabled={isDisabled}
        type="button"
        className={`tab-button active  ${isActive ? "active" : "inactive"}`}
        onClick={handleTabClick}
      >
        <span className={`tab-button__text ${typographyClassnames} `}>
          {label}
        </span>
        {newsInsightTabs && (
          <ArticleCount count={count ? count : 0} isLoading={isLoading} />
        )}
      </button>
    </li>
  )
})
export default Tabs
