import React, { useEffect, useState } from "react"
import Cta from "../cta"
import {
  truncateText,
  strippedString,
  isDesktop,
  renderHtmlString,
} from "../../../../helpers"
import Spacer from "../../../layout/spacer"
import classnames from "classnames"
import { MiniTileTypes } from "../types"
import Picture from "../../../elements/picture"

import { handleTileClick } from "../helpers"

const MiniTile: React.FC<MiniTileTypes> = ({
  text,
  title,
  recommendedMiniTile,
  image_url,
  currentPath,
  slug,
  featuredOnCapabilityLanding = false,
  parentCapability = "",
}) => {
  const tileClassnames = classnames("tile__mini-item", {
    "tile__mini--recommended-mini": recommendedMiniTile,
  })

  const completeSlug = currentPath ? `${currentPath}/${slug}` : slug

  const [isDesktopDetector, setIsDesktopDetector] = useState(isDesktop())
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (isDesktop()) setIsDesktopDetector(true)
      else {
        setIsDesktopDetector(false)
      }
    })

    return () => {
      window.removeEventListener("resize", () => null)
    }
  }, [])

  const overviewLength = isDesktopDetector ? 45 : 20
  const markdownText = renderHtmlString(
    truncateText(strippedString(text ? text : ""), overviewLength)
  )

  return (
    <article
      className={`tile ${tileClassnames}`}
      onClick={() => handleTileClick(completeSlug)}
    >
      {recommendedMiniTile && image_url && (
        <div className="tile__image-cell">
          <Picture
            cssClassName="tile__image-wrapper"
            image1x={image_url}
            image2x={image_url}
          />
        </div>
      )}

      {featuredOnCapabilityLanding && (
        <>
          <Spacer deviceSize="xxs" desktopSize="s" />
          <div className="tile__info-col tile__info--backslash tile__info--capability">
            <h2 className="typography__caption--02 typography__weight--400 typography__color--green typography__weight--600">
              {parentCapability}
            </h2>
          </div>
          <Spacer desktopOnly desktopSize="xxs" />
        </>
      )}

      <div className="tile__content-cell">
        {!featuredOnCapabilityLanding && <Spacer deviceSize="n" deviceOnly />}
        <h1 className="typography__body--01-700 typography__weight--700 typography__color--navy">
          {truncateText(title, 75)}
        </h1>

        <Spacer desktopOnly={true} desktopSize="xs" deviceLayout="row" />

        {text && (
          <div
            className="tile__overview  tile__overview--mini typography__color--mid-grey"
            dangerouslySetInnerHTML={markdownText}
          />
        )}
      </div>

      <Cta completeSlug={completeSlug} />
    </article>
  )
}

export default MiniTile
