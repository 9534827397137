import React from "react"
import Spacer from "../../layout/spacer"

import PrimaryCta from "../../cta/primary_cta"

export type ContentType = {
  id: number
  header: string
  body: string
}

interface Props {
  id: string
  header: string
  withCta: boolean
  cta?: {
    label: string
    link: string
  }
  content: ContentType[]
}
const TwoColumnTextBlock: React.FC<Props> = ({
  id,
  header,
  withCta,
  content,
  cta,
}) => {
  return (
    <section id={id}>
      <Spacer deviceSize="xs" deviceOnly />
      <div className="two-column-text-block__header">
        <div className="two-column-text-block__header__title">
          <h1 className="typography__display--04">{header}</h1>
        </div>
        {withCta && cta && (
          <div className="two-column-text-block__header__cta">
            <PrimaryCta link={cta.link} label={cta.label} />
          </div>
        )}
      </div>
      <Spacer deviceSize="xs" desktopSize="m" />
      <div className="two-column-text-block careers__benefits-block">
        {content.map(({ id, header, body }) => {
          return (
            <article key={id} className="two-column-text-block__column">
              <Spacer deviceSize="n" />
              <div className="two-column-text-block__title">
                <h2 className="typography__display--06 typography__color--navy">
                  {header}
                </h2>
              </div>
              <Spacer deviceSize="xxs" desktopSize="s" />
              <p className="typography__body--01 typography__color--dark-grey">
                {body}
              </p>
              <Spacer deviceSize="xxs" desktopSize="s" />
            </article>
          )
        })}
      </div>
    </section>
  )
}

export default TwoColumnTextBlock
