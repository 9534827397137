import React from "react"

interface PrimaryNavTypes {
  link: string
  label: string
  modifierClass?: string
  openInNewTab?: boolean
  onMouseEnter?: (e) => void
  onMouseLeave?: (e) => void
}

const PrimaryCtaDownload: React.FC<PrimaryNavTypes> = ({
  link,
  label,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <>
      <a
        download={true}
        className="primary-cta download"
        href={link}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <span className="typography__cta--01 typography__weight--600 typography__uppercase primary-cta__label">
          {label}
        </span>
      </a>
    </>
  )
}

export default PrimaryCtaDownload
