import React from "react"
import FilterTag from "../../elements/tag/filter_tag"

interface FilterTypes {
  id: string
  filter: string
  category: string
  isDisabled?: boolean
  filterModifierClass?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const Filter: React.FC<FilterTypes> = (
  { filter, isDisabled = false, id, category, filterModifierClass, onChange },
  props
) => {
  return (
    <FilterTag
      {...props}
      id={filter}
      filterModifierClass={filterModifierClass}
      name={id}
      category={category}
      onChange={onChange}
      isDisabled={isDisabled}
    />
  )
}
export default Filter
