import React from "react"
import classnames from "classnames"
export type StatusTypes = "mobilising" | "ongoing" | "in_progress " | "complete"

interface StatusPillTypes {
  status: StatusTypes | null
}

const StatusPill: React.FC<StatusPillTypes> = ({ status }) => {
  const formattedStatus = status ? status.toLowerCase() : null

  const statusClasses = classnames({
    mobilising: formattedStatus === "mobilising",
    in_progress: formattedStatus === "in_progress",
    complete: formattedStatus === "complete",
    ongoing: formattedStatus === "ongoing",
  })

  const formatText = (text) => {
    if (text === "in_progress") return "In progress"
    else return text
  }

  return (
    <div className={`status-pill ${statusClasses}`}>
      <span className="typography__caption--01">
        Status:{" "}
        {formattedStatus === "in_progress" ? (
          <span>{formatText(status)}</span>
        ) : (
          <span style={{ textTransform: "capitalize" }}>
            {formatText(status)}
          </span>
        )}
      </span>
    </div>
  )
}

export default StatusPill
