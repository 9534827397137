import React from "react"

import SliderBanner from "../../modules/slider_banner"
import Capabilities from "../../modules/capabilities"
import CtaBanner from "../../modules/cta_banner"

// Types
import { BannerTypes, LoopingVidTypes } from "../../modules/slider_banner/types"
import {
  CapabilityListTypes,
  FeaturedCapabilityTypes,
} from "../../modules/capabilities/types"
import { FilterTypes } from "../../modules/filters/types"

interface Props {
  banners: BannerTypes[]
  loopingVidBanners: LoopingVidTypes
  publishedCapabilities: CapabilityListTypes[]
  services: FilterTypes[]
  industries: FilterTypes[]
  featuredCapabilities: FeaturedCapabilityTypes[]
  ctaBanner: {
    title: string
    description: string
    button_title: string
    button_url: string
    image: string
  }
}
const CapabilitiesPage: React.FC<Props> = ({
  banners,
  loopingVidBanners,
  publishedCapabilities,
  services,
  industries,
  featuredCapabilities,
  ctaBanner,
}) => {
  return (
    <>
      <SliderBanner
        introText=""
        readIn=""
        date=""
        breadcrumb=""
        banners={banners}
        heading="Our Capabilities"
        isGenericHeader={true}
        loopingVidBanners={loopingVidBanners}
      />
      <div className="page-content-wrap">
        <Capabilities
          publishedCapabilities={publishedCapabilities}
          services={services}
          industries={industries}
          featured={featuredCapabilities}
        />
      </div>
      {ctaBanner && (
        <CtaBanner
          isContentBanner={true}
          title={ctaBanner.title}
          description={ctaBanner.description}
          buttonTitle={ctaBanner.button_title}
          ctaLink={ctaBanner.button_url}
          footer={ctaBanner}
        />
      )}
    </>
  )
}

export default CapabilitiesPage
