import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import TextInputSearch from "../../ui/forms/text_input_search"
import { FilterStateTypes } from "./types"

interface KeywordSearchTypes {
  handleSearchSubmit: (e) => void
  setActiveFilters: Dispatch<SetStateAction<FilterStateTypes>>
  activeFilters: FilterStateTypes
}

const KeywordSearch: React.FC<KeywordSearchTypes> = ({
  activeFilters,
  setActiveFilters,
  handleSearchSubmit,
}) => {
  const defaultKeywords = activeFilters["keywords"]

  const [keywords, setKeywords] = useState(defaultKeywords)

  useEffect(() => {
    const filters: FilterStateTypes = { ...activeFilters }
    filters["keywords"] = keywords
    setActiveFilters(filters)
  }, [keywords])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeywords(e.currentTarget.value)
  }

  return (
    <div className="text-input-search--careers">
      <TextInputSearch
        submit={true}
        handleSearchSubmit={handleSearchSubmit}
        handleOnChange={handleChange}
        name="keywords"
        id="search-keywors"
        placeholder="What are you looking for?"
        searchTerm={keywords}
      />
    </div>
  )
}

export default KeywordSearch
