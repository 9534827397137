import React from "react"
import classnames from "classnames"

interface FilterButtonTypes {
  onClick?: () => void
  children?: any
  title: string
  modifierClass?: string
  toggled?: boolean
  checkedFilters: number
}

const FilterButton: React.FC<FilterButtonTypes> = ({
  children,
  modifierClass,
  title,
  checkedFilters,
}) => {
  const buttonClassnames = classnames({
    [`${modifierClass}`]: modifierClass,
  })

  const handleOnClick = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <button
        className={`filter__category-opener ${buttonClassnames}`}
        onClick={handleOnClick}
      >
        <span
          className={`typography__body--01  typography__weight-500  typography__uppercase ${
            checkedFilters > 0 ? "typography__color--green" : ""
          }`}
        >
          {title}
          {checkedFilters > 0 && checkedFilters < 10 && (
            <span className="filter__keyword-counter">0{checkedFilters}</span>
          )}
          {checkedFilters >= 10 && (
            <span className="filter__keyword-counter">{checkedFilters}</span>
          )}
        </span>
        <span className="typography__body--01  typography__weight-500  typography__uppercase"></span>
      </button>
      {children}
    </>
  )
}

export default FilterButton
