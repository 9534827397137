import React from "react"

interface Props {
  webm: string
  mp4: string
}
// export const stripVideoPath = (url: string) => {
//   if (url) {
//     const output = url.replace("/app/public/", "/")
//     return output
//   }
// }

const UploadedVideo: React.FC<Props> = ({ webm, mp4 }) => {
  return (
    <div className="page-banner__slide-background">
      <div
        style={{
          padding: "0 0 0 0",
          position: "relative",
        }}
      >
        <video
          className="homepage-video home-bg__video active"
          width="100%"
          poster=""
          muted
          loop
          autoPlay
        >
          <source id="webmSource" src={webm} type="video/webm" />
          <source id="mp4Source" src={mp4} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}

export default UploadedVideo
