import React from "react"

const IconSearch = ({ color = "#ffffff" }) => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1038.000000, -34.000000)">
        <polygon
          className="search-icon-path-1"
          points="0 0 1200 0 1200 79.999696 0 79.999696"
        />
        <g transform="translate(954.999950, -0.000000)">
          <polygon
            className="search-icon-polygon"
            fill="rgba(0,0,0,0)"
            points="54.00005 0 245.00005 0 245.00005 79.999696 -1.27808888e-12 79.999696"
          />
          <path
            d="M91.2438509,34 C95.7967764,34 99.4876518,37.7909449 99.4876518,42.4673124 C99.4876518,44.519625 98.7767671,46.4013999 97.594115,47.8670369 L102.680166,52.3448524 C103.064259,52.6830012 103.108741,53.2769361 102.779518,53.6714431 C102.450295,54.0659501 101.872038,54.1116374 101.487945,53.7734885 L101.487945,53.7734885 L96.2693995,49.1798578 C94.8782719,50.2802435 93.1354636,50.9346248 91.2438509,50.9346248 C86.6909254,50.9346248 83.00005,47.1436799 83.00005,42.4673124 C83.00005,37.7909449 86.6909254,34 91.2438509,34 Z M91.2438509,35.881625 C87.7026866,35.881625 84.8320058,38.8301377 84.8320058,42.4673124 C84.8320058,46.1044871 87.7026866,49.0529998 91.2438509,49.0529998 C94.7850152,49.0529998 97.6556961,46.1044871 97.6556961,42.4673124 C97.6556961,38.8301377 94.7850152,35.881625 91.2438509,35.881625 Z"
            fill={color}
            className="search-icon-path-2"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default IconSearch
