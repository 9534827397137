import React from "react"

interface ColumnTypes {
  children: any
}

const Column: React.FC<ColumnTypes> = ({ children }) => {
  return <li className="grid-column grid-column__tile">{children}</li>
}

export default Column
