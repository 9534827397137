/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect } from "react"

// Based on the state of 'toggled' the body element style overflow will be updated to restrict body scrolling
// Use case: modal, mobile menu, search dropdown etc...
const bodyElStyle = document.body.style

const useToggleBodyLock = (toggled: boolean) => {
  useEffect(() => {
    if (toggled) {
      bodyElStyle.overflowY = "hidden"
    } else {
      bodyElStyle.overflowY = "auto"
    }
  }, [toggled])
}

export default useToggleBodyLock
