import React from "react"

interface GetInTouchTypes {
  show_enquire_online: boolean
  hide_work_with_us: boolean
  show_number_icon: boolean
  show_title_icon: boolean
  enquiries_path?: string
  work_with_us_path?: string
}
const GetInTouch: React.FC<GetInTouchTypes> = ({
  show_enquire_online,
  hide_work_with_us,
  show_number_icon,
  show_title_icon,
  enquiries_path,
  work_with_us_path,
}) => {
  return (
    <div className="more_info-wrapper">
      <div className="more_info-title">
        Get in touch
        {show_title_icon && (
          <svg
            style={{ marginLeft: "5px" }}
            xmlns="https://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 31.125 32"
          >
            <path
              className="cls-1"
              d="M26.3 20L25 19a3 3 0 0 0-2.4-.5 3.4 3.4 0 0 0-1.4 1.2 3.8 3.8 0 0 1-3.4 1.6 5 5 0 0 1-3-1.6l-2.8-3a5.2 5.2 0 0 1-1.6-3 4 4 0 0 1 1.5-3.4 3.5 3.5 0 0 0 1-1.6 3.4 3.4 0 0 0-.3-2.5l-1-1.2-3-4A2.7 2.7 0 0 0 6 0a5 5 0 0 0-2 1.5C1 4.5-.4 8 0 11.3c.6 3 2.5 6 6.3 10l4 4.2c4 4 7 6 9.8 6.4a8.5 8.5 0 0 0 1.4 0c3 0 5.8-1.4 8.3-4a5.3 5.3 0 0 0 1.3-2.2 3 3 0 0 0-.7-2.8l-4-3zM10.7 9a6 6 0 0 0-2.2 5 7 7 0 0 0 2.2 4l3 3a6.7 6.7 0 0 0 4 2.2 5.7 5.7 0 0 0 4.8-2.2 2.4 2.4 0 0 1 .7-.7 1.2 1.2 0 0 1 1 .3l4.8 3.7a.8.8 0 0 1 .3 1 4 4 0 0 1-1 1.3c-2.7 3-5.2 4-8 3.4-2.3-.4-5.2-2.3-8.6-5.8l-4-4.2c-3.5-3.5-5.4-6.5-5.8-9-.6-2.8.5-5.3 3.3-8.2a3.8 3.8 0 0 1 1.3-1A1 1 0 0 1 7 2a1 1 0 0 1 .5.4l3.6 5a1.4 1.4 0 0 1 .4 1 2.4 2.4 0 0 1-.7.7z"
            />
          </svg>
        )}
      </div>
      <div className="phone-container">
        <a className="call_ventia" href="tel:1300836842">
          <span>AU: 1300 VENTIA (836 842)</span>
          {show_number_icon && (
            <svg
              style={{ marginLeft: "5px" }}
              xmlns="https://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 31.125 32"
            >
              <path
                className="cls-1"
                d="M26.3 20L25 19a3 3 0 0 0-2.4-.5 3.4 3.4 0 0 0-1.4 1.2 3.8 3.8 0 0 1-3.4 1.6 5 5 0 0 1-3-1.6l-2.8-3a5.2 5.2 0 0 1-1.6-3 4 4 0 0 1 1.5-3.4 3.5 3.5 0 0 0 1-1.6 3.4 3.4 0 0 0-.3-2.5l-1-1.2-3-4A2.7 2.7 0 0 0 6 0a5 5 0 0 0-2 1.5C1 4.5-.4 8 0 11.3c.6 3 2.5 6 6.3 10l4 4.2c4 4 7 6 9.8 6.4a8.5 8.5 0 0 0 1.4 0c3 0 5.8-1.4 8.3-4a5.3 5.3 0 0 0 1.3-2.2 3 3 0 0 0-.7-2.8l-4-3zM10.7 9a6 6 0 0 0-2.2 5 7 7 0 0 0 2.2 4l3 3a6.7 6.7 0 0 0 4 2.2 5.7 5.7 0 0 0 4.8-2.2 2.4 2.4 0 0 1 .7-.7 1.2 1.2 0 0 1 1 .3l4.8 3.7a.8.8 0 0 1 .3 1 4 4 0 0 1-1 1.3c-2.7 3-5.2 4-8 3.4-2.3-.4-5.2-2.3-8.6-5.8l-4-4.2c-3.5-3.5-5.4-6.5-5.8-9-.6-2.8.5-5.3 3.3-8.2a3.8 3.8 0 0 1 1.3-1A1 1 0 0 1 7 2a1 1 0 0 1 .5.4l3.6 5a1.4 1.4 0 0 1 .4 1 2.4 2.4 0 0 1-.7.7z"
              />
            </svg>
          )}
        </a>
      </div>
      {show_enquire_online && <a href={enquiries_path}>Enquire online</a>}

      {!hide_work_with_us && <a href={work_with_us_path}>Browse careers</a>}
    </div>
  )
}

export default GetInTouch
