import React from "react"
// import Row from "../grid/row"
// import Column from "../grid/column"
// import Tile from "../tile"
// import Spacer from "../../layout/spacer"
// import { configurationNames } from "./configuration_names"

// todo: types

const ModularRow = () => {
  return <h1>Commented out</h1>
}
// export const renderSingleColumn = (content, rowType, currentPath) => {
//   return content.map(
//     ({
//       id,
//       client_name,
//       title,
//       slug,
//       date,
//       read_in,
//       image,
//       format,
//       overview,
//       news_overview,
//     }) => {
//       return (
//         <Column key={`modular-row-column-${id}`}>
//           <Tile
//             caseStudyClient={client_name}
//             image={`${image}`}
//             title={title}
//             text={rowType === "news_article" ? news_overview : overview}
//             slug={slug}
//             date={date}
//             readIn={read_in}
//             format={format.toLowerCase()}
//             tileType={rowType}
//             currentPath={currentPath}
//           />

//           <Spacer deviceSize="xxs" deviceLayout="row" deviceOnly={true} />
//         </Column>
//       )
//     }
//   )
// }
// // todo: types

// export const renderGroupColumn = (content, rowType, currentPath) => {
//   return (
//     <Column>
//       {content.map(
//         ({
//           id,
//           client_name,
//           title,
//           slug,
//           date,
//           read_in,
//           image,
//           format,
//           overview,
//           news_overview,
//         }) => {
//           return (
//             <Tile
//               key={`modular-row-tile-${id}`}
//               caseStudyClient={client_name}
//               image={`${image}`}
//               title={title}
//               text={rowType === "news_article" ? news_overview : overview}
//               slug={slug}
//               date={date}
//               readIn={read_in}
//               format={format.toLowerCase()}
//               tileType={rowType}
//               currentPath={currentPath}
//             />
//           )
//         }
//       )}
//     </Column>
//   )
// }
// // todo: types
// const ModularRow = ({
//   rowId,
//   content,
//   rowType,
//   rowName,
//   rowCount,
//   currentPath,
// }) => {
//   if (rowName === configurationNames.SSS) {
//     return (
//       <>
//         <Row id={rowId} columnCount={3}>
//           {renderSingleColumn(content, rowType, currentPath)}
//         </Row>

//         {rowCount > 1 && <Spacer deviceSize="xxs" desktopSize="s" />}
//       </>
//     )
//   }

//   if (rowName === configurationNames.SSG) {
//     const singleColumns = content.slice(0, 2)
//     const groupColumn = content.slice(2)
//     return (
//       <>
//         <Row id={rowId} columnCount={3}>
//           {renderSingleColumn(singleColumns, rowType, currentPath)}
//           {renderGroupColumn(groupColumn, rowType, currentPath)}
//         </Row>

//         {rowCount > 1 && <Spacer deviceSize="xxs" desktopSize="s" />}
//       </>
//     )
//   }

//   if (rowName === configurationNames.FR) {
//     //  1 article
//     return (
//       <>
//         <Row id={rowId} columnCount={1}>
//           <Column>
//             <Spacer deviceSize="xxs" deviceOnly={true} />
//             <Tile
//               caseStudyClient={content[0].client_name}
//               image={`${content[0].image}`}
//               title={content[0].title}
//               text={content[0].overview}
//               slug={content[0].slug}
//               date={content[0].publish_date}
//               readIn={content[0].read_in}
//               format="featured"
//               tileType={rowType}
//               currentPath={currentPath}
//             />
//             <Spacer deviceSize="xxs" deviceLayout="row" deviceOnly={true} />
//           </Column>
//         </Row>

//         {rowCount > 1 && <Spacer deviceSize="xxs" desktopSize="s" />}
//       </>
//     )
//   }

//   if (rowName === configurationNames.SGS) {
//     // 5 articles
//     const singleColumn1 = content[0]
//     const groupColumn = content.slice(1, 4)
//     const singleColumn3 = content[4]

//     return (
//       <>
//         <Row id={rowId} columnCount={3}>
//           <Column>
//             <Tile
//               caseStudyClient={singleColumn1.client_name}
//               image={`${singleColumn1.image}`}
//               title={singleColumn1.title}
//               text={
//                 rowType === "news_article"
//                   ? singleColumn1.news_overview
//                   : singleColumn1.overview
//               }
//               slug={singleColumn1.slug}
//               date={singleColumn1.publish_date}
//               readIn={singleColumn1.read_in}
//               format={"single"}
//               tileType={rowType}
//               currentPath={currentPath}
//             />
//             <Spacer deviceSize="xxs" deviceLayout="row" deviceOnly={true} />
//           </Column>

//           {renderGroupColumn(groupColumn, rowType, currentPath)}

//           <Column>
//             <Tile
//               caseStudyClient={singleColumn3.client_name}
//               image={`${singleColumn3.image}`}
//               title={singleColumn3.title}
//               text={
//                 rowType === "news_article"
//                   ? singleColumn3.news_overview
//                   : singleColumn3.overview
//               }
//               slug={singleColumn3.slug}
//               date={singleColumn3.publish_date}
//               readIn={singleColumn3.read_in}
//               format={"single"}
//               tileType={rowType}
//               currentPath={currentPath}
//             />
//             <Spacer deviceSize="xxs" deviceLayout="row" deviceOnly={true} />
//           </Column>
//         </Row>

//         {rowCount > 1 && (
//           <Spacer
//             deviceSize="xxs"
//             desktopSize="s"
//             deviceLayout="row"
//             desktopOnly={true}
//           />
//         )}
//       </>
//     )
//   }

//   if (rowName === configurationNames.GGG) {
//     // 5 articles
//     const groupColumn1 = content.slice(0, 3)
//     const groupColumn2 = content.slice(3, 6)
//     const groupColumn3 = content.slice(6)
//     return (
//       <>
//         <Row id={rowId} columnCount={3}>
//           {renderGroupColumn(groupColumn1, rowType, currentPath)}
//           {renderGroupColumn(groupColumn2, rowType, currentPath)}
//           {renderGroupColumn(groupColumn3, rowType, currentPath)}
//         </Row>

//         {rowCount > 1 && (
//           <Spacer
//             deviceSize="xxs"
//             desktopSize="s"
//             deviceLayout="row"
//             desktopOnly={true}
//           />
//         )}
//       </>
//     )
//   }

//   if (rowName === configurationNames.GSG) {
//     // 5 articles
//     const groupColumn1 = content.slice(0, 3)
//     const singleColumn = content.slice(3, 4)
//     const groupColumn2 = content.slice(4)
//     return (
//       <>
//         <Row id={rowId} columnCount={3}>
//           {renderGroupColumn(groupColumn1, rowType, currentPath)}
//           {renderSingleColumn(singleColumn, rowType, currentPath)}
//           {renderGroupColumn(groupColumn2, rowType, currentPath)}
//         </Row>

//         {rowCount > 1 && <Spacer deviceSize="xxs" desktopSize="s" />}
//       </>
//     )
//   }

//   if (rowName === configurationNames.FL) {
//     //  1 article
//     return (
//       <>
//         <Row id={rowId} columnCount={1}>
//           <Column>
//             <Spacer deviceSize="xxs" deviceLayout="row" deviceOnly={true} />
//             <Tile
//               caseStudyClient={content[0].client_name}
//               image={`${content[0].image}`}
//               title={content[0].title}
//               text={
//                 rowType === "news_article"
//                   ? content[0].news_overview
//                   : content[0].overview
//               }
//               slug={content[0].slug}
//               date={content[0].publish_date}
//               readIn={content[0].read_in}
//               format="featured"
//               tileType={rowType}
//               leftAlignedFeature={true}
//               currentPath={currentPath}
//             />
//             <Spacer deviceSize="xxs" deviceLayout="row" deviceOnly={true} />
//           </Column>
//         </Row>

//         {rowCount > 1 && (
//           <Spacer
//             deviceSize="xxs"
//             desktopSize="s"
//             deviceLayout="row"
//             desktopOnly={true}
//           />
//         )}
//       </>
//     )
//   }

//   if (rowName === configurationNames.SGG) {
//     // 5 articles
//     const singleColumn = content.slice(0, 1)
//     const groupColumn1 = content.slice(1, 4)
//     const groupColumn2 = content.slice(4)
//     return (
//       <>
//         <Row id={rowId} columnCount={3}>
//           {renderSingleColumn(singleColumn, rowType, currentPath)}
//           {renderGroupColumn(groupColumn1, rowType, currentPath)}
//           {renderGroupColumn(groupColumn2, rowType, currentPath)}
//         </Row>

//         {rowCount > 1 && (
//           <Spacer
//             deviceSize="xxs"
//             desktopSize="s"
//             deviceLayout="row"
//             desktopOnly={true}
//           />
//         )}
//       </>
//     )
//   }

//   if (rowName === configurationNames.GSS) {
//     // 5 articles
//     const groupColumn = content.slice(0, 3)
//     const singleColumn1 = content.slice(3, 4)
//     const singleColumn2 = content.slice(4, 5)
//     return (
//       <>
//         <Row id={rowId} columnCount={3}>
//           {renderGroupColumn(groupColumn, rowType, currentPath)}
//           {renderSingleColumn(singleColumn1, rowType, currentPath)}
//           {renderSingleColumn(singleColumn2, rowType, currentPath)}
//         </Row>

//         {rowCount > 1 && (
//           <Spacer
//             deviceSize="xxs"
//             desktopSize="s"
//             deviceLayout="row"
//             desktopOnly={true}
//           />
//         )}
//       </>
//     )
//   } else return null
// }

export default ModularRow
