import React from "react"
import Spacer from "../../layout/spacer"

interface TabRowTypes {
  children: any
  followerWidth: number
  followerXpos: number
}

const TabRow: React.FC<TabRowTypes> = ({
  children,
  followerWidth,
  followerXpos,
}) => {
  return (
    <ul role="tablist" className="tab-row">
      {children}
      <Spacer deviceSize="s" desktopSize="l" />
      <span
        className="tab-follower"
        style={{
          width: `${followerWidth}px`,
          transform: `translateX(${followerXpos}px)`,
        }}
      />
    </ul>
  )
}

export default TabRow
