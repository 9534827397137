import React from "react"

interface Props {
  id?: string
  modifierClass?: string
}

const GenericContentWrapper: React.FC<Props> = ({
  children,
  id,
  modifierClass,
}) => {
  return (
    <section
      id={id ? id : ""}
      className={`generic-content-wrapper module ${modifierClass}`}
    >
      {children}
    </section>
  )
}

export default GenericContentWrapper
