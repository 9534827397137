import React from "react"
import classnames from "classnames"

interface PrimaryNavTypes {
  id: string
  label: string
  modifierClass?: string
}

const PrimaryCtaSubmit: React.FC<PrimaryNavTypes> = ({
  id = "",
  label,
  modifierClass,
}) => {
  const modifierClassnames = classnames({
    [`${modifierClass}`]: modifierClass,
  })
  return (
    <button
      id={`${id !== null ? id : ""}`}
      type="submit"
      className={`button primary-cta ${modifierClassnames}`}
    >
      <span className="typography__cta--01 typography__weight--600 typography__uppercase primary-cta__label">
        {label}
      </span>
    </button>
  )
}

export default PrimaryCtaSubmit
