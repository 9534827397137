import React from "react"
// import Spacer from "../../layout/spacer"
// import { LinkTypes } from "../../../types"

export interface ExpanderTypes {
  onClick: () => void
  name: string
  toggled: boolean
}

const Expander: React.FC<ExpanderTypes> = ({ onClick, toggled, name }) => {
  return (
    <>
      <button
        onClick={onClick}
        className="list-module__expander-button typography__cta--01 typography__uppercase typography__color--green typography__weight--600"
      >
        {!toggled ? name : "close"}

        <span
          className={`list-module__expander-icon ${
            toggled ? "toggled" : "default"
          }`}
        />
      </button>
    </>
  )
}

export default Expander
