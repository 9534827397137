import React, { useState, useEffect } from "react"
import useDesktopDetector from "../../../hooks/useDesktopDetector"
import { upperCaseFirstCharacter } from "../../../helpers"
import Spacer from "../../layout/spacer"
import Tag from "../../elements/tag"
import { FilterTypes } from "../../modules/filters/types"

interface Props {
  tags: FilterTypes[]
  tagType: "Industries" | "Services" | "Capabilities" | "Locations"
}

const TagGroup: React.FC<Props> = ({ tags, tagType }) => {
  const isDesktop = useDesktopDetector()
  const scrollerRef = React.createRef<HTMLDivElement>()
  const [scrollerWidth, setScrollerWidth] = useState(0)

  useEffect(() => {
    if (!isDesktop) {
      if (scrollerRef.current !== null) {
        const items = scrollerRef.current.children
        let width = items.length * 10

        for (let i = 0; i < items.length; i++) {
          width += items[i].clientWidth
          if (i === items.length - 1) {
            setScrollerWidth(width)
          }
        }
      }
    }
  }, [isDesktop, scrollerRef])

  const formattedTagType = tagType.toLowerCase()

  function locationTags() {
    return tags.map((tag) => {
      const { id, name } = tag
      if (name === "Victoria" || name === "Tasmania") {
        return (
          <Tag
            isTileTag={false}
            key={id}
            name={upperCaseFirstCharacter(name)}
            id={name}
            link="/page/victoria-tasmania"
          />
        )
      } else if (name === "Australia") {
        return (
          <Tag
            isTileTag={false}
            key={id}
            name={upperCaseFirstCharacter(name)}
            id={name}
            link="/where-we-work"
          />
        )
      } else {
        return (
          <Tag
            isTileTag={false}
            key={id}
            name={upperCaseFirstCharacter(name)}
            id={name}
            link={`/page/${name.toLocaleLowerCase().replace(" ", "-")}`}
          />
        )
      }
    })
  }
  return (
    <section className="anchored-tags anchored-tags__campaign">
      <div className="anchored-tags__content">
        <>
          <h1 className="typography__caption--01 typography__weight--600  anchored-tags__content-title">
            Related {tagType}
          </h1>
          <Spacer desktopSize="xs" desktopOnly />
          <div className="anchored-tags__content-tags">
            <div
              className="scroller"
              style={{
                width: `${!isDesktop ? scrollerWidth + "px" : "auto"}`,
              }}
              ref={scrollerRef}
            >
              {tagType === "Locations"
                ? locationTags()
                : tags.map((tag) => {
                    const { id, name, slug } = tag
                    return (
                      <Tag
                        isTileTag={false}
                        key={id}
                        name={upperCaseFirstCharacter(name)}
                        id={name}
                        link={`/${formattedTagType}/${slug}`}
                      />
                    )
                  })}
            </div>
          </div>
          <Spacer desktopSize="xs" desktopOnly />
        </>
      </div>
    </section>
  )
}

export default TagGroup
