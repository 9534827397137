export const removeLocations = [
  "New South Wales",
  "Queensland",
  "South Australia",
  "Tasmania",
  "Victoria",
  "Western Australia",
  "Australian Capital Territory",
  "Northern Territory",
  "New Zealand",
  "Auckland",
  "Wellington",
  "Hamilton",
  "Christchurch",
  "Dunedin",
  "Australia",
  "New South Wales ",
  "Queensland ",
  "South Australia ",
  "Tasmania ",
  "Victoria ",
  "Western Australia ",
  "Australian Capital Territory ",
  "Northern Territory ",
  "New Zealand ",
  "Auckland ",
  "Wellington ",
  "Hamilton ",
  "Christchurch ",
  "Dunedin ",
  "Australia ",
]
