import React from "react"
import { colors } from "../../variables"

const IconClose = ({ color = colors.green }) => (
  <svg width="24px" height="24px" viewBox="0 0 18 18" version="1.1">
    <g istroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1139.000000, -200.000000)" fill={color}>
        <g transform="translate(1148.000000, 209.000000) rotate(45.000000) translate(-1148.000000, -209.000000) translate(1136.000000, 197.000000)">
          <polygon points="12.7058824 -2.27373675e-12 12.705 11.294 24 11.2941176 24 12.7058824 12.705 12.705 12.7058824 24 11.2941176 24 11.294 12.705 0 12.7058824 0 11.2941176 11.294 11.294 11.2941176 -2.27373675e-12" />
        </g>
      </g>
    </g>
  </svg>
)

export default IconClose
