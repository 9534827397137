import React from "react"

import FeaturedImageText from "."
import { OptionsTypes, FeaturedImageTextContentTypes } from "./types"
import classnames from "classnames"
import { ParallaxProvider } from "react-scroll-parallax"

interface SingleFeaturedImageTextTypes extends OptionsTypes {
  content: FeaturedImageTextContentTypes
  isHomepage?: boolean
}

const SingleFeaturedImageText: React.FC<SingleFeaturedImageTextTypes> = ({
  content,
  blockColourOverride,
  aligned,
  isHomepage,
}) => {
  const wrapperClassnames = classnames({
    "content-page__wrapper--home": isHomepage,
  })

  return (
    <div className={`content-page__wrapper ${wrapperClassnames}`}>
      <ParallaxProvider>
        <FeaturedImageText
          title={content.title}
          subtitle={content.subtitle}
          cta_path={content.cta_path}
          cta_text={content.cta_text}
          body_text={content.body_text}
          images={content.images}
          blockColourOverride={blockColourOverride}
          aligned={aligned}
        />
      </ParallaxProvider>
    </div>
  )
}

export default SingleFeaturedImageText
