import React, { useState } from "react"
import { Waypoint } from "react-waypoint"
import AnchoredSubNav from "../../modules/anchored_sub_nav"
import ContentPage from "../../layout/content_page"
import Wysiwyg from "../../ui/wysiwyg"

// Content Modules
import DataBlock from "../../modules/data_block"
import Quote from "../../modules/quote"
import Multimedia from "../../modules/multimedia"
import OurPeople from "../../modules/our_people"
import RelatedNews from "../../modules/related_news"
import RelatedProjects from "../../modules/related_projects"
import LinkedInFeed from "../../modules/linkedin_feed"
import VideoImage from "../../modules/video_image"


// UI Modules
import Spacer from "../../layout/spacer"
import SideNav from "../../modules/side_nav"
import ContentWithStickyAside from "../../modules/content_with_sticky_aside"
import Grid from "../../modules/grid"
import Row from "../../modules/grid/row"
import Column from "../../modules/grid/column"
import FeaturedNewsTile from "../../modules/tile/featured/news"
import Breadcrumbs from "../../ui/breadcrumbs"

// Helpers
import useDesktopDetector from "../../../hooks/useDesktopDetector"
import { renderHtmlString } from "../../../helpers"

// Types
import { PageModuleTypes, WysiwygType } from "./types"

function wysiwygContent(
  isDesktop: boolean,
  sideNav: () => void,
  wysiwyg: WysiwygType[],
  handleWaypoint: (string) => void
): React.ReactElement {
  return (
    <ContentPage modifierClass="article">
      {!isDesktop && sideNav()}
      <section className="content-page__inner content-page__inner--lift-up">
        <section className="content-page__with-sidebar" id="overview">
          <Waypoint
            onEnter={() => handleWaypoint("overview")}
            bottomOffset={600}
          />
          {wysiwyg && <Wysiwyg content={`${wysiwyg}`} />}
        </section>
      </section>
    </ContentPage>
  )
}

const CampaignPage: React.FC<PageModuleTypes> = ({
  parentPage,
  pageName,
  showSideNavCta,
  sideNavCtaText,
  sideNavCtaPath,
  wysiwyg,
  videos,
  downloads,
  dataBlockStats,
  featuredNews,
  ourPeople,
  featuredProjects,
  industries,
  services,
  leadGen,
  campaign,
  hasLeadGenForm,
  leadGenFormUrl,
  showStickyNav,
  headingForVideos,
  quoteContent,
  featureArticle,
  featuredArticleHeading,
  articlesHeading,
  ourPeopleHeading,
  linkedInFeed,
  videoImage
}) => {
  const isDesktop = useDesktopDetector()

  const anchorItems = [
    {
      id: 0,
      name: "Overview",
      anchor: "overview",
      show: !!wysiwyg,
    },
    {
      id: 1,
      name: "Resources",
      anchor: "multimedia",
      show: !!videos.length || !!downloads.length,
    },
    {
      id: 2,
      name: "Statistics",
      anchor: "statistics",
      show: !!dataBlockStats.length,
    },
    {
      id: 3,
      name: "News",
      anchor: "related-news",
      show: !!featuredNews.length,
    },
    {
      id: 5,
      name: "Our People",
      anchor: "our-people",
      show: !!ourPeople.length,
    },
    {
      id: 6,
      name: "Projects",
      anchor: "projects",
      show: !!featuredProjects.length,
    },
  ]

  const [activeId, setActiveId] = useState("overview")
  const handleWaypointEnter = (id: string) => {
    setActiveId(id)
  }

  const filteredItems = anchorItems.filter((i) => i.show)

  const sideNav = () => {
    return (
      <SideNav
        cta={{
          isVisible: showSideNavCta,
          label: sideNavCtaText,
          link: sideNavCtaPath,
        }}
        isArticle={false}
        isCampaign={false}
        name={leadGen.name}
        linkedin={leadGen.linkedin}
        title={leadGen.title}
        email={leadGen.email}
        image1x={leadGen.image1x}
        image2x={leadGen.image2x}
        phone={leadGen.phone}
        contactLabel={leadGen.contactLabel}
        hidePhone={leadGen.hidePhone}
        hideEmail={leadGen.hideEmail}
        team={campaign}
        hasLeadGenForm={hasLeadGenForm}
        formHtml={leadGenFormUrl}
        relatedCapabilities={[]}
        relatedIndustries={industries}
        relatedServices={services}
        relatedLocations={[]}
      />
    )
  }

  return (
    <>
      {showStickyNav && (
        <AnchoredSubNav
          anchorItems={filteredItems}
          showFollower={true}
          activeId={activeId}
        />
      )}
      {parentPage && parentPage.name.toLowerCase() === "careers" && (
        <Breadcrumbs
          isParentPage={false}
          parentPage={{ slug: "/careers", name: "Careers" }}
          currentPage={pageName}
        />
      )}
      <ContentWithStickyAside
        hasStickySubNav={showStickyNav}
        content={wysiwygContent(
          isDesktop,
          sideNav,
          wysiwyg,
          () => handleWaypointEnter
        )}
        aside={sideNav()}
      />

      {(videos.length > 0 || downloads.length > 0) && (
        <>
          <Waypoint
            onEnter={() => handleWaypointEnter("multimedia")}
            bottomOffset={600}
          />
          <Multimedia
            downloads={downloads}
            videos={videos}
            heading={headingForVideos}
          />
        </>
      )}

      {quoteContent.length >= 1 && (
        <>
          <Spacer desktopSize="l" />
          <div style={{ position: "relative", overflowX: "hidden" }}>
            <Quote quotes={quoteContent} />
          </div>
        </>
      )}
      {dataBlockStats.length >= 1 && (
        <>
          <Waypoint
            onEnter={() => handleWaypointEnter("statistics")}
            bottomOffset={600}
          />
          <Spacer deviceSize="m" />
          <DataBlock stats={dataBlockStats} />
        </>
      )}
       {videoImage && (
        <VideoImage
          image_content_type={videoImage.image_content_type}
          video_id={videoImage.video_id}
          images={videoImage.images}
          image_alt_text={videoImage.image_alt_text}
        />
      )}

      {featureArticle && (
        <ContentPage>
          <>
            <div className="device-content-padding" id="highlight">
              <Grid>
                <Row columnCount={1}>
                  <Column>
                    {featuredArticleHeading ? (
                      <>
                        <h1
                          className="typography__weight--700 related-news__article-heading typography__display--05"
                          dangerouslySetInnerHTML={renderHtmlString(
                            featuredArticleHeading
                          )}
                        />
                        <Spacer deviceSize="s" />
                        <div className="horizontal-divider" />
                        <Spacer deviceSize="s" />
                      </>
                    ) : (
                      <Spacer deviceSize="xxs" deviceOnly={true} />
                    )}
                    <FeaturedNewsTile
                      id={featureArticle.id}
                      read_in={featureArticle.read_in}
                      publish_date={featureArticle.publish_date}
                      title={featureArticle.title}
                      content={featureArticle.content}
                      listing_image={featureArticle.listing_images.large}
                      slug={featureArticle.slug}
                      leftAlignedFeature={true}
                    />
                  </Column>
                </Row>
              </Grid>
            </div>
          </>
        </ContentPage>
      )}
      {featuredNews.length >= 1 && (
        <>
          <Waypoint
            onEnter={() => handleWaypointEnter("related-news")}
            bottomOffset={600}
          />
          <ContentPage>
            <div className="device-content-padding">
              <RelatedNews
                inCampaign={true}
                articlesHeading={articlesHeading ? articlesHeading : ""}
                articles={featuredNews}
              />
            </div>
          </ContentPage>
        </>
      )}
      {ourPeople.length >= 1 && (
        <>
          <Waypoint
            onEnter={() => handleWaypointEnter("our-people")}
            bottomOffset={600}
          />
          <ContentPage modifierClass="device-content-padding content-page__our-people">
            <OurPeople
              name={campaign}
              people={ourPeople}
              blockHeading={ourPeopleHeading}
            />
          </ContentPage>
        </>
      )}
      {featuredProjects.length >= 1 && (
        <>
          <Waypoint
            onEnter={() => handleWaypointEnter("projects")}
            bottomOffset={600}
          />
          <ContentPage modifierClass="device-content-padding">
            <RelatedProjects name="Related" projects={featuredProjects} />
          </ContentPage>
        </>
      )}

      {linkedInFeed && linkedInFeed.length >= 1 && (
        <LinkedInFeed feed={linkedInFeed} />
      )}
    </>
  )
}

export default CampaignPage
