import React, { useEffect } from "react"
import { Waypoint } from "react-waypoint"
import Wysiwyg from "../../ui/wysiwyg"

// Content Modules
import LinkModule from "../../modules/link_module"
import FeaturedTileSlider from "../../modules/featured_tile_slider"
import CtaBanner from "../../modules/cta_banner"

// UI Modules
import Spacer from "../../layout/spacer"
import SideNav from "../../modules/side_nav"
import ContentWithStickyAside from "../../modules/content_with_sticky_aside"
import Breadcrumbs from "../../ui/breadcrumbs"
import GenericContentWrapper from "../../layout/generic_content_wrapper"
import PrimaryCtaDownload from "../../cta/primary_cta_download"
import PrimaryCta from "../../cta/primary_cta"

// Helpers
import useDesktopDetector from "../../../hooks/useDesktopDetector"

// Types
import { PageModuleTypes } from "./types"
import { FeatureCta } from "./types"

// Data
import linkData from "./data.json"

const iframeUrl_1 =
  "https://wcsecure.weblink.com.au/Clients/Ventia/priceframe.aspx"
const iframeUrl_2 =
  "https://wcsecure.weblink.com.au/Clients/Ventia/mainchart.html"

function wysiwygContent(
  isDesktop: boolean,
  sideNav: () => void,
  wysiwyg: string,
  handleWaypoint: (string) => void,
  showFeaturedLinkCta: boolean,
  showFeaturedDownloadCta: boolean,
  featuredDownloadCta: FeatureCta,
  featuredLinkCta: FeatureCta
): React.ReactElement {
  return (
    <>
      <section className="content-page__inner content-page__inner--lift-up">
        <section className="content-page__with-sidebar" id="overview">
          <Waypoint
            onEnter={() => handleWaypoint("overview")}
            bottomOffset={600}
          />
          {wysiwyg && (
            <>
              <Wysiwyg content={`${wysiwyg}`} />
              <Spacer deviceSize="xs" desktopSize="s" />
            </>
          )}
          {(showFeaturedLinkCta || showFeaturedDownloadCta) && (
            <>
              <div className="content-page__cta-group">
                {featuredDownloadCta.name && showFeaturedDownloadCta && (
                  <PrimaryCtaDownload
                    link={featuredDownloadCta.path}
                    label={featuredDownloadCta.name}
                  />
                )}
                {featuredLinkCta.name && showFeaturedLinkCta && (
                  <PrimaryCta
                    modifierClass="small navy"
                    link={featuredLinkCta.path}
                    label={featuredLinkCta.name}
                  />
                )}
              </div>
              <Spacer deviceSize="xxs" desktopSize="s" />
            </>
          )}
        </section>
      </section>
      {!isDesktop && sideNav()}
    </>
  )
}

const InvestorCentreLanding: React.FC<PageModuleTypes> = ({
  pageName,
  subNavItems,
  showSideNavCta,
  sideNavCtaText,
  wysiwyg,
  ctaBanner,
  sideNavCtaPath,
  featuredDownloadCta,
  featuredLinkCta,
  showFeaturedLinkCta,
  showFeaturedDownloadCta,
  featuredTileSliderHeading,
  featuredTileSliderContent,
  showFeaturedTiles,
  leadGen,
  hasLeadGenForm,
  leadGenFormUrl,
}) => {
  const isDesktop = useDesktopDetector()
  const sideNav = () => {
    return (
      <SideNav
        cta={{
          isVisible: showSideNavCta,
          label: sideNavCtaText,
          link: sideNavCtaPath,
        }}
        isArticle={false}
        isCampaign={false}
        name={leadGen.name}
        linkedin={leadGen.linkedin}
        title={leadGen.title}
        email={leadGen.email}
        image1x={leadGen.image1x}
        image2x={leadGen.image2x}
        phone={leadGen.phone}
        contactLabel={leadGen.contactLabel}
        hidePhone={leadGen.hidePhone}
        hideEmail={leadGen.hideEmail}
        team="Investors Centre"
        hasLeadGenForm={hasLeadGenForm}
        formHtml={leadGenFormUrl}
        relatedCapabilities={[]}
        relatedIndustries={[]}
        relatedServices={[]}
        relatedLocations={[]}
      />
    )
  }

  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://wcsecure.weblink.com.au/styles/chartstyles/responsive/WL_TabFrameFunctions.js"
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <Breadcrumbs
        isParentPage={true}
        parentPage={{ name: "Investor Centre", slug: "/investor-centre" }}
        currentPage={pageName}
      />

      <GenericContentWrapper modifierClass="article investors-centre investors-centre-wysiwyg">
        <ContentWithStickyAside
          hasStickySubNav={false}
          content={wysiwygContent(
            isDesktop,
            sideNav,
            wysiwyg,
            () => null,
            showFeaturedLinkCta,
            showFeaturedDownloadCta,
            featuredDownloadCta,
            featuredLinkCta
          )}
          aside={sideNav()}
        />
      </GenericContentWrapper>

      {/* Iframe */}
      <GenericContentWrapper modifierClass="investors-iframes">
        <div className="investor-center__iframe-wrapper">
          <h2 className="typography__display--04">Last Price</h2>
          <Spacer deviceSize="xxs" desktopSize="xs" />
          <iframe
            style={{
              boxSizing: "border-box",
            }}
            src={iframeUrl_1}
            width="100%"
            height="130px"
            scrolling="no"
            frameBorder="0"
          />

          <Spacer deviceSize="s" desktopSize="l" />
          <h2 className="typography__display--04">Stock Graph</h2>
          <Spacer deviceSize="xxs" desktopSize="xs" />
          <iframe
            style={{ boxSizing: "border-box" }}
            id="wl_chartTab"
            src={iframeUrl_2}
            width="100%"
            height="500px"
            scrolling="no"
            frameBorder="0"
          />
        </div>
      </GenericContentWrapper>
      <LinkModule
        title="Quick links"
        links={subNavItems}
        image={linkData[0].hover_image_url}
        slim={true}
        modifierClass="investors-centre module"
      />

      {showFeaturedTiles && (
        <GenericContentWrapper>
          <div className="device-content-padding">
            <FeaturedTileSlider
              heading={`${featuredTileSliderHeading}`}
              content={featuredTileSliderContent}
            />
          </div>
        </GenericContentWrapper>
      )}
      {ctaBanner && (
        <CtaBanner
          isContentBanner={true}
          title={ctaBanner.title}
          description={ctaBanner.description}
          buttonTitle={ctaBanner.button_title}
          ctaLink={ctaBanner.button_url}
          footer={ctaBanner}
        />
      )}
    </>
  )
}

export default InvestorCentreLanding
