// * Create the array of kewords from news and insight articles
export const pushKeywords = (
  newsArticles,
  industries,
  services,
  locations,
  categories
) => {
  newsArticles.forEach((article) => {
    industries.push(article.industries)
    services.push(article.services)
    locations.push(article.locations)
    categories.push(article.filter_categories)
  })
}
