import React, { useEffect, useState } from "react"
import IconButton from "../../elements/icon_button"
import IconSearch from "../../../images/svgs/icon_search"
import IconMail from "../../../images/svgs/icon_mail"
import IconPhone from "../../../images/svgs/icon_phone"
import SearchDropdown from "../search_dropdown"
import useToggleBodyLock from "../../../hooks/useBodyLock"
import { HeaderActionTypes } from "../../../types"

const Actions: React.FC<HeaderActionTypes> = ({
  setIsSearchDropdownVisible,
  isSearchDropdownVisible,
}) => {
  const [isSearchActive, setIsSearchActive] = useState(false)

  const toggleSearch = () => {
    setIsSearchDropdownVisible(!isSearchDropdownVisible)
    setIsSearchActive(!isSearchActive)
  }

  useToggleBodyLock(isSearchDropdownVisible)

  const handleCloseSearchClick = () => {
    setIsSearchDropdownVisible(false)
    setIsSearchActive(false)
  }

  return (
    <React.Fragment>
      <div className="actions">
        <ul className="actions__content">
          <li
            className={`action ${
              isSearchActive ? "search-active" : "search-inactive"
            }`}
          >
            <IconButton onClick={toggleSearch} modifierClass="search">
              <IconSearch />
            </IconButton>
          </li>
          <li className="action action__mail">
            <a href="/contact">
              <IconMail />
            </a>
          </li>
          <li className="action action__phone">
            <a href="tel:1300836842">
              <IconPhone />
            </a>
          </li>
        </ul>
      </div>
      <SearchDropdown
        isSearchVisible={isSearchDropdownVisible}
        modifierClass={`${isSearchDropdownVisible ? "open" : "close"}`}
        handleCloseSearchClick={handleCloseSearchClick}
      />
    </React.Fragment>
  )
}

export default Actions
