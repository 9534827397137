import React from "react"
import classnames from "classnames"

interface BannerTypes {
  bannerName: string
  heading: string
}
const SliderBanner: React.FC<BannerTypes> = ({ heading, bannerName }) => {
  const bgImage = classnames({
    "static-banner__talent-agreement": bannerName === "talent-agreement",
  })

  return (
    <>
      <div id="print-only-logo" />
      <div id="print-only-banner" className="print-only-banner">
        <picture className="print-only-banner__picture">
          <img src={bgImage} />
        </picture>
      </div>
      <div role="banner" className="slider-banner slider-banner__generic-page">
        <div className="slider-banner__words-wrapper">
          <div className="slider-banner__heading">
            <h1 className="typography__display--02 typography__uppercase typography__color--white typography__text-shadow">
              {heading}
            </h1>
          </div>
        </div>
        <div className="page-banner">
          <div className="page-banner__wrapper swiper-wrapper">
            <div className="page-banner__slide swiper-slide">
              <div className="page-banner__slide-background  page-banner__slide-background--single">
                <div className={`page-banner__background-image ${bgImage} `} />
              </div>
              <div className="page-banner__gradient page-banner__gradient--top" />
              <div className="page-banner__gradient page-banner__gradient--article-cover" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SliderBanner
