import React, { useRef } from "react"
import SwiperCore, { Navigation, Autoplay } from "swiper"
import { Swiper as SwiperReact, SwiperSlide } from "swiper/react"
import { CareersSliderBannerTypes } from "../types"
import RenderMediaType from "./render_media_type"

const CareersSliderBanner: React.FC<CareersSliderBannerTypes> = ({
  banners,
  loopingVidBanners,
}) => {
  SwiperCore.use([Navigation, Autoplay])
  const prevRef = useRef<HTMLDivElement | null>(null)
  const nextRef = useRef<HTMLDivElement | null>(null)

  return (
    <div role="banner" className="slider-banner">
      <div className="slider-banner__home-page slider-banner__careers">
        <div className="page-banner">
          {banners.length > 1 && (
            <div className="page-banner__navigation">
              <div
                ref={prevRef}
                className="page-banner__navigation-button page-banner__prev"
              />
              <span className="navigation-button-divider"></span>
              <div
                ref={nextRef}
                className="page-banner__navigation-button page-banner__next"
              />
            </div>
          )}

          <div className="page-banner__wrapper">
            <SwiperReact
              effect="slide"
              speed={1000}
              navigation={{
                prevEl: prevRef.current ? prevRef.current : undefined,
                nextEl: prevRef.current ? prevRef.current : undefined,
              }}
              onInit={(swiper: any) => {
                swiper.params.navigation.prevEl = prevRef.current
                swiper.params.navigation.nextEl = nextRef.current
              }}
              spaceBetween={0}
              slidesPerView={1}
              loop={banners.length > 1 ? true : false}
              autoplay={{
                delay: 4000,
                disableOnInteraction: true,
              }}
            >
              {banners.length >= 1 &&
                banners.map(
                  ({
                    id,
                    image,
                    name,
                    link_text,
                    link,
                    video_id,
                    looping_vid_id,
                  }) => {
                    return (
                      <SwiperSlide key={id}>
                        <div className="page-banner__slide swiper-slide">
                          <div className="page-banner__slide-background homepage">
                            <>
                              <RenderMediaType
                                uploadedVideoId={looping_vid_id}
                                bannerImage={image}
                                vimeoId={video_id}
                                bannerName={name}
                                bannerLinkText={link_text}
                                bannerLink={link}
                                loopingVideoBanners={loopingVidBanners}
                              />
                            </>
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  }
                )}
            </SwiperReact>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CareersSliderBanner
