export const REGION_DICTIONARY = {
  nzSouth: {
    id: "nz-south",
    dataId: "new-zealand-south",
    slug: "new-zealand",
  },
  nzNorth: {
    id: "nz-north",
    dataId: "new-zealand-north",
    slug: "new-zealand",
  },
  nz: {
    id: "nz",
    dataId: "new-zealand",
    slug: "new-zealand",
  },
  nsw: {
    id: "aus-nsw",
    dataId: "new-south-wales",
    slug: "new-south-wales",
  },
  vicTas: {
    id: "aus-vic-tas",
    dataId: "victoria-&-tasmania",
    slug: "victoria-tasmania",
  },
  wa: {
    id: "aus-wa",
    dataId: "western-australia",
    slug: "west-australia",
  },
  qld: {
    id: "aus-qld",
    dataId: "queensland",
    slug: "queensland",
  },
  sa: {
    id: "aus-sa",
    dataId: "south-australia",
    slug: "south-australia",
  },
  act: {
    id: "aus-act",
    dataId: "act",
    slug: "australian-capital-territory",
  },
  nt: {
    id: "aus-nt",
    dataId: "northern-territory",
    slug: "northern-territory",
  },
}

export const defaultGrey = "#DEE2E5"
export const activeGreen = "#009946"
