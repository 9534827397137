import React from "react"

const IconFacebook = ({ color = "#ffffff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8px"
    height="15px"
    viewBox="0 0 8 15"
    className="svg_icon svg_facebook"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="icon/square/facebook_off"
        transform="translate(-20.000000, -16.000000)"
      >
        <rect
          id="Rectangle"
          stroke={color}
          fill="none"
          x="0.5"
          y="0.5"
          width="46"
          height="46"
        ></rect>
        <path
          d="M25.0585923,31 L25.0585923,24.1640615 L27.3632808,24.1640615 L27.6953115,21.4882808 L25.0585923,21.4882808 L25.0585923,19.7890615 C25.0585923,19.3984346 25.1399731,19.0826846 25.3027346,18.8417962 C25.4654962,18.6009115 25.8203115,18.4804692 26.3671885,18.4804692 L27.7734385,18.4804692 L27.7734385,16.0976577 C27.4348923,16.0716154 27.0931,16.0488269 26.7480462,16.0292962 C26.4029923,16.0097654 26.0612,16 25.7226577,16 C24.7070269,16 23.8802115,16.3027308 23.2421877,16.9082038 C22.6041635,17.5136731 22.2851562,18.3893154 22.2851562,19.5351577 L22.2851562,21.4882808 L20,21.4882808 L20,24.1640615 L22.2851562,24.1640615 L22.2851562,31 L25.0585923,31 Z"
          id="Fill-1"
          fill={color}
        ></path>
      </g>
    </g>
  </svg>
)

export default IconFacebook
