import React from "react"
import Column from "../../modules/grid/column"
import Row from "../../modules/grid/row"
import Spacer from "../../layout/spacer"

const SkelletonRow = () => {
  return (
    <Row columnCount={3}>
      <Column>
        <article className="tile tile__skelleton tile tile__single">
          <div className="tile__image-cell">
            <div className="tile__image-wrapper" />
          </div>
          <div className="tile__content-cell">
            <section className="tile__info">
              <Spacer deviceSize="n" />
              <div className="tile__skelleton-title" />
              <div className="tile__skelleton-title-second" />
            </section>
          </div>
        </article>
        <Spacer deviceSize="xxs" deviceLayout="row" />
      </Column>
      <Column>
        <article className="tile tile__skelleton tile tile__single">
          <div className="tile__image-cell">
            <div className="tile__image-wrapper" />
          </div>
          <div className="tile__content-cell">
            <section className="tile__info">
              <Spacer deviceSize="n" />
              <div className="tile__skelleton-title" />
              <div className="tile__skelleton-title-second" />
            </section>
          </div>
        </article>
        <Spacer deviceSize="xxs" deviceLayout="row" />
      </Column>
      <Column>
        <article className="tile tile__skelleton tile tile__single">
          <div className="tile__image-cell">
            <div className="tile__image-wrapper" />
          </div>
          <div className="tile__content-cell">
            <section className="tile__info">
              <Spacer deviceSize="n" />
              <div className="tile__skelleton-title" />
              <div className="tile__skelleton-title-second" />
            </section>
          </div>
        </article>
        <Spacer deviceSize="xxs" deviceLayout="row" />
      </Column>
    </Row>
  )
}

const Skelleton: React.FC = () => {
  return (
    <>
      <SkelletonRow />
      <SkelletonRow />
      <SkelletonRow />
      <SkelletonRow />
    </>
  )
}

export default Skelleton
