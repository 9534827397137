import React from "react"
import Spacer from "../../layout/spacer"

interface Props {
  isExpanded: boolean
  handleOnClick: (e) => void
  isLoading: boolean
  results: number
}

const FilterResultsCount: React.FC<Props> = ({
  handleOnClick,
  isExpanded,
  isLoading,
  results,
}) => {
  return (
    <>
      {isExpanded && (
        <div className="filter-results-count-wrapper">
          <Spacer desktopOnly desktopSize="xxs" />
          <button
            onClick={handleOnClick}
            className={`filter-results-count ${
              isExpanded
                ? "filter-results-count--show"
                : "filter-results-count--hide"
            }`}
          >
            <span>
              <h2
                className="typography__display--06 typography__color--green"
                style={{ position: "relative" }}
              >
                <small
                  className={`filter-results-count--loader ${
                    isLoading
                      ? "filter-results-count--loader-loading"
                      : "filter-results-count--loader-done"
                  }`}
                >
                  {isLoading ? "Loading" : `${results} Results`}
                </small>
              </h2>
            </span>
          </button>
          <Spacer desktopOnly desktopSize="s" />
        </div>
      )}
    </>
  )
}

export default FilterResultsCount
