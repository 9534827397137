import React, { useEffect, useState } from "react"

import {
  renderHtmlString,
  truncateText,
  strippedString,
  isDesktop,
} from "../../../../helpers"
import Spacer from "../../../layout/spacer"
import classnames from "classnames"
import ToggleCta from "../../../cta/toggle_cta"
import { FeaturedLocationTileTypes } from "../types"

const FeaturedLocationTile: React.FC<FeaturedLocationTileTypes> = ({
  toggleCtaLabel,
  handleToggleCtaClick,
  name,
  details,
  image_large,
  position,
}) => {
  const Cta = () => {
    return (
      <ToggleCta
        label={toggleCtaLabel ? toggleCtaLabel : ""}
        modifierClass="tile__cta--location"
        onClick={handleToggleCtaClick}
      />
    )
  }

  const [isDesktopDetector, setIsDesktopDetector] = useState(isDesktop())
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (isDesktop()) setIsDesktopDetector(true)
      else {
        setIsDesktopDetector(false)
      }
    })

    return () => {
      window.removeEventListener("resize", () => null)
    }
  }, [])

  const h1Classnames = classnames("typography__display--04", {
    "typography__display--06": !isDesktopDetector,
  })

  return (
    <article
      className="tile tile__feature tile__feature--location"
      id={`${position}`}
    >
      <div className="tile__image-cell">
        <picture className="tile__image-wrapper">
          <img className="tile__image" src={`${image_large}`} alt={name} />
        </picture>
      </div>
      <div className="tile__content-cell">
        <Spacer deviceSize="n" />
        {name && (
          <h1
            className={`${h1Classnames} typography__weight--700  typography__color--navy tile__location-name--feature"
              }`}
          >
            {truncateText(name, 67)}
          </h1>
        )}
        <Spacer deviceSize="xs" />
        {details && (
          <div
            className="tile__overview typography__color--mid-grey"
            dangerouslySetInnerHTML={renderHtmlString(
              truncateText(strippedString(details), 140)
            )}
          />
        )}
        <Cta />
      </div>
    </article>
  )
}

export default FeaturedLocationTile
