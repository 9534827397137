import React from "react"
import ContentPage from "../../layout/content_page"
import EmployeeTile from "../../modules/tile/employee"
import Grid from "../../modules/grid"
import Row from "../../modules/grid/row"
import { PersonTileTypes } from "../../modules/tile/types"

export interface EmployeeTypes {
  intro: string
  people: PersonTileTypes[]
}

const ExecutiveTeam: React.FC<EmployeeTypes> = ({ people }) => {
  return (
    <ContentPage>
      <Grid modifierClass="grid--pos-static device-content-padding">
        <Row columnCount={3}>
          {people.map((person) => {
            const {
              executive,
              id,
              name,
              teaser,
              profile_pics,
              summary,
              bio,
              linkedin_link,
              job_title,
              quote,
            } = person
            return (
              <EmployeeTile
                key={`executive-team-employee${id}`}
                id={id}
                name={name}
                teaser={teaser}
                profile_pics={profile_pics}
                bio={bio}
                summary={summary}
                linkedin_link={linkedin_link}
                executive={executive}
                job_title={job_title}
                quote={quote}
              />
            )
          })}
        </Row>
      </Grid>
    </ContentPage>
  )
}

export default ExecutiveTeam
