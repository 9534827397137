import React from "react"
import moment from "moment"
import classnames from "classnames"

interface NewsMetadataTypes {
  readIn?: string
  date: string
  darkBg?: boolean
}

const NewsMetadata: React.FC<NewsMetadataTypes> = ({
  readIn,
  date,
  darkBg,
}) => {
  const formattedDate = moment(date).format("DD.MM.YY")

  const colorModifier = classnames({
    ["typography__color--dark-grey"]: !darkBg,
    ["typography__color--white"]: darkBg,
  })

  return (
    <>
      <div className="tile__info-col tile__info--date">
        <h2
          className={`typography__caption--02 typography__weight--400 typography__text-shadow ${colorModifier}`}
        >
          {formattedDate}
        </h2>
      </div>
      {readIn && (
        <div className="tile__info-col tile__info--read-in">
          <h2
            className={`typography__caption--02 typography__weight--400 typography__text-shadow ${colorModifier}`}
          >
            {readIn}
          </h2>
        </div>
      )}
    </>
  )
}

export default NewsMetadata
