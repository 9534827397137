import React, { useState } from "react"
import IconButton from "../../elements/icon_button"
import IconClose from "../../../images/svgs/icon_close"
import NavDropdownLink from "../../cta/nav_dropdown_link"
import PrimaryCta from "../../cta/primary_cta"
import Link from "../../cta/link"
import { NavDropdownTypes, SecondaryNavItemTypes } from "../../../types"
import { minDesktopSize } from "../../../variables"
import useDesktopDetector from "../../../hooks/useDesktopDetector"
import Spacer from "../../layout/spacer"
import classnames from "classnames"
import { placeholderImage } from "../../__mocks__/placeholder"

import WhereWeWorkMap from "./where_we_work_map"

const NavDropdown: React.FC<NavDropdownTypes> = ({
  contentFade,
  hasParentItemBeenHovered,
  image,
  isStorybookExample,
  onCloseDropdownClick,
  parentNavName,
  parentNavSlug,
  parentNavOverview,
  secondaryNavLinks,
  ctaLabel,
  ctaLink,
}) => {
  const isDesktop = useDesktopDetector()

  const secondaryNavItemClassnames = classnames({
    "navigation__dropdown-link-items--1": secondaryNavLinks.length === 1,
    "navigation__dropdown-link-items--2": secondaryNavLinks.length === 2,
    "navigation__dropdown-link-items--4": secondaryNavLinks.length === 4,
    "navigation__dropdown-link-items--5": secondaryNavLinks.length === 5,
    "navigation__dropdown-link-items--6": secondaryNavLinks.length === 6,
    "navigation__dropdown-link-items--many": secondaryNavLinks.length >= 7,
  })

  const secondaryLinkLinkClassnames = classnames({
    "navigation__dropdown-link-list--4":
      secondaryNavLinks.length === 2 || secondaryNavLinks.length === 4,
  })

  const [secondaryImage, setSecondaryImage] = useState(image)
  const [activeLink, setActiveLink] = useState("")
  const [mouseLeft, setMouseLeft] = useState(false)
  const handleMouseEnter = (e, img) => {
    const { id } = e.target

    setActiveLink(id)
    setMouseLeft(false)

    if (img) {
      setSecondaryImage(img)
    } else {
      setSecondaryImage(placeholderImage)
    }
  }

  const [activeMapRegion, setActiveMapRegion] = useState("")
  const handleActiveRegion = (e) => {
    setActiveMapRegion(e)
  }

  const [highlightMap, setHighlightMap] = useState(false)

  const whereWeWorkActive = parentNavName === "Where we work"

  const renderNavLinks = (parentNavSlug: string) => {
    return (
      <ul
        className={`grid grid-direction--row navigation__dropdown-link-list ${secondaryLinkLinkClassnames}`}
      >
        {!isDesktop && ctaLabel && ctaLink && (
          <li
            className="grid-column navigation__dropdown-link-item"
            style={{ marginTop: "30px" }}
          >
            <PrimaryCta
              label={ctaLabel}
              link={ctaLink}
              modifierClass="primary-cta--sub-nav"
            />
          </li>
        )}
        {secondaryNavLinks !== null &&
          secondaryNavLinks.map(
            (
              {
                name,
                link,
                hover_image_url,
                generic_page_slug,
              }: SecondaryNavItemTypes,
              i
            ) => {
              const linkUrl =
                generic_page_slug && parentNavSlug
                  ? `/${parentNavSlug}/${generic_page_slug}`
                  : link
              return (
                <li
                  onMouseOver={(e) => handleMouseEnter(e, hover_image_url)}
                  onMouseOut={() => setMouseLeft(true)}
                  key={i}
                  className={`grid-column navigation__dropdown-link-items ${
                    activeMapRegion === name.toLowerCase().replace(/\s/g, "-")
                      ? "active"
                      : ""
                  } ${secondaryNavItemClassnames}`}
                >
                  {isDesktop ? (
                    <>
                      <NavDropdownLink
                        slug={linkUrl}
                        name={name}
                        openInNewTab={
                          name.toLowerCase() === "apply now" ? true : false
                        }
                      />
                      <Spacer desktopSize="xs" />
                    </>
                  ) : (
                    <Link slug={linkUrl} name={name} />
                  )}
                </li>
              )
            }
          )}
      </ul>
    )
  }

  if (isDesktop) {
    return (
      <div
        className={`navigation__dropdown ${
          hasParentItemBeenHovered || isStorybookExample ? "show" : "hide"
        }`}
      >
        <section
          className={`grid grid-direction--row ${
            hasParentItemBeenHovered || isStorybookExample ? "show" : "hide"
          }`}
        >
          <div
            className={`grid-column navigation__dropdown--desktop-content  ${
              contentFade || isStorybookExample ? "fade-in" : "fade-out"
            } `}
          >
            <div className="grid grid-direction--row">
              <IconButton
                modifierClass="button__modal-dropdown--close navigation__dropdown--desktop-content"
                onClick={onCloseDropdownClick}
              >
                <IconClose />
              </IconButton>
              <div
                className={`grid-column  ${
                  whereWeWorkActive
                    ? "navigation__dropdown-grid-column--map"
                    : "navigation__dropdown-grid-column--image"
                } navigation__dropdown--desktop-content`}
              >
                <Spacer desktopSize="s" />

                {whereWeWorkActive ? (
                  <WhereWeWorkMap
                    activeRegion={(e) => handleActiveRegion(e)}
                    activeLink={activeLink}
                    nothingTouched={mouseLeft}
                    lightUpMap={highlightMap}
                  />
                ) : (
                  <picture className="navigation__dropdown-image-wrapper">
                    <source
                      srcSet={secondaryImage}
                      media={`(min-width:${minDesktopSize}px)`}
                    />
                    <img
                      className="navigation__dropdown-image"
                      src={secondaryImage}
                      alt=""
                    />
                  </picture>
                )}
              </div>

              <div className="grid-column navigation__dropdown--desktop-content navigation__dropdown--right-col">
                <Spacer desktopSize="s" />
                <h1 className="typography__display--04 typography__color--navy">
                  {parentNavName}
                </h1>
                <Spacer desktopSize="s" />
                {whereWeWorkActive && (
                  <>
                    <div className="navigation__dropdown-cta-block">
                      <p className="typography__body--01">
                        Ventia is one of the largest essential services
                        providers in Australia and New Zealand. Discover all our
                        projects and news on our interactive maps.
                      </p>

                      {ctaLabel && ctaLink && (
                        <PrimaryCta
                          label={ctaLabel}
                          link={ctaLink}
                          modifierClass="primary-cta__size--md primary-cta--sub-nav"
                          onMouseEnter={() => setHighlightMap(true)}
                          onMouseLeave={() => setHighlightMap(false)}
                        />
                      )}
                    </div>

                    <Spacer desktopSize="xs" />
                  </>
                )}

                {parentNavOverview && (
                  <>
                    <div className="navigation__dropdown-cta-block">
                      <p className="typography__body--01 typography__color--navy  navigation__dropdown-copy">
                        {parentNavOverview}
                      </p>
                      {ctaLabel && ctaLink && (
                        <PrimaryCta
                          label={ctaLabel}
                          link={ctaLink}
                          modifierClass="primary-cta__size--md primary-cta--sub-nav"
                        />
                      )}
                    </div>
                    <Spacer desktopSize="xs" />
                  </>
                )}
                <div className="grid grid-direction--row navigation__dropdown-link-list">
                  {renderNavLinks(parentNavSlug)}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  } else {
    return renderNavLinks(parentNavSlug)
  }
}

export default NavDropdown
