// * Returns matached results based on filters and article keywrods
function getKeywords(array) {
  return array.map((i) => i.name)
}

export const filterOutEmptyIndex = (array) => {
  return array.filter((i) => i.length)
}

export const createUniqueSet = (array) => {
  return new Set(array)
}

function concatonateKeywords(array1, array2, array3) {
  return array1.concat(array2).concat(array3).flat()
}

export const filterArticles = (articles, checkedFilters) => {
  return articles.filter((article) => {
    const industryKeywords = filterOutEmptyIndex(
      getKeywords(article.industries)
    )
    const categoryKeywords = filterOutEmptyIndex(
      getKeywords(article.filter_categories)
    )
    const servicesKeywords = filterOutEmptyIndex(getKeywords(article.services))
    const concatKeywords = concatonateKeywords(
      industryKeywords,
      categoryKeywords,
      servicesKeywords
    )

    const uniqueKeywords = createUniqueSet(concatKeywords)
    const keywords = Array.from(uniqueKeywords)

    const found = keywords.some((keyword) => {
      return checkedFilters.includes(keyword)
    })

    return found
  })
}

export const renderUsefullKeywords = (categoryArray: string[]): string[] => {
  const newKeywords = filterOutEmptyIndex(categoryArray).flat()
  const keywordNames = newKeywords.map((i) => i.name)
  return Array.from(new Set(keywordNames))
}
