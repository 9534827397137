import React, { useState } from "react"
import IconCtaArrow from "../../../images/svgs/icon_cta_arrow"
import IconButton from "../../elements/icon_button"
import NavDropdown from "./nav_dropdown"
import useDesktopDetector from "../../../hooks/useDesktopDetector"
import classnames from "classnames"
import { NavItemTypes } from "../../../types"
import { placeholderImage } from "../../__mocks__/placeholder"

const NavItem: React.FC<NavItemTypes> = ({
  navData,
  storybookNavData,
  setIsSearchDropdownVisible,
  isStorybookExample,
  contentFade,
  currentNavItemHovered,
}) => {
  const [isMobileMenuItemActive, setIsMobileMenuItemActive] = useState(false)
  const [expandMobileMenuItem, setExpandMobileMenuItem] = useState(false)
  const [mousedOver, setIsMousedOver] = useState(false)
  const splitPathname: string[] = location.pathname.split("/")
  const isActive: boolean = splitPathname.indexOf(navData.slug) > -1

  function mobileMenuItemToggler(e: React.SyntheticEvent<HTMLElement>) {
    e.preventDefault()
    setIsMobileMenuItemActive(!isMobileMenuItemActive)
    setIsMousedOver(false)

    if (navData.has_drop_down) {
      setExpandMobileMenuItem(!expandMobileMenuItem)
    }
  }

  const isDesktop = useDesktopDetector()

  function handleNavItemMouseEnter(e) {
    currentNavItemHovered(e)
    if (isDesktop) {
      setIsMousedOver(true)
      setIsSearchDropdownVisible(false)
    } else return null
  }

  function handleNavItemMouseLeave() {
    if (isDesktop) {
      setIsMousedOver(false)
    } else return
  }

  const navigationListItemClassnames = classnames({
    "navigation__list-item--primary": true,
    expand: expandMobileMenuItem,
    contract: !expandMobileMenuItem,
  })

  const expanderClassnames = classnames({
    icon_button__expander: true,
    "icon_button__mobile-nav": true,
  })

  const handleImage = (img: string) => {
    if (img) {
      return img
    } else {
      placeholderImage
    }
  }

  return (
    <React.Fragment>
      <li
        className={navigationListItemClassnames}
        id={navData.id}
        onMouseOver={() => handleNavItemMouseEnter(navData.slug)}
        onMouseOut={handleNavItemMouseLeave}
      >
        {navData.no_link ? (
          <span
            className={`navigation__link--primary ${
              mousedOver || isStorybookExample ? "active" : "inactive"
            } ${isActive ? "slug-active" : ""} ${
              contentFade ? "fade-in" : "fade-out"
            }`}
          >
            {navData.name}
          </span>
        ) : (
          <a
            href={`/${navData.slug}`}
            className={`navigation__link--primary ${
              mousedOver || isStorybookExample ? "active" : "inactive"
            } ${isActive ? "slug-active" : ""} ${
              contentFade || isStorybookExample ? "fade-in" : "fade-out"
            }`}
          >
            {navData.name}
          </a>
        )}
        {navData.has_drop_down ? (
          <IconButton
            modifierClass={expanderClassnames}
            isToggled={isStorybookExample ? true : isMobileMenuItemActive}
            onClick={mobileMenuItemToggler}
          />
        ) : (
          <IconButton
            modifierClass="icon_button__arrow icon_button__mobile-nav"
            isAnchorLink
            anchorLink={navData.slug}
          >
            <IconCtaArrow />
          </IconButton>
        )}
        {navData.has_drop_down && (
          <NavDropdown
            contentFade={contentFade}
            onCloseDropdownClick={handleNavItemMouseLeave}
            hasParentItemBeenHovered={mousedOver}
            parentNavName={navData.name}
            parentNavSlug={navData.slug}
            parentNavOverview={navData.overview}
            secondaryNavLinks={navData.published_nav_items}
            isMobileNavItemActive={isMobileMenuItemActive}
            image={handleImage(navData.image_url)}
            ctaLink={navData.cta_path}
            ctaLabel={navData.cta_text}
          />
        )}

        {isStorybookExample && storybookNavData && (
          <NavDropdown
            contentFade={true}
            onCloseDropdownClick={handleNavItemMouseLeave}
            hasParentItemBeenHovered={mousedOver}
            parentNavName={storybookNavData.name}
            parentNavSlug={storybookNavData.slug}
            parentNavOverview={storybookNavData.overview}
            secondaryNavLinks={storybookNavData.published_nav_items}
            isMobileNavItemActive={true}
            isStorybookExample={isStorybookExample}
            image={handleImage(storybookNavData.image_url)}
            ctaLabel=""
            ctaLink="/"
          />
        )}
      </li>
    </React.Fragment>
  )
}

export default NavItem
