import React from "react"
import Link from "./link"
import Spacer from "../../../layout/spacer"
import { FilterTypes } from "../../../modules/filters/types"

interface LetterRange {
  handleSlug: (e: string) => string
  defaultList: FilterTypes[]
  filteredList: FilterTypes[]
}

const LetterRange: React.FC<LetterRange> = ({
  handleSlug,
  defaultList,
  filteredList,
}) => {
  return (
    <section className="list-module">
      <div className="list-module__content">
        <ul className="list-module__grid">
          {filteredList.length === 0
            ? defaultList !== null &&
              defaultList.map(({ name, slug }, i) => {
                if (slug) {
                  return (
                    <li key={i} className="list-module__column">
                      <Link slug={handleSlug(slug)} name={name} />
                      <Spacer deviceSize="xs" />
                    </li>
                  )
                } else return null
              })
            : filteredList.map(({ name, slug }, i) => {
                if (slug) {
                  return (
                    <li key={i} className="list-module__column">
                      <Link slug={handleSlug(slug)} name={name} />
                      <Spacer deviceSize="xs" />
                    </li>
                  )
                } else return null
              })}
        </ul>
      </div>
    </section>
  )
}

export default LetterRange
