import React from "react"
import TabbedContent from "../../modules/tabbed_content"
import ArticleListing from "../../modules/article_listing"
import { FilterTypes } from "../../modules/filters/types"
import LinkedInFeed from "../../modules/linkedin_feed"
import { LinkedInFeedArticleTypes } from "../../modules/linkedin_feed/types"

interface Props {
  industries: FilterTypes[]
  services: FilterTypes[]
  categories: FilterTypes[]
  locations: FilterTypes[]
  newsTotal: number
  insightsTotal: number
  linkedInFeed: LinkedInFeedArticleTypes[]
}

export const perPageLimit = 9
const newsEndpoint = "/api/news"
const noLimitNewsEndpoint = "/api/no-limit-news"

const insightsEndpoint = "/api/insights"
const noLimitInsightsEndpoint = "/api/no-limit-insights"

const NewsAndInsights: React.FC<Props> = ({
  industries,
  services,
  locations,
  categories,
  newsTotal,
  insightsTotal,
  linkedInFeed,
}) => {
  const content = [
    {
      id: 0,
      tab: {
        label: "News",
      },
      component: (
        <ArticleListing
          articleTotal={newsTotal}
          articleType="news_article"
          currentPath="/news-and-insights"
          endpoint={newsEndpoint}
          noLimitEndpoint={noLimitNewsEndpoint}
          industries={industries}
          services={services}
          categories={categories}
          locations={locations}
        />
      ),
      filteredComponent: null,
      count: newsTotal,
      isDisabled: false,
    },
    {
      id: 1,
      tab: {
        label: "Insights",
      },
      component: (
        <ArticleListing
          articleTotal={insightsTotal}
          articleType="news_article"
          currentPath="/news-and-insights"
          endpoint={insightsEndpoint}
          noLimitEndpoint={noLimitInsightsEndpoint}
          industries={industries}
          services={services}
          categories={categories}
          locations={locations}
        />
      ),
      filteredComponent: null,
      count: insightsTotal,
      isDisabled: false,
    },
  ]

  return (
    <>
      <TabbedContent
        modifierClass="news-and-insights"
        modifierClassTwo="device-content-padding"
        isNewsInsightsTab={true}
        content={content}
        isContentLoading={false}
        disablingAllTabs={false}
        isFilterList={false}
      />
      {linkedInFeed && linkedInFeed.length >= 1 && (
        <LinkedInFeed feed={linkedInFeed} />
      )}{" "}
    </>
  )
}

export default NewsAndInsights
