import React from "react"
import classnames from "classnames"
import { motion } from "framer-motion"
import { renderHtmlString } from "../../helpers"

interface ContentPage {
  isHtmlString?: boolean
  intro?: string
  children?: any
  modifierClass?: string
}

const animateInUp4 = {
  hidden: { opacity: 0, y: "20px" },
  visible: { opacity: 1, y: 0 },
}
const ContentPage: React.FC<ContentPage> = ({
  intro,
  isHtmlString,
  children,
  modifierClass,
}) => {
  const modifierClassnames = classnames({
    [`${modifierClass}`]: modifierClass,
  })

  return (
    <motion.section
      className={`grid content-page  ${modifierClassnames}`}
      variants={animateInUp4}
      initial="hidden"
      animate="visible"
      transition={{
        default: {
          delay: 1.2,
          type: "spring",
          damping: 20,
          duration: 0.8,
          ease: "easeOut",
        },
      }}
    >
      <div className="content-page__wrapper">
        {intro && (
          <div className="content-page__inner  content-page__inner--lift-up">
            <div className="content-page__intro-text">
              {!isHtmlString ? (
                <h2 className="typography__display--05 typography__weight--400">
                  {intro}
                </h2>
              ) : (
                <h2
                  className="content-page__intro-text--html"
                  dangerouslySetInnerHTML={renderHtmlString(intro)}
                />
              )}
            </div>
          </div>
        )}
        {children}
      </div>
    </motion.section>
  )
}

export default ContentPage
