import React from "react"
import classnames from "classnames"

import { handleScrollTo } from "../../../helpers"
import useDesktopDetector from "../../../hooks/useDesktopDetector"

interface AnchoredSideNavTypes {
  showFollower: boolean
  isVisible: boolean

  anchorItems: anchorItem[]
  followerHeight: number
  activeId: string
}

interface anchorItem {
  id: number
  name: string
  anchor: string
}

const AnchoredSideNav: React.FC<AnchoredSideNavTypes> = ({
  showFollower,
  followerHeight,
  activeId,
  anchorItems,
  isVisible = true,
}) => {
  const isDesktop = useDesktopDetector()

  const handleActiveState = (name: string): "active" | "inactive" => {
    return name === activeId ? "active" : "inactive"
  }

  const ulModiferClassnames = classnames({
    "no-follower": !showFollower,
  })

  return (
    <>
      <aside className={`anchored-side-nav ${isVisible ? "show" : "hide"}`}>
        <nav className="anchored-side-nav__content">
          {showFollower && (
            <div className="anchored-side-nav__scroll-bar">
              <div
                className="anchored-side-nav__scroll-bar-follower"
                style={{
                  height: `${followerHeight}%`,
                }}
              />
            </div>
          )}
          {anchorItems !== null && (
            <ul className={`anchored-side-nav__links ${ulModiferClassnames}`}>
              {anchorItems.map((item) => {
                const { anchor, name } = item
                return (
                  <>
                    {isDesktop ? (
                      <li
                        onClick={() => handleScrollTo(anchor, -300)}
                        key={anchor}
                        className="anchored-side-nav__link-item"
                      >
                        <a
                          onClick={() => handleActiveState(anchor)}
                          className={`typography__cta--02  anchored-side-nav__button ${handleActiveState(
                            anchor
                          )}`}
                        >
                          {name}
                        </a>
                      </li>
                    ) : (
                      <li
                        onClick={() => handleScrollTo(anchor, 150)}
                        key={anchor}
                        className="anchored-side-nav__link-item"
                      >
                        <a
                          onClick={() => handleActiveState(anchor)}
                          className={`typography__cta--02  anchored-side-nav__button ${handleActiveState(
                            anchor
                          )}`}
                        >
                          {name}
                        </a>
                      </li>
                    )}
                  </>
                )
              })}
            </ul>
          )}
        </nav>
      </aside>
    </>
  )
}

export default AnchoredSideNav
