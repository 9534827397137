import React, { useEffect, useState } from "react"
import Cta from "../cta"

import {
  renderHtmlString,
  truncateText,
  strippedString,
  isDesktop,
} from "../../../../helpers"
import Spacer from "../../../layout/spacer"
import classnames from "classnames"
import { FeaturedGenericTileTypes } from "../types"

// Helpers
import { handleTileClick } from "../helpers"

const FeaturedTile: React.FC<FeaturedGenericTileTypes> = ({
  leftAlignedFeature,
  listing_image,
  name,
  overview,
  slug,
  currentPath,
  modifierClass,
  isCareersLanding,
}) => {
  const tileClassnames = classnames("tile tile__feature", {
    "tile__feature--left": leftAlignedFeature,
    [`${modifierClass}`]: modifierClass,
  })

  const completeSlug = currentPath ? `${currentPath}/${slug}` : slug

  const [isDesktopDetector, setIsDesktopDetector] = useState(isDesktop())
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (isDesktop()) setIsDesktopDetector(true)
      else {
        setIsDesktopDetector(false)
      }
    })

    return () => {
      window.removeEventListener("resize", () => null)
    }
  }, [])

  const h3Classnames = classnames("tile__title--backslash", {
    "typography__display--06": !isDesktopDetector,
    "typography__display--04": isDesktopDetector,
  })
  const overviewLength = isDesktopDetector ? 250 : isCareersLanding ? 200 : 80
  return (
    <article>
      <a className="tile__feature--wrapper-link" href={completeSlug}>
        <div className={tileClassnames}>
          <div className="tile__image-cell">
            <picture className="tile__image-wrapper">
              <img className="tile__image" src={listing_image} alt="" />
            </picture>
          </div>
          <div className="tile__content-cell">
            <span className="tile__title-link">
              {name && (
                <h3
                  className={`${h3Classnames} typography__weight--700  typography__color--navy
              `}
                >
                  {truncateText(name, 67)}
                </h3>
              )}
              <Spacer deviceSize="xs" />

              {overview && (
                <div
                  onClick={() => handleTileClick(`${completeSlug}`)}
                  className="typography__body--01 tile__overview typography__color--mid-grey"
                  dangerouslySetInnerHTML={renderHtmlString(
                    truncateText(strippedString(overview), overviewLength)
                  )}
                />
              )}
              <Cta completeSlug={completeSlug} />
            </span>
          </div>
        </div>
      </a>
    </article>
  )
}

export default FeaturedTile
