import React from "react"
// import Column from "../../modules/grid/column"
// import Row from "../../modules/grid/column"
// import ModularRow from "../../modules/grid/modular_row"
// import NewsInsightsTile from "../../modules/tile/news_insights"
// import MiniTile from "../../modules/tile/mini"
// import { configurationNames } from "../../modules/grid/configuration_names"
import ContentPage from "../../layout/content_page"

import Spacer from "../../layout/spacer"

const RelatedNews = ({ articles }) => {
  return (
    <>
      <Spacer deviceSize="m" />
      <div style={{ background: "#f5f5f5" }}>
        <Spacer deviceSize="m" />
        <ContentPage>
          <section id="anchor-news-section">
            <h1 className="typography__display--05 typography__weight--700">
              {articles} news
            </h1>

            <Spacer deviceSize="s" />
            <div className="horizontal-divider" />
            <Spacer deviceSize="s" />

            {/* <Row columnCount={3}>
                <NewsInsightsTile />
                <NewsInsightsTile />
                <Column>
                    <MiniTile />
                    <MiniTile />
                    <MiniTile />
                </Column>
              </Row>
      
              <Spacer deviceSize="xxs" desktopSize="s" />
            </Row> */}
          </section>
          <Spacer deviceSize="m" />
        </ContentPage>
      </div>
    </>
  )
}

export default RelatedNews
